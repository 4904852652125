import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { IStyleCustomization } from "react-dropzone-uploader";
import { theme } from "./theme";

export const common = {
  paddingTopTwo: {
    paddingTop: "2rem",
  },
};

export const dropzoneStyle: IStyleCustomization<CSSProperties> = {
  dropzone: {
    minHeight: "200px",
    borderColor: theme.palette.grey[300],
    borderStyle: "dashed",
    borderWidth: "2px",
    borderRadius: "10px",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    paddingTop: "4rem",
  },
  input: {
    display: "none",
  },
  inputLabel: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontSize: "17px",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonContainer: {
    display: "none",
  },
  preview: {
    lineHeight: "200px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontSize: "17px",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabelWithFiles: {
    justifyContent: "center",
    display: "flex",
    fontSize: "1.2rem",
    padding: "2rem 0",
  },
};
