import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { DateHelper } from "../../helpers";
import { isRequimentOrSubscriptionUser } from "../../helpers/appHelper";
import { signOut } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { useAuthUser } from "./useAuthUser";

const defaultDateHelper = new DateHelper(new Date());
const allowedUnauthenticatedPaths = [
  paths.auth.signUp,
  paths.auth.passwordReset,
  paths.products,
  paths.subscriptionIssue,
  paths.termsAndConditions,
  paths.privacyPolicy,
  paths.success,
];

const disallowedPathsWhenNoSubscripton = [paths.users.create];

export const useAuth = (dateHelper: DateHelper = defaultDateHelper) => {
  const historyRef = useRef(useHistory());
  const { current: history } = historyRef;
  const { user, checkedAuth, loading, trialDaysRemaining } = useAuthUser(
    history,
    dateHelper
  );

  useEffect(() => {
    if (!checkedAuth || loading) return;

    if (history.location.pathname.includes(paths.success)) {
      signOut();
    }

    if (
      !user &&
      !allowedUnauthenticatedPaths.includes(history.location.pathname)
    ) {
      history.push(paths.auth.login);
    }

    if (
      !isRequimentOrSubscriptionUser(user) &&
      disallowedPathsWhenNoSubscripton.includes(history.location.pathname)
    ) {
      history.goBack();
    }
  }, [checkedAuth, loading, history, user]);

  // returning these shouldn't really be this hooks job but to prevent
  // needing to call useAuthUser again when its already use in here
  return { user, checkedAuth, trialDaysRemaining };
};
