export type CollectionData = {
  values: string[];
  selected: number[];
};

export type AuthenticationScreenInfo = {
  login: boolean;
  signup: boolean;
  forgotPassword: boolean;
};

export type WireframesData = {
  pages: Page[];
  entities: Entity[];
  authInfo: AuthenticationScreenInfo;
  wireframeId: string;
};

export type Page = {
  pageId?: string;
  name: string;
  content: PageSection[];
  onMenu: boolean;
  layout: Layout;
  order: number;
};

export type PageSection = {
  entityId: string;
  action: string;
  style?: string;
  selectedFields?: string[];
  headerType?: string;
};

export type Entity = {
  active: boolean;
  name: string;
  id: string;
  fields: Field[];
  actions: string[];
};

export type Field = {
  id: string;
  type: string;
  name: string;
  fakerCall: string;
  fakeData?: string[];
  collectionData?: CollectionData[];
};

export type Layout = {
  name: string;
  config: number[];
};

export type Conflict = {
  pageId: string;
  entityId: string;
  description: string;
  keepChange: boolean;
  action?: string;
};

export interface SearchValue {
  priority?: number;
  label?: string;
  value: string;
}

export enum WireframesScreenSize {
  DESKTOP,
  TABLET,
  MOBILE,
}

export enum WireframesPage {
  LIST,
  CREATE,
  VIEW,
  EDIT,
}

export enum WireframesAuthPage {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
}
