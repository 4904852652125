import { TextField } from "@material-ui/core";
import type { ChangeHandler, FormInput } from "../types/Modify";

interface Props<D extends object> {
  config: FormInput<D>;
  handleChange: ChangeHandler;
}

export const Input = <D extends object>({
  config,
  handleChange,
}: Props<D>): JSX.Element => {
  const inputConfig = Object.assign({}, config);
  if (inputConfig.type) {
    const dateTypes = ["date", "time"];
    if (dateTypes.some((type) => inputConfig.type?.includes(type)))
      inputConfig.InputLabelProps = {
        ...inputConfig.InputLabelProps,
        shrink: true,
      };
  }

  return (
    <TextField
      {...inputConfig}
      onChange={handleChange}
      InputProps={{ title: config.name, ...inputConfig.InputProps }}
    />
  );
};
