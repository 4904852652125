import { useMemo } from "react";
import { FilterOption as Option } from "../../types/documents/TicketTemplate";
import { TableFilterConfiguration } from "@pulsion/forms-designer-2";
import { TicketTemplateFilters, TicketTemplateTaskType } from "../../types/documents/TicketTemplate";
import { taskTypeOptions } from "../../resources/options/ticketTemplates";
import { useTaskDropdownOptions } from "./useTaskDropdownOptions";
import { ticketTemplateStrings as strings } from "../../resources/strings";

export const getUseTaskFilters = (entityActionFilters: Option[]) => (
  filters: TicketTemplateFilters = {}
) => {
  const { taskType, controlTypeId } = filters;

  const controlFields = useMemo(() => ({
    type: "dropdown",
    visible: true,
    required: false,
    numberOfRows: 1,
    fqn: "",
    xs: 3 as any
  }), []);

  let outputControls: ReturnType<(filters: object) => TableFilterConfiguration> = [
    {
      ...controlFields,
      controlTypeId: "taskType",
      caption: "Task Type",
      name: "taskType",
      options: taskTypeOptions,
    },
  ];

  const { controls: dropdownControls, options } = useTaskDropdownOptions(filters);

  switch (taskType) {
    case TicketTemplateTaskType.Entity:
      outputControls = outputControls.concat({
        ...controlFields,
        controlTypeId: "actionType",
        caption: "Action Type",
        name: "actionType",
        options: entityActionFilters
      });
      break;
      case TicketTemplateTaskType.Report:
      case TicketTemplateTaskType.Users:
        const controlsToAdd = dropdownControls.map((x)=> ({
          key: x.caption,
          value: x.controlTypeId,
          text: x.caption
        }));
        outputControls = outputControls.concat({
          ...controlFields,
          controlTypeId: "controlTypeId",
          caption: strings.labels.controlTypeId,
          name: "controlTypeId",
          options: controlsToAdd
        });
  
        outputControls = outputControls.concat({
          ...controlFields,
          controlTypeId: "optionId",
          caption: strings.labels.optionId,
          name: "optionId",
          visible: Boolean(controlTypeId && options && options.length),
          options: options
        });
        break;
      default:
        break;
  }
  return { controls: outputControls };
};
