import { FormHelperText, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropdownArrow } from "../../../../components/general/DropdownArrow";
import { colours, fonts } from "../../../../styles/theme";
import { Component, DropdownOptions } from "../../../../types";
import { Label } from "../Label";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontFamily: fonts.secondary,
      color: colours.greyColour,
    },
    "& .MuiOutlinedInput-input": {
      padding: "0.6rem 1rem",
      backgroundColor: colours.inputBackgroundGrey,
    },
    "& .MuiInputBase-root": {
      fontFamily: fonts.secondary,
      fontSize: "15px",
      color: colours.greyColour,
    },
    "& .MuiOutlinedInput-root": {
      height: "2.5rem",
      "& fieldset": {
        border: "1px solid #cccccc",
      },
      "&:hover fieldset": {
        opacity: "0.4",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #cccccc",
        opacity: "0.4",
      },
    },
  },
  listItem: {
    fontFamily: fonts.primary,
  },
  noShrinkLabel: {
    transform: "translate(0.8rem, 0.8rem)",
    fontWeight: 500,
  },
}));

interface Props {
  labelText?: string;
  required?: boolean;
  inputLabel?: string;
  options: DropdownOptions;
  errorMessage?: string;
  className?: string;
  onChange?: (event: any) => void;
  value?: any;
}

const ArrowIcon = (props: any) => (
  <DropdownArrow {...props} className={props.className} />
);

export const DropdownDisplay: Component<Props> = ({
  className,
  labelText,
  inputLabel,
  value,
  required,
  onChange,
  options,
  errorMessage,
}) => {
  const classes = useStyles();

  return (
    <div aria-label="form-dropdown" className={className}>
      {labelText && (
        <span className="inputHeader">
          <Label text={labelText} required={required} />
        </span>
      )}
      <TextField
        aria-label="dropdown-input"
        fullWidth
        required={required}
        label={inputLabel}
        id="select-display"
        select
        className={classes.root}
        value={value}
        onChange={onChange}
        SelectProps={{ IconComponent: ArrowIcon }}
        InputLabelProps={{ shrink: false, className: classes.noShrinkLabel }}
        variant="outlined"
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            disabled={option.disabled}
            value={option.value}
            classes={{ root: classes.listItem }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {errorMessage ? (
        <FormHelperText error>{errorMessage}</FormHelperText>
      ) : null}
    </div>
  );
};
