import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import clsx from "clsx";
import {
  getDisplayValue,
  isNumberEven,
} from "../../../helpers/wireframesHelper";
import { constants } from "../../../resources/numerics/app";
import {
  wireframesStrings as strings,
  wireframesStrings,
} from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/list";
import { Component } from "../../../types";
import { Entity, Field } from "../../../types/Wireframes";

interface Props {
  entity: Entity;
  changePage: (actionName: string, entity: Entity, rowIndex: number) => void;
  deletable: boolean;
  readable: boolean;
  editable: boolean;
  selectedFields: string[];
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const FieldTable: Component<Props> = ({
  entity,
  changePage,
  deletable,
  readable,
  editable,
  selectedFields,
}) => {
  const classes = useStyles();

  const getTableCell = (field: Field, rowIndex: number, index: number) => {
    if (
      field.type !== strings.fieldTypes.image &&
      selectedFields.includes(field.id)
    ) {
      return (
        <TableCell
          className={classes.tableCell}
          key={index}
          onClick={() => {
            if (readable) {
              changePage(wireframesStrings.actions.read, entity, rowIndex);
            } else if (editable) {
              changePage(wireframesStrings.actions.update, entity, rowIndex);
            }
          }}
          align="left"
        >
          {getDisplayValue(field, rowIndex)}
        </TableCell>
      );
    } else {
      return null;
    }
  };

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableHeader}>
            {entity.fields.map((field, index) => {
              if (
                field.type !== strings.fieldTypes.image &&
                selectedFields.includes(field.id)
              ) {
                return (
                  <TableCell
                    className={classes.tableCell}
                    key={index}
                    align="left"
                  >
                    {field.name}
                  </TableCell>
                );
              } else {
                return null;
              }
            })}
            {deletable && (
              <TableCell className={classes.tableIconCell}></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(constants.wireframesListRowCount)].map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={clsx(
                isNumberEven(rowIndex)
                  ? classes.tableEvenRow
                  : classes.tableOddRow,
                rowIndex !== 4 && classes.tableRowBorder,
                rowIndex === 0 && classes.topBorder,
                (readable || editable) && classes.pointerCursor
              )}
            >
              {entity.fields.map((field, index) =>
                getTableCell(field, rowIndex, index)
              )}
              {deletable && (
                <TableCell className={classes.tableIconCell}>
                  <DeleteOutline className={classes.deleteIcon} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
