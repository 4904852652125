import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { wireframesStrings as strings } from "../../../resources/strings/wireframes";
import { getStyles } from "../../../styles/wireframes/fields";
import { Component } from "../../../types";

interface Props {
  label: string;
  type: string;
  initialValue: string | number | number[];
  editable: boolean;
  values: string[];
  updateValue: (value: any) => void;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const CustomDropdown: Component<Props> = ({
  label,
  type,
  initialValue,
  editable,
  values,
  updateValue,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);

  const handleChange = (event: any) => {
    if (type !== strings.fieldTypes.collectionEntity) {
      const valueArray = [event.target.value];
      updateValue(valueArray);
    } else {
      updateValue(event.target.value);
    }
    setValue(event.target.value);
  };

  return (
    <div>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <FormControl variant="outlined" fullWidth>
        <Select
          multiple={type === strings.fieldTypes.collectionEntity}
          disabled={!editable}
          labelId="wireframes-dropdown-label"
          value={value}
          onChange={handleChange}
        >
          {values.map((element, index) => (
            <MenuItem key={index} value={index}>
              {element}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
