import { Theme } from "@material-ui/core";
import { listTheme } from "../formsDesigner/listTheme";

const { table } = listTheme;
const header = table?.header;

export const getStyles = (theme: Theme) => {
  return {
    headerItem: {
      textAlign: "center" as any,
      fontSize: header?.fontSize,
      marginBottom: table?.marginBottom,
      fontFamily: header?.font,
      padding: header?.padding,
    },
    iconButton: {
      display: "inline-block",
      color: theme.palette.secondary.main,
    },
    logo: {
      height: "10vh",
      marginTop: "10vh",
    },
  };
};
