import { IEntityListApi } from "@pulsion/forms-designer-2";
import {
  deleteLibraryEntity,
  getLibraryEntities,
} from "../services/entityLibraryService";

export const entityLibraryApi: IEntityListApi = {
  getEntityRows: getLibraryEntities,
  deleteRow: deleteLibraryEntity,
};
