import { makeStyles } from "@material-ui/core/styles";
import {
  ApiContextProvider,
  ControlContextProvider,
  EntityListTableContainer,
  EntityListTableContainerProps as TemplatesListProps,
} from "@pulsion/forms-designer-2";
import clsx from "clsx";
import { htmlToText } from "html-to-text";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ticketTemplatesListApi } from "../../apis/ticketTemplatesListApi";
import { NavButton } from "../../components/general/controls/NavButton";
import { findValue } from "../../helpers";
import { getDisplayOptions } from "../../helpers/jiraTicketHelper";
import { sortTaskActionFilterOptions } from "../../helpers/projectHelper";
import { getUseTaskFilters } from "../../hooks/jiraTickets/useTaskFilters";
import { paths } from "../../navigation/paths";
import { taskTypeOptions } from "../../resources/options/ticketTemplates";
import { appStrings } from "../../resources/strings";
import { ticketTemplateStrings as strings } from "../../resources/strings/ticketTemplates";
import { listJiraTicketTemplates } from "../../services/jiraGenService";
import { listTheme } from "../../styles/formsDesigner/listTheme";
import { getStyles } from "../../styles/jiraTickets/ticketTemplatesList";
import { TicketTemplate } from "../../types/documents";
import {
  EntityActions,
  FilterOption,
  TicketTemplateTaskType,
  type TicketTemplateFilters,
} from "../../types/documents/TicketTemplate";
import { EmptyListDisplay } from "../general/EmptyListDisplay/EmptyListDisplay";

const useStyles = makeStyles((theme) => getStyles(theme));

const convertedTaskTypeOptions = getDisplayOptions(taskTypeOptions);

export const TicketTemplatesList: () => JSX.Element = () => {
  const [filterableOptions, setFilterableOptions] = useState<FilterOption[]>(
    []
  );

  const history = useHistory();
  const appendHistory = (url: string) => {
    history.push(url);
  };

  const classes = useStyles();

  useEffect(() => {
    const query = async () => {
      const optionsWithoutTasks: FilterOption[] = [];
      const optionsWithTasks: FilterOption[] = [];
      const { items: allTicketTemplates } = (await listJiraTicketTemplates(
        { page: 0, size: 0 },
        undefined,
        {}
      )) as { items: TicketTemplate[]; count: number };

      for (const action of EntityActions) {
        try {
          const ticketTemplates = allTicketTemplates.filter(
            (x) => x.entityAction === action
          );
          const count = ticketTemplates.length;

          const newOption = {
            key: action,
            value: action,
            text: `${action} (${count})`,
          };
          if (count === 0) {
            optionsWithoutTasks.push(newOption);
          } else {
            optionsWithTasks.push(newOption);
          }
        } catch (e: any) {
          console.log(
            `Could not retrieve ticket templates count for action ${action}.`
          );
        }
      }
      const filterOptions = optionsWithTasks
        .sort(sortTaskActionFilterOptions)
        .concat(optionsWithoutTasks.sort(sortTaskActionFilterOptions));
      setFilterableOptions(filterOptions);
    };
    query();
  }, []);

  const defaultDisplay = (
    <EmptyListDisplay
      onClickButton={() => appendHistory(paths.ticketTemplates.create)}
      titleText={strings.labels.emptyListDisplayTitle()}
      infoText={appStrings.labels.noDataInfo("Task Template")}
      buttonText={strings.labels.newTicketTemplate}
    ></EmptyListDisplay>
  );

  const uploadButton = (
    <NavButton
      className={clsx(classes.MuiButtonOutlined, "button-width")}
      path={paths.ticketTemplates.upload}
      color="primary"
      variant="outlined"
    >
      {strings.labels.uploadTasks}
    </NavButton>
  );

  const props: TemplatesListProps<TicketTemplate> = {
    entity: "Task Template",
    inputTheme: listTheme,
    createButtonConfig: {
      label: strings.labels.newTicketTemplate,
      path: paths.ticketTemplates.create,
    },
    clickHandler: (rowObject) => {
      appendHistory(paths.ticketTemplates.modify(rowObject.id));
    }, // should open view/edit modal
    manualPagination: true,
    deleteButtons: true,
    getViewEntityPath: (rowObject: TicketTemplate) => "",
    sortOptions: {},
    header: strings.labels.tableHeader,
    resizableColumns: true,
    tableColumns: [
      {
        id: "taskType",
        Header: "Task Type",
        accessor: ({ taskType }) =>
          findValue(taskType, convertedTaskTypeOptions),
        isSortable: false,
        isResizable: true,
      },
      {
        id: "component",
        Header: "Category",
        accessor: "component",
        isSortable: true,
        isResizable: true,
      },
      {
        id: "summary",
        Header: "Summary",
        accessor: ({ summary }: TicketTemplate) => htmlToText(summary),
        isSortable: true,
        isResizable: true,
      },
      {
        id: "description",
        Header: "Description",
        accessor: ({ description }: TicketTemplate) => htmlToText(description),
        isSortable: true,
        isResizable: true,
      },
      {
        id: "estimate",
        Header: "Estimate (hours)",
        accessor: "estimate",
        isSortable: true,
      },
    ],
    filterConfiguration: getUseTaskFilters(filterableOptions),
    handleFilterChange: (
      oldFilters: TicketTemplateFilters,
      newFilters: TicketTemplateFilters
    ): TicketTemplateFilters => {
      const emptyEntityFilters: TicketTemplateFilters = {
        actionType: "",
      };
      const emptyReportUserFilters: TicketTemplateFilters = {
        controlTypeId: "",
        optionId: "",
      };

      const taskTypeChange = oldFilters.taskType !== newFilters.taskType;

      // clear non entity related filters
      if (newFilters.taskType === TicketTemplateTaskType.Entity) {
        return {
          ...newFilters,
          ...emptyReportUserFilters,
        };
        // clear non user or report related filters
      } else if (
        newFilters.taskType === TicketTemplateTaskType.Report ||
        newFilters.taskType === TicketTemplateTaskType.Users
      ) {
        let returnFilters = {
          ...newFilters,
          ...emptyEntityFilters,
        };
        if (taskTypeChange)
          returnFilters = { ...returnFilters, ...emptyReportUserFilters };
        return returnFilters;
      } else if (!newFilters.taskType) {
        return {
          ...newFilters,
          ...emptyReportUserFilters,
          ...emptyEntityFilters,
        };
      }
      return newFilters;
    },
    appendHistory,
    defaultDisplay,
    additionalButtons: [uploadButton],
  };

  return (
    <div aria-label="ticketTemplates-list">
      <ApiContextProvider api={ticketTemplatesListApi}>
        <ControlContextProvider allowControlChanges>
          <EntityListTableContainer<TicketTemplate> {...props} />
        </ControlContextProvider>
      </ApiContextProvider>
    </div>
  );
};
