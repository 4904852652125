import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    root: {
      position: "absolute" as any,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "4px",
      width: "600px",
      backgroundColor: colours.whiteColour,
    },
    header: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      padding: theme.spacing(2),
      backgroundColor: colours.darkBlueColour,
      display: "flex",
      alignItems: "center",
    },
    headerText: {
      color: colours.whiteColour,
      width: "100%",
      fontSize: "18px",
    },
    exitButton: {
      color: colours.whiteColour,
      fontSize: "16px",
      justifyContent: "end",
    },
    logo: {
      height: "50px",
      display: "block",
      margin: "5px",
    },
    titleText: {
      color: colours.darkGreyColour,
      fontFamily: fonts.primary,
      fontSize: "19px",
      margin: "5px",
    },
    title: {
      margin: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      flexDirection: "column" as any,
      padding: theme.spacing(2),
      backgroundColor: colours.whiteColour,
    },
    content: {
      display: "flex",
      flexDirection: "column" as any,
      padding: theme.spacing(2),
      backgroundColor: colours.whiteColour,
    },
    paragraph: {
      padding: theme.spacing(1),
      color: colours.greyColour,
      fontFamily: fonts.secondary,
      fontSize: "15px",
      textAlign: "center" as any,
    },
    navigation: {
      backgroundColor: colours.whiteColour,
      borderRadius: "4px",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
    },
    legalLinks: {
      color: colours.primaryPurple,
      fontFamily: fonts.secondary,
      fontSize: "1.2rem",
      textDecoration: "underline",
      textDecorationColor: colours.primaryPurple,
    },
    label: {
      fontSize: "1.2rem",
      fontFamily: fonts.secondary,
      margin: theme.spacing(1, "0"),
    },
    trialStartPopup: {
      transform: "translate(-50%, -50%)",
      top: "40%",
      left: "20%",
      width: "40%",
    },
    trialStartHeader: {
      paddingTop: "10px",
      textAlign: "center" as any,
      fontFamily: fonts.primary,
      fontSize: "1.4rem",
    },
    trialStartText: {
      fontFamily: fonts.primary,
      fontSize: "1.2rem",
      paddingTop: "3rem",
      paddingBottom: "0.8rem",
    },
    featureItem: {
      paddingTop: "2px",
      paddingBottom: "2px",
    },
    featureItemText: {
      fontFamily: fonts.primary,
      fontSize: "1.3rem",
      color: colours.darkGreyColour,
    },
  };
};
