import {  
  CircularProgress, 
  CircularProgressProps
} from "@material-ui/core";
import { useState } from "react";
import { Component } from "../../../types";

interface Props {
  onClick?: Function;
  icon?: JSX.Element;
  spinnerProps?: CircularProgressProps;
  ButtonComponent: (onClick: any, disabled: boolean) => JSX.Element;
  customLoading?: boolean;
};

export type ButtonComponentProps = Component<{ onClick: any, disabled: boolean, children: JSX.Element }>;

export const IconActionButton: Component<Props> = ({ 
  onClick,
  icon, 
  spinnerProps,
  ButtonComponent,
  customLoading
}) => {
  const [loading, setLoading] = useState(false);

  const onClickHandler = onClick ? async () => {
    try {
      setLoading(true);
      await onClick();
    } catch (e: any) {} 
    finally {
      setLoading(false);
    }
  } : undefined;

  const isLoading = (customLoading !== undefined) ? customLoading : loading;

  return (
    <ButtonComponent onClick={onClickHandler} disabled={isLoading}>
      {isLoading 
        ? <CircularProgress aria-label="action-button-spinner" { ...spinnerProps } />
        : icon
      }
    </ButtonComponent>
  );
};
