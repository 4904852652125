import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Notification } from "../../components/general";
import { FileUpload } from "../../components/general/FileUploader/FileUpload";
import { LinkItemConfig, NavLinks } from "../../components/layout/NavLinks";
import { paths } from "../../navigation";
import { fileStrings as strings } from "../../resources/strings/files";
import { bulkUploadFiles } from "../../services/filesService";
import { getStyles } from "../../styles/general/fileUpload";
import { FileData, FileToUpload } from "../../types/documents/ImageFile";

const linkItems: LinkItemConfig[] = [
  {
    to: paths.imageLibrary.list,
    text: "Image Library",
  },
];

const useStyles = makeStyles((theme) => getStyles(theme));

export const ImageLibraryUpload = () => {
  const history = useHistory();
  const classes = useStyles();

  const [success, setSuccess] = useState(false);

  const onSubmit = async (files: FileData[]) => {
    const filesToUpload: FileToUpload[] = files.map((x) => ({
      fileName: x.fileName,
      tags: x.tags,
      data: x.data,
      extension: x.extension,
      fileType: "imageLibrary",
    }));

    await bulkUploadFiles(filesToUpload);
    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
      history.push(paths.imageLibrary.list);
    }, 2000);
  };

  const pageHeader = (
    <div className="edit-header">
      <NavLinks
        linkItems={linkItems}
        currentPageText={strings.breadcrumbs.fileUpload}
      />
      <span
        className={clsx("project-modify-buttons", classes.uploadPageHeaderSpan)}
      ></span>
    </div>
  );
  return (
    <>
      {pageHeader}
      {!success && (
        <FileUpload
          onSubmit={onSubmit}
          uploadAsBase64
          maxFiles={10}
          accept="image/*"
        />
      )}
      <Notification
        message={success ? strings.labels.imageLibraryUploadSuccess : ""}
      />
    </>
  );
};
