import { useEffect, useState } from "react";
import { getControlsForTaskDropdown } from "../../services/projectService";
import { TaskDropdownControlOption } from "../../types/documents/TicketTemplate";

export const useTaskDropdownOptions = ({ taskType, controlTypeId } : { taskType?: string, controlTypeId?: string }) => {
  const [controls, setControls] = useState<TaskDropdownControlOption[]>([]);
  const [options, setOptions] = useState<{ caption: string }[]>([]);

  useEffect(() => {
    const query = async () => {
      let controlsToAdd: TaskDropdownControlOption[] = [];
      let optionsToAdd: {caption: string }[] = [];
      if (taskType) {
        try {
          const controlsWithOptions = await getControlsForTaskDropdown(taskType) as TaskDropdownControlOption[];
          if (controlsWithOptions) controlsToAdd = controlsWithOptions;
          
          const selectedControl = controlsToAdd.find((x) => x.caption === controlTypeId || x.controlTypeId === controlTypeId);
          if (selectedControl && selectedControl.options) optionsToAdd = selectedControl.options;
          
          setControls(controlsToAdd);
          setOptions(optionsToAdd);
        } catch(e) {
          console.log("Failed to get table filter controls and options", e);
          setControls([]);
          setOptions([]);
        }
      } 
    };
    query();
  }, [taskType, controlTypeId]);

  return { controls, options };
};
