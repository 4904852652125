import React from "react";
import ReactDOM from "react-dom";
import "./assets/index.css";
import App from "./components/App";
import reportWebVitals from "./utils/reportWebVitals";

import { Wrappers } from "./components";
import { DateHelper } from "./helpers";

/*
  See readme for comment on strict mode Material UI error
*/

const dateHelper = new DateHelper(new Date());

ReactDOM.render(
  <React.StrictMode>
    <Wrappers>
      <App dateHelper={dateHelper} />
    </Wrappers>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
