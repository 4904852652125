import { makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect } from "react";
import { getStyles } from "../../../styles/wireframes/modal";
import { Component, ModalProps } from "../../../types";

const useStyles = makeStyles((theme) => getStyles(theme));

export const FullscreenModal: Component<ModalProps> = ({
  active,
  children,
  header,
  onCloseModal,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [active]);

  return active ? (
    <div className={classes.container}>
      <div className={classes.header}>
        <p className={classes.headerText}>{header}</p>
        <Close className={classes.exitButton} onClick={onCloseModal} />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  ) : (
    <></>
  );
};
