import {
  Avatar,
  Box,
  CardMedia,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import bgImage from "../../assets/requiment-bg-image.jpg";
import logo from "../../assets/requiment-login-logo.svg";
import { Link } from "../../components/general/controls";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings";
import { authStrings as strings } from "../../resources/strings/auth";
import { getStyles } from "../../styles/auth/authWrapper";
import { Component } from "../../types";
import Spacing from "../general/Spacing";

interface Props {
  children: JSX.Element | JSX.Element[];
  rightHandDisplay?: JSX.Element;
}

interface LayoutProps {
  children: JSX.Element;
  bgImage: string;
  rightHandDisplay?: JSX.Element;
  logo: string;
  legalText: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

const PageLayout: Component<LayoutProps> = ({
  children,
  bgImage,
  rightHandDisplay,
  logo,
  legalText,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="row" height="100%" minHeight="100vh">
        <Grid
          container
          className={clsx(classes.scrollable, classes.fullHeight)}
        >
          <Grid item sm={12} md={4} className={classes.form}>
            <div />
            <Grid item xs={9} classes={{ root: classes.content }}>
              <Avatar className={classes.logo} src={logo} variant="square" />
              {children}
            </Grid>
            <div />
            <div className={classes.smallPrint}>
              <span className={classes.loginSpan}>
                <Link
                  key="termsAndConditions"
                  config={{
                    controltype: "link",
                    text: strings.labels.termsandConditions,
                    to: paths.termsAndConditions,
                    className: clsx(classes.links, classes.loginLink),
                  }}
                  target={appStrings.other.linkTargetNewTab}
                />
                <div className={clsx(classes.linkSpace, classes.loginLink)}>
                  <Spacing />
                </div>
                <Link
                  key="privacyPolicy"
                  config={{
                    controltype: "link",
                    text: strings.labels.privacyPolicy,
                    to: paths.privacyPolicy,
                    className: clsx(classes.links, classes.loginLink),
                  }}
                  target={appStrings.other.linkTargetNewTab}
                />
              </span>
              <Typography className={classes.caption} variant="caption">
                <p>{legalText}</p>
              </Typography>
            </div>
          </Grid>
          {rightHandDisplay ? (
            <Grid item md>
              {rightHandDisplay}
            </Grid>
          ) : (
            <Hidden smDown>
              <Grid item md>
                <CardMedia className={classes.image} image={bgImage} />
              </Grid>
            </Hidden>
          )}
          {/* Hide background image on smaller screen sizes */}
        </Grid>
      </Box>
    </div>
  );
};

export const AuthWrapper: Component<Props> = ({
  children,
  rightHandDisplay,
}) => {
  return (
    <PageLayout
      bgImage={bgImage}
      rightHandDisplay={rightHandDisplay}
      logo={logo}
      legalText={strings.text.copyright + strings.text.legal}
    >
      <Box display="flex" flexDirection="column" alignItems={"center"}>
        {children}
      </Box>
    </PageLayout>
  );
};
