import { Theme } from "@material-ui/core";
import { common } from "./common";
import { fonts } from "./theme";

const drawerWidth = 250;

export const getStyles = (theme: Theme) => {
  return {
    ...common(theme),
    root: {
      display: "flex",
      height: "100%",
    },
    rootMobile: {
      position: "relative" as any,
      overflow: "auto",
      background: theme.palette.background.paper,
    },
    toolbar: {
      paddingRight: "1.5rem", // keep right padding when drawer closed
    },
    toolbarHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "30px 0px",
    },
    appBar: {
      boxShadow: "none",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.common.white,
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.secondary.main,
    },
    menuButtonHidden: {
      display: "none",
    },
    flexSpacer: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative" as any,
      whiteSpace: "nowrap" as any,
      overflowX: "hidden" as any,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderRightColor: theme.palette.secondary.main,
      width: drawerWidth,
    },
    mobileDrawerPaper: {
      position: "relative" as any,
      whiteSpace: "nowrap" as any,
      overflowX: "hidden" as any,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderRightColor: theme.palette.secondary.main,
      width: "350px",
    },
    drawerPaperClose: {
      overflowX: "hidden" as any,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    },
    deviceDrawerPaperClose: {
      overflowX: "hidden" as any,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "0px",
    },
    appBarSpacerContainer: {
      flex: "0 1 auto",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      display: "flex",
      flexGrow: 1,
      flexFlow: "column",
      height: "100%",
      overflow: "auto",
      backgroundColor: theme.palette.common.white,
    },
    container: {
      flex: "1 1 auto",
      paddingRight: "0px",
      paddingLeft: "0px",
      display: "flex",
      flexFlow: "column",
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column" as any,
    },
    fixedHeight: {
      height: "15rem",
    },
    media: {
      height: "25px",
      width: "185px",
    },
    menuText: {
      fontFamily: fonts.secondary,
      fontSize: "17px",
      color: theme.palette.secondary.dark,
      fontWeight: 500,
    },
    menuTextSelected: {
      fontFamily: fonts.secondary,
      fontSize: "17px",
      color: theme.palette.common.black,
      fontWeight: 500,
    },
    itemRoot: {
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
      },
      "&$selected": {
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    menuIcon: {
      minWidth: "38px",
      color: theme.palette.secondary.dark,
    },
    menuIconSelected: {
      minWidth: "38px",
      color: theme.palette.primary.main,
    },
    selected: {},
    noOverflow: {
      overflow: "hidden",
    },
    toggleButton: {
      color: theme.palette.primary.main,
    },
    menuListIndicator: {
      "&:before": {
        content: '""',
        position: "absolute",
        left: "0",
        width: "4px",
        height: "100%",
        backgroundColor: theme.palette.primary.main,
      },
    },
    defaultLogo: {
      width: "150px",
    },
    appBarLabel: {
      color: theme.palette.common.black,
      font: fonts.primary,
      fontSize: "15px",
      fontWeight: 500,
    },
    listItem: {
      display: "flex",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "16px",
      marginBottom: "0px",
      marginTop: "0px",
      paddingRight: "16px",
    },
    logoutButton: {
      textTransform: "none" as any,
      fontSize: "15px",
      fontWeight: 500,
    },
    logoutIcon: {
      margin: "10px",
      color: theme.palette.primary.main,
      marginRight: "0px",
    },
    mobileLogo: {
      maxWidth: "100px",
      maxHeight: "60px",
      marginRight: "10px",
    },
  };
};
