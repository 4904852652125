import { makeStyles } from "@material-ui/core";
import {
  wireframesTutorialSteps as tutorialSteps,
  tutorialStrings,
} from "../../../resources/strings/tutorial";
import { getStyles } from "../../../styles/wireframes/tutorial";
import { Component } from "../../../types";
import { HelpCarousel } from "../../carousel/HelpCarousel";

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

export const WireframesTutorial: Component<Props> = ({
  isOpen,
  closeModal,
}) => {
  const classes = useStyles();
  return (
    <HelpCarousel
      isModalOpen={isOpen}
      closeModal={closeModal}
      tutorialSteps={tutorialSteps}
      header={tutorialStrings.labels.wireframesTutorialHeader}
      modalClassName={classes.modal}
    ></HelpCarousel>
  );
};
