import { Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import {
  wireframesStrings as strings,
  wireframesStrings,
} from "../../../resources/strings/wireframes";
import { getStyles } from "../../../styles/wireframes/form";
import { Component } from "../../../types";
import { Entity, Field, WireframesPage } from "../../../types/Wireframes";
import { InputFields } from "../general/InputFields";
import { PageSectionHeader } from "../general/PageSectionHeader";
import { TextFields } from "../general/TextFields";

interface Props {
  entity: Entity;
  doesEntityContainAction: (actionName: string, entity: Entity) => boolean;
  changePage: (actionName: string, entity: Entity, rowIndex: number) => void;
  currentPage: WireframesPage;
  selectedData: number;
  saveFields: (fields: Field[]) => void;
  width: number;
  style: string;
  selectedFields: string[];
  headerType: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const FormPage: Component<Props> = ({
  entity,
  changePage,
  doesEntityContainAction,
  currentPage,
  selectedData,
  saveFields,
  width,
  style,
  selectedFields,
  headerType,
}) => {
  const classes = useStyles();
  const [currentFields, setCurrentFields] = useState(entity.fields);
  const { isMobile } = useScreenSizeContext();

  useEffect(() => {
    setCurrentFields(entity.fields);
  }, [entity]);

  const areFieldsPrepopulated =
    currentPage === WireframesPage.VIEW || currentPage === WireframesPage.EDIT;

  const areFieldsEditable =
    currentPage === WireframesPage.CREATE ||
    currentPage === WireframesPage.EDIT;

  const isEntityDeletable =
    doesEntityContainAction(strings.actions.delete, entity) &&
    currentPage !== WireframesPage.CREATE;

  const getPageByStyle = () => {
    const fields = currentFields.filter((field) =>
      selectedFields.includes(field.id)
    );
    const fieldProps = {
      fields,
      selectedIndex: selectedData,
      fullWidth: width === 100,
    };
    switch (style) {
      case strings.styles.form:
        return inputFields(fieldProps);
      case strings.styles.text:
        return <TextFields {...fieldProps}></TextFields>;
      default:
        return inputFields(fieldProps);
    }
  };

  const inputFields = (fieldProps: any) => {
    return (
      <InputFields
        {...fieldProps}
        editable={areFieldsEditable}
        prepopulated={areFieldsPrepopulated}
        updateFields={(updatedFields) => setCurrentFields(updatedFields)}
      ></InputFields>
    );
  };

  return (
    <div className={classes.content}>
      <PageSectionHeader
        currentPage={currentPage}
        entity={entity}
        changePage={changePage}
        doesEntityContainAction={doesEntityContainAction}
        index={selectedData}
        headerType={headerType}
        width={width}
      />
      {getPageByStyle()}
      {isMobile && isEntityDeletable && currentPage === WireframesPage.EDIT && (
        <div className={classes.deleteButtonContainer}>
          <Button
            variant="outlined"
            className={classes.mobileDeleteButton}
            onClick={() =>
              changePage(wireframesStrings.actions.list, entity, selectedData)
            }
          >
            {strings.labels.delete}
          </Button>
        </div>
      )}
    </div>
  );
};
