import {
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import type { Dropdown as TDropdown, ChangeHandler } from "../types/Modify";

import { useTagGroup } from "../../../hooks/tags";
import { findValue } from "../../../helpers";

interface Props<D extends object> {
  config: TDropdown<D>;
  handleChange: ChangeHandler;
  errorMessage?: string;
}

const loadingValue = "loading";
const loadingState = [
  { label: "Loading Tags...", value: loadingValue, disabled: true },
];

export const Dropdown = <D extends object>({
  config: input,
  handleChange,
  errorMessage,
}: Props<D>): JSX.Element => {
  const {
    name,
    label,
    value = "",
    options: inputOptions = [],
    tagConfig,
    ...config
  } = input;

  const [options, loading] = useTagGroup(
    inputOptions,
    tagConfig?.endpoint,
    tagConfig?.jwt,
    tagConfig?.tagGroup
  );

  const renderOptions = (dropdownOptions: typeof options) => {
    return dropdownOptions.map((option, index) => (
      <MenuItem
        key={`${option.value}-${index}`}
        value={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <>
      <InputLabel id={name}>{label}</InputLabel>
      <Select
        {...config}
        labelId={name}
        value={
          // this looks ugly but we have to check if the dropdown option value is available
          // to suppress a material UI warning since they won't always be immediately ready
          findValue(value as string | number, loading ? loadingState : options)
            ? value
            : ""
        }
        title={name}
        onChange={handleChange}
        name={name}
        error={!!errorMessage}
        SelectDisplayProps={{
          // this is a valid prop but typescript doesnt seem to like it
          //@ts-ignore
          "data-testid": `select-${name}`,
        }}
      >
        {renderOptions(loading ? loadingState : options)}
      </Select>
      {errorMessage ? (
        <FormHelperText error>{errorMessage}</FormHelperText>
      ) : null}
    </>
  );
};
