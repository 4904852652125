import { appStrings } from "./app";

export const wireframesStrings = {
  global: { ...appStrings },
  labels: {
    wireframes: "Wireframes",
    save: "SAVE",
    newImage: "Upload New Image",
    error: "Error, please contact an administrator",
    empty:
      "Add entities and actions to your projects to see example pages for them...",
    noPage:
      "No page found. Add a new page containing this entity and action to view it in the wireframes.",
    cancel: "CANCEL",
    delete: "DELETE",
    create: "CREATE",
    addNew: "Add Screen",
    edit: "EDIT",
    watermark: "Made in Requiment",
    logoAlt: "Custom Logo",
    list: "LIST",
    logout: "Log Out",
    login: "Log In",
    signup: "Sign Up",
    forgotPassword: "Forgot Password",
    resetPassword: "Reset Password",
    signupSuffix: "Don't have an account yet?",
    forgotPasswordLink: "Forgot Password?",
    emailAddress: "Email Address",
    password: "Password",
    loginSuffix: "Already have an account?",
    confirmPassword: "Confirm Password",
    forgotPasswordText:
      "We'll email you instructions on how to reset your password.",
    returnToLogin: "Return To Login",
    addSection: "Add Section",
    submit: "Submit",
    style: "Style",
    fields: "Fields",
    entity: "Entity",
    action: "Action",
    back: "Back",
    add: "Add",
    headerType: "Header Type",
  },
  actions: {
    list: "List",
    search: "Search",
    create: "Create",
    read: "Read",
    update: "Update",
    delete: "Delete",
  },
  headers: {
    list: (entity: string) => `${entity} List`,
    update: (entity: string) => `Update ${entity}`,
    create: (entity: string) => `Create ${entity}`,
    read: (entity: string) => `View ${entity}`,
    viewPagesModal: "Wireframe Screens",
    editPage: "Edit Page",
    createPage: "Create Page",
    conflicts: "Conflicts",
    details: "1. Details",
    selectLayout: "2. Select Layout",
    selectContent: "3. Select Content",
  },
  fieldTypes: {
    text: "Text String",
    number: "Number",
    date: "Date",
    time: "Time",
    boolean: "Boolean True or False",
    singleEntity: "Entity (Single)",
    collectionEntity: "Entity (Collection)",
    image: "Image",
    multiValue: "Multi-Value Dropdown",
  },
  textFieldTypes: {
    search: "search",
    input: "input",
    auth: "Auth",
  },
  sectionName: {
    page: "Page",
    actionGrid: "EntityActionsGrid",
    fieldGrid: "EntityFieldsGrid",
  },
  controls: {
    fieldName: "FieldName",
    fieldType: "FieldType",
    actionName: "EntityAction",
    userAccess: "AuthenticationAndUsers_AuthenticationType_UserAccess",
    forgotPassword: "AuthenticationAndUsers_LoginAndSecurity_ForgetPassword",
  },
  userAccessTypes: {
    noLogin: "No login required",
    selfService: "Login Required: Self-service sign-up",
    setByAdmin: "Login Required: User set by admin",
    organisationAD: "Login Required: Organisation AD",
  },
  titles: {
    refresh: "Refresh",
    desktop: "Dektop",
    tablet: "Tablet",
    mobile: "Mobile",
    editPage: "Edit Page",
    backToForm: "Back To Form",
    viewScreens: "View Screens",
    helpGuide: "Wireframes Help Guide",
  },
  text: {
    conflictsOne:
      "Changes have been made to the project that affect some of the pages created in the wireframes.",
    conflictsTwo:
      "Please choose whether you would like these changes to be applied to the wireframes pages. If the checkbox is left checked then the deleted entities or actions will be removed from the wireframes pages.",
  },
  styles: {
    table: "Table",
    detailedCard: "Detailed Card",
    condensedCard: "Condensed Card",
    catalogue: "Catalogue",
    form: "Form",
    text: "Text",
  },
  headerType: {
    titleAndButtons: "Title And Buttons",
    titleOnly: "Title Only",
    buttonsOnly: "Buttons Only",
    noHeader: "No Header",
  },
};
