import { Theme } from "@material-ui/core";
import { common } from "./common";

export const getStyles = (theme: Theme) => {
  return {
    ...common(theme),
    tableBorder: {
      borderTop: "solid 1px",
      borderColor: theme.palette.secondary.main,
    },
    tableHeader: {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.light,
    },
    tableOddRow: {
      backgroundColor: theme.palette.secondary.light,
    },
    tableEvenRow: {
      backgroundColor: theme.palette.common.white,
    },
    tableRowBorder: {
      borderBottom: "solid 1px " + theme.palette.secondary.main,
    },
    topBorder: {
      borderTop: "solid 1px " + theme.palette.secondary.main,
    },
    pointerCursor: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    deleteIcon: {
      color: theme.palette.error.main,
    },
    tableCell: {
      padding: "10px",
      maxWidth: "100px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      border: "none",
    },
    tableIconCell: {
      padding: "10px",
      width: "15px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      border: "none",
    },
    cardContainer: {
      margin: "10px",
    },
  };
};
