import {
  Button,
  FormHelperText,
  FormLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { InlineLink } from "../../components/general/InlineLink";
import { Loader } from "../../components/general/Loader";
import { DateHelper } from "../../helpers";
import { CookieEnum } from "../../helpers/appHelper";
import { getUserFacingErrorMessage } from "../../helpers/errorHelper";
import { useCookies } from "../../hooks/general/useCookies";
import { useForm } from "../../hooks/general/useForm";
import {
  completeNewPassword,
  resendConfirmationCode,
  signInWithDetails,
} from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import { getStyles } from "../../styles/auth/auth";
import type { CognitoUser, Component } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
  dateHelper: DateHelper;
}

interface LoginState {
  subscriptionError?: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const CustomLogin: Component<Props> = ({ user, dateHelper }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState({ challengeName: "" });
  const [success, setSuccess] = useState(false);

  const [formData, getChangeHandler] = useForm({
    email: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [cookies, setCookie] = useCookies(dateHelper);

  const history = useHistory();
  const { state } = useLocation<LoginState>();
  const { subscriptionError } = state || {};

  const handleChange = getChangeHandler();

  useEffect(() => {
    if (user || success) {
      if (success && !user) {
        setLoading(true);
      } else if (user && user.accessExpired && !user.subscriptionRef) {
        history.push(paths.products, {
          userId: user.userId,
          email: user.tenantEmail,
        });
      } else {
        history.push(paths.root);
      }
    }

    if (subscriptionError) {
      setLoading(false);
      setError(subscriptionError);
    }
  }, [user, success, subscriptionError, history]);

  const onLogin: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const result = await signInWithDetails(formData.email, formData.password);

      setLoading(false);
      setError("");

      if (result.challengeName) {
        setResult(result);
      } else {
        setSuccess(true);
      }
    } catch (e: any) {
      setSuccess(false);
      setError(getUserFacingErrorMessage(e));
      if (strings.errors.userNotConfirmed === e) {
        await resendConfirmationCode(formData.email);
        history.push(paths.auth.signUp, {
          isUnconfirmed: true,
          username: formData.email,
          password: formData.password,
        });
      }
      setLoading(false);
    }
  };

  const setPrivacyPolicyCookie = () => {
    if (cookies) {
      setCookie(CookieEnum.UpdatedPrivacyPolicy, true);
    }
  };

  const onChangePassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await completeNewPassword(result, formData.newPassword);
      setPrivacyPolicyCookie();

      setLoading(false);
      setError("");
      setResult({ challengeName: "" });

      setSuccess(true);
    } catch (e: any) {
      setLoading(false);
      setSuccess(false);
      setError(getUserFacingErrorMessage(e));
    }
  };

  const renderControl = (control: JSX.Element) => {
    return <BaseControl>{control}</BaseControl>;
  };

  const renderLoginForm = () => {
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={classes.loginHeader}
        >
          {strings.headers.login}
        </Typography>
        <form noValidate onSubmit={onLogin} autoComplete="off">
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.email}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  variant: "outlined",
                  value: formData.email,
                  fullWidth: true,
                  name: "email",
                  autoComplete: "email",
                  placeholder: strings.labels.enterYourEmailPlaceholder,
                  InputProps: { classes: { input: classes.input } },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.password}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  value: formData.password,
                  variant: "outlined",
                  type: "password",
                  fullWidth: true,
                  name: "password",
                  autoComplete: "password",
                  InputLabelProps: { className: classes.inputLabel },
                  placeholder: strings.labels.enterYourPasswordPlaceholder,
                  InputProps: { classes: { input: classes.input } },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          <Button
            type="submit"
            children={strings.labels.login}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!formData.email || !formData.password}
            className={classes.submitButton}
          />
          {error ? (
            <FormHelperText className={classes.errorText} error>
              {error}
            </FormHelperText>
          ) : null}
        </form>
        <div className={classes.paddingTopTwo}>
          <InlineLink
            className={classes.label}
            linkItems={[
              { text: strings.labels.signUpPrefix, className: classes.label },
              { text: strings.labels.signUp, to: paths.auth.signUp },
            ]}
          />
        </div>
        <div className={classes.paddingTopTwo}>
          <InlineLink
            className={classes.label}
            linkItems={[
              {
                text: strings.labels.resetPassword,
                to: paths.auth.passwordReset,
              },
            ]}
          />
        </div>
      </>
    );
  };

  const renderNewPasswordForm = () => {
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={classes.loginHeader}
        >
          {strings.headers.changePassword}
        </Typography>
        <form noValidate onSubmit={onChangePassword} autoComplete="off">
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.newPassword}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  variant: "outlined",
                  value: formData.newPassword,
                  fullWidth: true,
                  name: "newPassword",
                  type: "password",
                  autoComplete: "new-password",
                  placeholder: strings.labels.enterNewPassword,
                  InputProps: { classes: { input: classes.input } },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.confirmNewPassword}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  variant: "outlined",
                  value: formData.confirmNewPassword,
                  fullWidth: true,
                  name: "confirmNewPassword",
                  type: "password",
                  autoComplete: "confirm-password",
                  placeholder: strings.labels.confirmNewPassword,
                  InputProps: { classes: { input: classes.input } },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          <Button
            type="submit"
            children={strings.labels.confirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={formData.newPassword !== formData.confirmNewPassword}
            className={classes.submitButton}
          />
          {error ? (
            <FormHelperText className={classes.errorText} error>
              {error}
            </FormHelperText>
          ) : null}
        </form>
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline text={strings.labels.signingIn} />;
    }

    switch (result.challengeName) {
      case strings.challenges.newPassword:
        return renderNewPasswordForm();
      default:
        return renderLoginForm();
    }
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
