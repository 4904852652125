import { makeStyles } from "@material-ui/core";
import { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import type {
  DependentConfiguration,
  Mode,
  Section,
} from "../../components/general/types/Modify";
import { getRequiredFieldText } from "../../helpers/appHelper";
import {
  getDisplayOptions,
  getEntityActionDropdownOptions,
  getTagOptionsFromTaskType,
} from "../../helpers/jiraTicketHelper";
import { useTaskDropdownOptions } from "../../hooks/jiraTickets/useTaskDropdownOptions";
import { paths } from "../../navigation";
import { taskTypeOptions } from "../../resources/options/ticketTemplates";
import { ticketTemplateStrings as strings } from "../../resources/strings";
import {
  createJiraTicketTemplate,
  readJiraTicketTemplate,
  updateJiraTicketTemplate,
} from "../../services/jiraGenService";
import { colours } from "../../styles/theme";
import { Component, RouteProps } from "../../types";
import type { TicketTemplate } from "../../types/documents";
import { TicketTemplateTaskType } from "../../types/documents/TicketTemplate";
import type { ValidationConstraints } from "../../validation/validators";
import { ModifyContainerUsingServices } from "../general";
import { Dropdown } from "../general/Form/Dropdown/Dropdown";
import { NumberInput } from "../general/Form/NumberInput";
import { TextInput } from "../general/Form/TextInput";
import { Modal as CustomModal } from "../general/Modal/Modal";
import { ModalButtons } from "../general/Modal/ModalButtons";
import { TagEditor } from "./TagEditor";

export interface Props extends RouteProps {
  mode: Mode;
  enableMenu: () => void;
  disableMenu: () => void;
}

export const controlIdsWithNoAnswer = [
  "8e9fd5ec-a037-4eb6-ab2b-ab4a40c15552",
  "097eba49-71d3-4c1d-9afb-2e033ca0341a",
];

const constraints: ValidationConstraints<TicketTemplate> = {
  taskType: {
    presence: {
      allowEmpty: false,
      message: getRequiredFieldText(strings.labels.taskType),
    },
  },
  entityAction: {
    prerequisites: {
      fieldNames: ["taskType"],
      fieldValues: [TicketTemplateTaskType.Entity],
      message: getRequiredFieldText(strings.labels.actionType),
    },
  },
  controlTypeId: {
    prerequisites: {
      fieldNames: ["taskType"],
      fieldValues: [
        [TicketTemplateTaskType.Users, TicketTemplateTaskType.Report],
      ],
      message: getRequiredFieldText(strings.labels.controlTypeId),
    },
  },
  optionId: {
    prerequisites: {
      fieldNames: ["taskType", "controlTypeId"],
      fieldValues: [
        [TicketTemplateTaskType.Users, TicketTemplateTaskType.Report],
        controlIdsWithNoAnswer,
      ],
      message: getRequiredFieldText(strings.labels.optionId),
    },
  },
};

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
  },
  root: {
    position: "absolute",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    backgroundColor: colours.whiteColour,
    borderRadius: "4px",
    width: "700px",
    minHeight: "500px",
  },
  header: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    padding: theme.spacing(2),
    backgroundColor: colours.darkBlueColour,
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    color: colours.whiteColour,
    width: "100%",
    fontSize: "18px",
  },
  exitButton: {
    color: colours.whiteColour,
    fontSize: "16px",
    justifyContent: "end",
  },
  form: {
    padding: "1rem 2rem 0 1rem",
  },
}));

const Configuration: DependentConfiguration<TicketTemplate> = ({ data }) => {
  const { controls, options } = useTaskDropdownOptions(data);
  const displayControls = getDisplayOptions(
    controls.map((x) => ({
      key: x.controlTypeId,
      text: x.caption,
      value: x.controlTypeId,
    }))
  );
  const displayOptions = getDisplayOptions(
    options.map((x) => ({
      key: x.caption,
      text: x.caption,
      value: x.caption,
    }))
  );

  const { taskType } = data;

  return useMemo(
    () => [
      {
        key: "taskTypeField",
        order: 0,
        content: [
          {
            controltype: "custom",
            name: "taskType",
            Component: (props: any) => (
              <Dropdown
                {...props}
                options={getDisplayOptions(taskTypeOptions)}
                labelText={strings.labels.taskType}
                required
              />
            ),
          },
        ],
      },
      {
        key: "entityFields",
        order: 1,
        filters: [taskType === TicketTemplateTaskType.Entity],
        content: [
          {
            controltype: "custom",
            name: "entityAction",
            Component: (props: any) => (
              <Dropdown
                {...props}
                options={getEntityActionDropdownOptions()}
                labelText={strings.labels.actionType}
                required
              />
            ),
          },
        ],
      },
      {
        key: "userOrReportFields",
        order: 2,
        filters: [
          taskType === TicketTemplateTaskType.Report ||
            taskType === TicketTemplateTaskType.Users,
        ],
        content: [
          {
            controltype: "custom",
            name: "controlTypeId",
            Component: (props) => (
              <Dropdown
                {...props}
                options={displayControls}
                labelText={strings.labels.controlTypeId}
                required
              />
            ),
          },
          {
            controltype: "custom",
            name: "optionId",
            Component: (props) => {
              return displayOptions.length > 1 ? (
                <Dropdown
                  {...props}
                  options={displayOptions}
                  labelText={strings.labels.optionId}
                  required
                />
              ) : (
                <></>
              );
            },
          },
        ],
      },
    ],
    [taskType, displayControls, displayOptions]
  );
};

export const TicketTemplateModify: Component<Props> = ({
  mode,
  enableMenu,
  disableMenu,
  ...routeProps
}) => {
  const { id } = useParams<{ id: string; childId?: string }>();
  const classes = useStyles();
  const history = useHistory();

  const isCreateMode = mode === "create";

  useEffect(() => {
    disableMenu();
  }, [disableMenu]);

  if (!id && !isCreateMode) throw new Error("Ticket Template Id not set.");

  const onCloseModal = () => {
    enableMenu();
    history.push(paths.ticketTemplates.list);
  };

  const staticControls: Section<TicketTemplate>[] = [
    {
      key: "details",
      order: 3,
      content: [
        {
          controltype: "custom",
          name: "component",
          Component: (props) => (
            <TextInput
              {...props}
              labelText={strings.labels.component}
              className="editable-input"
            />
          ),
        },
        {
          controltype: "custom",
          name: "summary",
          Component: (props) => (
            <TagEditor
              {...props}
              tagSelectorOptions={getTagOptionsFromTaskType(
                props.data.taskType
              )}
              id="summary"
              labelText={strings.labels.summary}
              contentEditableClassName="task-summary"
            />
          ),
        },
        {
          controltype: "custom",
          name: "description",
          Component: (props) => (
            <TagEditor
              {...props}
              tagSelectorOptions={getTagOptionsFromTaskType(
                props.data.taskType
              )}
              id="description"
              labelText={strings.labels.description}
              contentEditableClassName="task-description"
            />
          ),
        },
        {
          controltype: "custom",
          name: "estimate",
          Component: (props) => (
            <NumberInput {...props} labelText={strings.labels.estimate} />
          ),
        },
      ],
    },
    {
      key: "submitButtons",
      order: 4,
      content: [
        {
          controltype: "custom",
          name: "modal buttons",
          Component: (props) => {
            const isCreateMode = props.mode === "create";

            const buttonConfig = {
              confirmConfig: {
                onClick: (event: any) => {},
                text: isCreateMode
                  ? strings.labels.modalCreate
                  : strings.labels.modalSave,
              },
              cancelConfig: {
                onClick: onCloseModal,
                text: strings.labels.modalCancel,
              },
            };

            if (props.onSubmit) {
              buttonConfig.confirmConfig.onClick = async (event: any) => {
                await props.onSubmit(event);
              };
            }
            return (
              <ModalButtons {...props} buttonConfiguration={buttonConfig} />
            );
          },
        },
      ],
    },
  ];

  const onUpdate = async (dataObject: any) => {
    await updateJiraTicketTemplate(dataObject);
    onCloseModal();
  };

  const onCreate = async (dataObject: any) => {
    await createJiraTicketTemplate(dataObject);
    onCloseModal();
  };

  const componentConfig = {
    staticConfig: staticControls,
    dependentConfig: Configuration,
  };

  const modifyContainer = (
    <div className={classes.form}>
      <ModifyContainerUsingServices<TicketTemplate>
        {...routeProps}
        getByIdFunction={readJiraTicketTemplate}
        createFunction={onCreate}
        updateFunction={onUpdate}
        mode={mode}
        initialData={{
          id: "",
          active: false,
          tenantId: "",
          createdDate: "",
          updatedDate: "",
          taskType: "",
          summary: "",
          description: "",
          estimate: "",
          component: "",
        }}
        componentConfiguration={componentConfig}
        constraints={constraints}
        redirectPath={paths.ticketTemplates.list}
        loaderProps={{ inline: true }}
      />
    </div>
  );

  return (
    <CustomModal
      active
      onCloseModal={onCloseModal}
      header={strings.labels.ticketTemplateModalHeader}
    >
      {modifyContainer}
    </CustomModal>
  );
};
