import { EntityListTheme } from "@pulsion/forms-designer-2";
import { colours, fonts } from "../theme";

export const listTheme: EntityListTheme = {
  colors: {
    primary: colours.primaryPurple,
    secondary: colours.secondaryPink,
    modalHeader: colours.darkBlueColour,
  },
  fonts: ["Noto Serif", "Varela Round"],
  font: {
    primary: {
      family: fonts.primary,
      color: colours.darkGreyColour,
    },
    secondary: {
      family: fonts.secondary,
      color: colours.greyColour,
      size: "15px",
    },
  },
  button: {
    fontSize: "16px",
  },
  table: {
    marginBottom: "1.5rem",
    header: {
      color: colours.darkGreyColour,
      backgroundColor: colours.inputBackgroundGrey,
      justification: "space-between",
      font: "Varela Round",
      fontSize: "19px",
      padding: "20px",
      textTransform: "none",
      fontWeight: "normal",
    },
    headerRow: {
      font: fonts.primary,
      fontSize: "14px",
      fontColor: colours.darkGreyColour,
      fontWeight: "normal",
      textAlign: "left",
      backgroundColor: colours.inputBackgroundGrey,
    },
    cell: {
      font: fonts.secondary,
      fontSize: "14px",
      fontColor: colours.greyColour,
      textAlign: "left",
      backgroundColor: "inherit",
    },
    createButton: {
      paddingRight: "20px",
      borderRadius: "4px",
      backgroundColor: colours.primaryPurple,
      width: "195px",
    },
    filterInput: {
      width: "200px !important",
    },
    columnHeaderResizing: {
      display: "inline-block",
      background: "rgba(224,224,224,1)",
      width: "3px",
      height: "100%",
      position: "absolute",
      right: "0",
      top: "0",
      transform: "translateX(50%)",
      zIndex: "1",
      touchAction: "none",
      hoverBackground: "rgba(158,158,158,1)",
    },
  },
};
