export interface CognitoUser {
  username: string;
  tenantId: string;
  tenantEmail?: string;
  userId: string;
  roleId: string;
  subscriptionRef: string;
  tenantTypeId: string;
  email: string;
  firstName: string;
  surname: string;
  accessExpired: boolean;
}

export interface Permissions {
  [key: string]: string[];
}

export interface RouteProps {
  permissions: Permissions | null;
  user: CognitoUser | null;
  jwt: string;
}

export interface UserAccess {
  entity: string;
  permission: string;
}

export interface TagConfig {
  endpoint: string;
  jwt: string;
  tagGroup: string;
}

export type DropdownOptions = Array<{
  label: string;
  value: string | number;
  disabled?: boolean;
}>;

export type SelectorOptions = Array<{
  label: string;
  value: string;
}>;

export type EventTargetValues = "value" | "checked";
export type Component<T> = (props: T) => JSX.Element;

export type { ButtonConfiguration, ModalProps } from "./Modal";
export * from "./Project";

export enum NotificationType {
  error = "error",
  info = "info",
}

export type FdNotificationMapping = {
  incomingMessage: string;
  translatedMessage: string | null;
};
