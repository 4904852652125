import { Typography, Link as UILink } from "@material-ui/core";
import { HTMLAttributeAnchorTarget } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Component } from "../../../types";
import type { Link as TLink } from "../types/Modify";

interface Props {
  config: TLink;
  target?: HTMLAttributeAnchorTarget;
}

export const Link: Component<Props> = ({ config, target = undefined }) => {
  const { text, to, color = "primary", style } = config;
  return (
    <Typography {...config}>
      <UILink
        target={target}
        style={style}
        color={color}
        component={RouterLink}
        to={to}
      >
        {text}
      </UILink>
    </Typography>
  );
};
