import {
  Box,
  Button,
  CardMedia,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import { ExitToApp as LogoutIcon, Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import appBarLogo from "../../assets/your-logo.png";
import { useScreenSizeContext } from "../../context/ScreenSizeContext";
import { wireframesStrings as strings } from "../../resources/strings";
import { getStyles } from "../../styles/wireframes/menu";
import { Component } from "../../types";
import { WireframesScreenSize } from "../../types/Wireframes";
import { MenuItem } from "../layout/listItems";

interface Props {
  children: JSX.Element;
  menuItems: MenuItem[];
  logoImageData?: string;
  logout?: () => void;
  selectedItem?: number;
}

interface AppBarProps {
  mobile: boolean;
  handleToggle?: () => void;
  logoImageData?: string;
  logout?: () => void;
}

interface MenuItemsProps {
  menuItems: MenuItem[];
  handleMenu: () => void;
  selectedItem?: number;
}

const useStyles = makeStyles((theme) => getStyles(theme));

const MenuItems: Component<MenuItemsProps> = ({
  menuItems,
  handleMenu,
  selectedItem,
}) => {
  const [selected, setSelected] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setSelected(0);
  }, [menuItems]);

  useEffect(() => {
    if (selectedItem) {
      setSelected(selectedItem);
    }
  }, [selectedItem]);

  return (
    <List>
      <div>
        {menuItems.map(({ label, Icon, onClick }, index) => {
          const isSelected = selected === index;
          const handleClick = () => {
            if (onClick) onClick();
            setSelected(index);
            handleMenu();
          };
          const Content = (
            <ListItem
              classes={{
                root: classes.itemRoot,
                selected: clsx(classes.selected, classes.menuListIndicator),
              }}
              button
              selected={isSelected}
              onClick={handleClick}
              className={classes.listItem}
            >
              {Icon && (
                <ListItemIcon
                  classes={{
                    root: isSelected
                      ? classes.menuIconSelected
                      : classes.menuIcon,
                  }}
                >
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={label}
                classes={{
                  primary: isSelected
                    ? classes.menuTextSelected
                    : classes.menuText,
                }}
              />
            </ListItem>
          );
          return (
            <React.Fragment key={`${label}-${index}`}>
              <>{Content}</>
            </React.Fragment>
          );
        })}
      </div>
    </List>
  );
};

export const AppBar: Component<AppBarProps> = ({
  mobile,
  handleToggle,
  logoImageData,
  logout,
}) => {
  const classes = useStyles();
  const useProjectLogo = !!logoImageData;
  return (
    <MuiAppBar position="absolute" className={clsx(classes.appBar)}>
      <Toolbar className={classes.toolbar}>
        <img
          alt=""
          src={useProjectLogo ? logoImageData : appBarLogo}
          className={clsx(
            mobile ? classes.mobileLogo : classes.logo,
            !useProjectLogo && classes.defaultLogo
          )}
        ></img>
        {handleToggle && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggle}
            data-testid="toggle-button"
          >
            <MenuIcon className={classes.toggleButton} />
          </IconButton>
        )}
        <Box color="inherit" className={classes.flexSpacer}></Box>
        {logout && (
          <Button className={classes.logoutButton} onClick={logout}>
            {!mobile && strings.labels.logout}
            <LogoutIcon className={classes.logoutIcon} />
          </Button>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export const WireframesMenuComponent: Component<Props> = ({
  children,
  menuItems,
  logoImageData,
  logout,
  selectedItem,
}) => {
  const classes = useStyles();
  const { screenSize, isMobile, isDesktop } = useScreenSizeContext();
  const [open, setOpen] = useState(isDesktop);
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);

  const drawerClass = () => {
    switch (screenSize) {
      case WireframesScreenSize.DESKTOP:
        return clsx(classes.drawerPaper, !open && classes.drawerPaperClose);
      case WireframesScreenSize.TABLET:
        return clsx(
          classes.drawerPaper,
          !open && classes.deviceDrawerPaperClose
        );
      case WireframesScreenSize.MOBILE:
        return clsx(
          classes.mobileDrawerPaper,
          !open && classes.deviceDrawerPaperClose
        );
    }
  };

  const handleMenu = () => {
    if (isMobile) {
      setOpen(false);
    }
  };

  return (
    <div
      className={clsx(classes.root, isMobile && classes.rootMobile)}
      role="menu"
    >
      <AppBar
        mobile={isMobile}
        handleToggle={handleToggle}
        logoImageData={logoImageData}
        logout={logout}
      />
      <Drawer
        variant="permanent"
        classes={{
          paper: drawerClass(),
        }}
        open={open}
      >
        <div className={classes.toolbarHeader}>
          <CardMedia className={clsx(classes.media)} image={"your-logo.png"} />
        </div>
        <Divider hidden />
        <MenuItems
          menuItems={menuItems}
          handleMenu={handleMenu}
          selectedItem={selectedItem}
        />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacerContainer}>
          <div className={classes.appBarSpacer} />
        </div>
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
};
