import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useScreenSizeContext } from "../../context/ScreenSizeContext";
import {
  wireframesStrings as strings,
  wireframesStrings,
} from "../../resources/strings";
import { getStyles } from "../../styles/wireframes/display";
import { Component } from "../../types";
import {
  Entity,
  Page,
  PageSection,
  WireframesPage,
} from "../../types/Wireframes";
import { FormPage } from "./pages/FormPage";
import { ListPage } from "./pages/ListPage";

interface Props {
  selectedPage?: Page;
  setSelectedPage: (page: Page) => void;
  pages: Page[];
  entities: Entity[];
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const WireframesDisplay: Component<Props> = ({
  selectedPage,
  setSelectedPage,
  pages,
  entities,
}) => {
  const classes = useStyles();
  const { isMobile } = useScreenSizeContext();
  const [pageFound, setPageFound] = useState(true);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);

  useEffect(() => {
    setPageFound(true);
  }, [selectedPage]);

  const doesEntityContainAction = (actionName: string, entity: Entity) => {
    return (
      entity.actions.includes(actionName) || findPage(actionName, entity, false)
    );
  };

  const findEntity = (section: PageSection) => {
    return entities.find((entity) => entity.id === section.entityId);
  };

  const findPage: any = (
    actionName: string,
    entity: Entity,
    nested: boolean
  ) => {
    const pagesContainingEntityAndAction = pages.filter((page) => {
      return page.content.find(
        (pageSection) =>
          pageSection.entityId === entity.id &&
          pageSection.action === actionName
      );
    });
    if (pagesContainingEntityAndAction.length < 1) {
      if (actionName === "List" && !nested) {
        return findPage("Search", entity, true);
      } else if (actionName === "Search" && !nested) {
        return findPage("List", entity, true);
      } else {
        return undefined;
      }
    } else if (pagesContainingEntityAndAction.length === 1) {
      return pagesContainingEntityAndAction[0];
    } else {
      const singleActionPage = pagesContainingEntityAndAction.find(
        (page) => page.content.length === 1
      );
      if (singleActionPage) {
        return singleActionPage;
      } else {
        const pageWithEntityAndActionFirst =
          pagesContainingEntityAndAction.find(
            (page) =>
              page.content.length &&
              page.content[0].entityId === entity.id &&
              page.content[0].action === actionName
          );
        if (pageWithEntityAndActionFirst) {
          return pageWithEntityAndActionFirst;
        } else {
          return pagesContainingEntityAndAction[0];
        }
      }
    }
  };

  const changePage = (actionName: string, entity: Entity, rowIndex: number) => {
    const page = findPage(actionName, entity, false);
    page ? setSelectedPage(page) : setPageFound(false);
    setSelectedRowIndex(rowIndex);
  };

  const renderPageSection = (
    section: PageSection,
    entity: Entity,
    width: number
  ) => {
    const pageProps = {
      entity,
      action: section.action,
      doesEntityContainAction,
      changePage,
      width,
      selectedFields: section.selectedFields
        ? section.selectedFields
        : entity.fields.map((field) => field.id),
      headerType: section.headerType
        ? section.headerType
        : strings.headerType.titleAndButtons,
    };
    const formPageProps = {
      selectedData: selectedRowIndex,
      saveFields: () => {},
      style: section.style ? section.style : strings.styles.form,
    };
    switch (section.action) {
      case wireframesStrings.actions.search:
      case wireframesStrings.actions.list:
        return (
          <ListPage
            style={section.style ? section.style : strings.styles.table}
            {...pageProps}
          />
        );
      case wireframesStrings.actions.read:
      case wireframesStrings.actions.delete:
        return formPage(pageProps, formPageProps, WireframesPage.VIEW);
      case wireframesStrings.actions.create:
        return formPage(pageProps, formPageProps, WireframesPage.CREATE);
      case wireframesStrings.actions.update:
        return formPage(pageProps, formPageProps, WireframesPage.EDIT);
    }
  };

  const formPage = (
    pageProps: any,
    formPageProps: any,
    currentPage: WireframesPage
  ) => {
    return (
      <FormPage currentPage={currentPage} {...formPageProps} {...pageProps} />
    );
  };

  if (!pageFound) {
    return (
      <div className={classes.empty}>
        <p>{strings.labels.noPage}</p>
      </div>
    );
  }

  const mobileView = (content: PageSection[]) => {
    let sectionCount = 0;
    return (
      <div className={classes.sectionContainer}>
        <div className={classes.pageColumn}>
          {content &&
            content.map((pageSection, index) => {
              const entity = findEntity(pageSection);
              const sectionIndex = sectionCount;
              sectionCount++;
              if (entity) {
                return (
                  <div
                    className={clsx(
                      classes.pageRow,
                      sectionIndex !== 0 && classes.verticalMargin
                    )}
                    key={index}
                  >
                    {renderPageSection(pageSection, entity, 100)}
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    );
  };

  const desktopAndTabletView = (selectedPage: Page) => {
    let sectionCount = 0;
    const pageSectionCount = selectedPage.layout.config.length
      ? selectedPage.layout.config.length
      : 1;
    const width = 100 / pageSectionCount;
    const fullWidth = selectedPage.layout.config.length === 1;
    return (
      <div
        className={clsx(
          classes.sectionContainer,
          !fullWidth && classes.bottomBorder
        )}
      >
        {selectedPage.content &&
          selectedPage.layout.config.map(
            (column: number, columnIndex: number) => {
              return (
                <div
                  key={columnIndex}
                  className={classes.pageColumn}
                  style={{ width: width + "%" }}
                >
                  {[...Array(column)].map((_, index) => {
                    const pageSection = selectedPage.content[sectionCount];
                    const entity = findEntity(pageSection);
                    const sectionIndex = sectionCount;
                    sectionCount++;
                    if (entity) {
                      return (
                        <div
                          className={getPageSectionClasses(
                            fullWidth,
                            sectionIndex
                          )}
                          key={index}
                        >
                          {renderPageSection(pageSection, entity, width)}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              );
            }
          )}
      </div>
    );
  };

  const getPageSectionClasses = (fullWidth: boolean, sectionIndex: number) => {
    const additionalClass = fullWidth ? classes.topMargin : classes.leftBorder;
    return clsx(classes.pageRow, sectionIndex !== 0 && additionalClass);
  };

  return (
    <>
      {selectedPage && selectedPage.content && selectedPage.content[0] ? (
        <>
          {!isMobile && (
            <div className={clsx(classes.header)}>
              <h2>{selectedPage.name}</h2>
            </div>
          )}
          {isMobile
            ? mobileView(selectedPage.content)
            : desktopAndTabletView(selectedPage)}
        </>
      ) : (
        <div className={classes.empty}>
          <p>{strings.labels.empty}</p>
        </div>
      )}
    </>
  );
};
