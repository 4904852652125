import {
  Button,
  IconButton,
  MobileStepper,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import clsx from "clsx";
import React from "react";
import logo from "../../assets/requiment-tick-icon.svg";
import { Modal as CustomModal } from "../../containers/general/Modal/Modal";
import { isMobile as isMediaTypeMobile } from "../../helpers";
import { tutorialStrings as strings } from "../../resources/strings/tutorial";
import { getStyles } from "../../styles/carousel/carousel";
import { Component } from "../../types";

const useStyles = makeStyles((theme) => getStyles(theme));

export interface TutorialStep {
  title: string;
  imgPath: string;
  imgClassName?: string;
  content: string[];
}

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  tutorialSteps: TutorialStep[];
  header: string;
  modalClassName?: string;
}

export const HelpCarousel: Component<Props> = ({
  isModalOpen,
  closeModal,
  tutorialSteps,
  header,
  modalClassName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;
  const activeTutorialStep = tutorialSteps[activeStep];

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      closeModal();
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isMobile = isMediaTypeMobile();

  return (
    <CustomModal
      active={isModalOpen}
      onCloseModal={closeModal}
      header={header}
      outerModalClassName={modalClassName}
    >
      <>
        <Paper square elevation={0} className={classes.title}>
          <img
            className={classes.logo}
            src={logo}
            alt={activeTutorialStep.title}
          />
          <Typography className={classes.titleText}>
            {activeTutorialStep.title}
          </Typography>
        </Paper>
        <div className={classes.imgContainer}>
          <img
            className={clsx(classes.img, activeTutorialStep.imgClassName)}
            src={activeTutorialStep.imgPath}
            alt={activeTutorialStep.title}
          />
        </div>
        <Paper square elevation={0} className={classes.content}>
          {activeTutorialStep.content.map((paragraph) => (
            <Typography className={classes.paragraph}>{paragraph}</Typography>
          ))}
        </Paper>
        <MobileStepper
          className={classes.navigation}
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={
            isMobile ? (
              <IconButton color="secondary" onClick={handleNext}>
                <KeyboardArrowRight />
              </IconButton>
            ) : activeStep === maxSteps - 1 ? (
              <Button variant="contained" color="primary" onClick={handleNext}>
                {strings.labels.getStarted}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
              >
                {strings.labels.next}
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight className={classes.keyboardArrow} />
                )}
              </Button>
            )
          }
          backButton={
            isMobile ? (
              <IconButton
                color="secondary"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft className={classes.keyboardArrow} />
                )}
                {strings.labels.back}
              </Button>
            )
          }
        />
      </>
    </CustomModal>
  );
};
