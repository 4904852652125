import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import {
  FormatListBulleted as JiraTicketsIcon,
  LibraryBooksOutlined as MyLibraryIcon,
  PermIdentityOutlined as Person,
  AssignmentTurnedInOutlined as ProjectsIcon,
  HelpOutline as TutorialIcon,
} from "@material-ui/icons";

import { paths } from "../../navigation";
import { appStrings as strings } from "../../resources/strings";
import { UserAccess } from "../../types";

type IconType =
  | OverridableComponent<SvgIconTypeMap<{}, "svg">>
  | (() => JSX.Element);

const { entities, permissions, menuLabels } = strings;

export interface MenuItem {
  id?: string;
  label: string;
  Icon?: IconType;
  itemPermission?: UserAccess;
  link?: string;
  onClick?: Function;
  menuItems?: MenuItem[];
}

export const menuItems = (openTutorial?: () => void) => {
  return [
    {
      id: "0",
      label: menuLabels.projects,
      Icon: ProjectsIcon,
      link: paths.projects.list,
    },
    {
      id: "1",
      label: menuLabels.tasks,
      Icon: JiraTicketsIcon,
      itemPermission: {
        entity: entities.project,
        permission: permissions.taskGeneration,
      },
      link: paths.ticketTemplates.list,
    },
    {
      id: "2",
      label: menuLabels.myLibraries,
      Icon: MyLibraryIcon,
      menuItems: [
        {
          id: "3",
          label: menuLabels.entityLibrary,
          link: paths.entityLibrary.list,
        },
        {
          id: "4",
          label: menuLabels.imageLibrary,
          link: paths.imageLibrary.list,
        },
      ],
    },
    {
      id: "5",
      label: menuLabels.users,
      Icon: Person,
      itemPermission: {
        entity: entities.user,
        permission: permissions.manage,
      },
      link: paths.users.list,
    },
    {
      id: "6",
      label: menuLabels.tutorial,
      Icon: TutorialIcon,
      onClick: openTutorial,
    },
  ];
};

export const setSelectedMenuItem = (menuItemId: string) => {
  sessionStorage.setItem("selectedMenuItem", menuItemId);
};
