import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import { getStyles } from "../../../styles/wireframes/header";
import { Component } from "../../../types";
import { Entity } from "../../../types/Wireframes";

interface Props {
  width: number;
  entity: Entity;
  index: number;
  actionName: string;
  changePage: (actionName: string, entity: Entity, index: number) => void;
  label: string;
  type: HeaderButtonType;
  icon?: JSX.Element;
}

export enum HeaderButtonType {
  main,
  secondary,
  delete,
  left,
  right,
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const HeaderButton: Component<Props> = ({
  width,
  entity,
  index,
  actionName,
  changePage,
  label,
  type,
  icon,
}) => {
  const classes = useStyles();
  const { isMobile, isTablet, isDesktop } = useScreenSizeContext();

  const onClick = () => {
    changePage(actionName, entity, index);
  };

  if (isMobile) {
    return (
      <div onClick={onClick} className={classes.iconContainer}>
        {type === HeaderButtonType.left && icon}
        <p>{label.toUpperCase()}</p>
        {type === HeaderButtonType.right && icon}
      </div>
    );
  }
  if ((isDesktop && width < 50) || (isTablet && width < 100)) {
    return (
      <div onClick={onClick} className={classes.textButtonContainer}>
        <p
          className={clsx(
            type === HeaderButtonType.delete
              ? classes.deleteTextButton
              : classes.textButton
          )}
        >
          {label.toUpperCase()}
        </p>
      </div>
    );
  } else {
    return (
      <Button
        className={clsx(
          type === HeaderButtonType.delete && classes.deleteButton
        )}
        variant={type !== HeaderButtonType.main ? "outlined" : "contained"}
        color={type === HeaderButtonType.main ? "primary" : undefined}
        onClick={onClick}
      >
        {label}
      </Button>
    );
  }
};
