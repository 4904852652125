import { SectionChange } from "../../types";
import { appStrings } from "./app";

export const projectStrings = {
  global: { ...appStrings },
  header: {
    projects: "Projects",
    projectDetails: "Project Details",
  },
  labels: {
    projectName: "Project Name",
    created: "Created",
    updated: "Updated",
    notes: "Notes",
    description:
      "Description (this text will be analysed for potential project entities)",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    newProject: "New Project",
    renderProject: "Edit Requirements",
    viewProject: "Edit Details",
    myProjects: "My Projects",
    createProject: "Create Project",
    getStarted: "Get Started",
    noProjectsInfo: "Click the button below to create a new project.",
    createNewProject: "Create New Project",
    entitiesDetected:
      "We have detected the following entities within the project. Which entities do you wish to create?",
    entityLimitText: (entityLimit?: number) =>
      entityLimit
        ? ` A maximum of ${entityLimit} entities can be created.`
        : "",
    selectEntities: "Select Entities",
    createEntities: "Create",
    cancel: "Cancel",
    selectAll: "Select All",
    documentsModalHeader: "Generate Document",
    next: "Next",
    viewVersionHistory: "View version history",
    versioningLastUpdated: (projectUpdatedDate: {
      time: string;
      date: string;
    }) =>
      `Last updated ${projectUpdatedDate.time} on ${projectUpdatedDate.date} by`,
    sectionChangeDropdownLabel: ({ time, date, user }: SectionChange) =>
      `${time} on ${date} by ${user}`,
    changeHistory: "Change History",
    back: "Back",
    before: "Before",
    after: "After",
    sectionDropdownLabel: "Section:",
    changeDropdownLabel: "Change:",
    noSectionChanges: "No section changes available",
    addImages: "Add Image(s)",
    imageLibraryListInfoText: "Click the button below to upload a file",
    imageLibrary: "Image Library",
  },
  navLinkLabels: {
    projectsList: "My Projects",
  },
  text: {
    mobile:
      "Requiment is not designed for requirements capture on smaller mobile devices. Please logout and login on a desktop or tablet device.",
    projectCreationLoadingText: "Creating project. Please wait...",
  },
  errorMessages: {
    entityLimitReached: "Entity limit reached. Cannot create new entity.",
  },
  chars: {
    divider: " | ",
  },
  placeholderText: {
    projectName: "Enter project name",
    description: "Enter project description",
    notes: "Enter project notes",
    sectionRemoval: "Section was removed",
    sectionAddition: "Section did not exist",
  },
  entityLibrary: {
    listPlaceholderTitleText: "No Entities Added",
    listPlaceholderButtonText: "Go to My Projects",
    listPlaceholderInfoText:
      "You can add entities to your library from within your project",
    entityLabel: "Entity From Library",
    entityNameColumn: "Entity Name",
  },
};
