import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-tenants";
import { getErrorMessage } from "../helpers";
import { Tenant } from "../types/documents";

const getTenantById = async (tenantId: string) => {
  try {
    const url = tenantId;
    const config: Axios.AxiosRequestConfig = {};
    
    const { data } = await axios.get<Response>(url, config) as any as { data: { item: Tenant } };
    return Promise.resolve(data.item);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export {
  getTenantById
};
