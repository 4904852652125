import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/list";
import { Component } from "../../../types";
import { Entity, WireframesPage } from "../../../types/Wireframes";
import { CardList } from "../general/CardList";
import { CatalogueList } from "../general/CatalogueList";
import { FieldTable } from "../general/FieldTable";
import { PageSectionHeader } from "../general/PageSectionHeader";

interface Props {
  entity: Entity;
  action?: string;
  doesEntityContainAction: (actionName: string, entity: Entity) => boolean;
  changePage: (actionName: string, entity: Entity, rowIndex: number) => void;
  style: string;
  selectedFields: string[];
  headerType: string;
  width: number;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const ListPage: Component<Props> = ({
  entity,
  doesEntityContainAction,
  changePage,
  style,
  selectedFields,
  headerType,
  action,
  width,
}) => {
  const classes = useStyles();
  const { isMobile } = useScreenSizeContext();

  const isListSearchable =
    action === strings.actions.list
      ? false
      : doesEntityContainAction(strings.actions.search, entity);

  const areEntitiesDeletable = doesEntityContainAction(
    strings.actions.delete,
    entity
  );

  const areEntitiesReadable = doesEntityContainAction(
    strings.actions.read,
    entity
  );

  const areEntitiesEditable = doesEntityContainAction(
    strings.actions.update,
    entity
  );

  const pageProps = {
    entity,
    changePage,
    deletable: areEntitiesDeletable,
    readable: areEntitiesReadable,
    editable: areEntitiesEditable,
    selectedFields: selectedFields,
  };

  const getListByStyle = (listStyle: string) => {
    switch (listStyle) {
      case strings.styles.table:
        return table;
      case strings.styles.condensedCard:
        return cards(false);
      case strings.styles.detailedCard:
        return cards(true);
      case strings.styles.catalogue:
        return catalogue;
      default:
        return table;
    }
  };

  const addTableBorder =
    isListSearchable || headerType !== strings.headerType.noHeader;

  const table = (
    <div
      className={clsx(
        addTableBorder && classes.tableBorder,
        classes.tableRowBorder
      )}
    >
      <FieldTable {...pageProps} />
    </div>
  );
  const cards = (detailed: boolean) => {
    return (
      <div className={clsx(!isMobile && classes.cardContainer)}>
        <CardList {...pageProps} detailed={detailed} />
      </div>
    );
  };

  const catalogue = (
    <div className={clsx(!isMobile && classes.cardContainer)}>
      <CatalogueList {...pageProps} />
    </div>
  );

  return (
    <div className={classes.content}>
      <PageSectionHeader
        currentPage={WireframesPage.LIST}
        entity={entity}
        changePage={changePage}
        doesEntityContainAction={doesEntityContainAction}
        index={0}
        headerType={headerType}
        action={action}
        width={width}
      />
      {isMobile && style === strings.styles.table
        ? getListByStyle(strings.styles.condensedCard)
        : getListByStyle(style)}
    </div>
  );
};
