import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    root: {
      position: "absolute" as any,
      top: "25%",
      left: "50%",
      transform: "translate(-50%, -20%)",
      backgroundColor: colours.whiteColour,
      borderRadius: "4px",
      width: "700px",
      minHeight: "500px",
    },
    header: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      padding: theme.spacing(2),
      backgroundColor: colours.darkBlueColour,
      display: "flex",
      alignItems: "center",
    },
    headerText: {
      color: colours.whiteColour,
      width: "100%",
      fontSize: "18px",
    },
    exitButton: {
      color: colours.whiteColour,
      fontSize: "16px",
      justifyContent: "end",
    },
    form: {
      padding: "25px",
      paddingLeft: "15px",
    },
    displayText: {
      paddingBottom: "1rem",
      textAlign: "center" as any,
      fontSize: "1.2rem",
      color: colours.darkGreyColour,
      fontFamily: fonts.primary,
    },
    buttonComponent: {
      paddingTop: "1rem",
    },
    actionButton: {
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
      backgroundColor: theme.palette.primary.main,
      fontFamily: fonts.primary,
      color: "white",
      textTransform: "none" as any,
      fontSize: "1.2rem",
      boxShadow: "none",
      borderRadius: "0.2rem",
    },
    manageButton: {
      height: "3.5rem",
    },
    userEmailText: {
      fontWeight: 600 as any,
    },
    paddingTopTwo: {
      paddingTop: "2rem",
    },
  };
};
