import { makeStyles } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import clsx from "clsx";
import { getStyles } from "../../styles/general/dropdownArrow";
import { Component } from "../../types";

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  [key: string]: any;
  className?: string;
  direction?: "down" | "up";
}

export const DropdownArrow: Component<Props> = (props) => {
  const classes = useStyles();

  const className = clsx(props.className, "dropdown-arrow", classes.root);

  return props.direction === "up" ? (
    <KeyboardArrowUp {...props} className={className} />
  ) : (
    <KeyboardArrowDown {...props} className={className} />
  );
};
