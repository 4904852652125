import type { AxiosRequestConfig } from "axios";
import { getErrorMessage } from "../helpers";
import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-projects";
import { SectionRevision } from "../types";
import { ProjectData } from "../types/documents";

const getProjectsListData = async (
  searchCriteria: {
    page: number;
    size: number;
  },
  abortSignal: AbortSignal,
  filters?: object,
  sortDirection?: string,
  sortColumn?: string
) => {
  // provide implementation of projects list data retrieval here
  // this should return data which is an object of the following form:
  // { count: int, items: []ProjectData };
  try {
    const url = ``;
    const config = {
      params: {
        pageNumber: searchCriteria.page + 1,
        pageSize: searchCriteria.size,
        orderColumn: sortColumn,
        orderDirection: sortDirection,
      },
    };
    const { data } = (await axios.get<Response>(url, config)) as any;
    console.log("PROJ DATA", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const getProjectById = async (id: string, cancelToken: Axios.CancelToken) => {
  const url = `/${id}`;
  const config = { cancelToken };
  const { data } = await axios.get<Response>(url, config);
  let body: any = Object.assign(data);
  return body.item;
};

const deleteProject = async (
  rowObject: ProjectData,
  abortSignal: AbortSignal,
  appendHistory: (url: string) => void
) => {
  // provide implementation of projects deletion here
  // call the appendHistory function provided to navigate to a page within the appp of your choice
  // this can just be the project list page that we were on anyway
  // it is necessary to 'refresh' after deleting to see the deleted project disappear
  try {
    const url = rowObject.projectId;
    const config = { signal: abortSignal };
    const { data } = await axios.delete<Response>(url, config);
    console.log("PROJ DATA", data);
    await Promise.resolve(data);
    appendHistory("/");
    appendHistory("/projects");
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const createProject = async (dataObject: { [key: string]: unknown }) => {
  try {
    const {
      projectId,
      tenantId,
      sortKey,
      createdDate,
      updatedDate,
      active,
      ...projectValues
    } = dataObject; //extract remaining properties after non-editable ones ignored - back end sets the created/updated dates
    const url = ``;
    const body = {
      ...projectValues,
      projectTypeId: "00000000-0000-0000-0000-000000000000",
    }; //Currently we will use the default project type
    const config = {};
    const { data } = await axios.post(url, body, config);
    console.log("CREATE PROJECT DATA", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(
      "An error occurred while creating the project. Please try again."
    );
  }
};

const updateProject = async (dataObject: { [key: string]: unknown }) => {
  try {
    console.log("dataObject", dataObject);
    const {
      projectId,
      tenantId,
      sortKey,
      createdDate,
      updatedDate,
      active,
      ...projectValues
    } = dataObject; //extract remaining properties after non-editable ones ignored - back end sets the created/updated dates
    if (typeof projectId !== "string") {
      throw new Error("Invalid project id");
    }
    const url = projectId;
    const body = {
      ...projectValues,
      projectTypeId: "00000000-0000-0000-0000-000000000000",
    }; //Currently we will use the default project type
    const config = {};
    const { data } = await axios.put<Response>(url, body, config);
    console.log("UPDATE PROJECT DATA", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(
      "An error occurred while updating the project. Please try again."
    );
  }
};

const addExtractedEntities = async (projectId: string, entities: string[]) => {
  try {
    const url = `${projectId}/addEntities`;
    const body = { entityNames: entities };
    const config = {};
    const { data } = await axios.put<Response>(url, body, config);
    console.log("ADD EXTRACTED ENTITIES DATA", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const getControlsForTaskDropdown = async (taskType: string) => {
  try {
    const url = `extractControlsForTasks`;
    const params: AxiosRequestConfig["params"] = {
      taskType,
    };
    const config = { params };
    const { data } = await axios.get(url, config);
    console.log("GET CONTROLS FOR TASK DROPDOWN", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const getProjectLogo = async (id: string) => {
  const url = `/getLogo/${id}`;
  const config = {};
  const { data } = await axios.get<string | null>(url, config);
  return Promise.resolve(data);
};

const getChangeHistoryForSection = async (
  sectionId: string,
  projectId: string
) => {
  const url = `${projectId}/getSectionRevisions/${sectionId}`;
  const config = {};
  const { data } = await axios.get<SectionRevision[]>(url, config);
  return Promise.resolve(data);
};

export {
  addExtractedEntities,
  createProject,
  deleteProject,
  getChangeHistoryForSection,
  getControlsForTaskDropdown,
  getProjectById,
  getProjectLogo,
  getProjectsListData,
  updateProject,
};
