import { getErrorMessage } from "../helpers";
import axios from "../libs/axios/axios-projects";
import {
  getControlAttachment,
  updateAttachmentMetadata,
  uploadAttachment,
} from "./filesService";

const getJobFormData = async (
  projectId: string,
  excludeInactive: boolean = true
) => {
  console.log("getJobFormData");
  const config = {
    params: {
      excludeInactive,
    },
  };
  const path = `${projectId}/projectTypeData`;
  const { data } = await axios.get<any[]>(path, config);
  return data;
};

const getProjectTypes = async () => {
  console.log("getJobTypes");
  return new Promise((resolve, reject) => {
    resolve([
      {
        id: "79cff735-d85a-4f78-a4c7-8a5968da7603",
        name: "project",
        version: 1,
        jobTypeState: 1,
        timeInMinutes: 10,
        hasSiteVisitSection: false,
        containsEntities: false,
      },
    ]);
  });
};

const updateSectionData = (updateVersioningDetails: any) => {
  return async (
    formData: [{ sectionId: string }],
    selectedSectionId: string,
    projectId: string
  ) => {
    try {
      console.log("updateSectionData", formData);
      //const { projectId, tenantId, sortKey, createdDate, updatedDate, active, ...projectValues } = formData //extract remaining properties after non-editable ones ignored - back end sets the created/updated dates
      const selectedSection = getSectionData(formData, selectedSectionId);
      if (!selectedSection) {
        console.log("Error - cannot resolve section data");
      }
      console.log("selectedSection", selectedSection);
      const url = `sections/${selectedSectionId}`;
      const body = { ...selectedSection, projectId };
      const config = {};
      const { data } = await axios.put<any>(url, body, config);
      console.log("updateSectionData result", data);
      updateVersioningDetails();
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(getErrorMessage(e));
    }
  };
};

const saveRow =
  (updateVersioningDetails: () => void) =>
  async (
    formData: [{ sectionId: string }],
    selectedSectionId: string,
    projectId: string
  ) => {
    console.log("saveRow");
    try {
      const selectedSection = formData.filter(
        (section) => section.sectionId === selectedSectionId
      );
      if (selectedSection.length !== 1) {
        console.log("Error - cannot resolve section data");
      }
      const selectedSectionData = selectedSection[0];
      console.log("selectedSectionData", selectedSectionData);
      const url = `sections/savesection`;
      const body = { ...selectedSectionData, projectId };
      const config = {};
      const { data } = await axios.post<Response>(url, body, config);
      console.log("saveRow result", data);
      updateVersioningDetails();
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(getErrorMessage(e));
    }
  };

const saveRowReordering =
  (updateVersioningDetails: () => void) =>
  async (projectId: string, formData: any[]) => {
    console.log("saveRowOrdering");
    try {
      const url = `${projectId}/reorderSections`;
      const body = {
        orderedSections: formData,
      };
      const config = {};
      const { data } = await axios.put<Response>(url, body, config);
      updateVersioningDetails();
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(getErrorMessage(e));
    }
  };

const addRepeatableSection = async (
  projectId: string,
  sectionTypeId: object,
  sectionName?: string,
  autoGenerateFields?: boolean
) => {
  console.log("addRepeatableSection");
  try {
    const url = `sections/addsection?save=true`;
    //Currently we will use the default project type
    const body = {
      projectId,
      sectionTypeId,
      newSectionCaption: sectionName,
      autoGenerateFields,
    };
    const config = {};
    const { data } = await axios.post<Response>(url, body, config);
    console.log("ADD REPEATABLE SECTION DATA", data);

    const newFormData = await getJobFormData(projectId);
    return Promise.resolve(newFormData);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

//It sets active status to false, rather than deleting completely
const deleteSection =
  (updateVersioningDetails: () => void) =>
  async (projectId: string, sectionId: string) => {
    console.log("deleteSection");
    try {
      const url = `section/${projectId}/${sectionId}`;
      const config = {};
      const { data } = await axios.delete<Response>(url, config);
      console.log("Deleted", data);
      const newFormData = await getJobFormData(projectId);
      updateVersioningDetails();
      return Promise.resolve(newFormData);
    } catch (e) {
      console.log(e);
      return Promise.reject(getErrorMessage(e));
    }
  };

// TO DO: Don't think this is required for the Job Renderer anymore
const getTemplateOnlySections = async (projectId: string) => {};

//Section Grids
const createRow = async (
  projectId: string,
  sectionTypeId: string,
  isDuplication: boolean = false,
  parentSectionId: string | undefined = undefined
) => {
  console.log("Grid - create row");
  try {
    const url = `sections/addsection?save=${isDuplication}`;
    const body = { projectId, sectionTypeId, parentSectionId }; //Currently we will use the default project type
    const config = {};
    const { data } = await axios.post<Response>(url, body, config);
    console.log("ADD REPEATABLE SECTION DATA", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const deactivateRow =
  (updateVersioningDetails: () => void) =>
  async (
    formData: any,
    containerSectionId: string,
    rowId: string,
    projectId: string
  ) => {
    console.log("Grid - deactivate row");
    try {
      const deleteRowUrl = `section/${projectId}/${rowId}`;
      const updateSectionUrl = `sections/${containerSectionId}`;

      const selectedSection = getSectionData(formData, containerSectionId);
      const updateSectionBody = { ...selectedSection, projectId };

      await axios.put<Response>(updateSectionUrl, updateSectionBody, {});
      await axios.delete<Response>(deleteRowUrl, {});

      updateVersioningDetails();
    } catch (e) {
      console.log(e);
      return Promise.reject(getErrorMessage(e));
    }
  };

const getJobData = async (projectId: string) => {
  console.log("getJobData");
  const config = {};
  const path = `${projectId}`;
  const { data } = await axios.get<Response>(path, config);
  let body: any = Object.assign(data);
  return body.item;
};

const addStoredSection = async (
  projectId: string,
  storedSectionId: string,
  sectionTenantOrganisationId: string
) => {
  try {
    const url = "sections/addStoredSection";
    const body = {
      projectId,
      storedSectionId,
      sectionTenantOrganisationId,
    };
    const config = {};
    const { data } = await axios.post<Response>(url, body, config);
    console.log("Add stored section", data);
    const newFormData = await getJobFormData(projectId);
    return Promise.resolve(newFormData);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const getStoredSections = async (projectId: string, sectionTypeId: string) => {
  try {
    const url = `${projectId}/sections/getStoredSections/${sectionTypeId}`;
    const config = {};
    const { data } = await axios.get<Response>(url, config);
    console.log("Get stored sections", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

function getSectionData(formData: Array<any>, sectionId: string) {
  return formData.find((section) => section?.sectionId === sectionId);
}

const addStoredSectionToLibrary = async (
  projectId: string,
  sectionId: string
) => {
  try {
    const url = "sections/createStoredSection";
    const body = {
      projectId,
      sectionId,
    };
    const config = {};
    const { data } = await axios.post<Response>(url, body, config);
    console.log("Add stored section to library", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export {
  addRepeatableSection,
  addStoredSection,
  addStoredSectionToLibrary,
  createRow,
  deactivateRow,
  deleteSection,
  getControlAttachment,
  getJobData,
  getJobFormData,
  getProjectTypes,
  getStoredSections,
  getTemplateOnlySections,
  saveRow,
  saveRowReordering,
  updateAttachmentMetadata,
  updateSectionData,
  uploadAttachment,
};
