import { useState, useEffect } from "react";
import { useCancelToken } from "../../general";
import axios from "axios";
import type { Axios } from "../../../libs/axios";
import type { TagItem } from "../../../types/documents";
import type { DropdownOptions } from "../../../types";
import { HooksLogger } from "../../hooks-logger";
import { getErrorMessage, createDropdownOptions } from "../../../helpers";

const logger = new HooksLogger("useTagGroup");

export const useTagGroup = (
  defaultOptions: DropdownOptions = [],
  endpoint?: string,
  jwt?: string,
  tagGroup?: string
) => {
  type Response = {
    item: TagItem;
  };

  const cancelToken = useCancelToken();
  const [options, setOptions] = useState(() => defaultOptions);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const query = async (url: string, token: string, group: string) => {
      const config: Axios.AxiosRequestConfig = {
        cancelToken,
        headers: {
          Authorization: token,
        },
      };

      logger.request(`Getting tag data for ${group}`);

      setLoading(true);
      try {
        const path = `${url}/${group}`;

        const {
          data: { item },
        } = await axios.get<Response>(path, config);
        const dropdownOptions = createDropdownOptions(item.tags, "id", "text");
        setOptions(dropdownOptions);
        setLoading(false);
        logger.success(item);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = getErrorMessage(e);
        setError(error);
        setLoading(false);
        logger.error(error);
      }
    };

    // pass these into the query function since this check ensures "tagConfig" is defined
    if (endpoint && jwt && tagGroup) query(endpoint, jwt, tagGroup);
  }, [endpoint, jwt, tagGroup, cancelToken]);

  return [options, loading, error] as const;
};
