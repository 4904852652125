import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoaderProps, Notification } from "../../components/general";
import type { Mode } from "../../components/general/types/Modify";
import {
  getRequiredFieldText,
  isRequimentOrSubscriptionUser,
} from "../../helpers/appHelper";
import { ENTITY_DETECTION_CREATION_LIMIT as entityDetectionCreationLimit } from "../../libs/config";
import { paths } from "../../navigation";
import { constants } from "../../resources/numerics/app";
import { appStrings, projectStrings as strings } from "../../resources/strings";
import {
  createProject,
  getProjectById,
  updateProject,
} from "../../services/projectService";
import { colours } from "../../styles/theme";
import {
  ButtonConfiguration,
  Component,
  NotificationType,
  RouteProps,
} from "../../types";
import type { ProjectData } from "../../types/documents";
import type { ValidationConstraints } from "../../validation/validators";
import { ModifyContainerUsingServices } from "../general";
import { TextInput } from "../general/Form/TextInput";
import { Modal as CustomModal } from "../general/Modal/Modal";
import { ModalButtons } from "../general/Modal/ModalButtons";
import { EntitySelection } from "./EntitySelection";

export interface Props extends RouteProps {
  mode: Mode;
  enableMenu: () => void;
  disableMenu: () => void;
}

const constraints: ValidationConstraints<ProjectData> = {
  name: {
    presence: {
      allowEmpty: false,
      message: getRequiredFieldText(strings.labels.projectName),
    },
  },
  description: {
    length: {
      maximum: constants.projectDescriptionMaxLength,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    backgroundColor: colours.whiteColour,
    borderRadius: "4px",
    width: "700px",
    minHeight: "500px",
  },
  header: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    padding: theme.spacing(2),
    backgroundColor: colours.darkBlueColour,
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    color: colours.whiteColour,
    width: "100%",
    fontSize: "18px",
  },
  exitButton: {
    color: colours.whiteColour,
    fontSize: "16px",
    justifyContent: "end",
  },
  form: {
    padding: "25px 25px 0px 15px",
  },
  loaderContent: {
    backgroundColor: "inherit",
    textAlign: "center",
    paddingTop: "5px",
    fontSize: "1rem",
  },
  buttons: {
    paddingRight: 0,
  },
}));

export const ProjectModify: Component<Props> = ({
  mode,
  enableMenu,
  disableMenu,
  ...routeProps
}) => {
  const { id } = useParams<{ id: string; childId?: string }>();
  const classes = useStyles();
  const history = useHistory();

  const [showEntitySelectionPage, setShowEntitySelectionPage] = useState(false);
  const [extractedEntities, setExtractedEntities] = useState<string[]>([]);
  const [newId, setNewId] = useState(id);
  const [error, setError] = useState<string | undefined>("");

  const isCreateMode = mode === "create";

  const { user } = routeProps;
  const isTrial = !isRequimentOrSubscriptionUser(user);

  const entityDetectionLimit = entityDetectionCreationLimit
    ? parseInt(entityDetectionCreationLimit)
    : undefined;

  const trialLimit = 7;
  const entityLimit = isTrial
    ? entityDetectionLimit
      ? Math.min(trialLimit, entityDetectionLimit)
      : trialLimit
    : entityDetectionLimit;

  useEffect(() => {
    disableMenu();
  }, [disableMenu]);

  if (!id && !isCreateMode) throw new Error("ProjectId not set.");

  const getRenderProjectPath = (id: string) => paths.projects.render(id);

  const routeToProjectRenderPage = (projectId?: string) => {
    const path = projectId
      ? getRenderProjectPath(projectId)
      : getRenderProjectPath(id);
    history.push(path);
  };

  const onCreate = async (formData: { [key: string]: unknown }) => {
    const { id, extractedEntities } = await createProject(formData);
    if (!extractedEntities || !extractedEntities.length) {
      routeToProjectRenderPage(id);
    } else {
      setNewId(id);
      setShowEntitySelectionPage(true);
      setExtractedEntities(extractedEntities);
      return { id };
    }
    enableMenu();
  };

  const onUpdate = async (dataObject: { [key: string]: unknown }) => {
    await updateProject(dataObject);
    onCloseModal();
  };

  const handleError = (msg?: string) => {
    setError(msg);
  };

  const onCloseModal = () => {
    enableMenu();
    if (isCreateMode) {
      history.push(paths.projects.list);
      return;
    }
    routeToProjectRenderPage(id);
  };

  const modalHeader = isCreateMode
    ? strings.labels.createNewProject
    : strings.header.projectDetails;

  const getLoaderProps = () => {
    const loadingText = isCreateMode ? "" : appStrings.labels.loading;
    const additionalContent = isCreateMode ? (
      <div className={classes.loaderContent}>
        {strings.text.projectCreationLoadingText}
      </div>
    ) : (
      <></>
    );

    return {
      text: loadingText,
      inline: true,
      additionalContent,
    } as Partial<LoaderProps>;
  };

  const modifyContainer = (
    <div className={classes.form}>
      <ModifyContainerUsingServices<ProjectData>
        {...routeProps}
        getByIdFunction={getProjectById}
        createFunction={onCreate}
        updateFunction={onUpdate}
        mode={mode}
        initialData={{
          projectId: "",
          active: false,
          tenantId: "",
          name: "",
          sortKey: "",
          notes: "",
          description: "",
          createdDate: "",
          updatedDate: "",
        }}
        componentConfiguration={{
          staticConfig: [
            {
              key: "details",
              content: [
                {
                  controltype: "custom",
                  name: "name",
                  Component: (props) => (
                    <TextInput
                      {...props}
                      labelText={strings.labels.projectName}
                      className="editable-input"
                      placeholder={
                        props.mode === "create"
                          ? strings.placeholderText.projectName
                          : undefined
                      }
                      required
                      autofocus={props.mode === "create"}
                    />
                  ),
                },
                {
                  controltype: "custom",
                  name: "description",
                  Component: (props) => (
                    <TextInput
                      {...props}
                      labelText={strings.labels.description}
                      numberOfRows={3}
                      labelStyle={{ textTransform: "none" }}
                      placeholder={
                        props.mode === "create"
                          ? strings.placeholderText.description
                          : undefined
                      }
                    />
                  ),
                },
                {
                  controltype: "custom",
                  name: "notes",
                  Component: (props) => (
                    <TextInput
                      {...props}
                      labelText={strings.labels.notes}
                      numberOfRows={3}
                      placeholder={
                        props.mode === "create"
                          ? strings.placeholderText.notes
                          : undefined
                      }
                    />
                  ),
                },
              ],
            },
            {
              key: "submitButtons",
              modes: ["create", "update"],
              content: [
                {
                  controltype: "custom",
                  name: "modal buttons",
                  Component: (props) => {
                    const isCreateMode = props.mode === "create";

                    let buttonConfig: ButtonConfiguration = {};

                    if (isCreateMode) {
                      buttonConfig = {
                        confirmConfig: {
                          onClick: (event: any) => {},
                          text: strings.labels.next,
                        },
                        cancelConfig: {
                          onClick: props.handleReset,
                          text: strings.labels.reset,
                        },
                      };
                    } else {
                      buttonConfig = {
                        confirmConfig: {
                          onClick: (event: any) => {},
                          text: strings.labels.save,
                        },
                        cancelConfig: {
                          onClick: onCloseModal,
                          text: strings.labels.cancel,
                        },
                      };
                    }

                    if (props.onSubmit && buttonConfig.confirmConfig) {
                      buttonConfig.confirmConfig.onClick = async (
                        event: any
                      ) => {
                        await props.onSubmit(event);
                      };
                    }
                    return (
                      <ModalButtons
                        className={classes.buttons}
                        buttonConfiguration={buttonConfig}
                      />
                    );
                  },
                },
              ],
            },
          ],
        }}
        constraints={constraints}
        loaderProps={getLoaderProps()}
      />
    </div>
  );

  return (
    <CustomModal active onCloseModal={onCloseModal} header={modalHeader}>
      {showEntitySelectionPage ? (
        <>
          <EntitySelection
            projectId={newId}
            entities={extractedEntities}
            redirectPath={getRenderProjectPath}
            enableMenu={enableMenu}
            loaderProps={getLoaderProps()}
            entityLimit={entityLimit}
            onError={handleError}
          />
          {error && (
            <Notification message={error} type={NotificationType.error} />
          )}
        </>
      ) : (
        modifyContainer
      )}
    </CustomModal>
  );
};
