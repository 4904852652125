import { useState } from "react";
import "../App.scss";
import { Loader } from "../components/general";
import { Layout } from "../components/layout";
import { DateHelper } from "../helpers";
import { useAuth, useJWT } from "../hooks/authentication";
import { useUserPermissions } from "../hooks/permissions";
import { Routes } from "../navigation";
import { Component } from "../types";

const Wrapper: React.FC = ({ children }) => (
  <div className="App">{children}</div>
);

interface Props {
  dateHelper: DateHelper;
}

const App: Component<Props> = ({ dateHelper }) => {
  const { user, checkedAuth, trialDaysRemaining } = useAuth(dateHelper);
  const { permissions, loading: loadingPermissions } = useUserPermissions(user);
  const jwt = useJWT(user);
  const [menuDisabled, setMenuDisabled] = useState(false);
  const [tutorialOpen, setTutorialOpen] = useState(false);

  const isLoading = [!checkedAuth, loadingPermissions].some(
    (condition) => condition
  );

  if (isLoading) {
    return (
      <Wrapper>
        <Loader active />
      </Wrapper>
    );
  }

  const enableMenu = () => {
    setTutorialOpen(false);
    setMenuDisabled(false);
  };

  const disableMenu = () => {
    setTutorialOpen(true);
    setMenuDisabled(true);
  };

  return (
    <Wrapper>
      <Layout
        permissions={permissions}
        user={user}
        dateHelper={dateHelper}
        menuDisabled={menuDisabled}
        trialDaysRemaining={trialDaysRemaining}
      >
        <Routes
          user={user}
          permissions={permissions}
          jwt={jwt}
          enableMenu={enableMenu}
          disableMenu={disableMenu}
          tutorialOpen={tutorialOpen}
          menuDisabled={menuDisabled}
        />
      </Layout>
    </Wrapper>
  );
};

export default App;
