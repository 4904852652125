import axios from "axios";
import { DOCGEN_ENDPOINT } from "../config";
import interceptors from "./interceptors";

const instance = axios.create({
  baseURL: DOCGEN_ENDPOINT,
});

instance.interceptors.request.use(
  interceptors.onRequest,
  interceptors.onRequestError
);
instance.interceptors.response.use(
  interceptors.onResponse,
  interceptors.onResponseError
);

export default instance;