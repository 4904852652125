import {
  InputAdornment,
  InputLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { wireframesStrings as strings } from "../../../resources/strings/wireframes";
import { getStyles } from "../../../styles/wireframes/fields";
import { Component } from "../../../types";

interface Props {
  label: string;
  type: string;
  initialValue: string;
  editable: boolean;
  updateValue: (value: string) => void;
  password?: boolean;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const CustomTextField: Component<Props> = ({
  label,
  type,
  initialValue,
  editable,
  updateValue,
  password = false,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (value: string) => {
    setValue(value);
    updateValue(value);
  };

  const inputProps = () => {
    if (type === strings.textFieldTypes.search) {
      return {
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      };
    }
    return {};
  };

  return (
    <div className={classes.textField}>
      {type === strings.textFieldTypes.input && (
        <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      )}
      <TextField
        disabled={!editable}
        variant="outlined"
        size={type === strings.textFieldTypes.search ? "small" : "medium"}
        value={value}
        InputProps={inputProps()}
        onChange={(e) => onChange(e.target.value)}
        placeholder={label}
        type={password ? "password" : "text"}
        autoComplete="new-password"
      ></TextField>
    </div>
  );
};
