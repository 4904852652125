import { Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import logo from "../../assets/requiment-tick-icon.svg";
import { appStrings, projectStrings as strings } from "../../resources/strings";
import { getStyles } from "../../styles/general/mobileDisplay";

const useStyles = makeStyles((theme) => getStyles(theme));

interface MobileDisplayProps {
  handleSignOut: () => void;
}

type MobileDisplayComponent = (props: MobileDisplayProps) => JSX.Element;

export const MobileDisplay: MobileDisplayComponent = ({ handleSignOut }) => {
  const classes = useStyles();

  return (
    <div id="alternativeDisplay">
      <img className={classes.logo} src={logo} alt="logo" />
      <Typography className={clsx(classes.headerItem)}>
        {strings.text.mobile}
      </Typography>
      <Button variant="contained" color="secondary" onClick={handleSignOut}>
        {appStrings.menuLabels.signOut}
      </Button>
    </div>
  );
};
