import clsx from "clsx";
import { useRef, useState } from "react";
import { Component } from "../../../types";

interface InputProps {
  inputValue: string;
  onEdit: (updatedTags: string[]) => void;
  inputKey?: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const InlineInput: Component<InputProps> = ({
  inputValue,
  inputKey,
  onEdit,
  className,
  disabled,
  placeholder,
}) => {
  const [value, setValue] = useState(inputValue);
  const [shouldHaveFocus, setShouldHaveFocus] = useState(false);
  const [inputRef, setInputFocus] = useFocus<HTMLInputElement>();

  const placeholderClassName = !value && placeholder ? "input-placeholder" : "";

  if (shouldHaveFocus) {
    setInputFocus();
  }

  return (
    <input
      className={clsx(
        "editable",
        "input-default",
        className,
        placeholderClassName
      )}
      onMouseUp={() => {
        setShouldHaveFocus(true);
      }}
      ref={inputRef}
      key={inputKey}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={(e) => {
        if (!onEdit) return;
        const updatedTags = (e.target.value as string).split(",");
        onEdit(updatedTags);
      }}
    ></input>
  );
};

export default function useFocus<T extends HTMLElement = HTMLElement>() {
  const ref = useRef<T>(null);
  const setFocus = () => ref?.current?.focus?.();

  return [ref, setFocus] as const;
}
