import { Theme } from "@material-ui/core";
import { common } from "./common";
import { fonts } from "./theme";

export const getStyles = (theme: Theme) => {
  return {
    ...common(theme),
    card: {
      margin: "10px",
      padding: "10px",
      border: "solid 1px",
      borderColor: theme.palette.secondary.main,
      borderRadius: "5px",
      display: "flex",
    },
    mobileCard: {
      padding: "10px",
      borderBottom: "solid 1px " + theme.palette.secondary.main,
      width: "100%",
    },
    cardWrap: {
      display: "flex",
    },
    catalogueCardContent: {
      display: "flex",
    },
    catalogueFields: {
      display: "flex",
      flexDirection: "column" as any,
      margin: "10px",
      float: "right" as any,
    },
    catalogueCard: {
      maxWidth: "460px",
      display: "flex",
      justifyContent: "space-between",
    },
    desktopCardWidth: {
      width: "95%",
    },
    field: {
      display: "flex",
    },
    fieldTitle: {
      "& p": {
        fontSize: "15px",
        fontWeight: 700,
      },
    },
    fieldSubtitle: {
      "& p": {
        fontSize: "13px",
        fontWeight: 700,
      },
    },
    fieldParagraph: {
      "& p": {
        fontSize: "13px",
        color: theme.palette.secondary.dark,
      },
    },
    detailText: {
      "& p": {
        fontSize: "13px",
      },
    },
    cardTextContainer: {
      width: "100%",
    },
    text: {
      margin: "0px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontFamily: fonts.primary,
    },
    label: {
      fontWeight: 700,
      width: "40%",
    },
    value: {
      width: "60%",
    },
    deleteIcon: {
      color: theme.palette.error.main,
    },
    iconContainer: {
      padding: "5px",
      display: "flex",
      justifyContent: "flex-end",
    },
    icon: {
      margin: "5px",
      fontSize: "20px",
    },
    catalogueContainer: {
      display: "flex",
      flexWrap: "wrap" as any,
      width: "100%",
    },
    textFieldContainer: {
      margin: "2.5px",
      display: "flex",
      flexDirection: "column" as any,
    },
  };
};
