import { AxiosRequestConfig } from "axios";
import { getErrorMessage } from "../helpers";
import axios from "../libs/axios/axios-users";
import { UserData } from "../types/documents";

const getUsersListData = async (
  searchCriteria: {
    page: number;
    size: number;
  },
  abortSignal: AbortSignal,
  filters?: object,
  sortDirection?: string,
  sortColumn?: string
) => {
  try {
    const { page, size } = searchCriteria;
    const url = "list-users";

    const params: AxiosRequestConfig["params"] = {};
    if (page !== undefined) params.pageNumber = page + 1;
    if (size !== undefined) params.pageSize = size;
    if (sortColumn) params.orderColumn = sortColumn;
    if (sortDirection) params.orderDirection = sortDirection;

    const config: AxiosRequestConfig = {
      params,
      signal: abortSignal,
    };
    const body = {};

    const { data } = (await axios.post<Response>(url, body, config)) as any;
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const deleteUser = async (
  rowObject: UserData,
  abortSignal: AbortSignal,
  appendHistory: (url: string) => void
) => {
  try {
    const url = rowObject.userId;
    const config = { signal: abortSignal };

    const { data } = await axios.delete<Response>(url, config);
    await Promise.resolve(data);

    appendHistory("/");
    appendHistory("/users");
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const getUser = async (userId: string) => {
  try {
    const url = userId;
    const config = {};
    const { data } = await axios.get<Response>(url, config);
    let body: any = Object.assign(data);
    return body.item;
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export { deleteUser, getUser, getUsersListData };
