import { getErrorMessage } from "../helpers";
import axios from "../libs/axios/axios-files";
import { constants } from "../resources/numerics/app";
import { SearchValue } from "../types/Wireframes";
import { FileToUpload, LibraryImageData } from "../types/documents/ImageFile";

const updateAttachmentMetadata = async (
  projectId: string,
  attachmentId: string,
  fileExtension: string
) => {
  try {
    const url = "addFileLocation";
    const body = {
      extension: fileExtension,
    };
    const config = {};
    console.log("Adding attachment metadata");
    const { data } = await axios.post<{ fileLocation: any }>(url, body, config);
    return Promise.resolve(data.fileLocation.id);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const uploadAttachment =
  (refetchData: () => void) => async (attachmentId: string, file: any) => {
    try {
      const url = attachmentId;
      const body = file;
      const config = {};
      console.log("Uploading attachment");
      const { data } = await axios.post<any>(url, body, config);
      refetchData();
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
      return Promise.reject(getErrorMessage(e));
    }
  };

const getControlAttachment = async (attachmentId: string) => {
  try {
    const url = attachmentId;
    const config = {};
    console.log("Getting control attachment");
    const { data } = await axios.get<any>(url, config);
    const fileData = "data:image/png;base64," + data;
    return Promise.resolve(fileData);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const searchMediaLibrary = async (searchValues: SearchValue[]) => {
  try {
    const url = "searchMediaLibrary";
    const fileCount = constants.wireframesListRowCount;
    const body = {
      numberOfFilesRequired: fileCount,
      searchValues: searchValues,
    };
    console.log("Searching media library");
    const { data } = await axios.post<any>(url, body);
    const files = data.files.map(
      (file: string) => "data:image/png;base64," + file
    );
    return Promise.resolve(files);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const bulkUploadFiles = async (filesToUpload: FileToUpload[]) => {
  try {
    const url = "bulkUpload";
    const body = { files: filesToUpload };
    const config = {};
    const { data } = await axios.post<any>(url, body, config);
    return Promise.resolve(data);
  } catch (e: any) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

const updateFile = async (file: LibraryImageData) => {
  try {
    const url = `updateFileLocation/${file.id}`;
    const body = { tags: file.tags };
    const config = {};
    const { data } = await axios.put<any>(url, body, config);
    return Promise.resolve(data);
  } catch (e: any) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export {
  bulkUploadFiles,
  getControlAttachment,
  searchMediaLibrary,
  updateAttachmentMetadata,
  updateFile,
  uploadAttachment,
};
