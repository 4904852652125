import { Theme } from "@material-ui/core";
import { colours, theme as requimentTheme } from "../theme";
import { common } from "./common";

export const getStyles = (theme: Theme) => {
  return {
    ...common(theme),
    container: {
      "& .segment": {
        margin: "20px",
      },
      display: "flex",
      flexDirection: "column" as any,
      background: requimentTheme.palette.grey[500],
      height: "100vh",
    },
    desktopContainer: {
      background: requimentTheme.palette.common.white,
      height: "100vw",
      overflowY: "auto" as any,
    },
    tabletContainer: {
      background: requimentTheme.palette.common.white,
      width: "1000px",
      position: "relative" as any,
      margin: "auto",
      marginTop: "100px",
      height: "700px",
      overflow: "auto",
    },
    mobileContainer: {
      background: requimentTheme.palette.common.white,
      width: "350px",
      position: "relative" as any,
      margin: "auto",
      marginTop: "100px",
      height: "700px",
      overflow: "auto",
    },
    fabContainer: {
      margin: 0,
      top: "auto",
      bottom: 20,
      position: "fixed" as any,
    },
    fabContainerLeft: {
      right: "auto",
      left: 20,
      zIndex: 10000,
      "& button": {
        marginRight: "10px",
      },
    },
    fabContainerRight: {
      left: "auto",
      right: 20,
      "& button": {
        marginLeft: "10px",
      },
    },
    watermark: {
      "& p": {
        lineHeight: "normal",
        fontSize: "15px",
        textTransform: "none",
        margin: "5px",
      },
      "& img": {
        height: "18px",
      },
    },
    displayIcon: {
      margin: "3px",
    },
    displayIconSelected: {
      color: requimentTheme.palette.secondary.main,
    },
    sectionContainer: {
      display: "flex",
      flex: "1 1 auto",
    },
    pageColumn: {
      display: "flex",
      flexDirection: "column" as any,
      flex: "auto",
      width: "100%",
    },
    pageRow: {
      flex: "auto",
      height: "100%",
    },
    verticalMargin: {
      marginTop: "30px",
    },
    leftBorder: {
      borderLeft: "solid 1px " + theme.palette.secondary.main,
    },
    bottomBorder: {
      borderBottom: "solid 1px " + theme.palette.secondary.main,
    },
    topMargin: {
      marginTop: "20px",
    },
    minHeight: {
      minHeight: "100%",
    },
    helpIcon: {
      color: colours.greyColour,
    },
  };
};
