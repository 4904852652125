import { Button, InputLabel, makeStyles } from "@material-ui/core";
import Image from "../../../assets/place-holder-image.jpg";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import { wireframesStrings as strings } from "../../../resources/strings/wireframes";
import { getStyles } from "../../../styles/wireframes/fields";

interface Props {
  label: string;
  editable: boolean;
  value?: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

type Component = (props: Props) => JSX.Element;

export const CustomImageInput: Component = ({ label, editable, value }) => {
  const classes = useStyles();
  const { isMobile } = useScreenSizeContext();

  return (
    <div className={classes.imageControl}>
      <InputLabel>{label}</InputLabel>
      <img
        alt=""
        src={value ? value : Image}
        className={isMobile ? classes.mobileImage : classes.image}
      />
      {editable && (
        <Button variant="outlined">{strings.labels.newImage}</Button>
      )}
    </div>
  );
};
