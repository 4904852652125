import { Theme } from "@material-ui/core";
import { colours } from "../theme";
import { fonts } from "./theme";

export const getStyles = (theme: Theme) => {
  return {
    container: {
      width: "100%",
      height: "100%",
      position: "fixed" as any,
      zIndex: 1300,
      background: colours.whiteColour,
      padding: "50px",
      overflow: "auto",
      paddingBottom: "50px",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    headerText: {
      color: colours.greyColour,
      fontSize: "32px",
    },
    exitButton: {
      color: colours.greyColour,
      width: "30px",
      height: "30px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    underline: {
      "&:after": {
        borderBottom: `2px solid ${colours.secondaryPink} !important`,
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: `1px solid ${colours.secondaryPink} !important`,
      },
    },
    content: {
      display: "flex",
      flexDirection: "column" as any,
      "& h2": {
        color: colours.greyColour,
        fontFamily: "Varela Round",
        fontWeight: "400",
      },
    },
    listContainer: {
      display: "flex",
      flexWrap: "wrap" as any,
      marginTop: "20px",
    },
    listItem: {
      display: "flex",
      flexDirection: "column" as any,
      borderRadius: "7px",
      border: "solid 2px",
      borderColor: colours.whiteColour,
      boxShadow: "0px 3px 6px" + colours.greySelectedColour,
      "&:hover": {
        cursor: "pointer",
        borderColor: colours.secondaryPink,
      },
    },
    selectedListItem: {
      borderColor: colours.secondaryPink,
    },
    icon: {
      width: "20px",
      height: "20px",
      margin: "0px",
      marginLeft: "2.5px",
    },
    iconDelete: {
      "&:hover": {
        color: colours.redHover,
      },
    },
    iconEdit: {
      "&:hover": {
        color: colours.greyColour,
      },
    },
    textContainer: {
      height: "20px",
      display: "flex",
      justifyContent: "space-between",
      margin: "15px 15px",
      alignItems: "center",
      "& p": {
        margin: "0px",
        fontSize: "15px",
        color: colours.greyColour,
      },
    },
    actionButton: {
      borderColor: colours.secondaryPink,
      color: colours.secondaryPink,
      alignSelf: "center",
      width: "fit-content",
      margin: "20px",
      fontSize: "1rem",
    },
    containedActionButton: {
      borderColor: colours.secondaryPink,
      color: colours.whiteColour,
      backgroundColor: colours.secondaryPink,
      alignSelf: "center",
      width: "fit-content",
      margin: "10px",
      boxShadow: "none",
    },
    saveButton: {
      "&:hover": {
        backgroundColor: colours.secondaryHover,
      },
    },
    buttonContainer: {
      wdith: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    layoutThumbnail: {
      border: "solid 2px",
      borderColor: colours.greySelectedColour,
      borderRadius: "5px",
      height: "100px",
      width: "175px",
      marginRight: "10px",
      display: "flex",
      "&:hover": {
        cursor: "pointer",
        borderColor: colours.secondaryPink,
      },
    },
    thumbnailContainer: {
      display: "flex",
    },
    column: {
      display: "flex",
      flexDirection: "column" as any,
      flex: "auto",
    },
    layoutSection: {
      border: "solid 1px",
      borderColor: colours.greyColour,
      backgroundColor: colours.greySelectedColour,
      margin: "5px",
      flex: "auto",
      borderRadius: "2px",
    },
    layoutContainer: {
      display: "flex",
      flexDirection: "column" as any,
      "& h3": {
        color: colours.greyColour,
        fontFamily: "Varela Round",
        fontWeight: "400",
        marginTop: "10px",
      },
    },
    selectedLayoutThumbnail: {
      borderColor: colours.secondaryPink,
    },
    contentContainer: {
      border: "solid 2px",
      borderColor: colours.greySelectedColour,
      borderRadius: "5px",
      width: "100%",
      display: "flex",
      paddingBottom: "10px",
      flex: 1,
    },
    contentColumn: {
      display: "flex",
      flexDirection: "column" as any,
      flex: "auto",
    },
    contentSection: {
      border: "solid 1px",
      borderColor: colours.greyColour,
      margin: "10px",
      marginBottom: "0px",
      flex: "auto",
      borderRadius: "5px",
      padding: "10px",
      "& h3": {
        color: colours.greyColour,
        fontFamily: "Varela Round",
        fontWeight: "400",
      },
      minWidth: "200px",
    },
    contentSectionHeader: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "1rem",
    },
    popover: {
      zIndex: 10000,
    },
    selectionContainer: {
      display: "flex",
      flexDirection: "column" as any,
      marginRight: "20px",
      minWidth: "300px",
    },
    sectionDropdown: {
      width: "100%",
      maxWidth: "450px",
      paddingRight: "10px",
      marginBottom: "10px",
    },
    topContainer: {
      display: "flex",
    },
    conflictLabel: {
      width: "100%",
      justifyContent: "space-between",
      margin: "5px",
      fontFamily: fonts.primary,
      fontSize: "14px",
    },
    colorRequimentSecondary: {
      color: colours.secondaryPink + " !important",
    },
    colorGrey: {
      color: colours.greyColour + " !important",
    },
    contentSelectionOptions: {
      paddingTop: "0.8rem",
    },
    screenThumbnail: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      width: "100%",
    },
    controlLabel: {
      fontSize: "1.1rem",
    },
    headerTypeLabel: {
      fontSize: "1.4rem",
    },
    headerTypeDropdown: {
      marginTop: "1.5rem !important",
    },
  };
};
