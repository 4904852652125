import { Grid, makeStyles } from "@material-ui/core";
import {
  ApiContextProvider,
  ControlContextProvider,
  SectionDisplay,
} from "@pulsion/forms-designer-2";
import { projectStrings as strings } from "../../../resources/strings";
import { getControlAttachment } from "../../../services/filesService";
import { rendererTheme } from "../../../styles/formsDesigner/rendererTheme";
import { getStyles } from "../../../styles/projects/changeHistory";
import {
  Component,
  SectionChangeEventType as EventType,
  Section,
  SectionChange,
} from "../../../types";

interface Props {
  projectSections: Section[];
  projectId: string;
  tenantId: string;
  sectionChange: SectionChange | undefined;
  sectionChangeList: SectionChange[];
  sectionId: string | undefined;
}

interface SectionPanelDisplayProps {
  projectSections: any[];
  projectId: string;
  tenantId: string;
  sectionId: string;
}

enum PlaceholderType {
  Add = "Add",
  Remove = "Remove",
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const SectionPanels: Component<Props> = ({
  projectSections,
  projectId,
  tenantId,
  sectionChange,
  sectionChangeList,
  sectionId,
}) => {
  const classes = useStyles();

  if (!sectionId) {
    return <></>;
  }
  const section = projectSections.find((x) => x.sectionId === sectionId);
  if (!sectionChange || !section) {
    return <></>;
  }

  const {
    previousSectionJson,
    currentSectionJson,
    eventType,
    previousActive,
    currentActive,
    previousDisplayOrder,
    currentDisplayOrder,
    sectionId: sectionIdOfChange,
    parentSectionJson,
    childrenSectionJson,
    siblingSectionJson,
    previousSiblingSectionJson,
  } = sectionChange;

  const sectionIndex = projectSections.findIndex(
    (x) => x.sectionId === sectionId
  );

  const isDeletion = previousActive === true && currentActive === false;
  const isChangeToChildSection = sectionIdOfChange !== sectionId;

  let beforePanel: JSX.Element | string = <></>;
  let afterPanel: JSX.Element | string = <></>;

  let projectSectionsBeforeChange = cloneProjectSections(projectSections);
  let projectSectionsAfterChange = cloneProjectSections(projectSections);

  const setAfterSection = () => {
    if (!currentSectionJson) return;
    projectSectionsAfterChange[sectionIndex] = {
      ...section,
      sectionFormData: currentSectionJson,
      displayOrder: currentDisplayOrder,
    };
  };

  const setBeforeSection = () => {
    if (!previousSectionJson) return;
    projectSectionsBeforeChange[sectionIndex] = {
      ...section,
      sectionFormData: previousSectionJson,
      displayOrder: previousDisplayOrder,
    };
  };

  const setParentSection = () => {
    const parentSection = {
      ...section,
      sectionFormData: parentSectionJson,
    };

    projectSectionsBeforeChange[sectionIndex] = parentSection;
    projectSectionsAfterChange[sectionIndex] = parentSection;
  };

  const sectionPanelDisplay = (projectSections: any[]) => {
    return (
      <SectionPanelDisplay
        projectId={projectId}
        tenantId={tenantId}
        sectionId={sectionId}
        projectSections={projectSections}
      />
    );
  };

  const nonExistentSectionPlaceholder = (type: PlaceholderType) => {
    const text =
      type === PlaceholderType.Add
        ? strings.placeholderText.sectionAddition
        : strings.placeholderText.sectionRemoval;
    return (
      <div className={classes.placeholder}>
        <div>{text}</div>
      </div>
    );
  };

  if (isChangeToChildSection) {
    setParentSection();
  }

  if (isChangeToChildSection && eventType === EventType.REORDER) {
    if (siblingSectionJson) {
      const groupedSections = getGroupedChildSections(siblingSectionJson);

      [projectSectionsAfterChange] = replaceChildSections(groupedSections, [
        projectSectionsAfterChange,
      ]);
    }

    if (previousSiblingSectionJson) {
      const groupedSections = getGroupedChildSections(
        previousSiblingSectionJson
      );

      [projectSectionsBeforeChange] = replaceChildSections(groupedSections, [
        projectSectionsBeforeChange,
      ]);
    }

    beforePanel = sectionPanelDisplay(projectSectionsBeforeChange);
    afterPanel = sectionPanelDisplay(projectSectionsAfterChange);
  } else if (isChangeToChildSection && eventType === "INSERT") {
    if (siblingSectionJson) {
      const groupedSections = getGroupedChildSections(siblingSectionJson);

      [projectSectionsBeforeChange, projectSectionsAfterChange] =
        replaceChildSections(groupedSections, [
          projectSectionsBeforeChange,
          projectSectionsAfterChange,
        ]);

      projectSectionsBeforeChange = projectSectionsBeforeChange.filter(
        (x) => x.sectionId !== sectionIdOfChange
      );
    }

    beforePanel = sectionPanelDisplay(projectSectionsBeforeChange);
    afterPanel = sectionPanelDisplay(projectSectionsAfterChange);
  } else if (isChangeToChildSection && eventType === "MODIFY") {
    if (siblingSectionJson) {
      const childSections = JSON.parse(siblingSectionJson) as any[];
      const previousChildSections = mapChildFormSections(
        childSections,
        sectionIdOfChange,
        previousSectionJson,
        previousDisplayOrder
      );
      const currentChildSections = mapChildFormSections(
        childSections,
        sectionIdOfChange,
        currentSectionJson,
        currentDisplayOrder
      );

      const groupedPreviousSections = groupBySectionTypeId(
        previousChildSections
      );

      [projectSectionsBeforeChange] = replaceChildSections(
        groupedPreviousSections,
        [projectSectionsBeforeChange]
      );

      const groupedCurrentSections = groupBySectionTypeId(currentChildSections);

      [projectSectionsAfterChange] = replaceChildSections(
        groupedCurrentSections,
        [projectSectionsAfterChange]
      );
    }

    beforePanel = sectionPanelDisplay(projectSectionsBeforeChange);
    afterPanel = sectionPanelDisplay(projectSectionsAfterChange);
  } else if (isDeletion) {
    setBeforeSection();

    beforePanel = sectionPanelDisplay(projectSectionsBeforeChange);
    afterPanel = nonExistentSectionPlaceholder(PlaceholderType.Remove);
  } else if (eventType === EventType.INSERT && currentSectionJson) {
    setAfterSection();

    if (childrenSectionJson) {
      const groupedSections = getGroupedChildSections(childrenSectionJson);

      [projectSectionsAfterChange] = replaceChildSections(groupedSections, [
        projectSectionsAfterChange,
      ]);
    }

    beforePanel = nonExistentSectionPlaceholder(PlaceholderType.Add);
    afterPanel = sectionPanelDisplay(projectSectionsAfterChange);
  } else if (
    eventType === EventType.MODIFY &&
    currentSectionJson &&
    previousSectionJson
  ) {
    setBeforeSection();
    setAfterSection();

    if (childrenSectionJson) {
      const groupedSections = getGroupedChildSections(childrenSectionJson);
      [projectSectionsBeforeChange, projectSectionsAfterChange] =
        replaceChildSections(groupedSections, [
          projectSectionsBeforeChange,
          projectSectionsAfterChange,
        ]);
    }

    beforePanel = sectionPanelDisplay(projectSectionsBeforeChange);
    afterPanel = sectionPanelDisplay(projectSectionsAfterChange);
  }

  return (
    <div aria-label="section-panels">
      <Grid container className={classes.grid}>
        <Grid xs={6} className={classes.leftGrid}>
          <p className={classes.panelLabel}>{strings.labels.before}</p>
          {beforePanel}
        </Grid>
        <Grid xs={6}>
          <p className={classes.panelLabel}>{strings.labels.after}</p>
          {afterPanel}
        </Grid>
      </Grid>
    </div>
  );
};

const SectionPanelDisplay: Component<SectionPanelDisplayProps> = ({
  projectSections,
  projectId,
  tenantId,
  sectionId,
}) => {
  return (
    <div key={`section-display-${sectionId}`}>
      <ApiContextProvider api={{ getControlAttachment } as any}>
        <ControlContextProvider allowControlChanges={false} readonly>
          <SectionDisplay
            key={sectionId}
            jobFormData={projectSections}
            selectedSectionId={sectionId}
            onControlDataChange={() => {}}
            onAddGridRow={async () => {
              return "";
            }}
            onDeleteGridRow={() => {}}
            onDuplicateGridRow={() => {}}
            onUndoRowChanges={() => {}}
            onReorderSections={() => {}}
            jobId={projectId}
            updateSectionData={undefined}
            rootOrganisation={tenantId}
            onControlFocusOut={() => {}}
            addRowStatus="idle"
            deleteRowStatus="idle"
            allowChanges={false}
            activeControl={undefined}
            onControlSelect={(id: string) => {}}
            onSectionDataChange={() => {}}
            setDataChangeState={undefined}
            mustBeComplete={false}
            designer={false}
            theme={rendererTheme}
          />
        </ControlContextProvider>
      </ApiContextProvider>
    </div>
  );
};

const groupBySectionTypeId = function <T extends { [key: string]: any }>(
  data: T[]
) {
  return data.reduce(function (storage: { [key: string]: T[] }, item) {
    const group = item["sectionTypeId"] as string;
    storage[group] = storage[group] || [];
    storage[group].push(item);
    return storage;
  }, {});
};

function cloneProjectSections(projectSections: any[]) {
  return JSON.parse(JSON.stringify(projectSections)) as any[];
}

const mapChildFormSections = (
  childSections: any[],
  childSectionId: string,
  childSectionJson: string | undefined,
  displayOrder: number | null
) => {
  return childSections.map((x) => ({
    sectionId: x.sectionId,
    sectionTypeId: x.sectionTypeId,
    sectionCaption: x.caption,
    internalSectionTypeId: x.internalSectionTypeId,
    sectionFormData:
      x.sectionId === childSectionId && childSectionJson
        ? childSectionJson
        : x.sectionJson,
    gridInput: x.gridInput,
    repeatable: x.repeatable,
    sectionOrder: x.order,
    displayOrder:
      x.sectionId === childSectionId ? displayOrder : x.displayOrder,
    visible: x.visible,
    fqn: x.fqn,
  })) as Section[];
};

function getGroupedChildSections(sections: string) {
  const childSections = JSON.parse(sections) as any[];
  const childFormSections = childSections.map((x) => ({
    sectionId: x.sectionId,
    sectionTypeId: x.sectionTypeId,
    sectionCaption: x.caption,
    internalSectionTypeId: x.internalSectionTypeId,
    sectionFormData: x.sectionJson,
    gridInput: x.gridInput,
    repeatable: x.repeatable,
    sectionOrder: x.order,
    displayOrder: x.displayOrder,
    visible: x.visible,
    fqn: x.fqn,
  })) as Section[];
  return groupBySectionTypeId(childFormSections);
}

function replaceChildSections(
  groupedChildSections: { [key: string]: Section[] },
  projectSectionsInstances: any[][]
) {
  const newProjectSectionsInstances: any[][] = JSON.parse(
    JSON.stringify(projectSectionsInstances)
  );
  for (const [sectionTypeId, sections] of Object.entries(
    groupedChildSections
  )) {
    sections.sort((a, b) => {
      return (a.displayOrder ?? 0) - (b.displayOrder ?? 0);
    });

    newProjectSectionsInstances.forEach((projectSections, index) => {
      let newProjectSections = projectSections.filter(
        (x) =>
          x.sectionTypeId !== sectionTypeId || Number.isInteger(x.sectionOrder)
      );

      newProjectSections = newProjectSections.concat(sections);

      newProjectSectionsInstances[index] = newProjectSections;
    });
  }

  return newProjectSectionsInstances;
}
