import { Theme } from "@material-ui/core";

export const getStyles = (theme: Theme) => {
  return {
    MuiButtonOutlined: {
      borderWidth: "2px",
      borderColor: "rgba(114, 96, 207, 0.5)",
      "&:hover": {
        borderWidth: "2px",
        borderColor: "rgba(114, 96, 207, 0.5)",
      },
    },
  };
};
