import { Theme } from "@material-ui/core";
import { fonts } from "./theme";

export const common = (theme: Theme) => {
  return {
    content: {
      width: "100%",
      flexDirection: "column" as any,
      justifyContent: "center",
      "& h2": {
        margin: "5px",
      },
      "& Button": {
        minWidth: "120px !important",
        boxShadow: "none !important",
        fontFamily: fonts.primary,
        textTransform: "none !important",
        fontSize: "15px !important",
        marginLeft: "10px",
      },
    },
    header: {
      backgroundColor: theme.palette.secondary.light,
      padding: "20px",
      borderBottom: "solid 1px " + theme.palette.secondary.main,
      display: "flex",
      justifyContent: "space-between",
      "& h2": {
        fontSize: "18px",
        fontWeight: "500",
        fontFamily: fonts.primary,
      },
      flex: "0 1 auto",
    },
    noBorder: {
      borderBottom: "none",
    },
    empty: {
      width: "100%",
      height: "300px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& p": {
        fontSize: "15px",
        color: theme.palette.secondary.dark,
      },
    },
    logo: {
      maxWidth: "200px",
      maxHeight: "60px",
      marginRight: "33px",
    },
    icon: {
      alignSelf: "center",
    },
    iconContainer: {
      width: "50px",
      display: "flex",
      "& p": {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: theme.palette.primary.main,
        marginBottom: "0px",
        alignSelf: "center",
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
};
