import { FormHelperText } from "@material-ui/core";
import { Component } from "../../../types";
import { Label } from "./Label";
import clsx from "clsx";

interface Props {
  inputData: number;
  labelText: string;
  required?: boolean;
  errorMessage?: string;
  onChange: (event: any) => void;
};

export const NumberInput: Component<Props> = ({ inputData, labelText, required, errorMessage, onChange }) => {
  return (
    <div aria-label="form-number-input">
      <span className="inputHeader">
        <Label text={labelText} required={required} />
      </span>
      <input 
        aria-label="number-input" 
        type="number" 
        value={inputData} 
        className={clsx("editable", "input-padding", "editable-input", "input-default")} 
        onChange={onChange}
      />
      {errorMessage ? (
        <FormHelperText error>{errorMessage}</FormHelperText>
      ) : null}
    </div>
  );
};