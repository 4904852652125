import { Component } from "../../types";
import { DropdownDisplay } from "../general/Form/Dropdown/DropdownDisplay";

interface Props {
  onClick: (...args: any[]) => void;
  className?: string;
  options: { label: string; value: string }[];
}

export const InsertTagSelector: Component<Props> = ({
  onClick,
  className,
  options,
}) => {
  const onChange = (event: any) => {
    onClick(event.target.value);
  };

  return (
    <DropdownDisplay
      inputLabel="Insert Tag"
      options={options}
      className={className}
      value=""
      onChange={onChange}
    />
  );
};
