import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    root: {
      flexGrow: 1,
      minHeight: "100vh",
      backgroundColor: colours.darkBlueColour,
      height: "100vh" as any,
    },
    image: {
      width: "auto",
      height: "100vh",
    },
    logo: {
      height: "auto",
      width: "auto",
      maxWidth: "290px",
      alignSelf: "center",
    },
    form: {
      marginTop: "1rem",
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center" as any,
    },
    content: {
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "space-evenly",
      marginBottom: "3rem",
      width: "75%",
    },
    caption: {
      paddingTop: "30px",
      color: colours.whiteColour,
      fontSize: "14px",
      fontFamily: fonts.secondary,
    },
    links: {
      fontFamily: fonts.secondary,
      fontSize: "14px",
      display: "inline",
      textDecoration: "underline",
    },
    linkSpace: {
      fontFamily: fonts.secondary,
      fontSize: "12px",
      display: "inline",
    },
    loginLink: {
      color: colours.whiteColour,
      "& $a": {
        color: colours.whiteColour,
      },
    },
    scrollable: {
      overflow: "auto",
    },
    fullHeight: {
      height: "100%",
    },
    smallPrint: {
      textAlign: "center" as any,
    },
    loginSpan: {
      display: "inline-block",
    },
  };
};
