import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    formGroup: {
      paddingTop: "8rem",
      paddingLeft: "2rem",
      width: "70%",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    primaryText: {
      fontFamily: fonts.primary,
    },
    inputLabel: {
      fontFamily: fonts.secondary,
      fontSize: "1.2rem",
      position: "static" as any,
    },
    card: {
      position: "relative" as any,
      width: "100%",
      height: "40rem",
      backgroundColor: colours.whiteColour,
      borderRadius: "1rem",
    },
    cardHeaderTitle: {
      fontFamily: fonts.primary,
      fontSize: "1.5rem",
      paddingLeft: "1rem",
    },
    cardHeader: {
      backgroundColor: colours.greySelectedColour,
    },
    infoText: {
      fontFamily: fonts.primary,
      color: colours.whiteColour,
      textTransform: "none" as any,
      textAlign: "center" as any,
      paddingTop: "1rem",
    },
    infoTextHeader: {
      fontSize: "1.4rem",
    },
    infoTextSubHeader: {
      fontSize: "1.2rem",
    },
    userDeletion: {
      color: theme.palette.secondary.main,
      fontWeight: 600 as any,
    },
    progressBox: {
      paddingBottom: "3rem",
      paddingLeft: "2.5rem",
      display: "flex",
      bottom: 0,
      position: "absolute" as any,
    },
    progressText: {
      fontSize: "1.2rem",
      fontFamily: fonts.primary,
    },
    userDeletionPanel: {
      display: "flex",
      justifyContent: "center",
      height: "100%",
      paddingTop: "6rem",
      paddingRight: "3rem",
    },
  };
};
