import { Button } from "@material-ui/core";
import { MouseEventHandler, useEffect, useState } from "react";
import { HelpCarousel } from "../../components/carousel/HelpCarousel";
import { Notification } from "../../components/general";
import { FileUpload } from "../../components/general/FileUploader/FileUpload";
import { LinkItemConfig, NavLinks } from "../../components/layout/NavLinks";
import { paths } from "../../navigation";
import {
  appStrings,
  ticketTemplateStrings as strings,
} from "../../resources/strings";
import {
  tutorialStrings,
  uploadTutorialSteps,
} from "../../resources/strings/tutorial";
import {
  getTicketUploadTemplate,
  uploadJiraTicketTemplate,
} from "../../services/jiraGenService";
import { Component, RouteProps } from "../../types";
import { FileData } from "../../types/documents/ImageFile";

export interface Props extends RouteProps {
  closeModal: () => void;
  openModal: () => void;
  tutorialOpen: boolean;
}

export const UploadTicketTemplates: Component<Props> = ({
  closeModal,
  openModal,
  tutorialOpen,
}) => {
  const [success, setSuccess] = useState(false);
  const linkItems: LinkItemConfig[] = [
    {
      to: paths.ticketTemplates.list,
      text: strings.labels.navLinkLabels.ticketTemplateList,
    },
  ];

  const onSubmit = async (files: FileData[]) => {
    const fileToUpload = files[0];
    if (!fileToUpload) {
      throw new Error("Cannot upload task template file as it is invalid");
    }

    await uploadJiraTicketTemplate(fileToUpload.data);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  const downloadTemplate = async () => {
    const result = await getTicketUploadTemplate();
    window.open(result, appStrings.other.linkTargetNewTab);
  };

  const button = (
    onClick: MouseEventHandler<HTMLButtonElement> | undefined,
    label: string
  ) => {
    return (
      <Button
        className="action-button"
        onClick={onClick}
        color="primary"
        variant={"outlined"}
      >
        {label}
      </Button>
    );
  };

  useEffect(() => {
    if (tutorialOpen) {
      openModal();
    }
  }, [tutorialOpen, openModal]);

  return (
    <div>
      <HelpCarousel
        closeModal={closeModal}
        isModalOpen={tutorialOpen}
        tutorialSteps={uploadTutorialSteps}
        header={tutorialStrings.labels.tasksGuide}
      />
      <div className="edit-header">
        <NavLinks
          linkItems={linkItems}
          currentPageText={strings.labels.uploadTasks}
        />
        <span className="project-modify-buttons">
          {button(openModal, strings.labels.uploadInformation)}
          {button(
            () => downloadTemplate(),

            strings.labels.downloadTemplate
          )}
        </span>
      </div>
      <FileUpload
        accept="text/csv"
        maxFiles={1}
        onSubmit={onSubmit}
        showUploadTable={false}
      />
      <Notification message={success ? strings.labels.uploadSuccess : ""} />
    </div>
  );
};
