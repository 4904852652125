import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    element: {
      "& .nav-link": {
        display: "flex",
        paddingTop: "10px",
        paddingRight: "5px",
        fontSize: "1rem",
        fontFamily: fonts.secondary,
      },
      "& .text": {
        display: "flex",
        paddingTop: "10px",
        paddingRight: "5px",
        fontSize: "1rem",
        fontFamily: fonts.secondary,
      },
    },
    root: {
      display: "flex",
      marginLeft: "auto",
      marginRight: "10px",
      height: "40px",
    },
    text: {
      color: colours.greyColour,
    },
    link: {
      color: colours.primaryPurple,
      textDecoration: "underline",
    },
  };
};
