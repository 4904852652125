import { ErrorMessage } from "../components/general/types/Modify";
import { authStrings } from "../resources/strings/auth";

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    const axiosError = error as any;
    const innerError = axiosError?.response?.data?.error;
    if (typeof innerError === "string" && innerError !== "") {
      return innerError;
    }

    if (typeof error.message === "string" && error.message !== "") {
      return error.message;
    }
  }

  return String(error);
}

export function getUserFacingErrorMessage(
  error: string,
  errorMessages: ErrorMessage[] = authStrings.errors.aws
) {
  let displayMessage = authStrings.errors.unknown;
  errorMessages.forEach((authError) => {
    if (
      error === authError.errorMessage ||
      error.startsWith(authError.errorMessage)
    ) {
      displayMessage = authError.displayMessage;
    }
  });
  return displayMessage;
}

export function getDescriptiveErrorMessage(error: any) {
  try {
    return error.response.data.error;
  } catch (e) {
    return getErrorMessage(e);
  }
}
