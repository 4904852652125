import { Typography } from "@material-ui/core";
import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../components/authentication";

import { appStrings as strings } from "../resources/strings";
import { paths } from "./paths";

import { PrivacyPolicy } from "../components/general/PrivacyPolicy";
import { TermsOfService } from "../components/general/TermsOfService";

import {
  CustomLogin as Login,
  PasswordReset,
  SignUp,
} from "../containers/authentication";

import { ProjectList, ProjectModify } from "../containers/projects";
import { ProjectsRenderer } from "../containers/projects/ProjectsRenderer";
import { WireframesRenderer } from "../containers/wireframes/WireframesRenderer";

import { EstimationContainer } from "../containers/estimation/EstimationContainer";

import { TicketTemplateModify } from "../containers/jiraTickets/TicketTemplateModify";

import { TicketTemplatesList } from "../containers/jiraTickets/TicketTemplatesList";
import { DateHelper } from "../helpers";
import { Component, RouteProps } from "../types";

import { ProductsPage } from "../containers/authentication/ProductsPage";
import { SubscriptionIssue } from "../containers/authentication/SubscriptionIssue";
import { SuccessPage } from "../containers/authentication/SuccessPage";
import { UserLimitIssue } from "../containers/authentication/UserLimitIssue";
import { ImageLibraryList } from "../containers/imageLibrary/ImageLibraryList";
import { ImageLibraryUpload } from "../containers/imageLibrary/ImageLibraryUpload";
import { UploadTicketTemplates } from "../containers/jiraTickets/UploadTicketTemplates";
import { ChangeHistory } from "../containers/projects/ChangeHistory/ChangeHistory";
import { EntityLibraryList } from "../containers/projects/EntityLibrary/EntityLibraryList";
import { UserList, UserModify } from "../containers/users";

const dateHelper = new DateHelper(new Date());

export interface Props extends RouteProps {
  enableMenu: () => void;
  disableMenu: () => void;
  tutorialOpen: boolean;
  menuDisabled: boolean;
}

type TRoutes = JSX.Element[];

export const Routes: Component<Props> = ({
  enableMenu,
  disableMenu,
  tutorialOpen,
  menuDisabled,
  ...props
}) => {
  const appRoutes: TRoutes = [
    <Route key="login" exact path={paths.auth.login}>
      <Login user={props.user} dateHelper={dateHelper} />
    </Route>,
    <Route key="passwordReset" exact path={paths.auth.passwordReset}>
      <PasswordReset user={props.user} />
    </Route>,
    <Route key="signUp" exact path={paths.auth.signUp}>
      <SignUp user={props.user} dateHelper={dateHelper} />
    </Route>,
    <Route key="root" exact path={paths.root}>
      <Redirect to={paths.projects.list} />
    </Route>,
    <Route key="terms" exact path={paths.termsAndConditions}>
      <TermsOfService loggedOut={!props.user} />
    </Route>,
    <Route key="terms" exact path={paths.privacyPolicy}>
      <PrivacyPolicy loggedOut={!props.user} />
    </Route>,
    <Route key="products" exact path={paths.products}>
      <ProductsPage user={props.user} />
    </Route>,
    <Route key="success" exact path={paths.success}>
      <SuccessPage user={props.user} />
    </Route>,
    <Route key="subscriptionIssue" exact path={paths.subscriptionIssue}>
      <SubscriptionIssue user={props.user} />
    </Route>,
    <Route key="userLimitIssue" exact path={paths.userLimitIssue}>
      <UserLimitIssue user={props.user} />
    </Route>,
  ];

  const projectRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="listProjects"
      exact
      path={paths.projects.list}
    >
      <ProjectList />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="viewProject"
      exact
      path={paths.projects.view(":id")}
    >
      <ProjectModify
        {...props}
        mode="update"
        enableMenu={enableMenu}
        disableMenu={disableMenu}
      />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="renderProject"
      exact
      path={paths.projects.render(":id")}
    >
      <ProjectsRenderer {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createProject"
      exact
      path={paths.projects.create()}
    >
      <ProjectModify
        {...props}
        mode="create"
        enableMenu={enableMenu}
        disableMenu={disableMenu}
      />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="viewChangeHistory"
      exact
      path={paths.projects.viewChangeHistory(":id")}
    >
      <ChangeHistory {...props} />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="entityLibraryList"
      exact
      path={paths.entityLibrary.list}
    >
      <EntityLibraryList />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="imageLibraryList"
      exact
      path={paths.imageLibrary.list}
    >
      <ImageLibraryList />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="imageLibraryUpload"
      exact
      path={paths.imageLibrary.upload}
    >
      <ImageLibraryUpload />
    </PrivateRoute>,
  ];

  const estimationRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="generateEstimates"
      exact
      path={paths.generateEstimates(":id")}
      userAccess={{
        entity: strings.entities.project,
        permission: strings.permissions.estimation,
      }}
    >
      <EstimationContainer />
    </PrivateRoute>,
  ];

  const jiraTicketRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="listTicketTemplates"
      exact
      path={paths.ticketTemplates.list}
      userAccess={{
        entity: strings.entities.project,
        permission: strings.permissions.taskGeneration,
      }}
    >
      <TicketTemplatesList />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="uploadTicketTemplates"
      exact
      path={paths.ticketTemplates.upload}
      userAccess={{
        entity: strings.entities.project,
        permission: strings.permissions.taskGeneration,
      }}
    >
      <UploadTicketTemplates
        {...props}
        closeModal={enableMenu}
        openModal={disableMenu}
        tutorialOpen={tutorialOpen}
      />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createTicketTemplate"
      exact
      path={paths.ticketTemplates.create}
      userAccess={{
        entity: strings.entities.project,
        permission: strings.permissions.taskGeneration,
      }}
    >
      <TicketTemplateModify
        {...props}
        mode="create"
        enableMenu={enableMenu}
        disableMenu={disableMenu}
      />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="modifyTicketTemplate"
      exact
      path={paths.ticketTemplates.modify(":id")}
      userAccess={{
        entity: strings.entities.project,
        permission: strings.permissions.taskGeneration,
      }}
    >
      <TicketTemplateModify
        {...props}
        mode="update"
        enableMenu={enableMenu}
        disableMenu={disableMenu}
      />
    </PrivateRoute>,
  ];

  const userRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="viewUser"
      exact
      path={paths.users.view(":id")}
      userAccess={{
        entity: strings.entities.user,
        permission: strings.permissions.manage,
      }}
    >
      <UserModify
        {...props}
        mode="view"
        enableMenu={enableMenu}
        disableMenu={disableMenu}
      />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="createUser"
      exact
      path={paths.users.create}
      userAccess={{
        entity: strings.entities.user,
        permission: strings.permissions.manage,
      }}
    >
      <UserModify
        {...props}
        mode="create"
        enableMenu={enableMenu}
        disableMenu={disableMenu}
      />
    </PrivateRoute>,
    <PrivateRoute
      {...props}
      key="userList"
      exact
      path={paths.users.list}
      userAccess={{
        entity: strings.entities.user,
        permission: strings.permissions.manage,
      }}
    >
      <UserList {...props} />
    </PrivateRoute>,
  ];

  const wireframesRoutes: TRoutes = [
    <PrivateRoute
      {...props}
      key="renderWireframes"
      exact
      path={paths.wireframes(":id")}
      userAccess={{
        entity: strings.entities.project,
        permission: strings.permissions.wireframes,
      }}
    >
      <WireframesRenderer dateHelper={dateHelper} />
    </PrivateRoute>,
  ];

  return (
    <Switch>
      {appRoutes}
      {projectRoutes}
      {estimationRoutes}
      {jiraTicketRoutes}
      {userRoutes}
      {wireframesRoutes}
      <Route key="notFound">
        <Typography>{strings.labels.pageNotFound}</Typography>
      </Route>
    </Switch>
  );
};
