import { IEntityListApi } from "@pulsion/forms-designer-2";
import {
  deleteLibraryImage,
  getLibraryImages,
} from "../services/imageLibraryService";

export const imageLibraryApi: IEntityListApi = {
  getEntityRows: getLibraryImages,
  deleteRow: deleteLibraryImage,
};
