import { FormHelperText } from "@material-ui/core";
import clsx from "clsx";
import { CSSProperties } from "react";
import { Component } from "../../../types";
import { Label } from "./Label";

interface Props {
  inputData: string;
  labelText: string;
  errorMessage?: string;
  required?: boolean;
  onChange: (event: any) => void;
  numberOfRows?: number;
  className?: string;
  labelStyle?: CSSProperties;
  disabled?: boolean;
  placeholder?: string;
  autofocus?: boolean;
}

export const TextInput: Component<Props> = ({
  inputData,
  labelText,
  errorMessage,
  required,
  onChange,
  numberOfRows = 1,
  className,
  labelStyle,
  disabled = false,
  autofocus,
  placeholder,
}) => {
  return (
    <div aria-label="form-text-input">
      <span className="inputHeader">
        <Label text={labelText} style={labelStyle} required={required} />
      </span>
      <textarea
        autoFocus={autofocus}
        disabled={disabled}
        rows={numberOfRows}
        aria-label="text-input"
        value={inputData}
        className={clsx(
          "editable",
          "input-padding",
          "input-default",
          className,
          !inputData && "light-text"
        )}
        onChange={onChange}
        placeholder={placeholder}
      />
      {errorMessage ? (
        <FormHelperText error>{errorMessage}</FormHelperText>
      ) : null}
    </div>
  );
};
