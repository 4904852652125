import { FormLabel, makeStyles } from "@material-ui/core";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import {
  getDisplayValue,
  isNumberEven,
} from "../../../helpers/wireframesHelper";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/fields";
import { Component } from "../../../types";
import { Field } from "../../../types/Wireframes";
import { CustomImageInput } from "../controls/CustomImageInput";

interface Props {
  fields: Field[];
  selectedIndex: number;
  fullWidth: boolean;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const TextFields: Component<Props> = ({
  fields,
  selectedIndex,
  fullWidth,
}) => {
  const classes = useStyles();
  const { isMobile } = useScreenSizeContext();

  const getTextField = (
    shouldReturnField: boolean,
    index: number,
    field: Field
  ) => {
    if (!shouldReturnField) {
      return null;
    }
    return (
      <div key={index} className={classes.textFieldContainer}>
        <FormLabel className={classes.formLabel}>{field.name}</FormLabel>
        {field.type === strings.fieldTypes.image ? (
          <CustomImageInput
            value={getDisplayValue(field, selectedIndex)}
            label={""}
            editable={false}
          />
        ) : (
          <p style={{ fontSize: "14px" }}>
            {getDisplayValue(field, selectedIndex)}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className={classes.fieldContainer}>
      {isMobile || !fullWidth ? (
        <div className={classes.mobileColumn}>
          {fields.map((field, index) => {
            return getTextField(true, index, field);
          })}
        </div>
      ) : (
        <>
          <div className={classes.column}>
            {fields.map((field, index) => {
              return getTextField(isNumberEven(index), index, field);
            })}
          </div>
          <div className={classes.column}>
            {fields.map((field, index) => {
              return getTextField(!isNumberEven(index), index, field);
            })}
          </div>
        </>
      )}
    </div>
  );
};
