import { Button } from "@material-ui/core";
import clsx from "clsx";
import defaultImage from "./emptyListImage.png";

interface Props {
  infoText: string;
  titleText: string;
  onClickButton: () => void;
  buttonText: string;
  image?: string;
}

export const EmptyListDisplay = ({
  onClickButton,
  titleText,
  infoText,
  buttonText,
  image = defaultImage,
}: Props) => {
  return (
    <div id="alternativeDisplay">
      <div className="empty-list-content">
        <img alt="" src={image} height="192px" />
        <h3>{titleText}</h3>
        <p className={clsx("font-secondary", "info")}>{infoText}</p>
        <div className="empty-list-btn">
          <Button variant="contained" color="secondary" onClick={onClickButton}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
