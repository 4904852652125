import { Theme } from "@material-ui/core";
import { fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    snackBar: {
      zIndex: 10000,
    },
    snackBarContent: {
      fontFamily: fonts.secondary,
      fontSize: "1.2rem",
      justifyContent: "center",
      textAlign: "center" as any,
    },
    snackBar_error: {
      backgroundColor: theme.palette.error.main,
    },
    snackBar_info: {
      backgroundColor: theme.palette.info.main,
    },
  };
};
