import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircleOutline } from "@material-ui/icons";
import logo from "../../assets/requiment-tick-icon.svg";
import { Modal as CustomModal } from "../../containers/general/Modal/Modal";
import { trialStartStrings } from "../../resources/strings";
import { getStyles } from "../../styles/general/popup";
import { Component } from "../../types";

const featureListItems = [
  "Requirements Document Output",
  "Wireframes",
  "Ticket Output",
];

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  isModalOpen: boolean;
  onClickOk: () => void;
  closeModal: () => void;
}

export const TrialStartPopup: Component<Props> = ({
  isModalOpen,
  onClickOk,
  closeModal,
}) => {
  const classes = useStyles();

  const buttonConfiguration = {
    confirmConfig: {
      text: trialStartStrings.ok,
      onClick: onClickOk,
    },
  };

  return (
    <CustomModal
      active={isModalOpen}
      header={trialStartStrings.header}
      buttonConfiguration={buttonConfiguration}
      onCloseModal={closeModal}
    >
      <Paper square elevation={0} className={classes.content}>
        <img className={classes.logo} src={logo} alt={"Requiment logo"} />
        <Typography className={classes.trialStartHeader}>
          {trialStartStrings.headline}
        </Typography>
        <Typography className={classes.trialStartText}>
          {trialStartStrings.mainText}
        </Typography>
        <List>
          {featureListItems.map((feature) => (
            <ListItem className={classes.featureItem}>
              <ListItemAvatar>
                <CheckCircleOutline color="secondary" />
              </ListItemAvatar>
              <ListItemText
                primary={feature}
                classes={{ primary: classes.featureItemText }}
              />
            </ListItem>
          ))}
        </List>
        <Typography className={classes.trialStartText}>
          {trialStartStrings.entityLimit}
        </Typography>
      </Paper>
    </CustomModal>
  );
};
