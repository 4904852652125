import { getDisplayValue } from "../../../helpers/wireframesHelper";
import { constants } from "../../../resources/numerics/app";
import { Component } from "../../../types";
import { Entity } from "../../../types/Wireframes";
import { Card } from "./Card";

interface Props {
  entity: Entity;
  changePage: (actionName: string, entity: Entity, rowIndex: number) => void;
  detailed: boolean;
  deletable: boolean;
  readable: boolean;
  editable: boolean;
  selectedFields: string[];
}

export const CardList: Component<Props> = ({
  entity,
  changePage,
  detailed,
  deletable,
  readable,
  editable,
  selectedFields,
}) => {
  const getValues = (index: number) => {
    const values: { label: string; value: string; type: string }[] = [];
    entity.fields.forEach((field) => {
      if (selectedFields.includes(field.id)) {
        values.push({
          label: field.name,
          value: getDisplayValue(field, index),
          type: field.type,
        });
      }
    });
    return values;
  };

  return (
    <>
      {[...Array(constants.wireframesListRowCount)].map((row, rowIndex) => {
        const values = getValues(rowIndex);
        return (
          <div key={rowIndex}>
            <Card
              entity={entity}
              index={rowIndex}
              values={values}
              changePage={changePage}
              detailed={detailed}
              deletable={deletable}
              readable={readable}
              editable={editable}
            />
          </div>
        );
      })}
    </>
  );
};
