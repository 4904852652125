import {
  Button,
  FormHelperText,
  FormLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { InlineLink } from "../../components/general/InlineLink";
import { Loader } from "../../components/general/Loader";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { getUserFacingErrorMessage } from "../../helpers/errorHelper";
import { useForm } from "../../hooks/general/useForm";
import { forgotPassword, forgotPasswordSubmit } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { authStrings as strings } from "../../resources/strings/auth";
import { getStyles } from "../../styles/auth/auth";
import type { CognitoUser, Component } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
}

enum ResetPasswordFormField {
  email = "email",
  code = "code",
  newPassword = "newPassword",
  confirmNewPassword = "confirmNewPassword",
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const PasswordReset: Component<Props> = ({ user }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [codeSent, setCodeSent] = useState(false);

  const [formData, getChangeHandler] = useForm({
    email: "",
    code: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const history = useHistory();

  const handleChange = getChangeHandler();
  const clearFormFields = (formFields: ResetPasswordFormField[]) => {
    formFields.forEach((fieldName) => {
      handleChange({
        target: {
          name: fieldName,
          value: "",
        },
      } as any);
    });
  };

  const renderError = () => (
    <FormHelperText className={classes.errorText} error>
      {error}
    </FormHelperText>
  );

  useEffect(() => {
    if (user) history.push(paths.root);
    if (success) history.push(paths.auth.login);
  }, [user, success, history]);

  const onRequestCode: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await forgotPassword(formData.email);

      setCodeSent(true);
      setLoading(false);
      setError("");
    } catch (e) {
      // Continue on the "normal" flow even if account/email address doesn't exist
      // to help limit brute force attempts
      setCodeSent(true);
      setLoading(false);
      setSuccess(false);
    }
  };

  const onSubmitPassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await forgotPasswordSubmit(
        formData.email,
        formData.code,
        formData.newPassword
      );

      setLoading(false);
      setError("");
      setSuccess(true);
    } catch (e) {
      const errorMessage = getUserFacingErrorMessage(e);
      const fieldsToClear = [
        ResetPasswordFormField.newPassword,
        ResetPasswordFormField.confirmNewPassword,
      ];
      if (errorMessage === strings.errors.invalidVerificationCode) {
        setCodeSent(false);
        fieldsToClear.push(ResetPasswordFormField.code);
      }
      clearFormFields(fieldsToClear);
      setLoading(false);
      setSuccess(false);
      setError(errorMessage);
    }
  };

  const renderControl = (control: JSX.Element) => {
    return <BaseControl>{control}</BaseControl>;
  };

  const renderPasswordForm = () => {
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={classes.loginHeader}
        >
          {strings.headers.completePassword}
        </Typography>
        <form noValidate onSubmit={onSubmitPassword} autoComplete="off">
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.verificationCode}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  variant: "outlined",
                  value: formData.code,
                  fullWidth: true,
                  name: ResetPasswordFormField.code,
                  type: "password",
                  autoComplete: "code",
                  placeholder: strings.labels.verificationCodePlaceholder,
                  InputProps: { classes: { input: classes.input } },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.newPassword}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  variant: "outlined",
                  value: formData.newPassword,
                  fullWidth: true,
                  name: ResetPasswordFormField.newPassword,
                  type: "password",
                  autoComplete: "new-password",
                  placeholder: strings.labels.resetPasswordPlaceholder,
                  InputProps: { classes: { input: classes.input } },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.confirmNewPassword}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  variant: "outlined",
                  value: formData.confirmNewPassword,
                  fullWidth: true,
                  name: ResetPasswordFormField.confirmNewPassword,
                  type: "password",
                  autoComplete: "confirm-password",
                  placeholder: strings.labels.confirmResetPasswordPlaceholder,
                  InputProps: { classes: { input: classes.input } },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          <Button
            type="submit"
            children={strings.labels.confirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={[
              !formData.code,
              !formData.newPassword,
              !formData.confirmNewPassword,
              formData.newPassword !== formData.confirmNewPassword,
            ].includes(true)}
            className={classes.submitButton}
          />
          {error && renderError()}
        </form>
      </>
    );
  };

  const renderRequestCodeForm = () => {
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={classes.loginHeader}
        >
          {strings.labels.resetPassword}
        </Typography>
        <Typography color="inherit" className={classes.formLabel}>
          {strings.text.receiveCode}
        </Typography>
        <form
          noValidate
          onSubmit={onRequestCode}
          autoComplete="off"
          className={classes.fullWidth}
        >
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.email}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  value: formData.email,
                  name: ResetPasswordFormField.email,
                  variant: "outlined",
                  placeholder: strings.labels.enterYourEmailPlaceholder,
                  fullWidth: true,
                  autoComplete: "email",
                  InputLabelProps: { className: classes.inputLabel },
                  InputProps: { classes: { input: classes.input } },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          <Button
            type="submit"
            children={strings.labels.submit}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!formData.email}
            className={classes.submitButton}
          />
          {error && renderError()}
        </form>
        <div className={classes.paddingTopTwo}>
          <InlineLink
            className={classes.label}
            linkItems={[
              { text: strings.labels.returnToLogin, to: paths.auth.login },
            ]}
          />
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline />;
    }

    if (codeSent) return renderPasswordForm();
    return renderRequestCodeForm();
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
