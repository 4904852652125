import { Button, Typography, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { redirectToExternalURL } from "../../helpers/appHelper";
import { signOut } from "../../libs/auth";
import { paths } from "../../navigation";
import { authStrings as strings } from "../../resources/strings/auth";
import { getCustomerPortalLink } from "../../services/stripeService";
import { getStyles } from "../../styles/auth/auth";
import { CognitoUser, Component } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const SubscriptionIssue: Component<Props> = ({ user }) => {
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (user) history.push(paths.root);
  }, [user, history]);

  const onClickManageSubscription = async () => {
    try {
      const portalLink = await getCustomerPortalLink();
      redirectToExternalURL(portalLink);
      await signOut();
    } catch (e: any) {
      console.log("Could not get link to manage subscription. ", e.message);
      history.push(paths.auth.login);
    }
  };

  const renderContent = () => {
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={classes.loginHeader}
        >
          {strings.headers.manageSubscription}
        </Typography>
        <Typography color="primary" className={classes.infoText}>
          {strings.text.subscriptionIssue}
        </Typography>
        <div className="manageSubscription-button">
          <Button
            variant="contained"
            color="primary"
            className={classes.submitButton}
            fullWidth
            onClick={onClickManageSubscription}
          >
            {strings.labels.manageSubscription}
          </Button>
        </div>
      </>
    );
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
