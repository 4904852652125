export const surveyStrings = {
  headerText: "We Welcome Your Feeback",
  mainText: "Help us improve your experience by taking our short survey.",
  surveyLink: "https://www.surveymonkey.com/r/P2NRDZ7",
  maybeLater: "Maybe Later",
  giveFeedback: "Give Feedback",
};

export const privacyPolicyUpdateStrings = {
  ok: "OK",
  updatedPolicyHeader: "Updated privacy policy",
  mainTextPrefix: "Requiment has updated its",
  mainTextSuffix:
    " By continuing to use Requiment, you agree to these updates.",
};

export const trialStartStrings = {
  ok: "OK",
  header: "Requiment trial",
  headline: "Welcome to your 14 day trial of Requiment",
  mainText: "Your trial gives you access to all of the Silver tier features:",
  entityLimit: "While trialling, you can create up to 7 entities per project.",
};
