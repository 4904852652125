import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Loader, LoaderProps, ModifyComponent } from "../../components/general";
import { ModifyContextProvider } from "../../context/ModifyContext";
import { getErrorMessage } from "../../helpers/errorHelper";
import { useCancelToken } from "../../hooks/general";
import { HooksLogger } from "../../hooks/hooks-logger";
import { NotificationType, RouteProps } from "../../types";
import validate from "../../validation";

import type { ComponentConfiguration } from "../../components/general/ModifyComponent";
import { Notification } from "../../components/general/Notification";
import type {
  ChangeHandler,
  Mode,
} from "../../components/general/types/Modify";
import { getRedirectPath } from "../../helpers/projectHelper";
import type { Axios } from "../../libs/axios";
import { ValidationConstraints } from "../../validation/validators";

const logger = new HooksLogger("ModifyContainer/submitData");

interface Props<D extends object> extends RouteProps {
  children?: JSX.Element;
  getByIdFunction: (id: string, cancelToken: Axios.CancelToken) => object;
  createFunction: (dataObject: { [key: string]: unknown }) => any;
  updateFunction: (dataObject: any) => any;
  initialData: D;
  componentConfiguration: ComponentConfiguration<D>;
  mode: Mode;
  constraints: ValidationConstraints<D>;
  redirectPath?: string | ((id: string) => string);
  loading?: boolean;
  loaderProps?: Partial<LoaderProps>;
}

export const ModifyContainerUsingServices = <
  D extends { [key: string]: unknown }
>({
  children,
  getByIdFunction,
  createFunction,
  updateFunction,
  jwt,
  initialData: _initialData,
  componentConfiguration,
  mode: inputMode,
  permissions,
  constraints,
  redirectPath,
  loading: propsLoading = false,
  loaderProps,
}: Props<D>) => {
  const history = useHistory();
  const { id, childId } = useParams<{ id?: string; childId?: string }>();
  const [mode, setMode] = useState<Mode>(() => inputMode);
  const [initialData, setInitialData] = useState(() => _initialData);
  const [formData, setFormData] = useState<D>(initialData);
  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");
  const [validationResults, setValidationResults] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [success, setSuccess] = useState(() => false);
  const [message, setMessage] = useState<{
    message: string;
    type?: NotificationType;
  }>({ message: "", type: undefined });
  const [redirectId, setRedirectId] = useState("");
  const cancelToken = useCancelToken();

  useEffect(() => {
    const query = async () => {
      logger.request("Getting item data");

      setLoading(true);
      try {
        if (id === undefined) {
          return;
        }
        const item: any = await getByIdFunction(id, cancelToken);
        setFormData(item);
        setInitialData(item);
        setLoading(false);
        logger.success(item);
      } catch (e) {
        if (cancelToken.reason) return;
        const error = getErrorMessage(e);
        setError(error);
        setLoading(false);
        logger.error(error);
      }
    };

    if (id && inputMode !== "create") query();
  }, [id, childId, inputMode, cancelToken, getByIdFunction]);

  //ModeSwitch changes update to view, view to update, and redirects for create if the success property is set
  const handleModeSwitch = useCallback(() => {
    switch (mode) {
      case "update":
        setMode("view");
        break;
      case "view":
        setMode("update");
        break;
      case "create":
        if (!redirectPath) break;
        const path = getRedirectPath(redirectPath, redirectId);
        if (success && path) history.push(path);
        break;
      default:
        break;
    }
  }, [mode, history, redirectPath, redirectId, success]);

  const handleReset = useCallback(() => {
    setFormData(initialData);
    handleModeSwitch();
    setValidationResults(null);
  }, [initialData, handleModeSwitch]);

  useEffect(() => {
    if (success) {
      handleModeSwitch();
      setMessage({
        message: "Data submitted successfully",
        type: NotificationType.info,
      });
      setSuccess(false);
    }
  }, [success, handleModeSwitch]);

  const handleChange: ChangeHandler = (e) => {
    const { target } = e;
    const { name, value, checked } = target;
    if (!name) return;

    setFormData((prev) => ({
      ...prev,
      [name]: target.hasOwnProperty("checked") ? checked : value,
    }));
  };

  const submitData = async () => {
    console.log("Submitting data");
    logger.request("Submitting form data");

    setLoading(true);
    setValidationResults(null);
    try {
      if (mode === "create") {
        const { id } = await createFunction(formData);
        console.log("create", id);
        setRedirectId(id);
      } else {
        const { id } = await updateFunction(formData);
        console.log("update", id);
        setRedirectId(id);
      }

      setSuccess(true);
      setLoading(false);
      logger.success();
    } catch (e) {
      const error = getErrorMessage(e);
      setError(error);
      setMessage({
        message: error,
        type: NotificationType.error,
      });
      setLoading(false);
      logger.error(error);
    }
  };

  const validateForm = () => {
    const results = validate(formData, constraints) ?? {};
    const isValid = !Object.keys(results).length;
    if (!isValid) {
      setValidationResults(results);
      return false;
    }
    submitData();
    return true;
  };

  const renderChildren = () => {
    if (!children) return null;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const isLoading = [loading, propsLoading].some((l) => l);

  return (
    <ModifyContextProvider value={{ handleModeSwitch }}>
      <Loader active={isLoading} {...loaderProps}>
        <div>
          <Notification
            message={message.message}
            onClose={() => setMessage({ message: "", type: undefined })}
            type={message.type}
          />
          <ModifyComponent<D>
            permissions={permissions}
            mode={mode}
            handleModeSwitch={handleModeSwitch}
            validateForm={validateForm}
            data={formData}
            onChange={handleChange}
            handleReset={handleReset}
            componentConfiguration={componentConfiguration}
            loading={isLoading}
            setFormData={setFormData}
            validationResults={validationResults}
            jwt={jwt}
          />
          {renderChildren()}
        </div>
      </Loader>
    </ModifyContextProvider>
  );
};
