import { Box, FormControl, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useMemo } from "react";
import { getStyles } from "../../styles/general/modifyComponent";
import { Permissions } from "../../types";
import { ShowIfAuthorised } from "../authentication";
import { Loader } from "./";
import { Button, Checkbox, Dropdown, Header, Input, Link } from "./controls";
import type {
  ChangeHandler,
  Config,
  Content,
  FormInput,
  Mode,
  Section,
  Checkbox as TCheckbox,
  Dropdown as TDropdown,
} from "./types/Modify";

export type ComponentConfiguration<D extends object> = Config<D>;

export interface Props<D extends object> {
  data: D;
  componentConfiguration: Config<D>;
  onChange: ChangeHandler;
  handleReset: () => void;
  mode: Mode;
  handleModeSwitch: () => void;
  validateForm: () => boolean;
  loading?: boolean;
  permissions: Permissions | null;
  setFormData: React.Dispatch<React.SetStateAction<D>>;
  validationResults: { [key: string]: string[] } | null;
  jwt: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const ModifyComponent = <D extends object>(
  props: Props<D>
): JSX.Element => {
  const {
    data,
    componentConfiguration: inputCompConfig,
    onChange: handleChange,
    handleReset,
    validateForm,
    loading,
    mode,
    permissions,
    validationResults,
    setFormData,
  } = props;
  const classes = useStyles();

  const { dependentConfig, staticConfig: staticComponentConfiguration } =
    inputCompConfig;
  const dependentComponentConfiguration = dependentConfig
    ? dependentConfig({ data, mode })
    : [];

  const orderedComponentConfig = dependentComponentConfiguration
    .concat(staticComponentConfiguration)
    .sort((a, b) =>
      a.order !== undefined && b.order !== undefined ? a.order - b.order : 0
    );

  const configuration = useMemo(
    () =>
      orderedComponentConfig.filter((input) => {
        let result = true;
        // not sure if there should be rules on these taking
        // precedence over each other (probably should be)

        if (input.modes) result = input.modes.includes(mode);
        // will be completely static, use with caution, won't be able to react
        // to state updates
        if (input.filters) result = input.filters.some((f) => f);

        return result;
      }),
    [orderedComponentConfig, mode]
  );

  const renderComponent = (config: Content<D>) => {
    if (config.modes) {
      const isCorrectMode = config.modes.includes(mode);
      if (!isCorrectMode) return null;
    }

    const disabled = mode === "view";
    let errorMessage = "";

    switch (config.controltype) {
      case "input":
        const inputConfig: FormInput<D> = {
          value: data[config.name],
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          inputConfig.error = true;
          inputConfig.helperText = validationResults[config.name].join(", ");
        }

        return <Input<D> config={inputConfig} handleChange={handleChange} />;
      case "header":
        return <Header className={clsx(classes.header)} config={config} />;
      case "link":
        return <Link config={config} />;
      case "dropdown":
        errorMessage = "";
        const dropdownConfig: TDropdown<D> = {
          value: data[config.name],
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          errorMessage = validationResults[config.name].join(", ");
        }

        return (
          <Dropdown<D>
            config={dropdownConfig}
            handleChange={handleChange}
            errorMessage={errorMessage}
          />
        );
      case "button":
        return <Button config={config} />;
      case "checkbox":
        errorMessage = "";
        const checkboxConfig: TCheckbox<D> = {
          disabled,
          ...config,
        };
        if (validationResults?.[config.name]) {
          errorMessage = validationResults[config.name].join(", ");
        }

        return (
          <Checkbox
            config={checkboxConfig}
            setFormData={setFormData}
            //@ts-ignore
            checkData={data[config.name]}
            errorMessage={errorMessage}
          />
        );
      case "custom":
        const { Component, name } = config;
        if (validationResults?.[name]) {
          errorMessage = validationResults[name].join(", ");
        }
        const onChange = (event: any) => {
          setFormData((prev: any) => ({
            ...prev,
            [name]: event.target.value,
          }));
        };

        return (
          <Component
            {...props}
            inputData={(data as any)[name]}
            disabled={disabled}
            errorMessage={errorMessage}
            onChange={onChange}
            onSubmit={handleSubmit}
            name={name}
          />
        );
    }
  };

  const renderConfiguration = (
    { key, content, userAccess }: Section<D>,
    index: number
  ) => {
    const section = (
      <Box key={key} display="flex" flexDirection="column">
        <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
          {content.map((config, subIndex) => (
            <Grid
              key={`${key}-${index}-${subIndex}`}
              container
              spacing={2}
              justifyContent="center"
            >
              <Grid item xs={12} md={12}>
                <FormControl
                  className={clsx(classes.control)}
                  fullWidth
                  color="primary"
                >
                  {renderComponent(config)}
                </FormControl>
              </Grid>
            </Grid>
          ))}
        </form>
      </Box>
    );

    return userAccess ? (
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        {section}
      </ShowIfAuthorised>
    ) : (
      <>{section}</>
    );
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    return validateForm();
  };

  return (
    <Loader active={loading}>
      <div className={clsx(classes.root)}>
        {configuration.map((section, index) => (
          <Fragment key={`configuration-${index}`}>
            {renderConfiguration(section, index)}
          </Fragment>
        ))}
      </div>
    </Loader>
  );
};
