import { appStrings } from "./app";

export const ticketTemplateStrings = {
  global: { ...appStrings },
  labels: {
    newTicketTemplate: "New Task Template",
    emptyListDisplayTitle: (action: string = "") => `No ${action} Tasks Added`,
    ticketTemplateModalHeader: "Task Template",
    tableHeader: "Task Templates",
    getStarted: "Get Started",
    noTemplatesInfo: "Click the button below to create a new task.",
    actionType: "Action Type",
    component: "Category",
    summary: "Summary",
    description: "Description",
    estimate: "Estimate (hours)",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    modalCreate: "Create",
    modalSave: "Save",
    modalCancel: "Cancel",
    insertTag: "Insert Tag",
    tagSelectorLabels: {
      Entity: {
        entityName: "Entity Name",
        entityFields: "Entity Fields",
        users: "Users",
      },
      AuthenticationAndUsers: {
        value: "Value",
      },
      Report: {
        reportName: "Report Name",
        value: "Value",
      },
    },
    taskType: "Task Type",
    controlTypeId: "Question",
    optionId: "Answer",
    generateJiraCsv: "Generate Tasks",
    taskTypes: {
      entity: "Entity",
      users: "Users",
      report: "Report",
    },
    uploadTasks: "Upload Task Templates",
    uploadInformation: "View Guide",
    downloadTemplate: "Download Template",
    navLinkLabels: {
      ticketTemplateList: "Task Templates",
    },
    submit: "Submit",
    remove: "Remove",
    uploadSuccess: "Task Templates uploaded!",
  },
  errors: {
    wrongFileType: "File must be a CSV file.",
  },
};
