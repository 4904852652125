import { useEffect, useState, useMemo, useCallback } from "react";
import type { Column } from "react-table";
import axios from "axios";
import type { Axios } from "../../libs/axios";
import { Table } from "../../components/general";
import { useCancelToken } from "../../hooks/general";
import { UserAccess, RouteProps } from "../../types";
import { HooksLogger } from "../../hooks/hooks-logger";
import { getErrorMessage } from "../../helpers";
const logger = new HooksLogger("TableContainer/useEffect");

interface Props<D extends object> extends RouteProps {
  endpoint: string;
  jwt: string;
  header: string;
  tableColumns: Column<D>[];
  createButtonConfig?: {
    label: string;
    userAccess: UserAccess;
    path: string;
  };
  clickHandler?: (data: D) => void;
  manualPagination?: boolean;
  filters?: object;
}

export const TableContainer = <D extends Record<string, unknown>>({
  permissions,
  endpoint,
  jwt,
  header,
  tableColumns,
  createButtonConfig,
  clickHandler,
  manualPagination,
  filters,
}: Props<D>) => {
  type Response = {
    totalCount: number;
    items: D[];
  };

  const token = useMemo(() => jwt, [jwt]);
  const url = useMemo(() => endpoint, [endpoint]);

  const [tableData, setTableData] = useState<D[]>(() => []);
  const [totalCount, setTotalCount] = useState(0);

  const [loading, setLoading] = useState(() => false);
  const [, setError] = useState("");
  const cancelToken = useCancelToken();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const searchCritera = useMemo(
    () => ({
      page,
      size,
    }),
    [page, size]
  );

  useEffect(() => {
    const config: Axios.AxiosRequestConfig = {
      cancelToken,
      headers: {
        Authorization: token,
      },
    };
    const body: {
      page?: number;
      size?: number;
      query?: object;
    } = manualPagination ? searchCritera : {};
    if (filters) {
      body.query = filters;
    }

    const query = async () => {
      logger.request("Getting table data");

      setLoading(true);
      try {
        const {
          data: { items = [], totalCount: count },
        } = await axios.post<Response>(url, body, config);
        setTableData(items);
        setTotalCount(count);
        setLoading(false);
        logger.success(items, count);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = getErrorMessage(e);
        setError(error);
        setLoading(false);
        logger.error(error);
      }
    };

    query();
  }, [manualPagination, cancelToken, token, url, searchCritera, filters]);

  const onPageChange = useCallback((pageIndex: number) => {
    setPage(pageIndex);
  }, []);

  const onSizeChange = useCallback((pageSize: number) => {
    setSize(pageSize);
  }, []);

  return (
    <div>
      <Table<D>
        // custom props
        permissions={permissions}
        header={header}
        createButtonConfig={createButtonConfig}
        loading={loading}
        clickHandler={clickHandler}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        // react table props
        data={tableData}
        columns={tableColumns}
        size={size}
        manualPagination={manualPagination}
        totalCount={totalCount}
      />
    </div>
  );
};
