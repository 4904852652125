import { FormControl } from "@material-ui/core";
import clsx from "clsx";
import { Component } from "../../../types";

interface Props {
  children: JSX.Element | null;
}

export const BaseControl: Component<Props> = ({ children }) => {
  return (
    <FormControl className={clsx()} fullWidth color="primary">
      {children}
    </FormControl>
  );
};
