import {
  ApiContextProvider,
  EntityListTableContainer,
  EntityListTableContainerProps as ListProps,
} from "@pulsion/forms-designer-2";
import { useHistory } from "react-router-dom";
import { entityLibraryApi } from "../../../apis/entityLibraryApi";
import { paths } from "../../../navigation";
import { projectStrings as strings } from "../../../resources/strings";
import { listTheme } from "../../../styles/formsDesigner/listTheme";
import { Component } from "../../../types";
import { EntityLibraryData } from "../../../types/documents";
import { EmptyListDisplay } from "../../general/EmptyListDisplay/EmptyListDisplay";
import image from "./emptyLibraryListImage.png";

interface Props {}

export const EntityLibraryList: Component<Props> = () => {
  const history = useHistory();
  const appendHistory = (url: string) => {
    history.push(url);
  };

  const defaultDisplay = (
    <EmptyListDisplay
      onClickButton={() => appendHistory(paths.projects.list)}
      titleText={strings.entityLibrary.listPlaceholderTitleText}
      buttonText={strings.entityLibrary.listPlaceholderButtonText}
      infoText={strings.entityLibrary.listPlaceholderInfoText}
      image={image}
    />
  );

  const props: ListProps<EntityLibraryData> = {
    entity: strings.entityLibrary.entityLabel,
    inputTheme: listTheme,
    manualPagination: true,
    deleteButtons: true,
    sortOptions: {},
    header: "My Library",
    getViewEntityPath: () => "",
    appendHistory,
    tableColumns: [
      {
        id: "name",
        Header: strings.entityLibrary.entityNameColumn,
        accessor: "name",
        isSortable: true,
      },
    ],
    defaultDisplay,
  };

  return (
    <ApiContextProvider api={entityLibraryApi}>
      <EntityListTableContainer<EntityLibraryData> {...props} />
    </ApiContextProvider>
  );
};
