export const EntityActions = [
  "Create",
  "Read",
  "Update",
  "Delete",
  "List",
  "Search",
  "Merge",
  "Import",
  "Export",
  "Assign",
  "Sync",
  "Render/Print",
  "Play",
  "Scan",
];

export type TicketTemplate = {
  id: string;
  tenantId: string;
  active: boolean;
  taskType: string;
  createdDate: string;
  updatedDate: string;
  entityAction?: string;
  summary: string;
  description: string;
  estimate: string;
  component: string;
  controlTypeId?: string;
  optionId?: string;
};

export enum TicketTemplateTaskType {
  Entity = "Entity",
  Users = "AuthenticationAndUsers",
  Report = "Report",
}

export type TicketTemplateFilters = Partial<{
  taskType: TicketTemplateTaskType;
  actionType: string;
  controlTypeId: string;
  optionId: string;
}>;

export type FilterOption = {
  key: string;
  value: string;
  text: string;
};

export interface TaskDropdownControlOption {
  caption: string;
  controlTypeId: string;
  options: { caption: string }[];
}
