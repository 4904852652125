export const appStrings = {
  entities: {
    project: "project",
    user: "user",
    subscription: "subscription",
  },
  permissions: {
    manage: "manage",
    taskGeneration: "taskGeneration",
    wireframes: "wireframes",
    estimation: "estimation",
    wordDocGeneration: "wordDocGeneration",
  },
  menuLabels: {
    projects: "My Projects",
    users: "Users",
    myLibraries: "My Libraries",
    entityLibrary: "Entity Library",
    imageLibrary: "Image Library",
    tutorial: "Getting Started",
    tasks: "Task Templates",
    signOut: "Log Out",
    manageSubscription: "Manage Subscription",
    openTitle: "Close Menu",
    closedTitle: "Open Menu",
  },
  labels: {
    signIn: "Login",
    pageNotFound: "Page not found",
    loading: "Loading...",
    welcomeBackUser: (userName?: string) =>
      userName ? `Welcome Back, ${userName}` : "Welcome Back",
    getStarted: "Get Started",
    noDataInfo: (entity: string) =>
      `Click the button below to create a new ${entity.toLowerCase()}`,
    confirmDeletion: "Delete",
    close: "Close",
    myAccount: "My Account",
    remove: "Remove",
    removeAll: "Remove All",
    submit: "Submit",
    cancel: "Cancel",
    save: "Save",
    trialDaysRemaining: "trial days remaining",
    upgradePlan: "Upgrade Plan",
    trialStartHeader: "Requiment trial",
  },
  chars: {
    requiredAsterisk: "*",
  },
  other: {
    linkTargetNewTab: "_blank",
  },
  titles: {
    pageOptions: "Page Options",
  },
  text: {},
};
