import { faker as fakerJS } from "@faker-js/faker";
import Filter from "bad-words";
import compositeThumbnail from "../assets/pageThumbnails/composite-thumbnail.png";
import createThumbnail from "../assets/pageThumbnails/create-thumbnail.png";
import deleteThumbnail from "../assets/pageThumbnails/delete-thumbnail.png";
import listThumbnail from "../assets/pageThumbnails/list-thumbnail.png";
import readThumbnail from "../assets/pageThumbnails/read-thumbnail.png";
import searchThumbnail from "../assets/pageThumbnails/search-thumbnail.png";
import updateThumbnail from "../assets/pageThumbnails/update-thumbnail.png";
import {
  ENTITY_INTERNALSECTIONTPEID as entityInternalId,
  FIELD_INTERNALSECTIONTPEID as fieldInternalId,
  USERS_INTERNALSECTIONTPEID as usersInternalId,
} from "../libs/config";
import { constants } from "../resources/numerics/app";
import { wireframesStrings as strings } from "../resources/strings";
import {
  fakerCallParameters,
  fakerCallsToReplace,
  fakerDataTypes,
  genderOptionData,
} from "../resources/strings/fakeData";
import { searchMediaLibrary } from "../services/filesService";
import {
  AuthenticationScreenInfo,
  CollectionData,
  Entity,
  Field,
  Page,
  SearchValue,
} from "../types/Wireframes";
import { convertDate, convertTime, getFakeDate } from "./dateHelper";

declare global {
  var faker: any;
}

globalThis.faker = fakerJS;

export const findRowSections = (
  sectionData: { controls: any[] },
  sectionName: any,
  sections: any[]
) => {
  const rows: any[] = [];
  try {
    const rowIds = JSON.parse(
      sectionData.controls.find(
        (control: any) =>
          control.name === sectionName && control.hasOwnProperty("value")
      ).value
    );
    sections.forEach((section) => {
      if (rowIds.includes(section.sectionId)) {
        rows.push(section);
      }
    });
    return rows;
  } catch (e: unknown) {
    return rows;
  }
};

export const isNumberEven = (index: number) => {
  return index % 2 === 0;
};

export const isDropdownField = (fieldType: string) => {
  return (
    fieldType === strings.fieldTypes.collectionEntity ||
    fieldType === strings.fieldTypes.singleEntity ||
    fieldType === strings.fieldTypes.multiValue
  );
};

export const populateFieldValues = async (entity: Entity, field: Field) => {
  switch (field.type) {
    case strings.fieldTypes.collectionEntity:
    case strings.fieldTypes.singleEntity:
    case strings.fieldTypes.multiValue:
      field.collectionData = getFakeCollectionValues(field.type, field);
      break;
    case strings.fieldTypes.image:
      field.fakeData = await getImageValues(entity, field);
      break;
    default:
      field.fakeData = getFakeValues(field.type, field);
      break;
  }
  return field;
};

export const getFakeValues = (fieldType: string, field: Field) => {
  const array: string[] = [];
  for (let i = 0; i < constants.wireframesListRowCount; i++) {
    array.push(getFakeValue(fieldType, field));
  }
  return array;
};

export const getFakeCollectionValues = (fieldType: string, field: Field) => {
  const array: CollectionData[] = [];
  for (let i = 0; i < constants.wireframesListRowCount; i++) {
    const fakeValues = getFakeValue(fieldType, field);
    const selected = getInitialArrayValues(
      fakeValues,
      fieldType === strings.fieldTypes.collectionEntity ? 2 : 1
    );
    const collectionData = {
      values: fakeValues,
      selected,
    };
    array.push(collectionData);
  }
  return array;
};

export const getImageValues = async (entity: Entity, field: Field) => {
  const searchValues: SearchValue[] = [
    { priority: 1, label: "EntityName", value: entity.name },
    { priority: 0, label: "FieldName", value: field.name },
  ];
  const imageValues = await searchMediaLibrary(searchValues);
  if (imageValues.length < constants.wireframesListRowCount) {
    const loopCount = constants.wireframesListRowCount - imageValues.length;
    let index = 0;
    for (let i = 0; i < loopCount; i++) {
      if (index >= imageValues.length) {
        index = 0;
      }
      imageValues.push(imageValues[index]);
      index++;
    }
  }
  return imageValues;
};

export const getFakeValue = (fieldType: string, field: Field) => {
  try {
    let fakerCall = field.fakerCall
      ? "faker." + field.fakerCall + "()"
      : getLoremIpsumValue(fieldType);
    const dataTypes = Object.values(fakerDataTypes);
    if (dataTypes.includes(fakerCall)) {
      fakerCall = replaceFakerCalls(fakerCall);
      fakerCall = addParametersToFakerCall(fakerCall);
      if (isDropdownField(fieldType)) {
        const array: string[] = [];
        if (fakerCall === fakerDataTypes.sex) {
          return genderOptionData;
        }
        for (let i = 0; i < constants.wireframesListRowCount; i++) {
          array.push(getFormattedAndValidatedFakerResult(fakerCall, fieldType));
        }
        return array;
      }
      return getFormattedAndValidatedFakerResult(fakerCall, fieldType);
    } else {
      return getLoremIpsumValue(fieldType);
    }
  } catch (e) {
    console.log(e);
    return getLoremIpsumValue(fieldType);
  }
};

const getLoremIpsumValue = (fieldType: string) => {
  switch (fieldType) {
    case strings.fieldTypes.text:
      return faker.lorem.words(2);
    case strings.fieldTypes.number:
      return faker.string.numeric(3);
    case strings.fieldTypes.date:
      return convertDate(getFakeDate());
    case strings.fieldTypes.time:
      return convertTime(getFakeDate());
    case strings.fieldTypes.boolean:
      return faker.datatype.boolean().toString();
    case strings.fieldTypes.multiValue:
    case strings.fieldTypes.singleEntity:
    case strings.fieldTypes.collectionEntity:
      const array: string[] = [];
      for (let i = 0; i < 4; i++) {
        array.push(faker.lorem.words(2));
      }
      return array;
    default:
      return faker.lorem.words(2);
  }
};

const getFormattedAndValidatedFakerResult = (
  call: string,
  fieldType: string
) => {
  const result = getFilteredCallValue(call);

  if (result === null) {
    return getLoremIpsumValue(fieldType);
  }
  if (fieldType === strings.fieldTypes.date && result instanceof Date) {
    return convertDate(result);
  }
  if (fieldType === strings.fieldTypes.time && result instanceof Date) {
    return convertTime(result);
  }
  if (typeof result === "object" && !(result instanceof Date)) {
    try {
      return result.name.toString();
    } catch (e) {
      return Object.values(result)[0];
    }
  }
  return result.toString();
};

const getFilteredCallValue = (call: string) => {
  const filter = new Filter();
  // eslint-disable-next-line
  let result = eval(call);
  let containsProfanity = filter.isProfane(result);
  let count = 0;
  while (containsProfanity && count < 10) {
    // eslint-disable-next-line
    result = eval(call);
    containsProfanity = filter.isProfane(result);
    count++;
  }
  if (count === 10) {
    throw new Error(
      "Could not get clean faker result, defaulting to lorem ipsum value."
    );
  }
  return result;
};

const replaceFakerCalls = (call: string) => {
  const callsToReplace = fakerCallsToReplace();
  if (Object.keys(callsToReplace).includes(call)) {
    return callsToReplace[call];
  }
  return call;
};

const addParametersToFakerCall = (call: string) => {
  const callParameters = fakerCallParameters();
  if (Object.keys(callParameters).includes(call)) {
    const parameters = callParameters[call];
    return call.replace("()", "(" + JSON.stringify(parameters) + ")");
  }
  return call;
};

const getInitialArrayValues = (array: string[], size: number) => {
  const initialValues: number[] = [];
  for (let index = 0; index < size; index++) {
    initialValues.push(Math.floor(Math.random() * array.length));
  }
  return initialValues;
};

export const isEntitySection = (section: any) => {
  return (
    section.internalSectionTypeId === entityInternalId &&
    !Number.isInteger(section.sectionOrder)
  );
};

export const isFieldSection = (section: any) => {
  return (
    section.internalSectionTypeId === fieldInternalId &&
    !Number.isInteger(section.sectionOrder)
  );
};

export const isUsersSection = (section: any) => {
  return section.internalSectionTypeId === usersInternalId;
};

export const getAuthenticationScreenInfo = (section: any) => {
  const userAccessControlValue = getControlValueFromName(
    section,
    strings.controls.userAccess
  );
  const forgotPasswordControlValue = getControlValueFromName(
    section,
    strings.controls.forgotPassword
  );
  const authenticationScreenInfo: AuthenticationScreenInfo = {
    login: userAccessControlValue
      ? userAccessControlValue === strings.userAccessTypes.selfService ||
        userAccessControlValue === strings.userAccessTypes.setByAdmin ||
        userAccessControlValue === strings.userAccessTypes.organisationAD
      : false,
    signup: userAccessControlValue === strings.userAccessTypes.selfService,
    forgotPassword: forgotPasswordControlValue === "true" ? true : false,
  };
  return authenticationScreenInfo;
};

export const getControlValueFromName = (section: any, name: string) => {
  const sectionData = JSON.parse(section.sectionFormData);
  let controlValue = "";
  if (sectionData.controls) {
    sectionData.controls.forEach((control: any) => {
      if (control.name === name) {
        controlValue = control.value;
      }
    });
  }
  if (sectionData.subsections) {
    sectionData.subsections.forEach((subsection: any) => {
      subsection.controls.forEach((control: any) => {
        if (control.name === name) {
          controlValue = control.value;
        }
      });
    });
  }
  return controlValue;
};

const getBooleanDisplayValue = (
  fakeData: string[] | undefined,
  index: number
) => {
  const data = fakeData ? fakeData : new Array<string>(index + 1).fill("");
  return data.map((x) => (x === "true" || x === "Yes" ? "Yes" : "No"));
};

export const getDisplayValue = (field: Field, rowIndex: number) => {
  let displayValue = "";
  if (field.type === strings.fieldTypes.boolean) {
    field.fakeData = getBooleanDisplayValue(field.fakeData, rowIndex);
  }
  if (isDropdownField(field.type)) {
    let fakeCollectionData =
      field.collectionData && field.collectionData[rowIndex];
    if (fakeCollectionData) {
      const values = fakeCollectionData.values;
      fakeCollectionData.selected.forEach((index: number) => {
        if (displayValue !== "") {
          displayValue += ", ";
        }
        displayValue += values[index];
      });
    }
  } else {
    displayValue = field.fakeData ? field.fakeData[rowIndex] : "";
  }
  return displayValue;
};

export function sortPagesByName(pages: Page[]) {
  return pages.sort(({ name: a }, { name: b }) => {
    const textA = a.toUpperCase();
    const textB = b.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
}

export const pageThumbnail = (page: Page) => {
  if (page.content.length > 1) {
    return compositeThumbnail;
  }
  if (page.content.length === 1) {
    switch (page.content[0].action) {
      case strings.actions.create:
        return createThumbnail;
      case strings.actions.read:
        return readThumbnail;
      case strings.actions.update:
        return updateThumbnail;
      case strings.actions.delete:
        return deleteThumbnail;
      case strings.actions.list:
        return listThumbnail;
      case strings.actions.search:
        return searchThumbnail;
    }
  }
  return undefined;
};
