import { createTheme } from "@material-ui/core/styles";

const primaryPurple = "#7260CF";
const secondaryPink = "#DA6294";
const errorRed = "#a11a18";

export const colours = {
  primaryPurple,
  whiteColour: "#FFFFFF",
  darkBlueColour: "#0D203D",
  secondaryPink,
  greyColour: "#666666",
  darkGreyColour: "#333333",
  blackColour: "#000000",
  indeterminateEntitySelectionColour: "#f50057",
  greySelectedColour: "#00000014",
  inputBackgroundGrey: "#FAFAFA",
  errorRed,
  disabledGreyText: "#999999",
  redHover: "#6e1919",
  secondaryHover: "#964164",
};

export const fonts = {
  primary: "'Varela Round', sans-serif",
  secondary: "'Noto Serif', serif",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryPurple,
    },
    secondary: {
      main: secondaryPink,
    },
    error: {
      main: errorRed,
    },
    info: {
      main: primaryPurple,
    },
  },
});
