import { Button, makeStyles } from "@material-ui/core";
import {
  ApiContextProvider,
  ControlContextProvider,
  JobSectionContainer,
  SectionState,
  handleNotification,
} from "@pulsion/forms-designer-2";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { projectsRendererApi } from "../../apis/projectsRendererApi";
import { ShowIfAuthorised } from "../../components/authentication";
import { DocumentDownloadButton } from "../../components/general/DocumentDownloadButton";
import {
  FormsDesignerNotificationMapping,
  Notification,
} from "../../components/general/Notification";
import {
  ButtonComponentProps,
  IconActionButton,
} from "../../components/general/controls/IconActionButton";
import { NavButton } from "../../components/general/controls/NavButton";
import { LinkItemConfig, NavLinks } from "../../components/layout/NavLinks";
import { constructUserDisplayName } from "../../helpers/appHelper";
import { parseDateAndTime } from "../../helpers/dateHelper";
import { downloadTaskCsv } from "../../helpers/jiraTicketHelper";
import {
  allowedImageTypes,
  getProjectDisplayName,
  projectRendererNotificationMessageMappings as notificationMappings,
} from "../../helpers/projectHelper";
import { useCancelToken } from "../../hooks/general";
import { ENTITY_INTERNALSECTIONTPEID as entityInternalSectionTypeId } from "../../libs/config";
import { paths } from "../../navigation";
import {
  appStrings,
  documentStrings,
  projectStrings as strings,
  ticketTemplateStrings,
  wireframesStrings,
} from "../../resources/strings";
import { generateJiraCsv } from "../../services/jiraGenService";
import { getProjectById } from "../../services/projectService";
import {
  addRepeatableSection,
  addStoredSection,
} from "../../services/projectsRendererService";
import { getUser } from "../../services/userService";
import { rendererTheme as theme } from "../../styles/formsDesigner/rendererTheme";
import { getStyles } from "../../styles/projects/projectRenderer";
import {
  CognitoUser,
  Component,
  NotificationType,
  Permissions,
} from "../../types";

interface Props {
  permissions: Permissions | null;
  user: CognitoUser | null;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const ProjectsRenderer: Component<Props> = ({ permissions, user }) => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const cancelToken = useCancelToken();
  const [projectTypeId, setProjectTypeId] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [projectUpdatedDate, setProjectUpdatedDate] = useState({
    date: "",
    time: "",
  });
  const [projectUpdatedBy, setProjectUpdatedBy] = useState({
    name: "",
    id: "",
  });
  const [tenantId, setTenantId] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<NotificationType | undefined>(
    undefined
  );

  const linkItems: LinkItemConfig[] = [
    {
      to: paths.projects.list,
      text: strings.navLinkLabels.projectsList,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      if (!id) return;

      const {
        projectTypeId = "",
        name,
        tenantId = "",
        updatedDate = "",
        updatedById = "",
      } = await getProjectById(id, cancelToken);

      const { forename = "", surname = "" } = await getUser(updatedById);
      setProjectTypeId(projectTypeId);
      setProjectName(name || documentStrings.defaultDocumentName);
      setTenantId(tenantId);
      setProjectUpdatedDate(parseDateAndTime(updatedDate));
      setProjectUpdatedBy({
        name: constructUserDisplayName(forename, surname),
        id: updatedById,
      });
    };
    getData();
  }, [projectTypeId, cancelToken, id]);

  const updateVersioningDetails = () => {
    setProjectUpdatedDate(parseDateAndTime(new Date().toISOString()));
    if (user) {
      const { firstName, surname, userId: id } = user;
      const name = constructUserDisplayName(firstName, surname);
      setProjectUpdatedBy({ name, id });
    }
  };

  const handleAddRepeatableSection = async (
    projectId: string,
    sectionTypeId: object,
    sectionName?: string,
    autoGenerateFields?: boolean
  ) => {
    try {
      const result = await addRepeatableSection(
        projectId,
        sectionTypeId,
        sectionName,
        autoGenerateFields
      );
      updateVersioningDetails();
      return result;
    } catch (e: any) {
      if (e === strings.errorMessages.entityLimitReached) {
        setMessage(e);
        setMessageType(NotificationType.error);
      }
      return Promise.reject(e);
    }
  };

  const handleAddStoredSection = async (
    projectId: string,
    storedSectionId: string,
    sectionTenantOrganisationId: string
  ) => {
    try {
      const result = await addStoredSection(
        projectId,
        storedSectionId,
        sectionTenantOrganisationId
      );
      updateVersioningDetails();
      return result;
    } catch (e: any) {
      if (e === strings.errorMessages.entityLimitReached) {
        setMessage(e);
      }
      return Promise.reject(e);
    }
  };

  const onClickGenerateTasks = () => {
    try {
      downloadTaskCsv(() => generateJiraCsv(id), projectName);
    } catch (e) {
      setMessage("Failed to download tasks. Please try again.");
      setMessageType(NotificationType.error);
    }
  };

  const generateTasksButton: ButtonComponentProps = ({
    onClick,
    disabled,
    children,
  }) => (
    <Button
      onClick={onClick}
      disabled={disabled}
      className="action-button"
      color="primary"
      variant="outlined"
    >
      {ticketTemplateStrings.labels.generateJiraCsv}
      {children}
    </Button>
  );

  const handleNotification: handleNotification = (
    type,
    title,
    message,
    timeout
  ) => {
    const messagesToProcess = notificationMappings.map(
      (x) => x.incomingMessage
    );
    let processedMessage = message;
    if (messagesToProcess.find((x) => message.includes(x))) {
      const messageTranslation = notificationMappings.find((x) =>
        message.includes(x.incomingMessage)
      )?.translatedMessage;
      if (messageTranslation === null) return;
      if (messageTranslation !== undefined) {
        processedMessage = messageTranslation;
      }
    }
    setMessage(processedMessage);
    setMessageType(FormsDesignerNotificationMapping[type]);
  };

  const versioningDetails = (sectionId: string) => (
    <div className={clsx(classes.root, classes.element)}>
      <p className={clsx(classes.text, "text")}>
        {strings.labels.versioningLastUpdated(projectUpdatedDate)}
      </p>
      <a
        className={clsx("nav-link", classes.link)}
        href={paths.users.view(projectUpdatedBy.id)}
      >
        {projectUpdatedBy.name}
      </a>
      <p className={clsx(classes.text, "text")}>{strings.chars.divider}</p>
      <Link
        key={`version-${sectionId}`}
        className={clsx("nav-link", classes.link)}
        to={() => ({
          pathname: paths.projects.viewChangeHistory(id),
          state: { sectionId, projectName, tenantId },
        })}
      >
        {strings.labels.viewVersionHistory}
      </Link>
    </div>
  );

  return (
    <div>
      <Notification
        message={message}
        onClose={() => setMessage("")}
        type={messageType}
      />
      <div className="edit-header">
        <NavLinks
          linkItems={linkItems}
          currentPageText={getProjectDisplayName(projectName)}
        />

        <span className="project-modify-buttons">
          <NavButton
            path={paths.projects.view(id)}
            color="primary"
            variant="outlined"
          >
            {strings.labels.viewProject}
          </NavButton>
          <ShowIfAuthorised
            userPermissions={permissions}
            entity={appStrings.entities.project}
            permission={appStrings.permissions.wireframes}
          >
            <Button
              onClick={() => {
                window.open(
                  paths.wireframes(id),
                  appStrings.other.linkTargetNewTab
                );
              }}
              className="action-button"
              color="primary"
              variant="outlined"
            >
              {wireframesStrings.labels.wireframes}
            </Button>
          </ShowIfAuthorised>
          <ShowIfAuthorised
            userPermissions={permissions}
            entity={appStrings.entities.project}
            permission={appStrings.permissions.wordDocGeneration}
          >
            <DocumentDownloadButton
              projectId={id}
              projectTypeId={projectTypeId}
              projectName={projectName}
              displayError={(message) => {
                setMessage(message);
                setMessageType(NotificationType.error);
              }}
            />
          </ShowIfAuthorised>
          <ShowIfAuthorised
            userPermissions={permissions}
            entity={appStrings.entities.project}
            permission={appStrings.permissions.taskGeneration}
          >
            <IconActionButton
              ButtonComponent={generateTasksButton}
              spinnerProps={{
                size: 24,
                color: "secondary",
                style: { marginLeft: "1rem" },
              }}
              onClick={onClickGenerateTasks}
            />
          </ShowIfAuthorised>
        </span>
      </div>

      <ApiContextProvider
        api={{
          ...projectsRendererApi(updateVersioningDetails),
          addRepeatableSection: handleAddRepeatableSection,
          addStoredSection: handleAddStoredSection,
        }}
      >
        <ControlContextProvider
          allowControlChanges
          handleNotification={handleNotification}
          resizableGridTables
          allowedImageTypes={allowedImageTypes}
        >
          <JobSectionContainer
            inputTheme={theme}
            jobId={id}
            rootOrganisation={tenantId}
            repeatableSectionNamePrompt
            completedSectionStates={[SectionState.Completed, SectionState.New]}
            enableRepeatableSectionReordering
            libraryInternalSectionTypeIds={[entityInternalSectionTypeId ?? ""]}
            includeFieldCheckbox
            additionalTopBarContent={versioningDetails}
          />
        </ControlContextProvider>
      </ApiContextProvider>
    </div>
  );
};
