import { createTheme } from "@material-ui/core";

const colours = {
  blackColour: "#000000",
  blueColour: "#1976D2",
  lightGreyColour: "#FAFAFA",
  greyColour: "#CCCCCC",
  darkGreyColour: "#666666",
  whiteColour: "#FFFFFF",
  redColor: "#CC3333",
};

export const fonts = {
  primary: "'Roboto', sans-serif",
  secondary: "'Roboto', serif",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: colours.blueColour,
    },
    secondary: {
      light: colours.lightGreyColour,
      main: colours.greyColour,
      dark: colours.darkGreyColour,
    },
    error: {
      main: colours.redColor,
    },
    common: {
      black: colours.blackColour,
      white: colours.whiteColour,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});
