import { makeStyles } from "@material-ui/core";
import { Add, ArrowBackIos } from "@material-ui/icons";
import clsx from "clsx";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/header";
import { Component } from "../../../types";
import { Entity, WireframesPage } from "../../../types/Wireframes";
import { CustomTextField } from "../controls/CustomTextField";
import { HeaderButton as Button, HeaderButtonType } from "./HeaderButton";

interface Props {
  currentPage: WireframesPage;
  entity: Entity;
  changePage: (actionName: string, entity: Entity, rowIndex: number) => void;
  doesEntityContainAction: (actionName: string, entity: Entity) => boolean;
  index: number;
  headerType: string;
  action?: string;
  width: number;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const PageSectionHeader: Component<Props> = ({
  currentPage,
  entity,
  doesEntityContainAction,
  changePage,
  index,
  headerType,
  action,
  width,
}) => {
  const classes = useStyles();
  const { isMobile } = useScreenSizeContext();

  const areFieldsEditable =
    currentPage === WireframesPage.CREATE ||
    currentPage === WireframesPage.EDIT;

  const isEntityDeletable =
    doesEntityContainAction(strings.actions.delete, entity) &&
    currentPage !== WireframesPage.CREATE;

  const isEntityCreateable = doesEntityContainAction(
    strings.actions.create,
    entity
  );
  const isEntityEditable = doesEntityContainAction(
    strings.actions.update,
    entity
  );
  const canEntityBeListed =
    doesEntityContainAction(strings.actions.list, entity) ||
    doesEntityContainAction(strings.actions.search, entity);

  const isListSearchable =
    action === strings.actions.list
      ? false
      : doesEntityContainAction(strings.actions.search, entity);

  const addTitleBorder =
    headerType === strings.headerType.titleAndButtons ||
    headerType === strings.headerType.titleOnly;

  const isListPage = currentPage === WireframesPage.LIST;

  const showButtons =
    headerType === strings.headerType.buttonsOnly ||
    headerType === strings.headerType.titleAndButtons;
  const showTitle =
    headerType === strings.headerType.titleOnly ||
    headerType === strings.headerType.titleAndButtons;

  const header = () => {
    switch (currentPage) {
      case WireframesPage.CREATE:
        return strings.headers.create(entity.name);
      case WireframesPage.EDIT:
        return strings.headers.update(entity.name);
      case WireframesPage.VIEW:
        return strings.headers.read(entity.name);
      case WireframesPage.LIST:
        return strings.headers.list(entity.name);
    }
  };

  const button = (
    type: HeaderButtonType,
    actionName: string,
    label: string,
    icon?: JSX.Element
  ) => {
    return (
      <Button
        type={type}
        actionName={actionName}
        label={label}
        width={width}
        entity={entity}
        index={index}
        changePage={changePage}
        icon={icon}
      />
    );
  };

  const mobileHeader = () => {
    return (
      <div>
        <div className={classes.mobileHeader}>
          {showButtons ? (
            getLeftButton()
          ) : (
            <div className={classes.iconContainer} />
          )}
          {showTitle && <h2>{header()}</h2>}
          {showButtons ? (
            getRightButton()
          ) : (
            <div className={classes.iconContainer} />
          )}
        </div>
        {isListSearchable && isListPage && (
          <div className={classes.searchContainer}>
            <CustomTextField
              initialValue=""
              editable={true}
              label="Search"
              type={strings.textFieldTypes.search}
              updateValue={() => {}}
            ></CustomTextField>
          </div>
        )}
      </div>
    );
  };

  const getLeftButton = () => {
    if (canEntityBeListed && !isListPage) {
      const backIcon = (
        <ArrowBackIos color="primary" className={classes.icon} />
      );
      return button(
        HeaderButtonType.left,
        strings.actions.list,
        strings.labels.back,
        backIcon
      );
    }
    return <div className={classes.iconContainer} />;
  };

  const getRightButton = () => {
    if (currentPage === WireframesPage.VIEW && isEntityEditable) {
      return button(
        HeaderButtonType.right,
        strings.actions.update,
        strings.labels.edit
      );
    }
    if (
      currentPage === WireframesPage.EDIT ||
      currentPage === WireframesPage.CREATE
    ) {
      return button(
        HeaderButtonType.right,
        strings.actions.read,
        strings.labels.save
      );
    }
    if (currentPage === WireframesPage.LIST && isEntityCreateable) {
      const addIcon = <Add className={classes.icon} color="primary" />;
      return button(
        HeaderButtonType.right,
        strings.actions.create,
        strings.labels.add,
        addIcon
      );
    }
    return <div className={classes.iconContainer} />;
  };

  const desktopAndTabletHeader = () => {
    return (
      <div
        className={clsx(
          classes.subHeader,
          addTitleBorder && classes.titleBorder
        )}
      >
        {showTitle ? <h2>{header()}</h2> : <div></div>}
        {isListPage ? listPageButtons() : showButtons && formPageButtons()}
      </div>
    );
  };

  const listPageButtons = () => {
    return (
      <div className={classes.buttonContainer}>
        {isListSearchable && (
          <CustomTextField
            initialValue=""
            editable={true}
            label="Search"
            type={strings.textFieldTypes.search}
            updateValue={() => {}}
          ></CustomTextField>
        )}
        {showButtons &&
          isEntityCreateable &&
          button(
            HeaderButtonType.main,
            strings.actions.create,
            strings.labels.add
          )}
      </div>
    );
  };

  const formPageButtons = () => {
    return (
      <div className={classes.buttonContainer}>
        {canEntityBeListed &&
          button(
            HeaderButtonType.secondary,
            strings.actions.list,
            strings.labels.list
          )}
        {currentPage !== WireframesPage.VIEW &&
          button(
            HeaderButtonType.secondary,
            strings.actions.read,
            strings.labels.cancel
          )}
        {currentPage !== WireframesPage.CREATE &&
          !canEntityBeListed &&
          isEntityCreateable &&
          button(
            HeaderButtonType.secondary,
            strings.actions.create,
            strings.labels.create
          )}
        {currentPage === WireframesPage.VIEW &&
          isEntityEditable &&
          button(
            HeaderButtonType.main,
            strings.actions.update,
            strings.labels.edit
          )}
        {areFieldsEditable &&
          button(
            HeaderButtonType.main,
            strings.actions.read,
            strings.labels.save
          )}
        {isEntityDeletable &&
          button(
            HeaderButtonType.delete,
            strings.actions.list,
            strings.labels.delete
          )}
      </div>
    );
  };

  return (
    <>
      {headerType !== strings.headerType.noHeader || isListSearchable
        ? isMobile
          ? mobileHeader()
          : desktopAndTabletHeader()
        : null}
    </>
  );
};
