export interface Section {
  sectionId: string;
  sectionFormData: string;
  sectionCaption: string;
  gridInput: boolean;
  repeatable: boolean;
  sectionOrder: number;
  displayOrder: number | null;
  sectionTypeId: string;
  internalSectionTypeId: string;
  visible: boolean;
  fqn: string;
}

export enum SectionChangeEventType {
  INSERT = "INSERT",
  MODIFY = "MODIFY",
  REMOVE = "REMOVE",
  REORDER = "REORDER",
}

export interface SectionChange {
  id: string;
  sectionId: string;
  user: string;
  time: string;
  date: string;
  previousDisplayOrder: number | null;
  currentDisplayOrder: number | null;
  previousSectionJson?: string;
  currentSectionJson?: string;
  parentSectionJson?: string;
  childrenSectionJson?: string;
  siblingSectionJson?: string;
  previousSiblingSectionJson?: string;
  previousActive: boolean;
  currentActive: boolean;
  eventType: `${SectionChangeEventType}` | undefined;
}

export interface SectionRevision {
  id: string;
  sectionId: string;
  createdBy: string;
  createdDate: string;
  previousDisplayOrder: number | null;
  currentDisplayOrder: number | null;
  previousSectionJson?: string;
  currentSectionJson?: string;
  previousActive: boolean;
  currentActive: boolean;
  eventType: "MODIFY" | "INSERT" | "REMOVE" | "REORDER" | undefined;
}
