import { Button, CssBaseline, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  CookieEnum,
  DateHelper,
  isMobile,
  redirectToExternalURL,
} from "../../helpers";
import { getCurrentSelectedMenuItem } from "../../helpers/appHelper";
import { useCookies } from "../../hooks/general/useCookies";
import { getIdToken, signOut } from "../../libs/auth";
import { paths } from "../../navigation";
import { appStrings as strings } from "../../resources/strings";
import {
  introTutorialSteps,
  tutorialStrings,
} from "../../resources/strings/tutorial";
import { getCustomerPortalLink } from "../../services/stripeService";
import { getStyles } from "../../styles/layout/index";
import { CognitoUser, Component, Permissions } from "../../types";
import { HelpCarousel } from "../carousel/HelpCarousel";
import { MobileDisplay } from "../general/MobileDisplay";
import { PrivacyPolicyPopup } from "../general/PrivacyPolicyPopup";
import { Survey } from "../general/Survey";
import { TrialStartPopup } from "../general/TrialStartPopup";
import { AccountOptionsDropdown } from "./AccountOptionsDropdown";
import { MenuComponent } from "./MenuComponent";
import { menuItems, setSelectedMenuItem } from "./listItems";

interface Props {
  children: JSX.Element;
  user: CognitoUser | null;
  permissions: Permissions | null;
  dateHelper: DateHelper;
  menuDisabled: boolean;
  trialDaysRemaining?: number;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const handleManageSubscription = async () => {
  try {
    const idToken = await getIdToken();
    if (!idToken) throw new Error("Current user is invalid.");

    const customerPortalLink = await getCustomerPortalLink();
    redirectToExternalURL(customerPortalLink);
  } catch (e: any) {
    console.log("Cannot redirect for subscription management", e);
    return;
  }
};

export const Layout: Component<Props> = ({
  children,
  user,
  permissions,
  dateHelper,
  menuDisabled,
  trialDaysRemaining,
}) => {
  const LoggedInLayout = () => {
    const [carouselOpen, setCarouselOpen] = useState(false);
    const [surveyOpen, setSurveyOpen] = useState(false);
    const [privacyPolicyPopupOpen, setPrivacyPolicyPopupOpen] = useState(false);
    const [trialStartPopupOpen, setTrialStartPopupOpen] = useState(false);
    const [cookies, setCookie] = useCookies(dateHelper);
    const [isMenuDisabled, setIsMenuDisabled] = useState(menuDisabled);
    const [previousMenuItemId, setPreviousMenutItemId] = useState("0");
    const [hasMenuChanged, setHasMenuChanged] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const wireframesOpen = location.pathname.startsWith("/wireframes/");
    const isPrivacyPage = location.pathname.includes(paths.privacyPolicy);

    const hasSubscription = !!user?.subscriptionRef;

    const numberOfModalsOpen = [
      carouselOpen,
      surveyOpen,
      privacyPolicyPopupOpen,
      trialStartPopupOpen,
    ].filter(Boolean).length;

    const handleSignOut = () => {
      signOut();
    };

    const onClickUpgrade = () => {
      history.push(paths.products, {
        email: user?.tenantEmail,
        userId: user?.userId,
      });
    };

    const classes = useStyles();

    useEffect(() => {
      if (numberOfModalsOpen) {
        setIsMenuDisabled(true);
      } else {
        setIsMenuDisabled(menuDisabled);
      }
    }, [numberOfModalsOpen]);

    const additionalContent = () => {
      if (!isMobile()) {
        return (
          <>
            {trialDaysRemaining !== undefined && (
              <span className={classes.trialInfo}>
                <div
                  className={clsx(classes.trialDays, classes.trialDaysNumber)}
                >
                  {`${trialDaysRemaining}`}
                </div>
                <div className={clsx(classes.trialDays, classes.trialDaysText)}>
                  &nbsp;
                  {strings.labels.trialDaysRemaining}
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onClickUpgrade}
                  disabled={isMenuDisabled}
                  className={classes.button}
                >
                  {strings.labels.upgradePlan}
                </Button>
              </span>
            )}
            <AccountOptionsDropdown
              userName={user?.firstName}
              handleSignOut={handleSignOut}
              handleManageSubscription={handleManageSubscription}
              permissions={permissions}
              menuDisabled={isMenuDisabled}
              hasSubscription={hasSubscription}
            ></AccountOptionsDropdown>
          </>
        );
      }
    };

    const openTutorial = () => {
      setCarouselOpen(true);
      setPreviousMenutItemId(getCurrentSelectedMenuItem());
      setHasMenuChanged(false);
    };

    const onClickMaybeLater = useCallback(() => {
      const nextWeek = dateHelper.nextWeek();
      setCookie(CookieEnum.AwaitSurvey, true, {
        path: "/",
        expires: nextWeek,
      });
      setIsMenuDisabled(menuDisabled);
      setSurveyOpen(false);
    }, [setCookie]);

    const setFirstVisitCookie = useCallback(() => {
      setCookie(CookieEnum.FirstVisit, true);
    }, [setCookie]);

    const setTrialStartCookie = useCallback(() => {
      setCookie(CookieEnum.TrialStart, true);
    }, [setCookie]);

    const closeCarousel = (currentMenuItemId: string) => {
      setCookie(CookieEnum.Tutorial, true);
      setCarouselOpen(false);
      setSelectedMenuItem(currentMenuItemId);
      setHasMenuChanged(true);
    };

    const completeSurvey = () => {
      setCookie(CookieEnum.SurveyComplete, true);
      setSurveyOpen(false);
    };

    const closePrivacyPolicyModal = () => {
      setPrivacyPolicyPopupOpen(false);
    };

    const onAgreeToPrivacyPolicy = () => {
      try {
        setCookie(CookieEnum.UpdatedPrivacyPolicy, true);
        closePrivacyPolicyModal();
      } catch (e: any) {
        console.log("Failed to set privacy policy cookie", e);
      }
    };

    const closeTrialStartPopup = () => {
      setTrialStartCookie();
      setTrialStartPopupOpen(false);
    };

    useEffect(() => {
      if (!cookies[CookieEnum.TrialStart] && trialDaysRemaining !== undefined) {
        setTrialStartPopupOpen(true);
      }
      if (!cookies.tutorial) {
        setCarouselOpen(true);
      }
      if (!cookies[CookieEnum.UpdatedPrivacyPolicy] && !isPrivacyPage) {
        setPrivacyPolicyPopupOpen(true);
      }
      if (
        !cookies[CookieEnum.AwaitSurvey] &&
        !cookies[CookieEnum.SurveyComplete]
      ) {
        if (cookies[CookieEnum.FirstVisit]) {
          setSurveyOpen(true);
        } else {
          setFirstVisitCookie();
          onClickMaybeLater();
        }
      }
    }, [
      cookies,
      onClickMaybeLater,
      setFirstVisitCookie,
      previousMenuItemId,
      isPrivacyPage,
    ]);

    return (
      <div>
        {wireframesOpen ? (
          children
        ) : (
          <>
            <div className={classes.greyBackground}>
              <MenuComponent
                menuItems={menuItems(openTutorial)}
                permissions={permissions}
                additionalContent={additionalContent()}
                menuDisabled={isMenuDisabled}
                disabledFactor={numberOfModalsOpen}
                hasChanged={hasMenuChanged}
              >
                {isMobile() ? (
                  <MobileDisplay handleSignOut={handleSignOut} />
                ) : (
                  children
                )}
              </MenuComponent>
            </div>

            <Survey
              onClickMaybeLater={onClickMaybeLater}
              completeModal={completeSurvey}
              isModalOpen={surveyOpen}
            ></Survey>
            <PrivacyPolicyPopup
              onAgreeToPolicy={onAgreeToPrivacyPolicy}
              isModalOpen={privacyPolicyPopupOpen}
              closeModal={closePrivacyPolicyModal}
            ></PrivacyPolicyPopup>
            <HelpCarousel
              closeModal={() => closeCarousel(previousMenuItemId)}
              isModalOpen={carouselOpen}
              tutorialSteps={introTutorialSteps}
              header={tutorialStrings.labels.gettingStarted}
            />
            <TrialStartPopup
              isModalOpen={trialStartPopupOpen}
              closeModal={closeTrialStartPopup}
              onClickOk={closeTrialStartPopup}
            ></TrialStartPopup>
          </>
        )}
      </div>
    );
  };

  const LoggedOutLayout = () => <>{children}</>;

  return (
    <>
      <CssBaseline />
      {user ? <LoggedInLayout /> : <LoggedOutLayout />}
    </>
  );
};
