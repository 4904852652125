import clsx from "clsx";
import { HTMLAttributeAnchorTarget } from "react";
import { Link } from "react-router-dom";

export interface LinkItemConfig {
  to?: string;
  text: string;
  className?: string;
  onClick?: () => void;
  target?: HTMLAttributeAnchorTarget;
}

interface InlineLinksProps {
  linkItems: LinkItemConfig[];
  className?: string;
}

type InlineLinksComponent = (props: InlineLinksProps) => JSX.Element;

export const InlineLink: InlineLinksComponent = ({ linkItems, className }) => {
  return (
    <span className="nav-span">
      <div className={className}>
        {linkItems.map((item) => {
          const {
            to,
            text,
            className: itemClassName,
            onClick,
            target = undefined,
          } = item;
          if (to) {
            return (
              <Link
                className={
                  itemClassName
                    ? itemClassName
                    : clsx("color-primary", "nav-link-auth")
                }
                to={to}
                onClick={onClick}
                target={target}
              >
                {text}
              </Link>
            );
          }
          return (
            <span className={clsx("nav-join", itemClassName)}>{text}</span>
          );
        })}
      </div>
    </span>
  );
};
