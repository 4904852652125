import type { AxiosRequestConfig } from "axios";
import {
  getDescriptiveErrorMessage,
  getErrorMessage,
} from "../helpers/errorHelper";
import { formatTicketTemplate } from "../helpers/jiraTicketHelper";
import axios from "../libs/axios/axios-jiragen";
import { TicketTemplate } from "../types/documents";
import type { TicketTemplateFilters } from "../types/documents/TicketTemplate";

export const generateJiraCsv = async (projectId: string) => {
  try {
    const url = `${projectId}/generateJiraCsv`;
    const config = {};
    const { data } = await axios.get(url, config);
    console.log("GENERATE JIRA CSV", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const createJiraTicketTemplate = async (ticketTemplateToCreate: any) => {
  const formattedObject = formatTicketTemplate(ticketTemplateToCreate);

  try {
    const url = "templates";
    const config = {};
    const { data } = await axios.post(url, formattedObject, config);
    console.log("CREATE JIRA TEMPLATE", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(
      "An error occurred while creating the task template. Please try again."
    );
  }
};

export const uploadJiraTicketTemplate = async (jiraTicketUpload: any) => {
  try {
    const url = "templates/upload";
    const config = {};
    const data = await axios.post(url, jiraTicketUpload, config);
    console.log("UPLOAD JIRA TICKET TEMPLATES", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getDescriptiveErrorMessage(e));
  }
};

export const getTicketUploadTemplate = async () => {
  try {
    const url = `templates/download`;
    const config = {};
    const { data } = await axios.get(url, config);
    console.log("DOWNLOAD JIRA TICKET TEMPLATE", data);
    return Promise.resolve(data.data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const readJiraTicketTemplate = async (id: string) => {
  try {
    const url = `templates/${id}`;
    const config = {};
    const { data } = await axios.get(url, config);
    console.log("READ JIRA TEMPLATE", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const deleteJiraTicketTemplate = async (
  ticketTemplate: TicketTemplate,
  abortSignal: AbortSignal,
  appendHistory: (url: string) => void
) => {
  try {
    const url = `templates/${ticketTemplate.id}`;
    const config = { signal: abortSignal };
    const { data } = await axios.delete(url, config);
    console.log("DELETE JIRA TEMPLATE", data);
    await Promise.resolve(data);
    appendHistory("/");
    appendHistory("/ticketTemplates");
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const updateJiraTicketTemplate = async (
  dataObject: { [key: string]: any } & { summary: string; description: string }
) => {
  try {
    const {
      id,
      tenantId,
      createdDate,
      updatedDate,
      active,
      ...ticketTemplateValues
    } = dataObject;
    const url = `templates/${id}`;
    const updateObject = formatTicketTemplate(ticketTemplateValues);
    const config = {};
    const { data } = await axios.put(url, updateObject, config);
    console.log("UPDATE JIRA TEMPLATE", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(
      "An error occurred while updating the taak template. Please try again."
    );
  }
};

export const listJiraTicketTemplates = async (
  searchCriteria: { page: number; size: number },
  abortSignal?: AbortSignal,
  filters: TicketTemplateFilters = {},
  sortDirection?: string,
  sortColumn?: string
) => {
  try {
    const { actionType: entityAction, ...requestFilters } = filters;

    const url = `templates`;
    const params: AxiosRequestConfig["params"] = {
      pageNumber: searchCriteria.page + 1,
      pageSize: searchCriteria.size,
      orderColumn: sortColumn,
      orderDirection: sortDirection,
      ...requestFilters,
      entityAction,
    };

    const config: AxiosRequestConfig = {
      params,
      signal: abortSignal,
    };

    const { data } = await axios.get(url, config);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};
