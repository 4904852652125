import {
  Checkbox,
  FormControl,
  FormGroup,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuProps,
  Select,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader, LoaderProps } from "../../components/general";
import { getErrorMessage } from "../../helpers";
import { capitaliseString, getUniqueArray } from "../../helpers/appHelper";
import { getRedirectPath } from "../../helpers/projectHelper";
import { projectStrings as strings } from "../../resources/strings";
import { addExtractedEntities } from "../../services/projectService";
import { colours, fonts } from "../../styles/theme";
import { Component } from "../../types";
import { ModalButtons } from "../general/Modal/ModalButtons";

const menuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "80%",
  },
  indeterminateColor: {
    color: colours.indeterminateEntitySelectionColour,
  },
  selectAllText: {
    fontWeight: 600,
  },
  selectedAll: {
    backgroundColor: colours.greySelectedColour,
    "&:hover": {
      backgroundColor: colours.greySelectedColour,
    },
  },
  primaryText: {
    fontFamily: fonts.primary,
  },
  inputLabel: {
    fontFamily: fonts.secondary,
  },
}));

const formatEntityList = (entities: string[]) => {
  const entityNames = entities
    .filter((x) => !!x)
    .map((y) => capitaliseString(y));
  return getUniqueArray(entityNames).sort();
};

enum SelectionState {
  allSelected,
  someSelected,
  noneSelected,
}

export interface Props {
  projectId: string;
  entities: string[];
  redirectPath: string | ((id: string) => string);
  enableMenu: () => void;
  loaderProps?: Partial<LoaderProps>;
  entityLimit?: number;
  onError?: (msg?: string) => void;
}

interface InputProps extends React.HTMLAttributes<HTMLDivElement> {
  "data-testid"?: string;
}

export const EntitySelection: Component<Props> = ({
  projectId,
  entities,
  redirectPath,
  enableMenu,
  loaderProps,
  entityLimit,
  onError,
}) => {
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [selectionState, setSelectionState] = useState<SelectionState>(
    SelectionState.noneSelected
  );
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const formattedEntities = formatEntityList(entities);

  const onClose = () => {
    history.push(getRedirectPath(redirectPath, projectId));
    enableMenu();
  };

  useEffect(() => {
    let newSelectionState = SelectionState.noneSelected;
    if (
      formattedEntities.length > 0 &&
      selectedEntities.length === formattedEntities.length
    ) {
      newSelectionState = SelectionState.allSelected;
    } else if (
      selectedEntities.length > 0 &&
      selectedEntities.length < formattedEntities.length
    ) {
      newSelectionState = SelectionState.someSelected;
    }
    setSelectionState(newSelectionState);
  }, [selectedEntities, formattedEntities]);

  const onCreate = async () => {
    setLoading(true);
    try {
      if (selectedEntities && selectedEntities.length) {
        await addExtractedEntities(projectId, selectedEntities);
      }
      onClose();
    } catch (e: any) {
      console.log(
        "Error adding detected entities to project",
        getErrorMessage(e)
      );
      if (onError) onError(e);
    } finally {
      setLoading(false);
    }
  };

  const isAllSelected = selectionState === SelectionState.allSelected;
  const isSomeSelected = selectionState === SelectionState.someSelected;

  const isWithinLimit =
    entityLimit === undefined || selectedEntities.length <= entityLimit;

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedEntities(isAllSelected ? [] : formattedEntities);
      return;
    }
    setSelectedEntities(value);
  };

  return (
    <Loader active={loading} {...loaderProps}>
      <>
        <div className="selectEntities">
          <span className="font-primary">
            {strings.labels.entitiesDetected}
          </span>
          <span className={clsx("font-primary", !isWithinLimit && "warning")}>
            {strings.labels.entityLimitText(entityLimit)}
          </span>
        </div>

        <FormGroup>
          <FormControl className={classes.formControl}>
            <InputLabel
              classes={{ root: classes.inputLabel }}
              id="mutiple-select-label"
            >
              {strings.labels.selectEntities}
            </InputLabel>
            <Select
              labelId="mutiple-select-label"
              multiple
              value={selectedEntities}
              onChange={handleChange}
              renderValue={(selected: any) => selected.join(", ")}
              MenuProps={menuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelected}
                    indeterminate={isSomeSelected}
                    data-testid="selectAll"
                    inputProps={
                      {
                        "data-testid": "selectAll-input",
                        "aria-checked": isSomeSelected ? "mixed" : "false",
                      } as InputProps
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  classes={{
                    root: classes.selectAllText + " " + classes.primaryText,
                  }}
                  primary={strings.labels.selectAll}
                />
              </MenuItem>
              {formattedEntities.map((entity) => (
                <MenuItem key={entity} value={entity}>
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedEntities.indexOf(entity) > -1}
                      inputProps={
                        { "data-testid": `check-${entity}` } as InputProps
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={entity}
                    classes={{ root: classes.primaryText }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
        <ModalButtons
          className="button-span"
          buttonConfiguration={{
            cancelConfig: {
              onClick: onClose,
              text: strings.labels.cancel,
            },
            confirmConfig: {
              onClick: onCreate,
              text: strings.labels.createEntities,
              disabled: !isWithinLimit,
            },
          }}
        />
      </>
    </Loader>
  );
};
