import { getErrorMessage } from "../helpers";
import axios from "../libs/axios/axios-docgen";

export const exportProject = async (
  projectId: string,
  projectTypeId: string,
  templateId: string
) => {
  try {
    const url = "generateDocumentation";
    const body = {
      projectId,
      projectTypeId,
      templateId,
    };
    const config = {};
    const { data } = await axios.post<Response>(url, body, config);
    console.log("GENERATE DOCUMENT", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const getDocumentStateList = async (
  projectId: string,
  projectTypeId: string
) => {
  try {
    if (!projectId || !projectTypeId) {
      throw new Error("Invalid URL parameters.");
    }

    const url = `${projectId}/generatedDocumentList/${projectTypeId}`;
    console.log("url", url);
    const config = {};
    const { data } = await axios.get(url, config);
    console.log("GET DOCUMENT STATE LIST", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const getDocumentDownload = async (
  projectId: string,
  documentId: string
) => {
  try {
    const url = `${projectId}/getDocument/${documentId}`;
    const config = {};
    const { data } = await axios.get(url, config);
    console.log("GET DOCUMENT DOWNLOAD", data);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};
