import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-stripe";
import { getErrorMessage } from "../helpers";

const getCustomerPortalLink = async () => {
  try {
    const url = "createPortalSession";
    const body = {};
    const config: Axios.AxiosRequestConfig = {};
    
    const { data } = await axios.post<Response>(url, body, config) as any as { data: string };
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export {
  getCustomerPortalLink
};
