import { useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import type { Row, TableOptions } from "react-table";
import { useFlexLayout, usePagination, useSortBy, useTable } from "react-table";

import {
  Box,
  Button,
  Grid,
  Paper,
  TableBody,
  TableCell,
  Table as TableComponent,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

import { appStrings } from "../../resources/strings";
import { getStyles } from "../../styles/general/table";
import { Permissions, UserAccess } from "../../types";
import { ShowIfAuthorised } from "../authentication";
import { Loader } from "../general";

/*
  The react table docs are useless when it comes to typescript

  Some useful resources:
    - Type declarations: 
        https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
    - For future projects, pay particular attention to the configuration 
      section of the readme in the above repo
    - React table example written in TypeScript from one of the React Table maintainers
        https://github.com/ggascoigne/react-table-example
*/

type TableEvent = React.MouseEvent<HTMLButtonElement, MouseEvent> | null;
type ChangeHandler = React.ChangeEventHandler<
  HTMLInputElement | HTMLTextAreaElement
>;

interface Props<D extends Record<string, unknown>> extends TableOptions<D> {
  header: string;
  totalCount?: number;
  size?: number;
  createButtonConfig?: {
    label: string;
    userAccess: UserAccess;
    path: string;
  };
  permissions: Permissions | null;
  loading?: boolean;
  clickHandler?: (data: D) => void;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const Table = <T extends Record<string, unknown>>({
  header,
  data: tableData,
  columns: tableColumns,
  totalCount = tableData.length,
  size = 10,
  createButtonConfig,
  permissions,
  loading,
  clickHandler,
  manualPagination,
  onPageChange,
  onSizeChange,
}: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const pageCount = useMemo(
    () => Math.ceil(totalCount / size),
    [totalCount, size]
  );

  const hooks = [useSortBy, usePagination, useFlexLayout];
  const classes = useStyles();

  const instance = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageSize: size,
        pageIndex: 0,
      },
      manualPagination,
      pageCount,
      // this flag prevents weird behaviour when changing page
      // like fetching the next page then re-fetching the previous page immediately
      autoResetPage: false,
    },
    ...hooks
  );

  // NOTE: if a new hook plugin is used, it may be necessary to update
  // `types/react-table-config.d.ts` to get the correct types
  // See `TableOptions` interface
  const {
    // basic table props
    getTableProps,
    getTableBodyProps,
    headerGroups = [],
    prepareRow,
    state: { pageIndex, pageSize: tablePageSize },
    // pagination props
    page: pageRows = [],
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setTablePageSize,
  } = instance;

  // If manual pagination is set, we want to forward any changes in table state
  // to trigger a new data fetch
  useEffect(() => {
    if (manualPagination) {
      onPageChange(pageIndex);
      onSizeChange(tablePageSize);
    }
  }, [manualPagination, pageIndex, tablePageSize, onPageChange, onSizeChange]);

  const handleChangePage = (_event: TableEvent, newPage: number) => {
    if (newPage > pageIndex) {
      nextPage();
    } else {
      previousPage();
    }
  };

  const handleChangeRowsPerPage: ChangeHandler = (event) => {
    if (!event) return;
    const { target } = event;

    setTablePageSize(+target.value);
    gotoPage(0);
  };

  const renderCreateButton = () => {
    if (!createButtonConfig) return <></>;
    const { userAccess, label, path } = createButtonConfig;

    return (
      <ShowIfAuthorised userPermissions={permissions} {...userAccess}>
        <Button
          color="primary"
          variant="contained"
          component={RouterLink}
          to={path}
        >
          {label}
        </Button>
      </ShowIfAuthorised>
    );
  };

  const renderHeader = () => {
    return (
      <Grid
        className={clsx(classes.header)}
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h5">{header}</Typography>
        </Grid>
        <Grid item>{renderCreateButton()}</Grid>
      </Grid>
    );
  };

  const handleClick = (row: Row<T>) => {
    if (clickHandler) clickHandler(row.original);
  };

  const renderTable = () => {
    return (
      <>
        <TableContainer component={Paper}>
          <TableComponent {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {pageRows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow
                    {...row.getRowProps()}
                    onClick={() => handleClick(row)}
                    hover
                    className={clsx(classes.tableRow)}
                  >
                    {row.cells.map((cell) => (
                      <TableCell {...cell.getCellProps()}>
                        <Typography noWrap variant="body2">
                          {cell.render("Cell")}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </TableComponent>
        </TableContainer>
        <TablePagination
          role="navigation"
          component="div"
          page={pageIndex}
          count={totalCount}
          rowsPerPageOptions={[5, 10, 20, 50]}
          rowsPerPage={tablePageSize}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{ title: appStrings.titles.pageOptions }}
        />
      </>
    );
  };

  return (
    <Box role="grid" display="flex" flexDirection="column">
      {renderHeader()}
      <Loader active={loading}>{renderTable()}</Loader>
    </Box>
  );
};
