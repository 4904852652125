import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

const drawerWidth = 250;
const smallDrawerWidth = 0;

export const getStyles = (theme: Theme) => {
  return {
    root: {
      display: "flex",
      position: "relative" as any,
    },
    toolbar: {
      paddingRight: "1.5rem", // keep right padding when drawer closed
    },
    toolbarHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "30px 0px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      boxShadow: "none",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarModal: {
      content: '""',
      display: "block",
      position: "absolute" as any,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(22,21,21,0.644)",
      pointerEvents: "none" as any,
      zIndex: 10000,
    },
    appBarShift: {
      [theme.breakpoints.up("xs")]: {
        marginLeft: smallDrawerWidth,
        width: `calc(100% - ${smallDrawerWidth}px)`,
      },
      [theme.breakpoints.up("md")]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {},
    menuButtonHidden: {
      display: "none",
    },
    flexSpacer: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: "relative" as any,
      whiteSpace: "nowrap" as any,
      overflowX: "hidden" as any,
      [theme.breakpoints.up("xs")]: {
        width: smallDrawerWidth,
      },
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperModal: {
      position: "relative" as any,
      whiteSpace: "nowrap" as any,
      overflowX: "hidden" as any,
      border: "none",
      [theme.breakpoints.up("xs")]: {
        width: smallDrawerWidth,
      },
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden" as any,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("xs")]: {
        width: drawerWidth,
      },
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(7),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
      backgroundColor: colours.whiteColour,
    },
    container: {
      paddingRight: "0px",
      paddingLeft: "0px",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column" as any,
    },
    fixedHeight: {
      height: "15rem",
    },
    media: {
      height: "25px",
      width: "185px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    menuText: {
      fontFamily: fonts.primary,
      fontSize: "17px",
      color: "#666666",
    },
    menuTextSelected: {
      fontFamily: fonts.primary,
      fontSize: "17px",
      color: "333333",
    },
    itemRoot: {
      "&:hover": {
        backgroundColor: colours.inputBackgroundGrey,
      },
      "&$selected": {
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: colours.inputBackgroundGrey,
        },
      },
    },
    menuIcon: {
      minWidth: "38px",
      color: "#666666",
    },

    menuIconSelected: {
      minWidth: "38px",
      color: colours.primaryPurple,
    },
    selected: {},
    noOverflow: {
      overflow: "hidden",
    },
    appBarLogo: {
      width: "190px",
      marginRight: "33px",
    },
    listItem: {
      display: "flex",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "16px",
      marginBottom: "0px",
      marginTop: "0px",
      paddingRight: "16px",
    },
    copyright: {
      marginBottom: "0",
    },
    legal: {
      position: "absolute" as any,
      textAlign: "center" as any,
      bottom: "5px",
      right: "0",
      left: "0",
    },
    legalLinks: {
      fontFamily: fonts.secondary,
      fontSize: "0.9rem",
      textDecoration: "underline",
      textDecorationColor: colours.primaryPurple,
    },
    menuItemArrow: {
      color: `${colours.greyColour} !important`,
    },
    nestedListItem: {
      paddingLeft: "5rem",
    },
  };
};
