import { getErrorMessage } from "../helpers";
import axios from "../libs/axios/axios-wireframes";
import { Conflict, Page, WireframesData } from "../types/Wireframes";

export const checkWireframesGenerated = async (projectId: string) => {
  try {
    console.log("checkWireframesGenerated");
    const config = {};
    const path = `${projectId}/checkWireframesGenerated`;
    const { data } = await axios.get<Conflict[]>(path, config);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const generateWireframes = async (
  projectId: string,
  conflicts: any[]
) => {
  try {
    console.log("generateWireframes");
    const url = `${projectId}/generateWireframes`;
    const body = {
      conflicts,
    };
    const config = {};
    const { data } = await axios.put<WireframesData>(url, body, config);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const getWireframes = async (projectId: string) => {
  try {
    console.log("getWireframes");
    const config = {};
    const path = `${projectId}/getWireframes`;
    const result = await axios.get<WireframesData>(path, config);
    return Promise.resolve(result.data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const deleteWireframesPage = async (
  projectId: string,
  pageId: string
) => {
  try {
    console.log("deleteWireframesPage");
    const deleteRowUrl = `pages/${projectId}/${pageId}`;
    await axios.delete<Response>(deleteRowUrl, {});
    return Promise.resolve(true);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const updateWireframesPage = async (projectId: string, page: Page) => {
  try {
    console.log("updateWireframesPage");
    const url = `pages/updateWireframesPage`;
    const body = {
      projectId,
      page,
    };
    const config = {};
    const { data } = await axios.put<Page>(url, body, config);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const addWireframesPage = async (projectId: string, page: Page) => {
  try {
    console.log("addWireframesPage");
    const url = `pages/addWireframesPage`;
    const body = {
      projectId,
      page,
    };
    const config = {};
    const { data } = await axios.put<Page>(url, body, config);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};
