import axios from "../libs/axios/axios-estimation";
import { getErrorMessage } from "../helpers/errorHelper";

export const generateEstimates = async (projectId: string) => {
  try {
    const url = `${projectId}/generateEstimates`;
    const config = { };
    const { data } = await axios.get(url, config);
    console.log("GENERATE ESTIMATES", data);
    return Promise.resolve(data);
  } 
  catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};
