import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    menuItem: {
      fontFamily: fonts.secondary,
      fontSize: "1.2rem",
      color: colours.greyColour,
      paddingTop: 0,
      paddingBottom: 0,
      "&:hover": {
        opacity: "0.95",
      },
    },
    dropdownButton: {
      fontFamily: fonts.secondary,
      color: colours.whiteColour,
      fontSize: "1.1rem",
      paddingRight: "0.3rem",
    },
    dropdownIcon: {
      color: colours.secondaryPink,
      marginRight: "0.5rem",
      marginLeft: "1rem",
      fontSize: "1.6rem",
    },
    menuItemSpan: {
      fontFamily: fonts.secondary,
      backgroundColor: "inherit",
      fontSize: "1.2rem",
      color: colours.greyColour,
    },
    iconButton: {
      paddingLeft: "0",
      backgroundColor: "transparent !important",
    },
  };
};
