import {
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/modal";
import { Component } from "../../../types";
import { Conflict } from "../../../types/Wireframes";
import { FullscreenModal as CustomModal } from "./FullscreenModal";

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  open: boolean;
  onClose: () => void;
  conflicts: Conflict[];
  updateConflicts: (conflicts: Conflict[]) => void;
}

export const ConflictChoicesModal: Component<Props> = ({
  open,
  onClose,
  conflicts,
  updateConflicts,
}) => {
  const classes = useStyles();
  const [changes, setChanges] = useState<Conflict[]>(conflicts);

  useEffect(() => {
    setChanges(conflicts);
  }, [conflicts]);

  const onSubmit = () => {
    updateConflicts(changes);
    onClose();
  };

  const onCheckBoxChange = (
    event: ChangeEvent<HTMLInputElement>,
    conflict: Conflict,
    index: number
  ) => {
    conflict.keepChange = event.target.checked;
    const newConflicts = changes.slice();
    newConflicts[index] = conflict;
    setChanges(newConflicts);
  };

  return (
    <CustomModal
      active={open}
      header={strings.headers.conflicts}
      onCloseModal={onClose}
    >
      <>
        <p className={classes.conflictLabel}>{strings.text.conflictsOne}</p>
        <p className={classes.conflictLabel}>{strings.text.conflictsTwo}</p>
        <div className={classes.listContainer}>
          {changes.map((conflict, index) => (
            <FormControlLabel
              className={classes.conflictLabel}
              color="secondary"
              control={
                <Checkbox
                  className={classes.colorRequimentSecondary}
                  onChange={(e) => onCheckBoxChange(e, conflict, index)}
                  checked={conflict.keepChange}
                />
              }
              label={conflict.description}
              labelPlacement="start"
            />
          ))}
        </div>
        <Button
          variant="outlined"
          className={classes.actionButton}
          onClick={onSubmit}
          size="large"
        >
          {strings.labels.submit}
        </Button>
      </>
    </CustomModal>
  );
};
