export const authStrings = {
  challenges: {
    newPassword: "NEW_PASSWORD_REQUIRED",
  },
  errors: {
    userNotConfirmed: "User is not confirmed.",
    aws: [
      {
        errorMessage:
          "Password does not conform to policy: Password not long enough",
        displayMessage: "Password must be at least eight characters.",
      },
      {
        errorMessage:
          "Password did not conform with policy: Password not long enough",
        displayMessage: "Password must be at least eight characters.",
      },
      {
        errorMessage:
          "Password does not conform to policy: Password must have uppercase characters",
        displayMessage: "Password must have uppercase characters.",
      },
      {
        errorMessage:
          "Password did not conform with policy: Password must have uppercase characters",
        displayMessage: "Password must have uppercase characters.",
      },
      {
        errorMessage:
          "Password does not conform to policy: Password must have lowercase characters",
        displayMessage: "Password must have lowercase characters.",
      },
      {
        errorMessage:
          "Password did not conform with policy: Password must have lowercase characters",
        displayMessage: "Password must have lowercase characters.",
      },
      {
        errorMessage:
          "Password does not conform to policy: Password must have numeric characters",
        displayMessage: "Password must contain at least one number.",
      },
      {
        errorMessage:
          "Password did not conform with policy: Password must have numeric characters",
        displayMessage: "Password must contain at least one number.",
      },
      {
        errorMessage:
          "Password does not conform to policy: Password must have symbol characters",
        displayMessage: "Password must contain at least one special character.",
      },
      {
        errorMessage:
          "Password did not conform with policy: Password must have symbol characters",
        displayMessage: "Password must contain at least one special character.",
      },
      {
        errorMessage: "Attempt limit exceeded, please try after some time.",
        displayMessage:
          "Password reset attempt limit exceeded, please try again later.",
      },
      {
        errorMessage:
          "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern:",
        displayMessage: "Not a valid email address.",
      },
      {
        errorMessage: "Incorrect username or password.",
        displayMessage: "Incorrect username or password.",
      },
      {
        errorMessage:
          "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern:",
        displayMessage: "Email address cannot contain spaces.",
      },
      {
        errorMessage: "Invalid email address format.",
        displayMessage: "Not a valid email address.",
      },
      {
        errorMessage: "An account with the given email already exists.",
        displayMessage: "An account with the given email already exists.",
      },
      {
        errorMessage: "Invalid verification code provided, please try again.",
        displayMessage: "Invalid verification code provided. Please try again.",
      },
      {
        errorMessage: "User is not confirmed.",
        displayMessage: "Your account is unconfirmed.",
      },
      {
        errorMessage:
          "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$",
        displayMessage: "Password must be at least eight characters.",
      },
    ],
    unknown: "Unknown error occured, please contact administrator.",
    subscriptionError:
      "Your subscription requires action. Please contact your administrator.",
    invalidVerificationCode:
      "Invalid verification code provided. Please try again.",
    errorDeletingUser: (userId: string) =>
      `Could not delete user with id ${userId}`,
    passwordsDoNotMatch: "Passwords do not match.",
  },
  headers: {
    login: "Log In",
    changePassword: "Change password",
    completePassword: "Complete password reset",
    signUp: "Sign up",
    confirmAccount: "Confirm account",
    manageSubscription: "Manage Subscription",
    manageUsers: "Manage Users",
    choosePlan: "Choose Plan",
  },
  labels: {
    username: "Username",
    email: "Email",
    password: "Password",
    createPassword: "Create password",
    login: "Log In",
    resetPassword: "Reset your password",
    enterNewPassword: "Enter new password",
    newPassword: "New password",
    confirmPassword: "Confirm password",
    confirmNewPassword: "Confirm new password",
    verificationCodePlaceholder: "Enter your verification code",
    enterYourEmailPlaceholder: "Enter your email",
    enterYourPasswordPlaceholder: "Enter your password",
    enterAPasswordPlaceholder: "Enter a password",
    reEnterPasswordPlaceholder: "Re-enter your password",
    confirm: "Confirm",
    signingIn: "Signing in...",
    signingUp: "Signing up...",
    verificationCode: "Verification code",
    resetPasswordPlaceholder: "Enter your new password",
    confirmResetPasswordPlaceholder: "Re-enter your new password",
    code: "Code",
    submit: "Submit",
    signUp: "Sign up",
    signUpPrefix: "Don't have an account?",
    returnToLoginFromSignUp: [
      "Already have an account? Click",
      "here",
      " to log in",
    ],
    returnToLogin: "Return to login",
    manageSubscription: "Manage Subscription",
    cookiePreferences: "Cookie Preferences, ",
    privacyPolicy: "Privacy Policy",
    termsandConditions: "Terms of Service",
    IAgree: "I agree to the",
    and: " and ",
    period: ".",
    comma: ", ",
    selectUsersToDelete: "Select users to delete",
    userDeletionProgress: (selectedUsers: number, usersToDelete: number) =>
      `${selectedUsers} of ${usersToDelete} selected`,
    success: "Your subscription has been successfully created!",
    clickBelowToLogin: "Click below to log in to Requiment.",
  },
  text: {
    receiveCode: "Enter your email below to receive a verification code.",
    signUpCodeSent:
      "We have sent a verification code to your inbox. Enter it below to confirm your account.",
    copyright: `\u00A9 ${new Date().getFullYear()} Fieldimp Ltd.`,
    legal: " All rights reserved.",
    subscriptionIssue:
      "Your subscription requires some action. Click the button below to manage your account.",
    userLimitAbove:
      "Your user limit is above the number of users in your system",
    deletionRequired: "You need to delete",
    deletionRequired_number: (userDeletionNumber: number) =>
      ` ${userDeletionNumber} `,
    deletionRequired_users: (userDeletionNumber: number) =>
      `user${userDeletionNumber > 1 ? "s" : ""}`,
    chooseAPlan:
      "Choose a plan which best suits your requirements gathering needs",
    pricesTaxExclusive: "All prices are exclusive of VAT or sales tax.",
    clickForCheckout: "Click Subscribe to be taken to Checkout",
  },
};
