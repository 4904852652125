import { CircularProgress, makeStyles } from "@material-ui/core";
import { Done, Edit } from "@material-ui/icons";
import {
  ApiContextProvider,
  EntityListTableContainer,
  EntityListTableContainerProps as ListProps,
} from "@pulsion/forms-designer-2";
import clsx from "clsx";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { imageLibraryApi } from "../../apis/imageLibraryApi";
import { Notification } from "../../components/general";
import { InlineInput } from "../../components/general/FileUploader/InlineInput";
import { formatDateTime } from "../../helpers";
import { paths } from "../../navigation/paths";
import { projectStrings as strings } from "../../resources/strings/projects";
import { updateFile } from "../../services/filesService";
import { listTheme } from "../../styles/formsDesigner/listTheme";
import { getStyles } from "../../styles/general/fileUpload";
import { Component, NotificationType } from "../../types";
import { LibraryImageData } from "../../types/documents/ImageFile";
import { EmptyListDisplay } from "../general/EmptyListDisplay/EmptyListDisplay";

const useStyles = makeStyles((theme) => getStyles(theme));

export const ImageLibraryList = () => {
  const history = useHistory();

  const appendHistory = (url: string) => {
    history.push(url);
  };

  const defaultDisplay = (
    <EmptyListDisplay
      onClickButton={() => appendHistory(paths.imageLibrary.upload)}
      titleText={strings.labels.getStarted}
      buttonText={strings.labels.addImages}
      infoText={strings.labels.imageLibraryListInfoText}
    ></EmptyListDisplay>
  );

  const props: ListProps<LibraryImageData> = {
    entity: "File",
    inputTheme: listTheme,
    createButtonConfig: {
      label: strings.labels.addImages,
      path: paths.imageLibrary.upload,
    },
    manualPagination: true,
    deleteButtons: true,
    resizableColumns: true,
    getViewEntityPath: (rowObject: LibraryImageData) => "",
    header: strings.labels.imageLibrary,
    tableColumns: [
      {
        id: "fileName",
        Header: "Filename",
        accessor: "fileName",
        isSortable: true,
        isResizable: true,
      },
      {
        id: "tags",
        Header: "Tags",
        accessor: (file: LibraryImageData) => {
          return <EditableTags file={file} />;
        },
        isResizable: true,
      },
      {
        id: "createdDate",
        Header: "Uploaded",
        accessor: ({ createdDate }: LibraryImageData) =>
          formatDateTime(createdDate),
        isSortable: true,
        isResizable: true,
      },
      {
        id: "createdBy",
        Header: "Uploaded By",
        accessor: "createdBy",
        isSortable: true,
      },
    ],
    appendHistory,
    defaultDisplay,
  };

  return (
    <ApiContextProvider api={imageLibraryApi}>
      <EntityListTableContainer<LibraryImageData> {...props} />
    </ApiContextProvider>
  );
};

const formatTags = (tags?: string[]) => {
  if (!tags) return "";
  return tags.join(",");
};

interface TagProps {
  file: LibraryImageData;
}

const EditableTags: Component<TagProps> = ({ file }) => {
  const classes = useStyles();

  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [tags, setTags] = useState<string[] | undefined>(file.tags);
  const [hasUpdated, setHasUpdated] = useState(false);

  const onEditTags = (updatedTags: string[]) => {
    setHasUpdated(true);
    setError("");
    setTags(updatedTags);
  };

  const onSave = async () => {
    setSaving(true);

    try {
      if (hasUpdated) {
        const updatedFile: LibraryImageData = { ...file, tags };
        await updateFile(updatedFile);
      }
      setEditMode(false);
      setError("");
    } catch (e: any) {
      console.log(e);
      setError("Failed to update tags. Please try again");
    } finally {
      setSaving(false);
      setHasUpdated(false);
    }
  };

  const isDisabled = !editMode;

  const onClickActionIcon = saving
    ? undefined
    : editMode
    ? onSave
    : () => setEditMode(true);

  const clickableIconClassName = !saving ? classes.clickableIcon : "";

  return (
    <>
      <span className={classes.editableTagsContainer}>
        <InlineInput
          className={clsx(
            classes.tagInput,
            isDisabled && classes.disabledTagInput
          )}
          inputValue={formatTags(file.tags)}
          inputKey={file.id}
          onEdit={onEditTags}
          disabled={isDisabled}
        />
        <span
          className={clsx(classes.actionIcon, clickableIconClassName)}
          onClick={onClickActionIcon}
        >
          {saving && <CircularProgress size={24} />}
          {!saving && (editMode ? <Done /> : <Edit />)}
        </span>
      </span>
      <Notification message={error} type={NotificationType.error} />
    </>
  );
};
