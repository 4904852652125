import {
  documentStrings,
  ticketTemplateStrings as strings,
} from "../resources/strings";
import {
  EntityActions,
  FilterOption,
  TicketTemplateTaskType,
} from "../types/documents/TicketTemplate";
import { downloadFile } from "./fileHelper";

const nonBreakingSpace = "&nbsp;";
const emptySpace = " ";

export const getEntityActionDropdownOptions = () => {
  return EntityActions.sort().map((x) => ({
    label: x,
    value: x,
  }));
};

export const convertHtmlSpaces = (htmlString: string) => {
  if (!htmlString) return "";
  const utf8String = Buffer.from(htmlString, "utf-8").toString();
  return utf8String.replace(new RegExp(nonBreakingSpace, "g"), emptySpace);
};

export const downloadTaskCsv = async (
  generateJiraCsv: () => any,
  projectName?: string
) => {
  const data = await generateJiraCsv();
  const newData = "data:text/csv;charset=utf-8," + data;
  const encodedUri = encodeURI(newData);
  const outputFileName = projectName
    ? documentStrings.outputFileNames.tasks(projectName)
    : documentStrings.outputFileNames.tasksDefault;
  downloadFile(encodedUri, outputFileName);
};

export const formatTicketTemplate = (
  ticketTemplateValues: { [key: string]: any } & {
    summary: string;
    description: string;
  }
) => {
  return {
    ...ticketTemplateValues,
    summary: convertHtmlSpaces(ticketTemplateValues.summary),
    description: convertHtmlSpaces(ticketTemplateValues.description),
  };
};

export const getDisplayOptions = (options: FilterOption[]) => {
  let outputOptions = options as any[];
  if (!options || !options.length) {
    outputOptions = [
      {
        key: "None",
        value: "None",
        text: "No options available",
        disabled: true,
      },
    ];
  }
  return outputOptions.map(({ text, ...option }) => ({
    ...option,
    label: text,
  }));
};

export const getTagOptionsFromTaskType = (
  taskType: TicketTemplateTaskType | string
) => {
  const tagOptionLabels: string[] =
    (strings.labels.tagSelectorLabels as any)[taskType] ?? [];
  return getDisplayOptions(
    Object.values(tagOptionLabels).map((x) => ({ key: x, text: x, value: x }))
  );
};
