import { Button } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { useHistory } from "react-router-dom";

interface Props {
  path: string;
  icon?: JSX.Element;
  children?: JSX.Element | string;
  color: any;
  variant: any;
  style?: CSSProperties;
  className?: string;
  indent?: string;
}
type Component = (props: Props) => JSX.Element | null;

export const NavButton: Component = ({
  path,
  icon,
  children,
  color,
  variant,
  className,
  indent = "15px"
}) => {

const history = useHistory();

  return (
    <Button
      style={{ marginLeft: indent }}
      className={className}
      onClick={() => {
        history.push(path);
      }}
      startIcon={icon}
      color={color}
      variant={variant}
    >
      {children}
    </Button>
  );
};