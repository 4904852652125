import { useEffect, useState } from "react";
import type {
  ErrorDisplayConfig,
  Mode,
} from "../../components/general/types/Modify";
import { useCancelToken } from "../../hooks/general";
import { ModifyContainer } from "../general";

import {
  Box,
  Button,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  ButtonComponentProps,
  IconActionButton,
} from "../../components/general/controls/IconActionButton";
import { handleManageSubscription } from "../../components/layout";
import { createDropdownOptions, getErrorMessage } from "../../helpers";
import { isRequimentTypeUser } from "../../helpers/appHelper";
import { HooksLogger } from "../../hooks/hooks-logger";
import type { Axios } from "../../libs/axios";
import { PERMISSIONS_ENDPOINT, USERS_ENDPOINT } from "../../libs/config";
import { paths } from "../../navigation";
import { userStrings as strings } from "../../resources/strings";
import { getStyles } from "../../styles/users/userModify";
import { Component, RouteProps } from "../../types";
import type { UserData } from "../../types/documents";
import { Modal } from "../general/Modal/Modal";

const logger = new HooksLogger("UserModify/getRoles");
const putEndpoint = `${USERS_ENDPOINT}/submit`;
const queryEndpoint = `${USERS_ENDPOINT}`;
const rolesEndpoint = `${PERMISSIONS_ENDPOINT}/roles`;

export interface Props extends RouteProps {
  mode: Mode;
  enableMenu: () => void;
  disableMenu: () => void;
}

type Role = { roleId: string; name: string };

const constraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.email} is required`,
    },
  },
  roleId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.roleId} is required`,
    },
  },
};

const useStyles = makeStyles((theme) => getStyles(theme));

export const UserModify: Component<Props> = ({
  mode,
  enableMenu,
  disableMenu,
  ...routeProps
}) => {
  const { jwt, user } = routeProps;

  const [roles, setRoles] = useState<Array<{ label: string; value: string }>>(
    []
  );
  const [loading, setLoading] = useState(false);

  const cancelToken = useCancelToken();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const config: Axios.AxiosRequestConfig = {
      cancelToken,
      headers: {
        Authorization: jwt,
      },
    };

    const query = async () => {
      logger.request("Getting role data");

      setLoading(true);
      try {
        const {
          data: { items = [] },
        } = await axios.get<{ items: Array<Role> }>(rolesEndpoint, config);

        const options = createDropdownOptions(items, "roleId", "name");
        setRoles(options);
        setLoading(false);

        logger.success(items);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = getErrorMessage(e);
        setLoading(false);
        logger.error(error);
      }
    };

    query();
    disableMenu();
  }, [jwt, cancelToken, disableMenu]);

  const closeModal = () => {
    history.push(paths.users.list);
    enableMenu();
  };

  const getSuccessfulDisplay = (userData: UserData) => {
    return (
      <div>
        <Typography className={classes.displayText}>
          {strings.text.userAdded}
        </Typography>
        <Typography component="div" className={classes.displayText}>
          {strings.text.userAddedInfo}
          <Box className={classes.userEmailText} display="inline">
            {userData.email}
          </Box>
        </Typography>
        <div className={classes.buttonComponent}>
          <Button
            className={classes.actionButton}
            color="primary"
            variant="contained"
            fullWidth
            onClick={closeModal}
          >
            {strings.global.labels.close}
          </Button>
        </div>
      </div>
    );
  };

  const manageSubscriptionButton: ButtonComponentProps = ({
    onClick,
    disabled,
    children,
  }) => (
    <IconButton
      className={
        "manageSubscription-button " +
        classes.actionButton +
        " " +
        classes.manageButton
      }
      onClick={onClick}
      disabled={disabled}
    >
      <span>{strings.global.menuLabels.manageSubscription}</span>
      {children}
    </IconButton>
  );

  const userLimitReachedDisplay = (
    <div>
      <Typography className={classes.displayText}>
        {strings.text.userLimitReachedInfo}
      </Typography>
      <Typography className={classes.displayText}>
        {strings.text.userLimitReachedDirection}
      </Typography>
      <div className={classes.paddingTopTwo}>
        <IconActionButton
          ButtonComponent={manageSubscriptionButton}
          onClick={handleManageSubscription}
          spinnerProps={{
            size: 24,
            color: "inherit",
            style: { marginLeft: "1rem" },
          }}
        />
      </div>
    </div>
  );

  const errorHandlingConfig: ErrorDisplayConfig[] | undefined =
    !isRequimentTypeUser(user)
      ? [
          {
            errorMessage: strings.errorMessages.userLimitReached,
            display: userLimitReachedDisplay,
          },
        ]
      : undefined;

  return (
    <Modal
      active
      onCloseModal={closeModal}
      header={
        mode === "create" ? strings.labels.createUser : strings.labels.editUser
      }
    >
      <div className={classes.form}>
        <ModifyContainer<UserData>
          {...routeProps}
          putEndpoint={putEndpoint}
          queryEndpoint={queryEndpoint}
          mode={mode}
          initialData={{
            userId: "",
            username: "",
            email: "",
            roleId: "",
            forename: "",
            surname: "",
            created: "",
            updated: "",
          }}
          componentConfiguration={{
            staticConfig: [
              {
                key: "details",
                content: [
                  {
                    controltype: "input",
                    name: "email",
                    label: strings.labels.email,
                    required: true,
                  },
                  {
                    controltype: "dropdown",
                    name: "roleId",
                    label: strings.labels.roleId,
                    options: roles,
                    required: true,
                  },
                  {
                    controltype: "input",
                    name: "forename",
                    label: strings.labels.forename,
                  },
                  {
                    controltype: "input",
                    name: "surname",
                    label: strings.labels.surname,
                  },
                ],
              },
              {
                key: "submitButtons",
                modes: ["create", "update"],
                content: [
                  {
                    controltype: "button",
                    label: strings.labels.save,
                    color: "primary",
                    variant: "contained",
                  },
                  {
                    controltype: "button",
                    label: strings.labels.reset,
                    color: "primary",
                    variant: "outlined",
                    type: "reset",
                  },
                ],
              },
              {
                key: "editButton",
                userAccess: {
                  entity: strings.global.entities.user,
                  permission: strings.global.permissions.manage,
                },
                content: [
                  {
                    controltype: "button",
                    modes: ["view"],
                    label: strings.labels.edit,
                    color: "primary",
                    variant: "contained",
                    type: "button",
                    buttonaction: "switch",
                  },
                ],
              },
            ],
          }}
          constraints={constraints}
          onUpdate={closeModal}
          loading={loading}
          errorHandlingConfig={errorHandlingConfig}
          errorMessages={strings.errorMessages.messages}
          getSuccessfulDisplay={getSuccessfulDisplay}
          loaderProps={{ inline: true }}
        />
      </div>
    </Modal>
  );
};
