import { appStrings } from "./app";

export const documentStrings = {
  global: { ...appStrings },
  header: {
    documents: "Documents",
    documentsModal: "Documents",
    generateButton: "Generate",
  },
  status: {
    new: "New",
    generating: "Generating!",
    failed: "Failed!",
    generated: "Completed",
  },
  stateIds: {
    // change to actual ids
    generating: "fb6959c5-9507-46a9-94fc-3d002a17b1bf",
    generated: "74c820ee-653b-4c14-b7f6-bf5abdb08826",
    failed: "922e67f2-5d6e-42ba-b0bb-3fc994248fef",
  },
  labels: {
    documentsModalClose: "Close",
    tableStatus: "Status",
    tableTemplateName: "Template",
    tableDownload: "Download",
  },
  outputFileNames: {
    tasks: (projectName: string) => `${projectName}`,
    tasksDefault: "requiment-project-tasks",
  },
  files: {
    docx: {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      extension: ".docx",
    },
  },
  defaultDocumentName: "requirements_document.docx",
  downloadErrorMessage: "Failed to download document. Please try again.",
};
