import {
  Box,
  CardMedia,
  Collapse,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Menu as MenuIcon } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { colours } from "../../styles/theme";
import { Component, Permissions } from "../../types";
import { ShowIfAuthorised } from "../authentication";
import { MenuItem, setSelectedMenuItem } from "./listItems";

import appBarLogo from "../../assets/requiment-logo.svg";
import { getCurrentSelectedMenuItem } from "../../helpers/appHelper";
import { paths } from "../../navigation";
import { appStrings } from "../../resources/strings";
import { authStrings } from "../../resources/strings/auth";
import { getStyles } from "../../styles/layout/menu";
import { Link } from "../general/controls";
import { DropdownArrow } from "../general/DropdownArrow";

interface Props {
  children: JSX.Element;
  menuItems: MenuItem[];
  permissions: Permissions | null;
  logo?: string;
  additionalContent?: JSX.Element;
  menuDisabled: boolean;
  disabledFactor?: number;
  hasChanged: boolean;
}
interface AppBarProps {
  handleToggle?: () => void;
  additionalContent?: JSX.Element;
  menuDisabled?: boolean;
  disabledFactor?: number;
  open?: boolean;
}

interface MenuItemsProps {
  menuItems: MenuItem[];
  permissions: Permissions | null;
  menuDisabled: boolean;
  hasChanged: boolean;
}

interface NestedMenuItemProps {
  menuDisabled: boolean;
  selected: string;
  menuItem: MenuItem;
  onSelect: (id: string) => void;
  permissions: Permissions | null;
}

interface ListItemProps extends NestedMenuItemProps {
  nested?: boolean;
}

interface DisabledShadingProps {
  disabledFactor?: number;
  menuDisabled?: boolean;
}

const useStyles = makeStyles((theme) => getStyles(theme));

const MenuItems: Component<MenuItemsProps> = ({
  menuItems,
  permissions,
  menuDisabled,
  hasChanged,
}) => {
  const [selected, setSelected] = useState(getCurrentSelectedMenuItem());

  useEffect(() => {
    const cachedMenuItemId = getCurrentSelectedMenuItem();
    setSelected(cachedMenuItemId);
  }, [hasChanged]);

  const onSelect = (id: string) => {
    setSelected(id);
    setSelectedMenuItem(id);
  };

  return (
    <List>
      {menuItems.map((menuItem) => {
        return (
          <ListItemComponent
            menuDisabled={menuDisabled}
            menuItem={menuItem}
            selected={selected}
            onSelect={onSelect}
            permissions={permissions}
          />
        );
      })}
    </List>
  );
};

const ListItemComponent: Component<ListItemProps> = (props) => {
  const { menuItem, menuDisabled, selected, permissions, onSelect, nested } =
    props;
  const classes = useStyles();
  const history = useHistory();

  const { label, Icon, itemPermission, link, onClick, menuItems, id } =
    menuItem;
  if (menuItems && menuItems.length) {
    return <NestedMenuItems {...props} />;
  }
  const isSelected = selected === id;
  const handleClick = () => {
    if (onClick) onClick();
    if (id) onSelect(id);
    if (link) {
      history.push(link);
    }
  };
  const Content = (
    <ListItem
      classes={{
        root: clsx(classes.itemRoot, nested && classes.nestedListItem),
        selected: clsx(classes.selected, "menu-list-item"),
      }}
      button
      selected={isSelected}
      onClick={handleClick}
      disabled={menuDisabled}
      className={classes.listItem}
    >
      {Icon && (
        <ListItemIcon
          classes={{
            root: isSelected ? classes.menuIconSelected : classes.menuIcon,
          }}
        >
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={label}
        classes={{
          primary: isSelected ? classes.menuTextSelected : classes.menuText,
        }}
      />
    </ListItem>
  );

  return (
    <React.Fragment key={`${label}-${id}`}>
      {itemPermission ? (
        <ShowIfAuthorised userPermissions={permissions} {...itemPermission}>
          {Content}
        </ShowIfAuthorised>
      ) : (
        <>{Content}</>
      )}
    </React.Fragment>
  );
};

const NestedMenuItems: Component<NestedMenuItemProps> = (props) => {
  const { menuDisabled, selected, menuItem, onSelect, permissions } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { Icon, label, menuItems } = menuItem;
  const isSelected = menuItems?.map((x) => x.id).includes(selected);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        classes={{
          root: classes.itemRoot,
          selected: clsx(classes.selected, "menu-list-item"),
        }}
        button
        onClick={handleClick}
        disabled={menuDisabled}
        className={classes.listItem}
      >
        {Icon && (
          <ListItemIcon
            classes={{
              root: isSelected ? classes.menuIconSelected : classes.menuIcon,
            }}
          >
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          classes={{
            primary: isSelected ? classes.menuTextSelected : classes.menuText,
          }}
          primary={label}
        />
        {open ? (
          <DropdownArrow direction="up" className={classes.menuItemArrow} />
        ) : (
          <DropdownArrow direction="down" className={classes.menuItemArrow} />
        )}
      </ListItem>
      {menuItems && menuItems.length && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuItems?.map((menuItem) => (
              <ListItemComponent
                menuDisabled={menuDisabled}
                menuItem={menuItem}
                selected={selected}
                onSelect={onSelect}
                permissions={permissions}
                nested
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export const AppBar: Component<AppBarProps> = ({
  handleToggle,
  additionalContent,
  menuDisabled,
  disabledFactor,
  open,
}) => {
  const classes = useStyles();

  return (
    <MuiAppBar
      position="absolute"
      className={clsx(classes.appBar)}
      style={{ backgroundColor: colours.darkBlueColour }}
    >
      <Toolbar className={classes.toolbar}>
        <img alt="" src={appBarLogo} className={classes.appBarLogo}></img>
        {handleToggle && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleToggle}
            className={clsx(classes.menuButton)}
            data-testid="toggle-button"
            disabled={menuDisabled}
            title={
              open
                ? appStrings.menuLabels.openTitle
                : appStrings.menuLabels.closedTitle
            }
          >
            <MenuIcon style={{ color: "white" }} />
          </IconButton>
        )}
        <Box color="inherit" className={classes.flexSpacer}></Box>
        {additionalContent ? additionalContent : null}
      </Toolbar>
      <DisabledShading
        menuDisabled={menuDisabled}
        disabledFactor={disabledFactor}
      />
    </MuiAppBar>
  );
};

const DisabledShading: Component<DisabledShadingProps> = ({
  disabledFactor,
  menuDisabled,
}) => {
  const classes = useStyles();

  const shadingFactor = disabledFactor
    ? new Array<number>(disabledFactor).fill(0)
    : menuDisabled
    ? [0]
    : undefined;

  return (
    <>
      {shadingFactor &&
        shadingFactor.map((x) => <div className={classes.appBarModal} />)}
    </>
  );
};

export const MenuComponent: Component<Props> = ({
  children,
  menuItems,
  permissions,
  logo,
  additionalContent,
  menuDisabled,
  disabledFactor,
  hasChanged,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={classes.root} role="menu">
      <AppBar
        handleToggle={handleToggle}
        additionalContent={additionalContent}
        menuDisabled={menuDisabled}
        disabledFactor={disabledFactor}
        open={open}
      />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            menuDisabled ? classes.drawerPaperModal : classes.drawerPaper,
            !open && classes.drawerPaperClose
          ),
        }}
        open={open}
      >
        <div className={classes.toolbarHeader}>
          <CardMedia className={clsx(classes.media)} image={logo} />
        </div>
        <Divider hidden />
        <MenuItems
          menuItems={menuItems}
          permissions={permissions}
          menuDisabled={menuDisabled}
          hasChanged={hasChanged}
        />
        <Typography className={classes.legal} variant="caption">
          <p className={classes.copyright}>{authStrings.text.copyright}</p>
          <Link
            key="termsAndConditions"
            config={{
              controltype: "link",
              text: authStrings.labels.termsandConditions,
              to: paths.termsAndConditions,
              className: clsx(classes.legalLinks),
            }}
            target={appStrings.other.linkTargetNewTab}
          />
          <Link
            key="privacyPolicy"
            config={{
              controltype: "link",
              text: authStrings.labels.privacyPolicy,
              to: paths.privacyPolicy,
              className: clsx(classes.legalLinks),
            }}
            target={appStrings.other.linkTargetNewTab}
          />
        </Typography>
        <DisabledShading
          menuDisabled={menuDisabled}
          disabledFactor={disabledFactor}
        />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
};
