import { Theme } from "@material-ui/core";
import { common } from "./common";
import { fonts } from "./theme";

export const getStyles = (theme: Theme) => {
  return {
    ...common(theme),
    subHeader: {
      margin: "20px",
      display: "flex",
      justifyContent: "space-between",

      "& h2": {
        fontSize: "14px",
        fontWeight: "400",
        fontFamily: fonts.secondary,
        textTransform: "uppercase",
        alignSelf: "flex-end",
        color: theme.palette.grey[600],
      },
    },
    titleBorder: {
      borderBottom: "solid 1px " + theme.palette.grey[600],
      paddingBottom: "10px",
    },
    mobileHeader: {
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      "& h2": {
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: fonts.primary,
        textAlign: "center",
        margin: "0px",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "50%",
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    deleteButtonContainer: {
      margin: "5px",
      width: "100%",
    },
    mobileDeleteButton: {
      width: "90%",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    searchContainer: {
      padding: "10px",
      paddingTop: "0px",
      width: "100%",
      maxWidth: "400px",
    },
    textButtonContainer: {
      display: "flex",
      margin: "0px 5px",
      "& p": {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        marginBottom: "0px",
        alignSelf: "center",
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    textButton: {
      color: theme.palette.primary.main,
    },
    deleteTextButton: {
      color: theme.palette.error.main,
    },
  };
};
