import { useState } from "react";
import { Component, DropdownOptions } from "../../../../types";
import { DropdownDisplay } from "./DropdownDisplay";

interface Props {
  inputData: string;
  labelText?: string;
  options: DropdownOptions;
  defaultValue?: string;
  required?: boolean;
  errorMessage?: string;
  setFormData?: (...args: any[]) => void;
  className?: string;
  onChange?: (event: any) => void;
}

export const Dropdown: Component<Props> = ({
  inputData,
  options,
  labelText,
  defaultValue,
  required,
  errorMessage,
  setFormData,
  className,
  onChange,
}) => {
  const [selected, setSelected] = useState(inputData);

  const handleChange = (event: any) => {
    if (onChange) onChange(event);
    setSelected(event.target.value);
  };

  return (
    <DropdownDisplay
      labelText={labelText}
      inputLabel={selected ? "" : defaultValue}
      options={options}
      errorMessage={errorMessage}
      className={className}
      onChange={handleChange}
      value={selected}
      required={required}
    />
  );
};
