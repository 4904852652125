import type { CircularProgressProps } from "@material-ui/core";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { appStrings as strings } from "../../resources/strings";
import { getStyles } from "../../styles/general/loader";

export interface Props {
  active: boolean | undefined;
  color?: "inherit" | "primary" | "secondary" | undefined;
  children?: JSX.Element;
  inline?: boolean;
  text?: string;
  boxStyle?: CSSProperties;
  additionalContent?: JSX.Element;
}

type Component = (props: Props) => JSX.Element | null;

const useStyles = makeStyles((theme) => getStyles(theme));

export const Loader: Component = ({
  active = false,
  children = null,
  inline = false,
  text = strings.labels.loading,
  color = "primary",
  boxStyle,
  additionalContent,
}) => {
  const classes = useStyles();
  const LoaderContent = (props: CircularProgressProps) => (
    <>
      <CircularProgress className={clsx(classes.content)} {...props} />
      <Typography className={clsx(classes.content)}>{text}</Typography>
    </>
  );

  const renderLoader = () => {
    return inline ? (
      <div className="">
        <Grid container className={clsx(classes.base, classes.inline)}>
          <LoaderContent size="1.25rem" />
        </Grid>
        {additionalContent}
      </div>
    ) : (
      <>
        <Backdrop className={clsx(classes.base, classes.backdrop)} open>
          <LoaderContent />
          {additionalContent}
        </Backdrop>
      </>
    );
  };

  return active ? (
    <Box style={boxStyle} role="progressbar">
      {renderLoader()}
    </Box>
  ) : (
    children
  );
};
