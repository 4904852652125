import { Button, Typography, makeStyles } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { paths } from "../../navigation";
import { authStrings as strings } from "../../resources/strings/auth";
import { getStyles } from "../../styles/auth/auth";
import { CognitoUser, Component } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user?: CognitoUser | null;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const SuccessPage: Component<Props> = ({ user }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const sessionId = searchParams.get("session_id");

  const history = useHistory();
  const classes = useStyles();

  const redirectToLogin = useCallback(
    () => history.push(paths.auth.login),
    [history]
  );

  useEffect(() => {
    if (user || !sessionId) {
      redirectToLogin();
    }
  }, [user, sessionId, redirectToLogin]);

  return (
    <AuthWrapper>
      <Typography
        variant="button"
        color="primary"
        className={classes.loginHeader}
      >
        {strings.labels.success}
      </Typography>
      <Typography color="inherit" className={classes.formLabel}>
        {strings.labels.clickBelowToLogin}
      </Typography>
      <Button
        type="button"
        children={strings.labels.login}
        color="primary"
        variant="contained"
        fullWidth
        className={classes.submitButton}
        onClick={redirectToLogin}
      />
    </AuthWrapper>
  );
};
