import { wireframesStrings as strings } from "../resources/strings";
import { Layout } from "../types/Wireframes";

export const layouts: Layout[] = [
  { name: "Vertical", config: [3] },
  { name: "Horizontal", config: [1, 1, 1] },
];

export const calculateConfig = (
  currentPageSectionCount: number,
  layoutName: string
) => {
  let newConfiguration: number[] = [];
  const layoutConfiguration = layouts.find(
    (layout) => layout.name === layoutName
  );
  layoutConfiguration?.config.forEach((count) => {
    if (currentPageSectionCount > 0) {
      if (count >= currentPageSectionCount) {
        newConfiguration.push(currentPageSectionCount);
      } else {
        newConfiguration.push(count);
      }
      currentPageSectionCount -= count;
    }
  });
  let limit = 10;
  let configIndex = 0;
  const configLength = newConfiguration.length;
  while (currentPageSectionCount > 0 && limit > 0) {
    if (layoutName !== "Horizontal") {
      newConfiguration[configIndex] = newConfiguration[configIndex] + 1;
      configIndex++;
      if (configIndex === configLength) {
        configIndex = 0;
      }
    } else {
      newConfiguration[newConfiguration.length] = 1;
    }
    limit--;
    currentPageSectionCount--;
  }
  return newConfiguration;
};

export const getActionStyles = (action: string) => {
  switch (action) {
    case strings.actions.list:
    case strings.actions.search:
      return [
        strings.styles.table,
        strings.styles.condensedCard,
        strings.styles.detailedCard,
        strings.styles.catalogue,
      ];
    case strings.actions.read:
    case strings.actions.delete:
      return [strings.styles.form, strings.styles.text];
    default:
      return [];
  }
};

export const headerOptions = [
  strings.headerType.titleAndButtons,
  strings.headerType.titleOnly,
  strings.headerType.buttonsOnly,
  strings.headerType.noHeader,
];
