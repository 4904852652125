// Cognito config
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const COGNITO_APP_CLIENT_ID =
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
export const COGNITO_SIGNIN_URL = process.env.REACT_APP_COGNITO_SIGNIN_URL;
export const COGNITO_SIGNOUT_URL = process.env.REACT_APP_COGNITO_SIGNOUT_URL;
export const COGNITO_ADMIN_ROLE_ID =
  process.env.REACT_APP_COGNITO_ADMIN_ROLE_ID;
export const COGNITO_STANDARD_ROLE_ID =
  process.env.REACT_APP_COGNITO_STANDARD_ROLE_ID;

// Endpoints
export const PERMISSIONS_ENDPOINT = `${process.env.REACT_APP_PERMISSIONS_ENDPOINT}/permissions`;
export const PROJECTS_ENDPOINT = `${process.env.REACT_APP_PROJECTS_ENDPOINT}/projects`;
export const USERS_ENDPOINT = `${process.env.REACT_APP_USERS_ENDPOINT}/users`;
export const DOCGEN_ENDPOINT = `${process.env.REACT_APP_DOCUMENTGENERATION_ENDPOINT}/projects`;
export const FILES_ENDPOINT = `${process.env.REACT_APP_FILES_ENDPOINT}/files`;
export const ESTIMATION_ENDPOINT = `${process.env.REACT_APP_ESTIMATION_ENDPOINT}/estimation`;
export const JIRAGEN_ENDPOINT = `${process.env.REACT_APP_JIRAGENERATION_ENDPOINT}/jiraGeneration`;
export const TENANTS_ENDPOINT = `${process.env.REACT_APP_TENANTS_ENDPOINT}/tenants`;
export const STRIPE_ENDPOINT = `${process.env.REACT_APP_STRIPE_ENDPOINT}/stripe`;
export const WIREFRAMES_ENDPOINT = `${process.env.REACT_APP_WIREFRAMES_ENDPOINT}/wireframes`;

// App config
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

// Self Service config
export const PRICING_TABLE_ID = process.env.REACT_APP_PRICING_TABLE_ID;
export const PRICING_TABLE_PUBLISHABLE_KEY =
  process.env.REACT_APP_PRICING_TABLE_PUBLISHABLE_KEY;
export const ACCESS_EXPIRATION_SLACK =
  process.env.REACT_APP_ACCESS_EXPIRATION_SLACK;
export const REQUIMENT_TENANT_TYPE_ID =
  process.env.REACT_APP_REQUIMENT_TENANT_TYPE_ID;

// Project config
export const ENTITY_INTERNALSECTIONTPEID =
  process.env.REACT_APP_ENTITY_INTERNALSECTIONTYPEID;
export const FIELD_INTERNALSECTIONTPEID =
  process.env.REACT_APP_FIELD_INTERNALSECTIONTYPEID;
export const USERS_INTERNALSECTIONTPEID =
  process.env.REACT_APP_USERS_INTERNALSECTIONTYPEID;
export const ENTITY_DETECTION_CREATION_LIMIT =
  process.env.REACT_APP_ENTITY_DETECTION_CREATION_LIMIT;
