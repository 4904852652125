import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppBar } from "../../components/layout/MenuComponent";
import {
  PRICING_TABLE_ID,
  PRICING_TABLE_PUBLISHABLE_KEY,
} from "../../libs/config";
import { paths } from "../../navigation";
import { authStrings as strings } from "../../resources/strings/auth";
import { getStyles } from "../../styles/auth/productsPage";
import { CognitoUser, Component } from "../../types";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

interface Props {
  user: CognitoUser | null;
}

interface ProductsPageState {
  userId?: string;
  email?: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const ProductsPage: Component<Props> = ({ user }) => {
  const { state } = useLocation<ProductsPageState>();
  const { userId, email } = state || {};

  const classes = useStyles();
  const history = useHistory();

  const isLoggedIn = !!user;
  const tableClassName = isLoggedIn
    ? classes.loggedInStripePricingTable
    : classes.stripePricingTable;

  useEffect(() => {
    if (!email) {
      history.push(paths.root);
    }
  }, [userId, email, history]);

  return (
    <div className={classes.stripePricingDisplay}>
      <AppBar />
      <div className={tableClassName}>
        <p className={classes.title}>{strings.headers.choosePlan}</p>
        <p className={classes.description}>{strings.text.chooseAPlan}</p>
        <p className={classes.subDescription}>
          {strings.text.clickForCheckout}
        </p>
        <stripe-pricing-table
          pricing-table-id={PRICING_TABLE_ID}
          publishable-key={PRICING_TABLE_PUBLISHABLE_KEY}
          client-reference-id={userId}
          customer-email={email}
        ></stripe-pricing-table>
      </div>
      <p>{strings.text.pricesTaxExclusive}</p>
    </div>
  );
};
