import { FormLabel, makeStyles } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import clsx from "clsx";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/card";
import { Component } from "../../../types";
import { Entity } from "../../../types/Wireframes";
import { CustomImageInput } from "../controls/CustomImageInput";

interface Props {
  entity: Entity;
  index: number;
  changePage: (actionName: string, entity: Entity, rowIndex: number) => void;
  values: { label: string; value: string; type: string }[];
  detailed: boolean;
  deletable: boolean;
  editable: boolean;
  readable: boolean;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const Card: Component<Props> = ({
  entity,
  index,
  values,
  changePage,
  detailed,
  readable,
  editable,
  deletable,
}) => {
  const classes = useStyles();
  const { isMobile } = useScreenSizeContext();

  return (
    <div
      onClick={() => {
        if (readable) {
          changePage(strings.actions.read, entity, index);
        } else if (editable) {
          changePage(strings.actions.update, entity, index);
        }
      }}
      key={index}
      className={isMobile ? classes.mobileCard : classes.card}
    >
      <div className={classes.cardTextContainer}>
        {values.map((element, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className={clsx(
                classes.field,
                detailed
                  ? classes.detailText
                  : rowIndex === 0
                  ? classes.fieldTitle
                  : rowIndex === 1
                  ? classes.fieldSubtitle
                  : classes.fieldParagraph
              )}
            >
              <div key={index} className={classes.textFieldContainer}>
                {detailed && <FormLabel>{element.label}</FormLabel>}
                {element.type === strings.fieldTypes.image ? (
                  <CustomImageInput
                    value={element.value}
                    label={""}
                    editable={false}
                  />
                ) : (
                  <p className={classes.text}>{element.value}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.iconContainer}>
        {deletable && !isMobile && (
          <DeleteOutline className={clsx(classes.icon, classes.deleteIcon)} />
        )}
      </div>
    </div>
  );
};
