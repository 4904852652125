import { Grid } from "@material-ui/core";
import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Loader, LoaderProps, ModifyComponent } from "../../components/general";
import { Notification } from "../../components/general/Notification";
import type {
  ChangeHandler,
  Config,
  ErrorDisplayConfig,
  ErrorMessage,
  Mode,
} from "../../components/general/types/Modify";
import { ModifyContextProvider } from "../../context/ModifyContext";
import { getErrorMessage } from "../../helpers";
import { getUserFacingErrorMessage } from "../../helpers/errorHelper";
import { useCancelToken } from "../../hooks/general";
import { HooksLogger } from "../../hooks/hooks-logger";
import type { Axios } from "../../libs/axios";
import { RouteProps } from "../../types";
import validate from "../../validation";

const logger = new HooksLogger("ModifyContainer/submitData");

interface Props<D extends object> extends RouteProps {
  children?: JSX.Element;
  putEndpoint: string;
  queryEndpoint: string;
  initialData: D;
  componentConfiguration: Config<D>;
  mode: Mode;
  constraints: object;
  redirectPath?: string | ((id: string) => string);
  onUpdate?: () => void;
  loading?: boolean;
  errorHandlingConfig?: ErrorDisplayConfig[];
  errorMessages?: ErrorMessage[];
  getSuccessfulDisplay?: (data: D) => JSX.Element;
  loaderProps?: Partial<LoaderProps>;
}

export const ModifyContainer = <D extends { [key: string]: unknown }>({
  children,
  putEndpoint,
  queryEndpoint,
  jwt,
  initialData: _initialData,
  componentConfiguration,
  mode: inputMode,
  permissions,
  constraints,
  redirectPath,
  onUpdate,
  loading: propsLoading = false,
  loaderProps,
  errorHandlingConfig,
  errorMessages,
  getSuccessfulDisplay,
}: Props<D>) => {
  type Response = { item: D };

  const history = useHistory();
  const { id, childId } = useParams<{ id?: string; childId?: string }>();
  const cancelToken = useCancelToken();
  const [mode, setMode] = useState<Mode>(() => inputMode);
  const token = useMemo(() => jwt, [jwt]);
  const url = useMemo(() => queryEndpoint, [queryEndpoint]);
  const [initialData, setInitialData] = useState(() => _initialData);
  const [formData, setFormData] = useState<D>(initialData);
  const [loading, setLoading] = useState(() => false);
  const [error, setError] = useState("");
  const [validationResults, setValidationResults] = useState<{
    [key: string]: string[];
  } | null>(null);
  const [success, setSuccess] = useState(() => false);
  const [message, setMessage] = useState("");
  const [redirectId, setRedirectId] = useState("");

  useEffect(() => {
    const config: Axios.AxiosRequestConfig = {
      cancelToken,
      headers: {
        Authorization: token,
      },
    };
    let path = `${url}/${id}`;
    if (childId) path = `${path}/${childId}`;

    const query = async () => {
      logger.request("Getting item data");

      setLoading(true);
      try {
        const {
          data: { item },
        } = await axios.get<Response>(path, config);
        setFormData(item);
        setInitialData(item);
        setLoading(false);
        logger.success(item);
      } catch (e) {
        if (cancelToken.reason) return;

        const error = getErrorMessage(e);
        setError(error);
        setLoading(false);
        logger.error(error);
      }
    };

    if (id && inputMode !== "create") query();
  }, [id, childId, inputMode, cancelToken, token, url]);

  const handleModeSwitch = useCallback(() => {
    switch (mode) {
      case "update":
        if (success && onUpdate) {
          onUpdate();
        } else {
          setMode("view");
        }
        break;
      case "view":
        setMode("update");
        break;
      case "create":
        let path = "";
        if (typeof redirectPath === "string") {
          path = redirectPath;
        } else if (typeof redirectPath === "function") {
          path = redirectPath(redirectId);
        }

        if (success && path) history.push(path);
        break;
      default:
        break;
    }
  }, [mode, history, redirectPath, onUpdate, redirectId, success]);

  const handleReset = useCallback(() => {
    setFormData(initialData);
    handleModeSwitch();
    setValidationResults(null);
  }, [initialData, handleModeSwitch]);

  useEffect(() => {
    if (success && (mode !== "create" || !getSuccessfulDisplay)) {
      handleModeSwitch();
      setMessage("Data submitted successfully");
      setSuccess(false);
    }
  }, [success, mode, handleModeSwitch, getSuccessfulDisplay]);

  const handleChange: ChangeHandler = (e) => {
    const { target } = e;
    const { name, value, checked } = target;
    if (!name) return;

    setFormData((prev) => ({
      ...prev,
      [name]: target.hasOwnProperty("checked") ? checked : value,
    }));
  };

  const submitData = async () => {
    const config: Axios.AxiosRequestConfig = {
      cancelToken,
      headers: {
        Authorization: jwt,
      },
    };
    const body = {
      eventType: mode,
      payload: formData,
    };

    logger.request("Submitting form data");

    setLoading(true);
    setValidationResults(null);
    try {
      const {
        data: { id },
      } = await axios.put<{ id: string }>(putEndpoint, body, config);
      setRedirectId(id);
      setSuccess(true);
      setLoading(false);
      logger.success();
    } catch (e) {
      const error = getErrorMessage(e);
      setMessage(getUserFacingErrorMessage(error, errorMessages));
      setError(error);
      setLoading(false);
      logger.error(error);
    }
  };

  const validateForm = () => {
    const results = validate(formData, constraints) ?? {};
    const isValid = !Object.keys(results).length;
    if (!isValid) {
      setValidationResults(results);
      return false;
    }
    submitData();
    return true;
  };

  const renderChildren = () => {
    if (!children) return null;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const isLoading = [loading, propsLoading].some((l) => l);

  const getCustomErrorDisplay = () => {
    if (!errorHandlingConfig) return;
    return errorHandlingConfig.find((x) => x.errorMessage === error)?.display;
  };

  const getSuccessDisplay = () => {
    if (!getSuccessfulDisplay || !success) return;
    return getSuccessfulDisplay(formData);
  };

  return (
    <div>
      {getCustomErrorDisplay() ?? getSuccessDisplay() ?? (
        <ModifyContextProvider value={{ handleModeSwitch }}>
          <Loader active={isLoading} {...loaderProps}>
            <div>
              <Notification message={message} onClose={() => setMessage("")} />
              <ModifyComponent<D>
                permissions={permissions}
                mode={mode}
                handleModeSwitch={handleModeSwitch}
                validateForm={validateForm}
                data={formData}
                onChange={handleChange}
                handleReset={handleReset}
                componentConfiguration={componentConfiguration}
                loading={isLoading}
                setFormData={setFormData}
                validationResults={validationResults}
                jwt={jwt}
              />
              {renderChildren()}
            </div>
          </Loader>
        </ModifyContextProvider>
      )}
    </div>
  );
};
