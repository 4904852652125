import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormGroup,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuProps,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { PermIdentityOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Loader, Notification } from "../../components/general";
import { paths } from "../../navigation";
import { appStrings } from "../../resources/strings";
import { authStrings } from "../../resources/strings/auth";
import { deleteUser } from "../../services/userService";
import { getStyles } from "../../styles/auth/userLimitsIssue";
import { CognitoUser, Component, NotificationType } from "../../types";
import { UserData } from "../../types/documents";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
}

interface UserLimitIssuePageState {
  tenantId: string;
  userDeletionNumber: number;
  currentUsers?: UserData[];
}

const menuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

interface InputProps extends React.HTMLAttributes<HTMLDivElement> {
  "data-testid"?: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const UserLimitIssue: Component<Props> = ({ user }) => {
  const { state } = useLocation<UserLimitIssuePageState>();
  const { tenantId, userDeletionNumber, currentUsers } = state || {};

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (user) history.push(paths.root);
    if (success || !tenantId) history.push(paths.auth.login);
  }, [user, success, tenantId, history]);

  const dbUsers = currentUsers ?? [];

  const getDisplayUserName = (user: UserData) => {
    if (user.forename || user.surname) {
      return `${user.forename ?? ""} ${user.surname ?? ""} [${user.email}]`;
    } else {
      return user.email;
    }
  };

  const onDelete = async () => {
    setLoading(true);
    if (selectedUsers && selectedUsers.length) {
      try {
        const promises: Promise<any>[] = [];
        const usersToDelete = dbUsers.filter((x) =>
          selectedUsers.includes(x.email)
        );

        for (const user of usersToDelete) {
          const { userId } = user;
          promises.push(
            new Promise<string>((resolve, reject) => {
              deleteUser(user, undefined as any, () => {})
                .then(() => resolve(userId))
                .catch(() =>
                  reject(authStrings.errors.errorDeletingUser(userId))
                );
            })
          );
        }
        await Promise.all(promises);
        setSuccess(true);
      } catch (e: any) {
        console.log("Error while deleting users.", e);
        setError(authStrings.errors.unknown);
      }
    }
    setLoading(false);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    setSelectedUsers(event.target.value);
  };

  const numberSelected = selectedUsers.length;
  const numberSelectedIsSufficient = numberSelected === userDeletionNumber;

  const renderInfo = () => {
    return (
      <>
        <Typography className={clsx(classes.infoText, classes.infoTextHeader)}>
          {authStrings.text.userLimitAbove}
        </Typography>
        <Typography
          component="div"
          className={clsx(classes.infoText, classes.infoTextSubHeader)}
        >
          {authStrings.text.deletionRequired}
          <Box className={classes.userDeletion} display="inline">
            {authStrings.text.deletionRequired_number(userDeletionNumber)}
          </Box>
          {authStrings.text.deletionRequired_users(userDeletionNumber)}
        </Typography>
      </>
    );
  };

  const userDeletionHeader = (
    <span className="inputHeader">
      <PermIdentityOutlined fontSize="large" />
      <Typography className={classes.cardHeaderTitle}>
        {authStrings.headers.manageUsers}
      </Typography>
    </span>
  );

  const renderUserDeletionPanel = () => {
    return (
      <div id="userLimit" className={classes.userDeletionPanel}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={userDeletionHeader}
          />
          <Loader active={loading} inline boxStyle={{ paddingTop: "8rem" }}>
            <>
              <FormGroup className={classes.formGroup}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    classes={{ root: classes.inputLabel }}
                    id="mutiple-select-label"
                  >
                    {authStrings.labels.selectUsersToDelete}
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="mutiple-select-label"
                    multiple
                    value={selectedUsers}
                    onChange={handleChange}
                    renderValue={(selected: any) =>
                      selected.join(authStrings.labels.comma)
                    }
                    MenuProps={menuProps}
                  >
                    {dbUsers.map((user) => (
                      <MenuItem key={user.userId} value={user.email}>
                        <ListItemIcon>
                          <Checkbox
                            checked={selectedUsers.indexOf(user.email) > -1}
                            inputProps={
                              {
                                "data-testid": `check-${user.forename}`,
                              } as InputProps
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={getDisplayUserName(user)}
                          classes={{ root: classes.primaryText }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
              <Box className={classes.progressBox}>
                <Typography
                  className={classes.progressText}
                  color={numberSelectedIsSufficient ? "primary" : "error"}
                >
                  {authStrings.labels.userDeletionProgress(
                    selectedUsers.length,
                    userDeletionNumber
                  )}
                </Typography>
              </Box>
              <span
                className={clsx("button-span", "pos-abs")}
                aria-label="buttons"
              >
                <Button
                  disabled={!numberSelectedIsSufficient}
                  color="primary"
                  variant="contained"
                  onClick={onDelete}
                >
                  {appStrings.labels.confirmDeletion}
                </Button>
              </span>
            </>
          </Loader>
        </Card>
        <Notification
          message={error}
          type={NotificationType.error}
          onClose={() => setError("")}
        />
      </div>
    );
  };

  return (
    <AuthWrapper rightHandDisplay={renderUserDeletionPanel()}>
      {renderInfo()}
    </AuthWrapper>
  );
};
