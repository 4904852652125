import { getErrorMessage } from "../helpers";
import { Auth } from "./amplify";

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (e) {}
};

export const signUp = async (
  username: string,
  password: string,
  email: string
) => {
  try {
    const response = await Auth.signUp({
      username,
      password,
      attributes: { email },
    });
    return Promise.resolve(response);
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const confirmSignUp = async (username: string, code: string) => {
  try {
    const response = await Auth.confirmSignUp(username, code);
    return Promise.resolve(response);
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const signIn = async (_type?: string) => {
  try {
    await Auth.federatedSignIn();
  } catch (e) {
    console.log(getErrorMessage(e));
  }
};

export const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return Promise.resolve(user);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getIdToken = async () => {
  try {
    // getting the current session should keep the tokens fresh
    const session = await Auth.currentSession();
    const token = session.getIdToken().payload;
    return Promise.resolve(token);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getToken = async () => {
  try {
    // getting the current session should keep the tokens fresh
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return Promise.resolve(token);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const signInWithDetails = async (email: string, password: string) => {
  try {
    const response = await Auth.signIn({ username: email, password });
    return Promise.resolve(response);
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const completeNewPassword = async (
  user: object,
  newPassword: string
) => {
  try {
    await Auth.completeNewPassword(user, newPassword);
    return Promise.resolve();
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const forgotPassword = async (email: string) => {
  try {
    await Auth.forgotPassword(email);
    return Promise.resolve();
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const forgotPasswordSubmit = async (
  email: string,
  code: string,
  newPassword: string
) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    return Promise.resolve();
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};

export const resendConfirmationCode = async (username: string) => {
  try {
    return await Auth.resendSignUp(username);
  } catch (e: any) {
    const error = e.message ? e.message : e;
    console.log(error);
    return Promise.reject(error);
  }
};
