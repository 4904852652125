import { makeStyles } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import clsx from "clsx";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import { getDisplayValue } from "../../../helpers/wireframesHelper";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/card";
import { Component } from "../../../types";
import { Entity, Field } from "../../../types/Wireframes";
import { CustomImageInput } from "../controls/CustomImageInput";

interface Props {
  entity: Entity;
  index: number;
  values: { label: string; value: string }[];
  changePage: (actionName: string, entity: Entity, rowIndex: number) => void;
  deletable: boolean;
  readable: boolean;
  editable: boolean;
  displayImage?: Field;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const CatalogueCard: Component<Props> = ({
  entity,
  index,
  values,
  changePage,
  deletable,
  readable,
  editable,
  displayImage,
}) => {
  const classes = useStyles();
  const { isMobile } = useScreenSizeContext();

  return (
    <div
      onClick={() => {
        if (readable) {
          changePage(strings.actions.read, entity, index);
        } else if (editable) {
          changePage(strings.actions.update, entity, index);
        }
      }}
      key={index}
      className={clsx(
        isMobile ? classes.mobileCard : classes.card,
        classes.catalogueCard,
        !isMobile && classes.desktopCardWidth
      )}
    >
      <div
        className={clsx(
          classes.catalogueCardContent,
          isMobile && classes.cardWrap
        )}
      >
        {displayImage && (
          <CustomImageInput
            label={""}
            editable={false}
            value={getDisplayValue(displayImage, index)}
          />
        )}
        <div className={classes.catalogueFields}>
          {values.map((element, rowIndex) => {
            return (
              <div
                key={rowIndex}
                className={clsx(
                  classes.field,
                  rowIndex === 0
                    ? classes.fieldTitle
                    : rowIndex === 1
                    ? classes.fieldSubtitle
                    : classes.fieldParagraph
                )}
              >
                <p className={clsx(classes.text)}>{element.value}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.iconContainer}>
        {!isMobile && deletable && (
          <DeleteOutline className={clsx(classes.icon, classes.deleteIcon)} />
        )}
      </div>
    </div>
  );
};
