import { Link } from "react-router-dom";
import clsx from "clsx";

export interface LinkItemConfig {
  to: string;
  text: string;
};

interface NavLinksProps {
  linkItems: LinkItemConfig[];
  currentPageText: string;
};

type NavLinksComponent = (props: NavLinksProps) => JSX.Element;

export const NavLinks: NavLinksComponent = ({ linkItems, currentPageText }) => {
  return (
    <span className="nav-span">
      {linkItems.map(item => {
        return (
          <div key={item.text}>
            <Link className={clsx("color-primary", "nav-link")} to={item.to}>{item.text}</Link>
            <span className="nav-join"> &gt; </span>
          </div>
        )
      })}
      <p className="nav-current-page">{currentPageText}</p>
    </span>
  );
}