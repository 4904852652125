import { Theme } from "@material-ui/core";
import { colours } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    header: {
      backgroundColor: colours.darkBlueColour,
      color: colours.whiteColour,
    },
    control: {
      margin: "0.5rem",
    },
  };
};
