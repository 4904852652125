import { Theme } from "@material-ui/core";

export const getStyles = (theme: Theme) => {
  return {
    base: {
      display: "flex",
      backgroundColor: "inherit",
      color: theme.palette.primary.main,
    },
    backdrop: {
      flexDirection: "column" as any,
      alignItems: "center",
      zIndex: "auto" as any,
    },
    inline: {
      alignContent: "center",
      flexWrap: "wrap" as any,
      justifyContent: "center",
    },
    content: {
      margin: "0.5rem",
      fontSize: "1.2rem",
    },
  };
};
