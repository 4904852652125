import { Theme } from "@material-ui/core";
import { colours } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    filesAdded: {
      display: "flex",
      justifyContent: "center",
      fontSize: "1.2rem",
      paddingBottom: "1rem",
      paddingTop: "2rem",
    },
    fileColumnSpan: {
      display: "flex",
      height: "1.5rem",
      "& $div": {
        paddingLeft: "1.5rem",
      },
    },
    tagInput: {
      paddingLeft: "0.5rem",
    },
    disabledTagInput: {
      color: colours.disabledGreyText,
    },
    uploadPageHeaderSpan: {
      height: "5rem",
    },
    actionIcon: {
      paddingLeft: "0.4rem",
    },
    clickableIcon: {
      cursor: "pointer",
    },
    editableTagsContainer: {
      display: "flex",
      height: "1.8rem",
    },
    dropzoneInput: {
      height: "10rem",
    },
    dropzoneInputPopulated: {
      height: "6rem",
    },
  };
};
