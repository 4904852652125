import { Theme } from "@material-ui/core";
import { common } from "./common";
import { fonts } from "./theme";

export const getStyles = (theme: Theme) => {
  return {
    ...common(theme),
    container: {
      border: "solid",
      borderWidth: "1px",
      borderColor: theme.palette.secondary.main,
      borderRadius: "5px",
      alignSelf: "center",
      padding: "15px",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column" as any,
      margin: "auto",
    },
    desktopContainer: {
      marginTop: "200px",
      width: "450px",
    },
    tabletContainer: {
      marginTop: "100px",
      width: "450px",
    },
    mobileContainer: {
      marginTop: "50px",
      border: "none",
    },
    logo: {
      height: "50px",
      margin: "20px",
      width: "fit-content",
      alignSelf: "center",
    },
    title: {
      fontSize: "30px",
      textAlign: "center" as any,
      fontWeight: 900,
      color: theme.palette.secondary.dark,
      fontFamily: fonts.primary,
      margin: "5px",
    },
    submitButton: {
      height: "50px",
      margin: "15px",
      marginTop: "20px",
      maxWidth: "400px",
      fontSize: "16px",
      boxShadow: "none",
    },
    linkContainer: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "center",
      margin: "5px",
      marginBottom: "10px",
    },
    plainText: {
      color: theme.palette.secondary.dark,
      fontFamily: fonts.primary,
      margin: "2.5px",
      textAlign: "center" as any,
    },
    link: {
      fontFamily: fonts.primary,
      margin: "2.5px",
    },
    inputContainer: {
      margin: "15px",
      marginBottom: "0px",
    },
  };
};
