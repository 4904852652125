import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import {
  AccountCircleOutlined,
  CreditCard,
  ExitToApp,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { appStrings as strings } from "../../resources/strings";
import { getStyles } from "../../styles/layout/accountOptionsDropdown";
import { Component, Permissions } from "../../types";
import { ShowIfAuthorised } from "../authentication";
import {
  ButtonComponentProps,
  IconActionButton,
} from "../general/controls/IconActionButton";
import { DropdownArrow } from "../general/DropdownArrow";

const useStyles = makeStyles((theme) => getStyles(theme));

interface AccountOptionsDropdownProps {
  userName?: string;
  handleSignOut: () => void;
  handleManageSubscription: () => void;
  permissions: Permissions | null;
  menuDisabled: boolean;
  hasSubscription: boolean;
}

export const AccountOptionsDropdown: Component<AccountOptionsDropdownProps> = ({
  userName,
  handleSignOut,
  handleManageSubscription,
  permissions,
  menuDisabled,
  hasSubscription,
}) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const manageSubscriptionButton: ButtonComponentProps = ({
    onClick,
    disabled,
    children,
  }) => (
    <IconButton className={classes.iconButton} onClick={onClick}>
      {children}
      <span className={clsx("paddingLeft-1", classes.menuItemSpan)}>
        {strings.menuLabels.manageSubscription}
      </span>
    </IconButton>
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (menuDisabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const handleClose = (event: React.ChangeEvent<{}>) => {
    setMenuOpen(false);
  };

  return (
    <>
      <span className="appbar-account">
        {strings.labels.welcomeBackUser(userName)}
      </span>
      <IconButton
        className={clsx(classes.dropdownButton)}
        onClick={handleClick}
      >
        <AccountCircleOutlined className={classes.dropdownIcon} />
        {strings.labels.myAccount}
      </IconButton>
      <DropdownArrow className="appBar" onClick={handleClick} />
      <Menu
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{ disablePadding: true }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {hasSubscription && (
          <ShowIfAuthorised
            userPermissions={permissions}
            entity={strings.entities.subscription}
            permission={strings.permissions.manage}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={handleManageSubscription}
            >
              <IconActionButton
                ButtonComponent={manageSubscriptionButton}
                icon={<CreditCard />}
                onClick={handleManageSubscription}
                spinnerProps={{ size: 24, color: "secondary" }}
              />
            </MenuItem>
          </ShowIfAuthorised>
        )}
        <MenuItem className={classes.menuItem} onClick={handleSignOut}>
          <IconButton className={classes.iconButton}>
            <ExitToApp />
            <span className={clsx("paddingLeft-1", classes.menuItemSpan)}>
              {strings.menuLabels.signOut}
            </span>
          </IconButton>
        </MenuItem>
      </Menu>
    </>
  );
};
