import { CSSProperties } from "react";
import { Component } from "../../../types";
import clsx from "clsx";
import { appStrings } from "../../../resources/strings";

interface Props {
  text: string;
  required?: boolean;
  style?: CSSProperties;
  className?: string;
};

export const Label: Component<Props> = ({ text, required, style, className = "default-control-label" }) => {
  return (
    <label style={style} aria-label="form-label" className={clsx("form-label", className && className)}>
      {text}
      {required && <div className="required-label">{appStrings.chars.requiredAsterisk}</div>}
    </label>
  );
};
