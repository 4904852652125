import { generateEstimates } from "../../services/estimationService";
import { useParams } from "react-router-dom";
import { Button } from '@material-ui/core';

// This is for internal purposes at the moment, so we can use/test the estimation feature ourselves without it being available for live users
// This will be edited and the UI improved once we wish live users to use the estimation feature

export const EstimationContainer = () => {
	const { id } = useParams<{ id: string; }>();

  return (
    <div style={{ textAlign: "center", marginTop: "8rem" }}>
      <Button 
        onClick={async () => {
          const data = await generateEstimates(id);
          const newData = "data:text/csv;charset=utf-8," + data; 
          var encodedUri = encodeURI(newData);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", "project_estimates.csv");
          document.body.appendChild(link);
          link.click();
        }}
        color="primary"
        variant="outlined"
      >
        Generate Estimates!
      </Button>
    </div>
  );
};
