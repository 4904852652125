import { documentStrings as strings } from "../resources/strings";

export const downloadBase64File = (data: any, fileName?: string) => {
  const type = strings.files.docx.type;
  const dataUrl = `data:${type};base64,${data}`;
  downloadFile(dataUrl, fileName);
};

export const downloadFile = (data: any, fileName?: string) => {
  const link = document.createElement("a");
  link.href = data;
  if (fileName) {
    link.download = fileName;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};