import { Theme } from "@material-ui/core";

export const getStyles = (theme: Theme) => {
  return {
    stripePricingDisplay: {
      height: "100vh",
      backgroundColor: "#ffffff",
      "& $p": {
        textAlign: "center",
        paddingBottom: "2rem",
        backgroundColor: "inherit",
      },
    },
    stripePricingTable: {
      marginTop: "10rem",
    },
    loggedInStripePricingTable: {
      marginTop: "2rem",
    },
    title: {
      fontSize: "2.2rem",
    },
    description: {
      fontSize: "1.2rem",
      paddingBottom: "0 !important",
    },
    subDescription: {
      fontSize: "1rem",
      marginTop: 0,
    },
  };
};
