import { Typography } from "@material-ui/core";
import type { Header as THeader } from "../types/Modify";
import { Component } from "../../../types";

interface Props {
  config: THeader;
  className: string;
}

export const Header: Component<Props> = ({ className, config }) => {
  const { text } = config;
  return <Typography className={className} {...config}>{text}</Typography>;
};
