import { getErrorMessage } from "../helpers";
import axios from "../libs/axios/axios-projects";
import { paths } from "../navigation";
import { EntityLibraryData } from "../types/documents";

export const getLibraryEntities = async (
  searchCriteria: {
    page: number;
    size: number;
  },
  abortSignal: AbortSignal,
  filters?: object,
  sortDirection?: string,
  sortColumn?: string
) => {
  try {
    const url = "/entityLibrary";
    const config = {
      params: {
        pageNumber: searchCriteria.page + 1,
        pageSize: searchCriteria.size,
        orderColumn: sortColumn,
        orderDirection: sortDirection,
      },
      signal: abortSignal,
    };

    const { data } = await axios.get<{ items: any[]; count: number }>(
      url,
      config
    );

    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};

export const deleteLibraryEntity = async (
  rowObject: EntityLibraryData,
  abortSignal: AbortSignal,
  appendHistory: (url: string) => void
) => {
  try {
    const { id } = rowObject;
    const url = `entityLibrary/${id}`;
    const config = { signal: abortSignal };
    const { data } = await axios.delete<any[]>(url, config);
    appendHistory(paths.root);
    appendHistory(paths.entityLibrary.list);
    return Promise.resolve(data);
  } catch (e) {
    console.log(e);
    return Promise.reject(getErrorMessage(e));
  }
};
