import { Theme } from "@material-ui/core";

export const getStyles = (theme: Theme) => {
  return {
    root: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  };
};
