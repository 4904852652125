import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../../components/general";
import { NavButton } from "../../../components/general/controls/NavButton";
import { LinkItemConfig, NavLinks } from "../../../components/layout/NavLinks";
import { parseDateAndTime } from "../../../helpers/dateHelper";
import { getProjectDisplayName } from "../../../helpers/projectHelper";
import { useCancelToken } from "../../../hooks/general";
import { paths } from "../../../navigation";
import { projectStrings as strings } from "../../../resources/strings";
import {
  getChangeHistoryForSection,
  getProjectById,
} from "../../../services/projectService";
import { getJobFormData as getProjectData } from "../../../services/projectsRendererService";
import { getStyles } from "../../../styles/projects/changeHistory";
import {
  Component,
  SectionChangeEventType as EventType,
  Section,
  SectionChange,
  SectionRevision,
} from "../../../types";
import { Dropdown } from "../../general/Form/Dropdown/Dropdown";
import { SectionPanels } from "./SectionPanels";

interface Props {}

interface ChangeHistoryState {
  sectionId?: string;
  projectName?: string;
  tenantId?: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const ChangeHistory: Component<Props> = () => {
  const { id: projectId } = useParams<{ id: string }>();

  const { state } = useLocation<ChangeHistoryState>();
  const {
    sectionId,
    projectName: projectNameFromLocation,
    tenantId: tenantIdFromLocation,
  } = state || {};

  const cancelToken = useCancelToken();
  const classes = useStyles();

  const [projectName, setProjectName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [loading, setLoading] = useState(false);

  const [sections, setSections] = useState<Section[]>([]);
  const [selectedSection, setSelectedSection] = useState({
    id: "",
    name: "",
  });
  const [hasLoadedSection, setHasLoadedSection] = useState(false);

  const [sectionChanges, setSectionChanges] = useState<SectionChange[]>([]);
  const [selectedChange, setSelectedChange] = useState({
    id: "",
    name: "",
  });
  const [hasLoadedChange, setHasLoadedChange] = useState(false);

  const dropdownSections = sections.filter(
    (x) => !x.gridInput && !(x.repeatable && Number.isInteger(x.sectionOrder))
  );

  const sectionOptions = dropdownSections.map((x) => ({
    label: x.sectionCaption,
    value: x.sectionId,
  }));

  const changeOptions = sectionChanges.map((x) => {
    return {
      label: strings.labels.sectionChangeDropdownLabel(x),
      value: x.id,
    };
  });

  const viewProjectPage = paths.projects.render(projectId);

  const sectionChange = sectionChanges.find((x) => x.id === selectedChange.id);

  useEffect(() => {
    const getProject = async () => {
      setLoading(true);
      const { name, tenantId } = await getProjectById(projectId, cancelToken);
      setTenantId(tenantId);
      setProjectName(name);
      setLoading(false);
    };

    if (projectNameFromLocation && tenantIdFromLocation) {
      setProjectName(projectNameFromLocation);
      setTenantId(tenantIdFromLocation);
    } else {
      getProject();
    }
  }, [projectId, projectNameFromLocation, tenantIdFromLocation, cancelToken]);

  useEffect(() => {
    const getSections = async () => {
      setLoading(true);
      const projectSections = await getProjectData(projectId, false);
      setSections(projectSections);
      const selectedSection = projectSections.find(
        (x) => x.sectionId === sectionId
      );
      if (selectedSection) {
        setSelectedSection({
          id: selectedSection.sectionId,
          name: selectedSection.sectionCaption,
        });
      }
      setLoading(false);
      setHasLoadedSection(true);
    };
    getSections();
  }, [sectionId, projectId]);

  useEffect(() => {
    const getChangeHistory = async () => {
      setLoading(true);
      const selectedSectionId = selectedSection.id;
      const changeHistory = selectedSectionId
        ? await getChangeHistoryForSection(selectedSectionId, projectId)
        : [];
      const dropdownChanges = getDropdownChanges(changeHistory);
      setSectionChanges(dropdownChanges);
      const latestChange = dropdownChanges[0];
      if (latestChange) {
        setSelectedChange({
          id: latestChange.id,
          name: strings.labels.sectionChangeDropdownLabel(latestChange),
        });
      }
      setLoading(false);
      setHasLoadedChange(true);
    };
    getChangeHistory();
  }, [selectedSection, projectId]);

  const linkItems: LinkItemConfig[] = [
    {
      to: paths.projects.list,
      text: strings.navLinkLabels.projectsList,
    },
    {
      to: viewProjectPage,
      text: getProjectDisplayName(projectName),
    },
  ];

  const onChangeSection = (event: any) => {
    const newSelectedId = event.target.value;
    const newSection = dropdownSections.find(
      (x) => x.sectionId === newSelectedId
    );
    if (newSection) {
      setSelectedSection({
        id: newSelectedId,
        name: newSection.sectionCaption,
      });
      setSelectedChange({ id: "", name: "" });
    }
  };

  const onChangeSectionChange = (event: any) => {
    const newChangeId = event.target.value;
    const newChange = sectionChanges.find((x) => x.id === newChangeId);
    if (newChange) {
      setSelectedChange({
        id: newChangeId,
        name: strings.labels.sectionChangeDropdownLabel(newChange),
      });
    }
  };

  const noSectionChangesPlaceholder = (
    <div className={classes.placeholder}>
      <div>{strings.labels.noSectionChanges}</div>
    </div>
  );

  return (
    <Loader active={loading}>
      <>
        <div className="edit-header">
          <NavLinks
            linkItems={linkItems}
            currentPageText={strings.labels.changeHistory}
          />

          <span className="project-modify-buttons">
            <NavButton
              path={viewProjectPage}
              color="primary"
              variant="outlined"
              className={classes.backButton}
            >
              {strings.labels.back}
            </NavButton>
          </span>
        </div>
        <div className={classes.dropdownContainer}>
          <span className={classes.dropdownSpan}>
            <p className={classes.dropdownLabel}>
              {strings.labels.sectionDropdownLabel}
            </p>
            {hasLoadedSection && (
              <Dropdown
                inputData={""}
                defaultValue={selectedSection.name}
                options={sectionOptions}
                className={classes.dropdown}
                onChange={onChangeSection}
              />
            )}
            <p className={classes.dropdownLabel}>
              {strings.labels.changeDropdownLabel}
            </p>
            {hasLoadedChange && (
              <Dropdown
                inputData={""}
                defaultValue={selectedChange.name}
                options={changeOptions}
                className={classes.dropdown}
                onChange={onChangeSectionChange}
              />
            )}
          </span>
        </div>
        {sectionChanges.length > 0 ? (
          <SectionPanels
            projectSections={sections}
            projectId={projectId}
            tenantId={tenantId}
            sectionChange={sectionChange}
            sectionChangeList={sectionChanges}
            sectionId={selectedSection.id}
          />
        ) : (
          noSectionChangesPlaceholder
        )}
      </>
    </Loader>
  );
};

function getDropdownChanges(changeHistory: SectionRevision[]) {
  const isDeletion = (sectionChange: SectionRevision) => {
    const { previousActive, currentActive, eventType } = sectionChange;
    return (
      eventType === EventType.REMOVE ||
      (currentActive === false && previousActive === true)
    );
  };

  return changeHistory
    .sort((a, b) => {
      if (isDeletion(a) && !isDeletion(b)) {
        return -1;
      }
      if (isDeletion(b) && !isDeletion(a)) {
        return 1;
      }
      return (
        new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf()
      );
    })
    .map((x) => {
      const { time, date } = parseDateAndTime(x.createdDate);
      return {
        ...x,
        user: x.createdBy,
        date,
        time,
      };
    });
}
