import { Button, Link, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import appBarLogo from "../../../assets/your-logo.png";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/auth";
import { Component } from "../../../types";
import {
  AuthenticationScreenInfo,
  WireframesAuthPage,
  WireframesScreenSize,
} from "../../../types/Wireframes";
import { CustomTextField } from "../controls/CustomTextField";

interface Props {
  authScreenInfo?: AuthenticationScreenInfo;
  login: () => void;
  logoImageData?: string;
  selectedPage: WireframesAuthPage;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const AuthenticationPage: Component<Props> = ({
  authScreenInfo,
  login,
  logoImageData,
  selectedPage,
}) => {
  const classes = useStyles();
  const { screenSize } = useScreenSizeContext();
  const useProjectLogo = !!logoImageData;
  const [currentPage, setCurrentPage] =
    useState<WireframesAuthPage>(selectedPage);

  const title = (title: string) => {
    return <p className={classes.title}>{title}</p>;
  };

  const textLink = (label: string, onClick: () => void, prefix?: string) => {
    return (
      <div className={classes.linkContainer}>
        {prefix && <p className={classes.plainText}>{prefix}</p>}
        <Link
          className={classes.link}
          component="button"
          variant="body2"
          onClick={onClick}
        >
          {label}
        </Link>
      </div>
    );
  };

  const plainText = (label: string) => {
    return (
      <div className={classes.linkContainer}>
        <p className={classes.plainText}>{label}</p>
      </div>
    );
  };

  const textField = (label: string, password: boolean) => {
    return (
      <div className={classes.inputContainer}>
        <CustomTextField
          label={label}
          type={strings.textFieldTypes.auth}
          initialValue={""}
          editable={true}
          updateValue={() => {}}
          password={password}
        />
      </div>
    );
  };

  const submitButton = (label: string, onClick: () => void) => {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        className={classes.submitButton}
        color="primary"
      >
        {label}
      </Button>
    );
  };

  const getCurrentPage = () => {
    switch (currentPage) {
      case WireframesAuthPage.LOGIN:
        return (
          <>
            {title(strings.labels.login)}
            {authScreenInfo?.signup &&
              textLink(
                strings.labels.signup,
                () => setCurrentPage(WireframesAuthPage.SIGNUP),
                strings.labels.signupSuffix
              )}
            {textField(strings.labels.emailAddress, false)}
            {textField(strings.labels.password, true)}
            {submitButton(strings.labels.login, login)}
            {authScreenInfo?.forgotPassword &&
              textLink(strings.labels.forgotPasswordLink, () =>
                setCurrentPage(WireframesAuthPage.FORGOT_PASSWORD)
              )}
          </>
        );
      case WireframesAuthPage.SIGNUP:
        return (
          <>
            {title(strings.labels.signup)}
            {textLink(
              strings.labels.login,
              () => setCurrentPage(WireframesAuthPage.LOGIN),
              strings.labels.loginSuffix
            )}
            {textField(strings.labels.emailAddress, false)}
            {textField(strings.labels.password, true)}
            {textField(strings.labels.confirmPassword, true)}
            {submitButton(strings.labels.signup, login)}
          </>
        );
      case WireframesAuthPage.FORGOT_PASSWORD:
        return (
          <>
            {title(strings.labels.forgotPassword)}
            {plainText(strings.labels.forgotPasswordText)}
            {textField(strings.labels.emailAddress, false)}
            {submitButton(strings.labels.resetPassword, () =>
              setCurrentPage(WireframesAuthPage.LOGIN)
            )}
            {textLink(strings.labels.returnToLogin, () =>
              setCurrentPage(WireframesAuthPage.LOGIN)
            )}
          </>
        );
      default:
        return (
          <div className={classes.empty}>
            <p>{strings.labels.empty}</p>
          </div>
        );
    }
  };

  const screenSizeClass = () => {
    switch (screenSize) {
      case WireframesScreenSize.DESKTOP:
        return classes.desktopContainer;
      case WireframesScreenSize.TABLET:
        return classes.tabletContainer;
      case WireframesScreenSize.MOBILE:
        return classes.mobileContainer;
    }
  };

  return (
    <div className={clsx(classes.container, screenSizeClass())}>
      <img
        alt=""
        src={useProjectLogo ? logoImageData : appBarLogo}
        className={clsx(classes.logo)}
      ></img>
      {getCurrentPage()}
    </div>
  );
};
