import {
  documentStrings,
  projectStrings as strings,
} from "../resources/strings";
import { FdNotificationMapping } from "../types";
import { FilterOption } from "../types/documents/TicketTemplate";
import { getTruncatedStringDisplay } from "./appHelper";

type DropdownOptions = Array<{ label: string; value: string }>;

export const allowedImageTypes = ["image/png", "image/jpg", "image/jpeg"];
const allowedImageExtensions = [".jpg", ".jpeg", ".png"];

export const canDownload = (template: any) => {
  const lastDocument = template.lastDocument;
  if (!lastDocument) return false;
  if (
    !(lastDocument.stateId === documentStrings.stateIds.generated) &&
    !(lastDocument.stateId === null)
  ) {
    return false;
  }
  return true;
};

export const findValue = <V extends string | number>(
  value: V,
  options: { label: string; value: V }[]
) => {
  const option = options.find((op) => op.value === value);

  if (option) return option.label;
  return "";
};

export const createDropdownOptions = <
  D extends { [key: string]: any; order?: number }
>(
  options: Array<D>,
  idKey: keyof D,
  textKey: keyof D
): DropdownOptions => {
  let dropdownOptions: DropdownOptions = [];
  const mapDropdownOptions = (option: D) => ({
    label: option[textKey],
    value: option[idKey],
  });

  if (options.some((option) => option.order)) {
    dropdownOptions = options.sort(sortTags).map(mapDropdownOptions);
  } else {
    dropdownOptions = options.map(mapDropdownOptions);
  }

  return dropdownOptions;
};

const sortTags = <D extends { order?: number }>(a: D, b: D): number => {
  if (a.order === undefined || b.order === undefined) return 0;

  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
};

export const sortTaskActionFilterOptions = (
  a: FilterOption,
  b: FilterOption
): number => {
  if (a.key === undefined || b.key === undefined) return 0;

  if (a.key < b.key) return -1;
  if (a.key > b.key) return 1;
  return 0;
};

export const getRedirectPath = (
  redirectPath: string | ((id: string) => string),
  redirectId?: string
) => {
  let path = "";
  if (typeof redirectPath === "string") {
    path = redirectPath;
  } else if (redirectId) {
    path = redirectPath(redirectId);
  } else {
    path = "";
  }
  return path;
};

export const projectRendererNotificationMessageMappings: FdNotificationMapping[] =
  [
    {
      incomingMessage: "Section must be completed",
      translatedMessage: "This section must be completed",
    },
    {
      incomingMessage:
        "An error occurred while adding the section. Entity limit reached. Cannot create new entity",
      translatedMessage: "Entity limit reached. Cannot create new entity",
    },
    {
      incomingMessage: "An error occurred while adding the section.",
      translatedMessage: "An error occurred while adding the section.",
    },
    {
      incomingMessage: "Changes submitted",
      translatedMessage: null,
    },
    {
      incomingMessage: "Section added to library",
      translatedMessage: "Entity added to library!",
    },
    {
      incomingMessage: "Section has been updated in the library",
      translatedMessage: "Entity updated in the library!",
    },
    {
      incomingMessage: "Failed to add section to library",
      translatedMessage: "Failed to add entity to library",
    },
    {
      incomingMessage: `File type must be one of ${allowedImageTypes.join(
        ", "
      )}`,
      translatedMessage: `Upload failed. File type must be one of [ ${allowedImageExtensions.join(
        ", "
      )} ]`,
    },
  ];

export function getProjectDisplayName(projectName: string | undefined) {
  return getTruncatedStringDisplay(projectName) ?? strings.labels.renderProject;
}
