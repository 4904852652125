import type { History } from "history";
import { useEffect, useState } from "react";
import { DateHelper } from "../../helpers";
import { hasAccessExpired } from "../../helpers/appHelper";
import type { HubCallback } from "../../libs/amplify";
import { Hub } from "../../libs/amplify";
import { getIdToken, signOut } from "../../libs/auth";
import {
  ACCESS_EXPIRATION_SLACK,
  COGNITO_ADMIN_ROLE_ID as AdminRoleId,
  REQUIMENT_TENANT_TYPE_ID as RequimentTenantTypeId,
} from "../../libs/config";
import { paths } from "../../navigation";
import { authStrings as strings } from "../../resources/strings/auth";
import { getTenantById } from "../../services/tenantsService";
import { getUsersListData } from "../../services/userService";
import { CognitoUser } from "../../types";
import { Tenant, UserData } from "../../types/documents";
import { useAbortController } from "../general/useAbortController";
import { HooksLogger } from "../hooks-logger";

const logger = new HooksLogger("useAuthUser.ts");

type IdToken = { [key: string]: any };
const defaultDateHelper = new DateHelper(new Date());

export const buildAuthUser = (userDetails: IdToken): CognitoUser => ({
  username: userDetails["cognito:username"],
  tenantId: userDetails["custom:tenantId"],
  roleId: userDetails["custom:roleId"],
  subscriptionRef: userDetails["subscriptionRef"],
  tenantTypeId: userDetails["tenantTypeId"],
  email: userDetails["email"],
  userId: userDetails["sub"],
  firstName: userDetails["given_name"],
  surname: userDetails["family_name"],
  accessExpired: userDetails["accessExpired"],
  tenantEmail: userDetails["tenantEmail"],
});

export const useAuthUser = (
  history: History,
  dateHelper: DateHelper = defaultDateHelper
) => {
  const [user, setUser] = useState<null | CognitoUser>(null);
  const [checkedAuth, setCheckedAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState<
    number | undefined
  >(undefined);

  const getSignal = useAbortController();

  useEffect(() => {
    let mounted = true;
    const callback: HubCallback = async (authEvent) => {
      switch (authEvent.payload.event) {
        case "signIn":
          let { attributes = {} } = authEvent.payload.data;
          const { username, challengeParam } = authEvent.payload.data;
          // if a challenge is present, the attributes are nested inside
          // that object instead of the payload data directly
          if (challengeParam?.userAttributes) {
            attributes = challengeParam.userAttributes;
          }

          const {
            authUser,
            subscriptionIsSet,
            tenantEmail,
            subscriptionRequiresAction,
            numberOfUsersRequiredToBeDeleted,
            currentUsers,
            accessExpired,
            trialDaysRemaining,
          } = await processUserDetails(
            attributes,
            dateHelper,
            getSignal(),
            username
          );

          if (!subscriptionIsSet && accessExpired) {
            handleExpiredTrial(history, authUser.userId, tenantEmail);
            return;
          }

          if (subscriptionRequiresAction) {
            await handleSubscriptionRequiringAction(history, authUser);
            return;
          }

          if (numberOfUsersRequiredToBeDeleted) {
            await handleSubscriptionRequiringDeletionOfUsers(
              history,
              authUser,
              numberOfUsersRequiredToBeDeleted,
              currentUsers
            );
            return;
          }

          if (mounted) {
            setTrialDaysRemaining(trialDaysRemaining);
            setUser(authUser);
          }
          break;
        case "signOut":
          if (mounted) setUser(null);
          break;
      }
    };

    Hub.listen("auth", callback);

    return () => {
      mounted = false;
      Hub.remove("auth", callback);
    };
  }, [history, dateHelper, getSignal]);

  useEffect(() => {
    let mounted = true;

    logger.info("Calling auth user");
    const query = async () => {
      logger.info("Getting user");
      if (mounted) setLoading(true);

      try {
        if (history.location.pathname === paths.success) {
          await signOut();
        } else {
          const userDetails = await getIdToken();
          logger.success(userDetails);

          const {
            authUser,
            subscriptionIsSet,
            tenantEmail,
            subscriptionRequiresAction,
            numberOfUsersRequiredToBeDeleted,
            currentUsers,
            accessExpired,
            trialDaysRemaining,
          } = await processUserDetails(userDetails, dateHelper, getSignal());

          if (!subscriptionIsSet && accessExpired) {
            await handleExpiredTrial(history, authUser.userId, tenantEmail);
          } else if (subscriptionRequiresAction) {
            await handleSubscriptionRequiringAction(history, authUser);
          } else if (numberOfUsersRequiredToBeDeleted) {
            await handleSubscriptionRequiringDeletionOfUsers(
              history,
              authUser,
              numberOfUsersRequiredToBeDeleted,
              currentUsers
            );
          } else if (mounted) {
            setTrialDaysRemaining(trialDaysRemaining);
            setUser(authUser);
          }
        }
      } catch (e) {}
      if (mounted) {
        setCheckedAuth(true);
        setLoading(false);
      }
    };
    query();

    return () => {
      mounted = false;
    };
  }, [history, dateHelper, getSignal]);

  return { user, checkedAuth, loading, trialDaysRemaining };
};

export const getSubscriptionDetails = async (
  tenantId: string,
  dateHelper: DateHelper,
  signal: AbortSignal
) => {
  if (!tenantId) {
    throw new Error(
      `Cannot get subscription details. Value "${tenantId}" for tenantId is invalid`
    );
  }

  const {
    stripeSubscriptionReference: subscriptionRef,
    hasOverduePayment,
    accessExpirationDate,
    tenantEmail,
    tenantTypeId,
    limits,
  } = (await getTenantById(tenantId)) as Tenant;

  const subscriptionIsSet = !!subscriptionRef;
  const isTrial = !subscriptionIsSet;

  const expirationSlack = !isTrial
    ? ACCESS_EXPIRATION_SLACK === undefined
      ? 3
      : parseInt(ACCESS_EXPIRATION_SLACK)
    : undefined;
  const accessExpired = hasAccessExpired(
    accessExpirationDate,
    dateHelper.currentDate,
    expirationSlack
  );

  const trialDaysRemaining =
    !isTrial || accessExpired
      ? undefined
      : dateHelper.daysUntil(accessExpirationDate);

  if (tenantTypeId === RequimentTenantTypeId) {
    return {
      subscriptionIsSet: true,
      subscriptionRef: undefined,
      tenantEmail,
      tenantTypeId,
      subscriptionRequiresAction: false,
      numberOfUsersRequiredToBeDeleted: 0,
      accessExpired: false,
    };
  }

  const getUsersResponse: { items: UserData[] } = await getUsersListData(
    {} as any,
    signal
  );
  const currentUsers = getUsersResponse.items;
  const numberOfCurrentUsers = currentUsers.length;
  const userLimitRemaining = limits.users - numberOfCurrentUsers;
  const numberOfUsersRequiredToBeDeleted =
    userLimitRemaining < 0 ? Math.abs(userLimitRemaining) : 0;

  const subscriptionRequiresAction = subscriptionIsSet
    ? hasOverduePayment || accessExpired
    : false;

  return {
    subscriptionIsSet,
    subscriptionRef,
    tenantEmail,
    tenantTypeId,
    subscriptionRequiresAction,
    numberOfUsersRequiredToBeDeleted,
    currentUsers,
    accessExpired,
    trialDaysRemaining,
  };
};

export const processUserDetails = async (
  userDetails: { [key: string]: any },
  dateHelper: DateHelper,
  signal: AbortSignal,
  username?: string
) => {
  const tenantId = userDetails["custom:tenantId"];
  const subscriptionDetails = await getSubscriptionDetails(
    tenantId,
    dateHelper,
    signal
  );
  const { subscriptionRef, tenantTypeId, accessExpired, tenantEmail } =
    subscriptionDetails;

  const authUser = buildAuthUser({
    "cognito:username": username,
    subscriptionRef: subscriptionRef,
    accessExpired,
    tenantTypeId: tenantTypeId,
    ...userDetails,
    tenantEmail,
  });
  return { ...subscriptionDetails, authUser };
};

function redirectToLogin(history: History, errorMessage?: string) {
  const message = errorMessage
    ? errorMessage
    : strings.errors.subscriptionError;
  history.push(paths.auth.login, { subscriptionError: message });
}

export const handleSubscriptionRequiringAction = async (
  history: History,
  user: CognitoUser
) => {
  if (user.roleId === AdminRoleId) {
    history.push(paths.subscriptionIssue);
  } else {
    redirectToLogin(history);
  }
};

const handleExpiredTrial = async (
  history: History,
  userId: string,
  tenantEmail: string
) => {
  history.push(paths.products, {
    userId,
    email: tenantEmail,
  });
};

export const handleSubscriptionRequiringDeletionOfUsers = async (
  history: History,
  user: CognitoUser,
  userDeletionNumber: number,
  currentUsers?: UserData[]
) => {
  if (user.roleId === AdminRoleId) {
    history.push(paths.userLimitIssue, {
      tenantId: user.tenantId,
      userDeletionNumber,
      currentUsers,
    });
  } else {
    redirectToLogin(history);
  }
};
