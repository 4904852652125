import { useState, useEffect } from "react";
import { CognitoUser } from "../../types";
import { getToken } from "../../libs/auth";
import { HooksLogger } from "../hooks-logger";
import { getErrorMessage } from "../../helpers";

const logger = new HooksLogger("useJWT.ts");

export const useJWT = (user: CognitoUser | null) => {
  const [jwt, setJWT] = useState(() => "");
  useEffect(() => {
    const query = async () => {
      logger.request("Getting token");
      try {
        const token = await getToken();
        setJWT(token);
        logger.success();
      } catch (e) {
        const error = getErrorMessage(e);
        logger.error(error);
      }
    };

    if (user) query();
  }, [user]);

  return jwt;
};
