import { Checkbox, InputLabel, makeStyles } from "@material-ui/core";
import { getStyles } from "../../../styles/wireframes/fields";
import { Component } from "../../../types";

interface Props {
  label: string;
  editable: boolean;
  updateValue: (value: string) => void;
  checked?: boolean;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const CustomCheckbox: Component<Props> = ({
  label,
  editable,
  updateValue,
  checked,
}) => {
  const classes = useStyles();

  return (
    <div>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <Checkbox
        checked={checked}
        onChange={(e) => {
          updateValue(e.target.checked ? "Yes" : "No");
        }}
        disabled={!editable}
      />
    </div>
  );
};
