import { TicketTemplateTaskType } from "../../types/documents/TicketTemplate";
import { ticketTemplateStrings as strings } from "../strings";

const { taskTypes } = strings.labels;

export const taskTypeOptions = [
  {
    key: TicketTemplateTaskType.Entity,
    value: TicketTemplateTaskType.Entity,
    text: taskTypes.entity,
  },
  {
    key: TicketTemplateTaskType.Users,
    value: TicketTemplateTaskType.Users,
    text: taskTypes.users,
  },
  {
    key: TicketTemplateTaskType.Report,
    value: TicketTemplateTaskType.Report,
    text: taskTypes.report,
  },
];
