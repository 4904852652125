import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    backButton: {
      width: "180px",
      height: "40px",
    },
    dropdownContainer: {
      borderBottom: "1px solid rgb(0, 0, 0, 0.12)",
    },
    dropdownSpan: {
      display: "flex",
      padding: "20px 10px 15px 10px",
    },
    dropdown: {
      width: "400px",
      paddingLeft: "10px",
      paddingRight: "20px",
    },
    dropdownLabel: {
      fontFamily: fonts.secondary,
      color: colours.greyColour,
      fontSize: "15px",
      paddingTop: "5px",
      paddingLeft: "10px",
    },
    panelLabel: {
      fontSize: "17px",
      textAlign: "center" as any,
      paddingTop: "15px",
      color: colours.darkGreyColour,
    },
    placeholder: {
      paddingTop: "15px",
      "& div": {
        backgroundColor: "#FCF0F1",
        textAlign: "center",
        fontSize: "15px",
        color: colours.greyColour,
        fontFamily: fonts.secondary,
        marginRight: "30%",
        marginLeft: "30%",
        height: "2.5rem",
        padding: "0.5rem 0",
      },
    },
    grid: {
      height: "100%",
    },
    leftGrid: {
      borderRight: "1px solid rgb(0, 0, 0, 0.12)",
    },
  };
};
