import { ButtonConfiguration } from "../../../types";

export const extractButtonConfig = (buttonConfig?: ButtonConfiguration) => {
  const { 
    onClick: onClickConfirm, 
    text: confirmText, 
    hidden: isConfirmHidden, 
    disabled: isConfirmDisabled, 
    color: confirmColor
  } = extractButtonConfigOfType("confirm", buttonConfig);

  const { 
    onClick: onClickCancel, 
    text: cancelText, 
    hidden: isCancelHidden, 
    disabled: isCancelDisabled, 
    color: cancelColor,
    className: cancelClassName
  } = extractButtonConfigOfType("cancel", buttonConfig);

  return {
    onClickConfirm, confirmText, isConfirmHidden, isConfirmDisabled, confirmColor, 
    onClickCancel, cancelText, isCancelHidden, isCancelDisabled, cancelColor, cancelClassName 
  };
};

export const extractButtonConfigOfType = (type: "confirm" | "cancel", buttonConfig?: ButtonConfiguration,) => {
  let objectToExtract: any = { hidden: true };
  if (buttonConfig && buttonConfig[`${type}Config`]) {
    objectToExtract = buttonConfig[`${type}Config`];
  }

  const {
    onClick = () => {},
    text = "",
    hidden = false,
    disabled = false,
    color = "secondary",
    className = ""
  } = objectToExtract;

  return { onClick, text, hidden, disabled, color, className };
};
