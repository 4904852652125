import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { InlineLink } from "../../components/general/InlineLink";
import { Loader } from "../../components/general/Loader";
import { BaseControl } from "../../components/general/controls/BaseControl";
import { Input } from "../../components/general/controls/Input";
import { CookieEnum, DateHelper } from "../../helpers";
import { getUserFacingErrorMessage } from "../../helpers/errorHelper";
import { useCookies } from "../../hooks/general/useCookies";
import { useForm } from "../../hooks/general/useForm";
import { confirmSignUp, signInWithDetails, signUp } from "../../libs/auth";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings";
import { authStrings as strings } from "../../resources/strings/auth";
import { getStyles } from "../../styles/auth/auth";
import { CognitoUser, Component } from "../../types";
import { AuthWrapper } from "./AuthWrapper";

interface Props {
  user: CognitoUser | null;
  dateHelper: DateHelper;
}

interface SignUpPageState {
  isUnconfirmed?: boolean;
  username?: string;
  password?: string;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const SignUp: Component<Props> = ({ user, dateHelper }) => {
  const classes = useStyles();

  const { state } = useLocation<SignUpPageState>();
  const { isUnconfirmed, username, password } = state || {};

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [signedUp, setSignedUp] = useState(isUnconfirmed ?? false);
  const [cookies, setCookie] = useCookies(dateHelper);

  const [formData, getChangeHandler] = useForm({
    password: password ?? "",
    confirmPassword: "",
    email: username ?? "",
    termsAgreed: false,
    code: "",
  });
  const history = useHistory();

  const handleChange = getChangeHandler();
  const handleCheckboxChange = getChangeHandler("checked");

  const doPasswordsMatch = formData.password === formData.confirmPassword;

  useEffect(() => {
    if (user || success) history.push(paths.root);
  }, [user, success, history]);

  useEffect(() => {
    if (formData.password && formData.confirmPassword && !doPasswordsMatch) {
      setError(strings.errors.passwordsDoNotMatch);
    }
  }, [formData.password, formData.confirmPassword, doPasswordsMatch]);

  useEffect(() => {
    if (doPasswordsMatch && error === strings.errors.passwordsDoNotMatch) {
      setError("");
    }
  }, [doPasswordsMatch, error]);

  const setSurveyCookie = () => {
    if (cookies) {
      const tomorrow = dateHelper.tomorrow();
      setCookie(CookieEnum.AwaitSurvey, true, {
        path: "/",
        expires: tomorrow,
      });
    }
  };

  const setPrivacyPolicyCookie = () => {
    if (cookies) {
      setCookie(CookieEnum.UpdatedPrivacyPolicy, true);
    }
  };

  const onSignUp: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await signUp(formData.email, formData.password, formData.email);

      setSurveyCookie();
      setLoading(false);
      setError("");
      setSignedUp(true);
    } catch (e: any) {
      handleCheckboxChange({
        target: {
          name: "termsAgreed",
          checked: false,
        },
      } as any);
      setLoading(false);
      setSignedUp(false);
      setError(getUserFacingErrorMessage(e));
    }
  };

  const onConfirmSignUp: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    try {
      setLoading(true);
      await confirmSignUp(formData.email, formData.code);

      await signInWithDetails(formData.email, formData.password);

      setPrivacyPolicyCookie();
      setLoading(false);
      setError("");
      setSuccess(true);
    } catch (e: any) {
      setLoading(false);
      setError(getUserFacingErrorMessage(e));
    }
  };

  const renderControl = (control: JSX.Element) => {
    return <BaseControl>{control}</BaseControl>;
  };

  const termsLabel = (
    <InlineLink
      className={classes.label}
      linkItems={[
        { text: strings.labels.IAgree, className: classes.label },
        {
          to: paths.termsAndConditions,
          text: strings.labels.termsandConditions,
          target: appStrings.other.linkTargetNewTab,
        },
        { text: strings.labels.and, className: classes.label },
        {
          to: paths.privacyPolicy,
          text: strings.labels.privacyPolicy,
          target: appStrings.other.linkTargetNewTab,
        },
        { text: strings.labels.period, className: classes.label },
      ]}
    />
  );

  const renderSignUpForm = () => {
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={classes.loginHeader}
        >
          {strings.headers.signUp}
        </Typography>
        <form noValidate onSubmit={onSignUp} autoComplete="off">
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.email}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  value: formData.email,
                  name: "email",
                  fullWidth: true,
                  autoComplete: "email",
                  variant: "outlined",
                  placeholder: strings.labels.enterYourEmailPlaceholder,
                  InputLabelProps: { className: classes.inputLabel },
                  InputProps: {
                    classes: { input: classes.input },
                  },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.createPassword}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  value: formData.password,
                  name: "password",
                  type: "password",
                  fullWidth: true,
                  autoComplete: "password",
                  variant: "outlined",
                  placeholder: strings.labels.enterAPasswordPlaceholder,
                  InputLabelProps: { className: classes.inputLabel },
                  InputProps: {
                    classes: { input: classes.input },
                  },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          {renderControl(
            <>
              <FormLabel className={classes.inputLabel}>
                {strings.labels.confirmPassword}
              </FormLabel>
              <Input
                config={{
                  controltype: "input",
                  value: formData.confirmPassword,
                  name: "confirmPassword",
                  type: "password",
                  variant: "outlined",
                  fullWidth: true,
                  placeholder: strings.labels.reEnterPasswordPlaceholder,
                  InputLabelProps: { className: classes.inputLabel },
                  InputProps: {
                    classes: { input: classes.input },
                  },
                }}
                handleChange={handleChange}
              />
            </>
          )}
          <FormControlLabel
            control={
              <Checkbox
                name="termsAgreed"
                value={formData.termsAgreed}
                checked={formData.termsAgreed}
                onChange={handleCheckboxChange}
                className={classes.checkbox}
              />
            }
            className={classes.formLabel}
            label={termsLabel}
          />
          <Button
            type="submit"
            children={strings.labels.signUp}
            color="primary"
            variant="contained"
            fullWidth
            disabled={
              !formData.password ||
              !formData.email ||
              !formData.termsAgreed ||
              !doPasswordsMatch
            }
            className={classes.submitButton}
          />
          {error ? (
            <FormHelperText className={classes.errorText} error>
              {error}
            </FormHelperText>
          ) : null}
        </form>
        <div className={classes.paddingTopTwo}>
          <InlineLink
            className={classes.label}
            linkItems={[
              {
                text: strings.labels.returnToLoginFromSignUp[0],
                className: classes.label,
              },
              {
                text: strings.labels.returnToLoginFromSignUp[1],
                to: paths.auth.login,
              },
              {
                text: strings.labels.returnToLoginFromSignUp[2],
                className: classes.label,
              },
            ]}
          />
        </div>
      </>
    );
  };

  const renderVerificationForm = () => {
    return (
      <>
        <Typography
          variant="button"
          color="primary"
          className={classes.loginHeader}
        >
          {strings.headers.confirmAccount}
        </Typography>
        <Typography className={classes.formLabel}>
          {strings.text.signUpCodeSent}
        </Typography>
        <form
          className={clsx(classes.fullWidth, classes.paddingTopTwo)}
          noValidate
          onSubmit={onConfirmSignUp}
          autoComplete="off"
        >
          {renderControl(
            <Input
              config={{
                controltype: "input",
                value: formData.code,
                name: "code",
                type: "password",
                fullWidth: true,
                autoComplete: "code",
                variant: "outlined",
                placeholder: strings.labels.verificationCodePlaceholder,
                InputLabelProps: { className: classes.inputLabel },
                InputProps: {
                  classes: { input: classes.input },
                },
              }}
              handleChange={handleChange}
            />
          )}
          <Button
            type="submit"
            children={strings.labels.confirm}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!formData.code}
            className={classes.submitButton}
          />
        </form>
        {error ? (
          <FormHelperText className={classes.errorText} error>
            {error}
          </FormHelperText>
        ) : null}
        <div className={classes.paddingTopTwo}>
          <InlineLink
            className={classes.label}
            linkItems={[
              { text: strings.labels.returnToLogin, to: paths.auth.login },
            ]}
          />
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loader active inline text={strings.labels.signingUp} />;
    }

    if (signedUp) {
      return renderVerificationForm();
    }
    return renderSignUpForm();
  };

  return <AuthWrapper>{renderContent()}</AuthWrapper>;
};
