import {
  ApiContextProvider,
  EntityListTableContainer,
  EntityListTableContainerProps as UserListProps,
} from "@pulsion/forms-designer-2";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { usersListApi } from "../../apis/usersListApi";
import { Notification } from "../../components/general/Notification";
import { formatDateTime } from "../../helpers";
import { isRequimentOrSubscriptionUser } from "../../helpers/appHelper";
import { COGNITO_ADMIN_ROLE_ID as AdminRoleId } from "../../libs/config";
import { paths } from "../../navigation/paths";
import { userStrings as strings } from "../../resources/strings/users";
import { deleteUser } from "../../services/userService";
import { listTheme } from "../../styles/formsDesigner/listTheme";
import { CognitoUser, Component } from "../../types";
import { UserData } from "../../types/documents";

interface Props {
  user: CognitoUser | null;
}

export const UserList: Component<Props> = ({ user }) => {
  const history = useHistory();
  const [message, setMessage] = useState("");

  const shouldDisplayCreateButton = isRequimentOrSubscriptionUser(user);

  const appendHistory = (url: string) => {
    history.push(url);
  };

  const clickHandler = ({ userId }: UserData) => {
    appendHistory(paths.users.view(userId));
  };

  const handleDeleteUser = async (
    rowObject: UserData,
    abortSignal: AbortSignal,
    appendHistory: (url: string) => void
  ) => {
    try {
      return await deleteUser(rowObject, abortSignal, appendHistory);
    } catch (e: any) {
      if (e === strings.errorMessages.cannotDeleteLastAdmin.apiMessage) {
        setMessage(strings.errorMessages.cannotDeleteLastAdmin.displayMessage);
      }
      return Promise.reject(e);
    }
  };

  const getRoleName = (roleId: string) => {
    switch (roleId) {
      case AdminRoleId:
        return strings.labels.admin;
      default:
        return strings.labels.standard;
    }
  };

  const defaultDisplay = <></>;

  const props: UserListProps<UserData> = {
    entity: "User",
    inputTheme: listTheme,
    createButtonConfig: shouldDisplayCreateButton
      ? {
          label: strings.labels.createUser,
          path: paths.users.create,
        }
      : undefined,
    deleteButtons: true,
    clickHandler,
    manualPagination: true,
    getViewEntityPath: (user: UserData) => paths.users.view(user.userId),
    sortOptions: {},
    header: strings.header.users,
    resizableColumns: true,
    tableColumns: [
      {
        id: "email",
        Header: strings.labels.email,
        accessor: "email",
        isSortable: true,
        isResizable: true,
      },
      {
        id: "forename",
        Header: strings.labels.forename,
        accessor: "forename",
        isSortable: true,
        isResizable: true,
      },
      {
        id: "surname",
        Header: strings.labels.surname,
        accessor: "surname",
        isSortable: true,
        isResizable: true,
      },
      {
        id: "roleId",
        Header: strings.labels.role,
        accessor: ({ roleId }) => getRoleName(roleId),
        isSortable: true,
        isResizable: true,
      },
      {
        id: "created",
        Header: strings.labels.created,
        accessor: ({ created }) => formatDateTime(created),
        isSortable: true,
        isResizable: true,
      },
      {
        id: "updated",
        Header: strings.labels.updated,
        accessor: ({ updated }) => formatDateTime(updated),
        isSortable: true,
      },
    ],
    appendHistory,
    defaultDisplay,
  };

  return (
    <>
      <ApiContextProvider
        api={{
          ...usersListApi,
          deleteRow: handleDeleteUser,
        }}
      >
        <EntityListTableContainer<UserData> {...props} />
      </ApiContextProvider>
      <Notification message={message} onClose={() => setMessage("")} />
    </>
  );
};
