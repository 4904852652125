import { Button } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { downloadBase64File } from "../../helpers/fileHelper";
import { constants } from "../../resources/numerics/app";
import {
  documentStrings,
  projectStrings as strings,
} from "../../resources/strings";
import {
  exportProject,
  getDocumentDownload,
  getDocumentStateList,
} from "../../services/docgenService";
import { Component } from "../../types";
import { DocumentTemplateListItem } from "../../types/documents";
import {
  ButtonComponentProps,
  IconActionButton,
} from "./controls/IconActionButton";

interface Props {
  displayError: (message: string) => void;
  projectId: string;
  projectTypeId: string;
  projectName: string;
}

export const DocumentDownloadButton: Component<Props> = ({
  projectId,
  projectTypeId,
  projectName,
  displayError,
}) => {
  const [template, setTemplate] = useState<DocumentTemplateListItem>();
  const [generating, setGenerating] = useState(false);
  const [count, setCount] = useState(0);

  const refreshTemplate = useCallback(async () => {
    const downloadDocument = async () => {
      try {
        if (template) {
          const data = await getDocumentDownload(
            projectId,
            template?.lastDocument.documentId
          );
          downloadBase64File(data, projectName);
        } else {
          displayError(documentStrings.downloadErrorMessage);
        }
      } catch (err) {
        displayError(documentStrings.downloadErrorMessage);
        console.log("Could not get document.", err);
      }
    };

    try {
      const templateRows: DocumentTemplateListItem[] =
        await getDocumentStateList(projectId, projectTypeId);
      if (templateRows.length > 0) {
        const templateRow = templateRows[0];
        const isDocumentGenerating =
          templateRow.lastDocument?.stateId ===
          documentStrings.stateIds.generating;
        setTemplate(templateRow);
        setGenerating(isDocumentGenerating);
        if (!isDocumentGenerating) {
          downloadDocument();
        }
      }
    } catch (e) {
      console.error("Failed to get template rows.", e.message);
    }
  }, [projectId, projectTypeId, displayError, projectName, template]);

  const handleGenerate = async () => {
    try {
      const templateRows: DocumentTemplateListItem[] =
        await getDocumentStateList(projectId, projectTypeId);
      if (templateRows.length > 0) {
        const templateRow = templateRows[0];
        exportProject(projectId, projectTypeId, templateRow.id)
          .then(() => {
            setGenerating(true);
            refreshTemplate();
          })
          .catch((err) => {
            console.log(
              "An error occurred while handling generation request.",
              err
            );
          });
        setTemplate(templateRow);
      }
    } catch (e) {
      console.error("Failed to get template rows.", e.message);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (generating) {
        setCount(count + 1);
        refreshTemplate();
      }
    }, constants.documentGenerationPollingTime);
    return () => clearTimeout(timer);
  }, [count, generating, refreshTemplate]);

  const onClickHandler = async () => {
    try {
      await handleGenerate();
    } catch (e: any) {
      console.log("Error generating document.");
    }
  };

  const inputButton: ButtonComponentProps = ({
    onClick,
    disabled,
    children,
  }) => (
    <Button
      className="action-button"
      onClick={onClick}
      disabled={disabled}
      color="primary"
      variant="outlined"
    >
      {strings.labels.documentsModalHeader}
      {children}
    </Button>
  );

  return (
    <IconActionButton
      ButtonComponent={inputButton}
      spinnerProps={{
        size: 24,
        color: "secondary",
        style: { marginLeft: "1rem" },
      }}
      onClick={onClickHandler}
      customLoading={generating}
    />
  );
};
