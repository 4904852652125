import { Theme } from "@material-ui/core";

export const getStyles = (theme: Theme) => {
  return {
    tableRow: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    header: {
      marginBottom: "1.5rem",
    },
  };
};
