import secondImage from "../../assets/tutorial/define-entities-for-the-system.svg";
import fifthImage from "../../assets/tutorial/define-other-requirements.svg";
import fourthImage from "../../assets/tutorial/define-the-actions-for-an-entity.svg";
import thirdImage from "../../assets/tutorial/define-the-fields-in-an-entity.svg";
import firstImage from "../../assets/tutorial/define-who-will-use-system.svg";
import sixthImage from "../../assets/tutorial/view-documents-and-wireframes.svg";
import welcomeImage from "../../assets/tutorial/welcome-to-requiment.svg";
import wireframes1 from "../../assets/tutorial/wireframes_tutorial_1.png";
import wireframes2 from "../../assets/tutorial/wireframes_tutorial_2.png";
import wireframes3 from "../../assets/tutorial/wireframes_tutorial_3.png";
import wireframes4 from "../../assets/tutorial/wireframes_tutorial_4.png";
import csvUpload from "../../assets/uploads/csv_upload.png";
import entityUpload from "../../assets/uploads/entity_upload.png";
import tagsUpload from "../../assets/uploads/tags_upload.png";
import usersReportsUpload from "../../assets/uploads/users_reports_upload.png";
import { TutorialStep } from "../../components/carousel/HelpCarousel";

export const introTutorialSteps = [
  {
    title: "Welcome to Requiment",
    imgPath: welcomeImage,
    content: [
      "Our mission with Requiment is to make the process of software requirements capture more accurate, agile and efficient leading to ultimately more successful projects.",
      "Before we start let's explain how it works. The system will guide you through a form to capture requirements. It's a multi-step process but it's not difficult…",
    ],
  },
  {
    title: "1. Define Who Will Use the System",
    imgPath: firstImage,
    content: [
      "Specifiy the types of users that will be using the system.",
      "This includes anyone that will need access such as the admins who will control the system, your staff who will work through it and customers who will use it.",
    ],
  },
  {
    title: "2. Define the Entities for the System",
    imgPath: secondImage,
    content: [
      "Entities are the items that make up a system. They can store, process and fetch data or perform some other operation.",
      "Clients, Jobs or Estimates are examples of an entity.  If you write a text description of a system then entities are generally nouns.",
    ],
  },
  {
    title: "3. Define the Fields in an Entity",
    imgPath: thirdImage,
    content: [
      "Entities are made up of fields. For example, we may have Name, Address, Email and Telephone fields to store data for a client entity.",
      "An appointment entity might have a Location, Persons Attending and Date/Time fields.",
    ],
  },
  {
    title: "4. Define the Actions for an Entity",
    imgPath: fourthImage,
    content: [
      "Actions define what you can do with an entity. For example we can Create, Read, Update, Delete and List a client entity. We may also want to Print an Invoice.",
      "We can define which users can perform this action. If written in a text description of a system then actions are generally verbs.",
    ],
  },
  {
    title: "5. Define Other Requirements",
    imgPath: fifthImage,
    content: [
      "You can define other requirements for the system such as what performance is expected, legal requirements, etc. as you fill out the requirements form.",
    ],
  },
  {
    title: "6. View the Requirements Document and Wireframes",
    imgPath: sixthImage,
    content: [
      "Finally, once defined, you can output the requirements specification in Word format, ready for further editing.",
      "If required, you can also view simple user interface wireframes to help visualise the system to potential users and stakeholders.",
    ],
  },
];

export const uploadTutorialSteps = [
  {
    title: "CSV Upload",
    imgPath: csvUpload,
    content: [
      "The file containing the task templates must be a .csv file including the following headers. Task Type, Action Type, Control Type Id, Question, Option Id, Category, Summary, Description, Estimate.",
      "The Task Type must be either 'Entity', 'Users' or 'Reports'.",
    ],
  },
  {
    title: "Entities",
    imgPath: entityUpload,
    content: [
      "If the Task Type is 'Entity' then the 'Action Type' must be a valid Action Type from the template CSV.",
      "The 'Control Type Id', 'Question' and 'Option Id' fields can be left blank.",
    ],
  },
  {
    title: "Users and Reports",
    imgPath: usersReportsUpload,
    content: [
      "If the Task Type is 'Users' or 'Reports' then the 'Control Type Id', 'Question' and 'Option Id' fields must contains valid values. The corresponding Ids and Questions are given in the template CSV.",
      "The 'Entity Action' field can be left blank.",
    ],
  },
  {
    title: "Tags",
    imgPath: tagsUpload,
    content: [
      "Tags can be added to the tickets by using {{Tag}} in the 'Summary' or 'Description' fields.",
      "The tags can be either 'Entity Name', 'Entity Fields' or 'Users' for 'Entity' type tickets, or 'Value' for 'Users' and 'Reports' type tickets.",
    ],
  },
];

export const wireframesTutorialSteps: TutorialStep[] = [
  {
    title: "1. Automatically create wireframes",
    imgPath: wireframes1,
    imgClassName: "wireframes-edit",
    content: [
      "For each entity you create in your Requiment project, we create mockup representations of pages for the actions you have added to that entity (e.g create, read, update).",
    ],
  },
  {
    title: "2. Modify wireframes",
    imgPath: wireframes2,
    imgClassName: "wireframes-edit",
    content: [
      "You can modify the major components of a page to display in different ways.",
      "For example, display an entity list in a table, card layout or catalogue and change the columns displayed.",
    ],
  },
  {
    title: "3. Create new wireframes pages",
    imgPath: wireframes3,
    imgClassName: "wireframes-edit",
    content: [
      "You can create new pages which are composed of components for different entities.",
      "For example you can have a 'house' entity read component at the top of the page and a list of inspections on that house below.",
      "We call these Composite Pages.",
    ],
  },
  {
    title: "4. Add images to wireframes",
    imgPath: wireframes4,
    imgClassName: "wireframes-edit",
    content: [
      "Our image library lets you add images and tag them.",
      "For example you can add an image of a house and tag as 'house'. Any entities with the name 'house' will use those images in the wireframes automatically.",
    ],
  },
];

export const tutorialStrings = {
  labels: {
    gettingStarted: "Getting Started",
    tasksGuide: "Task Templates Guide",
    back: "Back",
    getStarted: "Get started",
    next: "Next",
    wireframesTutorialHeader: "Wireframes Explained",
  },
};
