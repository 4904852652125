import { Theme } from "@material-ui/core";
import { common } from "./common";

export const getStyles = (theme: Theme) => {
  return {
    ...common(theme),
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    deleteButtonContainer: {
      margin: "5px",
      width: "100%",
    },
    mobileDeleteButton: {
      width: "90%",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
  };
};
