import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { colours, fonts } from "../../../styles/theme";
import { ButtonConfiguration, Component } from "../../../types";
import "./Modal.css";
import { extractButtonConfig } from "./modalHelper";

const useStyles = makeStyles((theme) => {
  return {
    buttonRoot: {
      fontFamily: fonts.primary,
      textTransform: "none",
      fontSize: "15px",
    },
    buttonDisabled: {
      backgroundColor: theme.palette.secondary.main + " !important",
      color: "#fff",
      opacity: 0.5,
    },
    headerText: {
      paddingTop: "1rem",
    },
    exitButton: {
      "&:hover": {
        opacity: "0.4",
        cursor: "pointer",
      },
      color: colours.whiteColour,
      fontSize: "20px",
      right: "10px",
      position: "absolute",
    },
  };
});

interface Props {
  buttonConfiguration: ButtonConfiguration;
  className?: string;
}

export const ModalButtons: Component<Props> = ({
  buttonConfiguration,
  className,
}) => {
  const classes = useStyles();

  const {
    onClickConfirm,
    confirmText,
    isConfirmHidden,
    isConfirmDisabled,
    confirmColor,
    onClickCancel,
    cancelText,
    isCancelHidden,
    isCancelDisabled,
    cancelColor,
    cancelClassName,
  } = extractButtonConfig(buttonConfiguration);

  return (
    <span
      aria-label="modal-buttons"
      className={clsx("modalButtons", className)}
    >
      {!isCancelHidden && (
        <Button
          className={clsx(
            cancelClassName,
            "cancel-button",
            classes.buttonRoot,
            isCancelDisabled && classes.buttonDisabled
          )}
          color={cancelColor}
          onClick={onClickCancel}
          disabled={isCancelDisabled}
        >
          {cancelText}
        </Button>
      )}
      {!isConfirmHidden && (
        <Button
          className={clsx(
            "confirm-button",
            isConfirmDisabled && classes.buttonDisabled
          )}
          variant="contained"
          color={confirmColor}
          onClick={onClickConfirm}
          disabled={isConfirmDisabled}
        >
          {confirmText}
        </Button>
      )}
    </span>
  );
};
