import { Theme } from "@material-ui/core";
import { common } from "../common";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    ...common,
    formFields: {
      margin: "0px",
    },
    formLabel: {
      color: "#FFFFFF",
      marginTop: "20px",
      textAlign: "center" as any,
    },
    infoText: {
      marginTop: "4rem",
      textAlign: "center" as any,
      fontSize: "18px",
    },
    loginHeader: {
      color: colours.whiteColour,
      fontSize: "24px",
      fontFamily: fonts.primary,
      textTransform: "none" as any,
      textAlign: "center" as any,
      paddingTop: "1rem",
    },
    inputLabel: {
      color: colours.whiteColour,
      fontSize: "15px",
      fontFamily: fonts.primary,
      marginTop: "25px",
      marginBottom: "10px",
    },
    label: {
      color: colours.whiteColour,
      fontSize: "14px",
      fontFamily: fonts.secondary,
      margin: theme.spacing(1, "0"),
    },
    input: {
      fontSize: "15px",
      color: colours.blackColour,
      fontFamily: fonts.secondary,
      padding: "0px",
      paddingLeft: "20px",
      backgroundColor: colours.whiteColour,
      margin: "0px",
      height: "50px",
      borderRadius: "4px",
    },
    checkbox: {
      fontSize: "15px",
      color: colours.whiteColour,
      fontFamily: fonts.secondary,
      padding: "0px",
      paddingLeft: "10px",
      margin: "0px",
      height: "50px",
      borderRadius: "4px",
    },
    submitButton: {
      "&:disabled": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.6",
        color: colours.whiteColour,
      },
      "&:hover": {
        boxShadow: "none",
      },
      backgroundColor: theme.palette.primary.main,
      fontFamily: fonts.primary,
      textTransform: "none" as any,
      fontSize: "18px",
      height: "50px",
      marginTop: "20px",
      boxShadow: "none",
    },
    linkPrefix: {
      color: colours.whiteColour,
      margin: theme.spacing(2, "0", 2),
      fontFamily: fonts.secondary,
      fontSize: "14px",
      lineHeight: "1.5",
    },
    linkContainer: {
      display: "flex",
      margin: theme.spacing("0", "0", 2),
    },
    errorText: {
      margin: "1rem",
      fontSize: "0.9rem",
      color: theme.palette.error.light + "!important",
      textAlign: "center" as any,
    },
    fullWidth: {
      width: "100%",
    },
  };
};
