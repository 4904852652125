import { makeStyles } from "@material-ui/core";
import { getDisplayValue } from "../../../helpers/wireframesHelper";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/card";
import { Component } from "../../../types";
import { Entity } from "../../../types/Wireframes";
import { CatalogueCard } from "./CatalogueCard";

interface Props {
  entity: Entity;
  changePage: (actionName: string, entity: Entity, rowIndex: number) => void;
  deletable: boolean;
  readable: boolean;
  editable: boolean;
  selectedFields: string[];
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const CatalogueList: Component<Props> = ({
  entity,
  changePage,
  deletable,
  readable,
  editable,
  selectedFields,
}) => {
  const classes = useStyles();

  const getValues = (index: number) => {
    const values: { label: string; value: string }[] = [];
    entity.fields.forEach((field) => {
      if (
        selectedFields.includes(field.id) &&
        field.type !== strings.fieldTypes.image
      ) {
        values.push({
          label: field.name,
          value: getDisplayValue(field, index),
        });
      }
    });
    return values;
  };

  const getDisplayImage = () => {
    const imageField = entity.fields.find(
      (field) => field.type === strings.fieldTypes.image
    );
    if (!imageField) return undefined;
    return selectedFields.includes(imageField.id) ? imageField : undefined;
  };

  return (
    <div className={classes.catalogueContainer}>
      {[...Array(5)].map((row, rowIndex) => {
        const values = getValues(rowIndex);
        return (
          <CatalogueCard
            key={rowIndex}
            entity={entity}
            index={rowIndex}
            values={values}
            changePage={changePage}
            deletable={deletable}
            readable={readable}
            editable={editable}
            displayImage={getDisplayImage()}
          />
        );
      })}
    </div>
  );
};
