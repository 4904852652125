import { Theme } from "@material-ui/core";
import { colours, fonts } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "80vw",
        margin: "10vw",
      },
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "700px",
      },
      borderRadius: "4px",
      backgroundColor: colours.whiteColour,
    },
    header: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2),
      },
      backgroundColor: colours.darkBlueColour,
      display: "flex",
      alignItems: "center",
    },
    headerText: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "18px",
      },
      color: colours.whiteColour,
      width: "100%",
    },
    exitButton: {
      color: colours.whiteColour,
      fontSize: "16px",
      justifyContent: "end",
      [theme.breakpoints.down("sm")]: {
        padding: "0px",
      },
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      maxHeight: "50px",
      height: "2.5vh",
      display: "block",
      margin: "5px",
    },
    titleText: {
      [theme.breakpoints.up("md")]: {
        margin: "5px",
      },
      fontSize: "19px",
      color: colours.darkGreyColour,
      fontFamily: fonts.primary,
    },
    title: {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1.5),
      },
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
      },
      display: "flex",
      alignItems: "center",
      flexDirection: "column" as any,
      backgroundColor: colours.whiteColour,
    },
    content: {
      display: "flex",
      flexDirection: "column" as any,
      padding: theme.spacing(2),
      backgroundColor: colours.whiteColour,
      minHeight: "160px",
    },
    paragraph: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "15px",
      },
      padding: theme.spacing(1),
      color: colours.greyColour,
      fontFamily: fonts.secondary,
    },
    imgContainer: {
      backgroundColor: theme.palette.primary.main,
      padding: "20px",
    },
    img: {
      maxHeight: 255,
      maxWidth: 600,
      display: "block",
      width: "100%",
      height: "20vh",
      margin: "auto",
    },
    navigation: {
      backgroundColor: colours.whiteColour,
      borderRadius: "4px",
      padding: theme.spacing(1),
      margin: "0.9vw",
    },
    keyboardArrow: {
      padding: 0,
    },
  };
};
