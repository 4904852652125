import { Theme } from "@material-ui/core";

export const getStyles = (theme: Theme) => {
  return {
    root: {
      marginBottom: "2rem",
    },
    label: {
      fontWeight: "bold" as any,
    },
    value: {},
  };
};
