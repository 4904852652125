import { faker } from "@faker-js/faker";
import { DateTime, DateTimeFormatOptions, LocaleOptions } from "luxon";

const dateOptions: LocaleOptions & DateTimeFormatOptions = {
  locale: "en-gb",
  dateStyle: "short",
};

export class DateHelper {
  readonly currentDate: Date;
  constructor(readonly now: Date | string) {
    this.currentDate = typeof now === "string" ? stringToDate(now) : now;
  }

  tomorrow() {
    const tomorrow = this.currentDate;
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }

  nextWeek() {
    const nextWeek = this.currentDate;
    nextWeek.setDate(nextWeek.getDate() + 7);
    return nextWeek;
  }

  daysInFuture(daysToAdd: number) {
    const futureDate = new Date(this.currentDate.getTime());
    futureDate.setDate(futureDate.getDate() + daysToAdd);
    return futureDate;
  }

  daysUntil(futureDateString: string) {
    const futureDate = stringToDate(futureDateString);
    return Math.floor(
      (futureDate.getTime() - this.currentDate.getTime()) /
        (1000 * 60 * 60 * 24)
    );
  }

  twentyFiveYears = 9131;
}

export const formatDateTime = (dateString: string) => {
  const formattedString = DateTime.fromISO(dateString).toLocaleString({
    ...dateOptions,
    timeStyle: "short",
  });
  return formattedString;
};

export const formatDate = (dateString: string) => {
  const formattedString = DateTime.fromISO(dateString).toLocaleString({
    ...dateOptions,
  });
  return formattedString;
};

export function convertDate(date: Date) {
  return date.toLocaleString("en-GB", {
    day: "numeric",
    year: "numeric",
    month: "numeric",
  });
}

export function convertTime(time: Date) {
  return time.toLocaleString("en-GB", { hour: "numeric", minute: "numeric" });
}

export function getFakeDate() {
  return faker.date.between({
    from: "1920-01-01T00:00:00.000Z",
    to: "2030-01-01T00:00:00.000Z",
  });
}

export function stringToDate(stringDate: string) {
  let newStringDate: string | null = stringDate;
  if (!newStringDate) {
    newStringDate = null;
  }

  const date = new Date(newStringDate as any);
  if (date.toString() === "Invalid Date") {
    throw new Error(
      `Invalid date string "${newStringDate}". Cannot convert to a date object.`
    );
  }
  return date;
}

function getDisplayTimePrefix(time: number) {
  return time < 10 ? "0" : "";
}

export function parseDateAndTime(dateString: string) {
  const parsedDate = stringToDate(dateString);
  const date = parsedDate.toLocaleString().split(",")[0];
  const hours = parsedDate.getHours();
  const minutes = parsedDate.getMinutes();
  const hoursPrefix = getDisplayTimePrefix(hours);
  const minutesPrefix = getDisplayTimePrefix(minutes);
  const time = `${hoursPrefix}${hours}:${minutesPrefix}${minutes}`;
  return { time, date };
}
