import {
  Portal,
  Snackbar,
  SnackbarContent,
  SnackbarProps,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { getStyles } from "../../styles/general/notification";
import { Component, NotificationType } from "../../types";

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  snackbarProps?: SnackbarProps;
  message: string;
  onClose?: (event: any) => void;
  type?: NotificationType.error | NotificationType.info;
}

export const FormsDesignerNotificationMapping = {
  warning: NotificationType.error,
  error: NotificationType.error,
  info: NotificationType.info,
  success: NotificationType.info,
};

export const Notification: Component<Props> = ({
  snackbarProps,
  message,
  onClose,
  type = NotificationType.info,
}) => {
  const classes = useStyles();

  return (
    <Portal>
      <Snackbar
        className={classes.snackBar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!message}
        onClose={onClose}
        autoHideDuration={5000}
        message={message}
        {...snackbarProps}
      >
        <SnackbarContent
          message={message}
          className={clsx(classes.snackBarContent, classes[`snackBar_${type}`])}
        />
      </Snackbar>
    </Portal>
  );
};
