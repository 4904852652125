import { Component } from "../../types";
import { AppBar } from "../layout/MenuComponent";

//type Component = () => JSX.Element;
interface Props {
  loggedOut?: boolean;
}

export const TermsOfService: Component<Props> = ({ loggedOut = true }) => {
  return (
    <div>
      {loggedOut && <AppBar />}
      <div id="terms" className="terms-privacy">
        <div className="terms-header">
          Requiment Product Terms & Conditions - Fieldimp Limited
        </div>
        <ol className="font-primary">
          <li className="terms-header">
            General
            <ol className="sub">
              <li>
                These terms set out the agreement ("Agreement") between you
                (“you”) and Fieldimp Limited, a company incorporated in Scotland
                under the Companies Acts with registered number SC 560636 and
                whose registered office is at 5 Eagle Street, Glasgow,
                Strathclyde, G4 9XA, United Kingdom ("Fieldimp"). Please read
                these terms carefully.
              </li>
              <li>
                By downloading, installing, copying or otherwise using the
                Services (defined below) you agree to be bound by all of the
                terms of this Agreement. You can only enter into this Agreement
                on behalf of a company or such other business entity and you
                represent that you have the authority to bind such entity to
                this Agreement, in which case the terms "you" or "your" shall
                refer to such entity.
              </li>
              <li>
                We licence use of the Services to you on the basis of this
                Agreement and, if you have downloaded the Services as an App,
                subject to any rules or policies applied by any app store
                provider or operator from whose site ("Appstore"), you
                downloaded the App.
              </li>
              <li>
                Any hardware and software required to operate the Services is
                specified on the Website. Please ensure that where applicable
                your devices meet these requirements before downloading the
                Service.
              </li>
              <li>
                The Services provide you with information which Fieldimp
                believes to be accurate. Please note that Fieldimp can give no
                assurances or warranty regarding the accuracy of such
                information. Fieldimp excludes to the fullest extent permitted
                by law all liability for the accuracy of information provided by
                the Service
              </li>
              <li>
                Fieldimp may change this Agreement at any time. We will notify
                you of a change using the methods of communication agreed
                between you and us from time to time. Such notification may also
                happen when you next start to use the Services or log on to the
                Website. The new terms and conditions may be displayed on-screen
                and you may be required to read and accept them to continue your
                use of the Service.
              </li>
              <li>
                From time to time updates to the Services may be issued through
                the Website or the Appstore. Depending on the update, you may
                not be able to use the Services until you have downloaded or
                streamed the latest version of the Services and accepted any new
                terms.
              </li>
              <li>
                All users of the Services will be assumed to have obtained
                permission from the owners of the mobile telephone or other
                devices that are usedto download or stream the Service. Charges
                may apply from Servicesproviders for internet access when using
                the Services on such devices. You accept responsibility for such
                charges in relation to any device, whether or not it is owned by
                you.
              </li>
              <li>
                The terms of our privacy policy
                app.requiment.com/privacy-policy.html ("Privacy Policy") are
                incorporated into this Agreement by reference and apply to the
                Service.
              </li>
              <li>
                By using the Service, you acknowledge and agree that internet
                transmissions are never completely private or secure. You
                understand that any message or information you send using the
                Services may be read or intercepted by others, even if there is
                a special notice that a particular transmission is encrypted.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Definitions
            <ol className="sub">
              <li>
                The following terms shall have the following meanings in this
                Agreement: "Intellectual Property Rights" means patents,
                inventions, know-how, trade marks (whether registered or
                unregistered), design rights (whether registered or
                unregistered), copyright, database rights, rights in data,
                together with any and all other intellectual property rights, or
                rights of a similar nature, whether existing in the United
                Kingdom or elsewhere, together with any applications for any of
                the foregoing; "Licence Fees " means any fee payable for use of
                the Services as described in this Agreement; "Services" means
                the products and services including the Software made available
                to you from time to time via the Appstore or the Website, as may
                be updated or amended from time to time as notified to you on
                the Website, together with data supplied with the software, and
                associated media; "Software" means the Requiment software which
                you have selected to download from either the Appstore or the
                Website. The term "Software" shall also include any upgrades,
                modified versions or updates relating to it and made available
                to you by Fieldimp; and "Website" means the websites accessible
                from the url (app.requiment.com) or any subsequent URLs which
                may replace these.
              </li>
              <li>
                Reference to any statute, enactment, order, regulation or other
                similar instrument shall be construed as a reference to the
                statute, enactment, order, regulation or instrument as amended
                by any subsequent statute, enactment, order, regulation or
                instrument or as contained in any subsequent re-enactment
                thereof.
              </li>
              <li>
                All headings included in this Agreement are for ease of
                reference only and shall not affect the interpretation or
                construction of this Agreement.
              </li>
              <li>
                Reference to "Clause" is, unless otherwise provided, reference
                to a clause of this Agreement.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Grant of Licence
            <ol className="sub">
              <li>
                Subject to the terms of this Agreement, Fieldimp hereby grants
                you a non-exclusive, non-transferable, revocable licence to use
                the Services solely for your own business purposes.
              </li>
              <li>
                Notwithstanding the foregoing, you acknowledge and agree that,
                depending on the version of the Services you select to download,
                certain restrictions may apply to your use of the Services and
                certain features of the Services may be unavailable to you or
                available subject to certain limitations.
              </li>
              <li>
                You acknowledge that the foregoing licence granted in Clause 3.1
                extends only to the features and functionality of the Services
                version as described in the Appstore and Website and as paid for
                by you.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Trial Period
            <ol className="sub">
              <li>
                Any charges payable by you for your use of the Services will be
                notified to you. Certain charges may be payable by you in
                advance before you download the Service.{" "}
              </li>
              <li>
                The Services may be made available to you free of charge for an
                initial trial period and any charges payable for your continued
                use of Services after this trial period ("Licence Fees ") will
                be notified to you in advance.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Fee For Services
            <ol className="sub">
              <li>
                If Licence Fees apply:
                <ol>
                  <li>
                    you must provide Fieldimp with the necessary bank account or
                    credit card details to enable payment; and
                  </li>
                  <li>
                    Fieldimp will make the Services available to you upon
                    payment of the relevant Licence Fees.
                  </li>
                </ol>
              </li>
              <li>
                All Licence Fees are inclusive of UK Value Added Tax or any
                equivalent sales tax applicable in the European Union, but are
                not inclusive of local sales taxes, levies or duties that may
                apply to your use of the Services in any other jurisdiction, and
                you shall be responsible for any such additional local sales
                taxes and shall inform us of them.
              </li>
              <li>
                Unless otherwise stated by us or required by law All payment
                obligations are non-cancellable and all amounts paid are
                non-refundable. You are responsible for paying the Licence Fees
                whether or not the Services is actively used.
              </li>
              <li>
                Fieldimp reserves the right to modify its Licence Fees and to
                introduce new charges on or upon at least 30 days prior notice
                to you, which notice may be provided by e-mail.
              </li>
              <li>
                In addition to any other rights Fieldimp has in this Agreement
                or otherwise, Fieldimp reserves the right to suspend or
                terminate your access to the Services if you fail to pay any
                Licence Fees due.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Licence Restrictions
            <ol className="sub">
              <li>
                Except as expressly set out in this Agreement or as permitted by
                any local law, you agree:
              </li>
              <ol>
                <li>
                  not to copy the Services except where such copying is
                  incidental to normal use of the Service;
                </li>
                <li>
                  not to rent, lease, sub-license, loan, translate, merge,
                  adapt, vary or modify the Service;
                </li>
                <li>
                  not to make alterations to, or modifications of, the whole or
                  any part of the Service, or permit the Services or any part of
                  it to be combined with, or become incorporated in, any other
                  programs;
                </li>
                <li>
                  not to reverse engineer or access the Services or any part of
                  the Services in order to:
                </li>
                <li>build a competitive product or service; or</li>
                <li>
                  build a product using similar ideas, features, functions or
                  graphics of the Service; or
                </li>
                <li>
                  copy any ideas, features, functions or graphics of the
                  Service.
                </li>
                <li>
                  not to disassemble, decompile, reverse-engineer or create
                  derivative works based on the whole or any part of the
                  Services or attempt to do any such thing without our express
                  written consent,
                </li>
                <li>
                  not to create internet "links" to the Services or "frame" or
                  "mirror" any Services content on any other server or wireless
                  or internetbased device;
                </li>
                <li>
                  to keep all copies of the Services, secure and to maintain
                  accurate and up-to-date records of the number and locations of
                  all copies of the Service;
                </li>
                <li>
                  to include our copyright notice on all entire and partial
                  copies you make of the Services on any medium;
                </li>
                <li>
                  not to provide or otherwise make available the Services in
                  whole or in part (including object and source code), in any
                  form to any person without prior written consent from
                  Fieldimp; and
                </li>
                <li>
                  to comply with all technology control or export laws and
                  regulations that apply to the technology used or supported by
                  the Service.
                </li>
              </ol>
            </ol>
          </li>
          <li className="terms-header">
            Acceptable Use Restrictions
            <ol className="sub">
              <li>You must:</li>
              <li>
                not use the Services in any unlawful manner, for any unlawful
                purpose, or in any manner inconsistent with this Agreement, or
                act fraudulently or maliciously, for example, by hacking into or
                inserting malicious code, including viruses, or harmful data,
                into the Services or any operating system;
              </li>
              <li>
                not infringe our Intellectual Property Rights or those of any
                third party in relation to your use of the Service, including
                the submission of any material (to the extent that such use is
                not licensed by this Agreement);
              </li>
              <li>
                not transmit any material that is defamatory, offensive or
                otherwise objectionable in relation to your use of the Service;
              </li>
              <li>
                not use the Services in a way that could damage, disable,
                overburden, impair or compromise our systems or security or
                interfere with other users;
              </li>
              <li>
                not collect or harvest any information or data from the Services
                or our systems or attempt to decipher any transmissions to or
                from the servers running the Service; and
              </li>
              <li>
                use the Services only for your internal business purposes.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Title
            <ol className="sub">
              <li>
                You agree that no title to any Intellectual Property Rights in
                any part of the Services or the Website are transferred to you.
                Title and ownership of all Intellectual Property Rights in and
                to the Services and the Website shall remain the exclusive
                property of Fieldimp and/or Fieldimp's licensors.
              </li>
              <li>
                You acknowledge that you have no right to have access to the
                Services in source code form.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Your Responsibilities
            <ol className="sub">
              <li>
                You shall abide by all applicable laws, treaties and regulations
                in connection with your use of the Services including inter alia
                the Data Protection Act 1998, the Privacy and Electronic
                Communications Regulations 2003 & 2011, the General Data
                Protection Regulation ((EU) 2016/679), as may be amended,
                updated or replaced with equivalent legislation from time to
                time.
              </li>
              <li>You shall:</li>
              <ol>
                <li>
                  notify Fieldimp immediately if you become aware of any
                  unauthorised use of the Services and any other known or
                  suspected breach of security;
                </li>
                <li>
                  report to Fieldimp immediately and use reasonable efforts to
                  stop immediately any copying or distribution of the Services
                  that is known or suspected by you; and
                </li>
                <li>
                  not impersonate another Services user or provide false
                  identity information to gain access to or use the Service.
                </li>
              </ol>
            </ol>
          </li>
          <li className="terms-header">
            Service Output
            <ol className="sub">
              <li>
                You have sole responsibility for the accuracy, quality,
                integrity, legality, reliability and appropriateness of the data
                input from your account when using the Service. For the
                avoidance of doubt, however, Fieldimp shall not be responsible
                or liable for the deletion, correction, destruction, damage,
                loss or failure to store any such data by you, or any person
                who, in Fieldimp's reasonable opinion, appears to be authorised
                by you.
              </li>
              <li>
                Fieldimp gives no undertakings in relation to retention of the
                information output produced by you using the Service.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Term and Termination
            <ol className="sub">
              <li>
                The Agreement has effect from when you agree to [it] or when you
                start using the Services (whichever is sooner) and shall
                continue for the agreed term unless terminated earlier in
                accordance with its terms.
              </li>
              <li>
                Either party may terminate this Agreement, by giving the other
                party at least thirty (30) days prior notice before payment of
                the next instalment of the Licence Fees is due. You may give
                such notice by following the process on the Website to
                unsubscribe from the Service.
              </li>
              <li>
                Fieldimp shall be entitled to terminate this Agreement and your
                access to the Services in the event that:
              </li>
              <ol>
                <li>
                  you are in material breach of this Agreement, including but
                  not limited to failure to pay Licence Fees s and such breach
                  has not been cured within 14 days of notice of such breach; or
                </li>
                <li>
                  you are in breach of the Licence Restrictions at Clause 6 or
                  the Acceptable Use Restrictions at Clause 7; or
                </li>
                <li>
                  you present a petition or have a petition presented by a
                  creditor for your winding up, or convene a meeting to pass a
                  resolution for voluntary winding up, or enter any liquidation,
                  other than for the purposes of a bona fide reconstruction or
                  amalgamation, call a meeting of your creditors or, have a
                  receiver, administrator or similar officer of all or any of
                  your undertakings or assets appointed, or be deemed by virtue
                  of the relevant statutory provisions under the applicable law
                  to be unable to pay your debts.
                </li>
              </ol>
            </ol>
          </li>
          <li className="terms-header">
            Consequences of Termination
            <ol className="sub">
              <li>On termination for any reason:</li>
              <ol>
                <li>
                  all rights granted to you under this Agreement shall cease;
                </li>
                <li>
                  you must immediately cease all activities authorised by this
                  Agreement, including your use of the Service; and
                </li>
                <li>
                  you must immediately delete or remove the Services from all
                  devices, and immediately destroy all copies of the Services
                  then in your possession, custody or control and, if requested
                  by Fieldimp, certify to Fieldimp that you have done so.
                </li>
              </ol>
            </ol>
          </li>
          <li className="terms-header">
            Representations and Warranties
            <ol className="sub">
              <li>
                Each party represents and warrants that it has the legal power
                and authority to enter into this Agreement.
              </li>
              <li>
                Fieldimp represents and warrants that it will provide the
                Services with reasonable skill and care.
              </li>
              <li>
                You represent and warrant that you have not provided any false
                information to gain access to the Services and that, if we have
                requested it, your billing information is correct.
              </li>
              <li>
                You represent and warrant that you are not using the Services as
                an individual or consumer.
              </li>
              <li>
                Except as otherwise provided in this Clause 13, Fieldimp makes
                no representations or warranties and expressly excludes the same
                whether express, implied or otherwise in so far as it is not
                prevented by legislation from so doing.
              </li>
              <li>
                You acknowledge that no software can be error free and agree
                that the existence of such errors in the Services shall not
                constitute a breach of this Agreement.
              </li>
              <li>
                The parties agree that when using the Website and the Service,
                they shall use all efforts to prevent the transmission of
                viruses. However, neither party shall be liable to the other for
                any losses suffered due to the transmission of such viruses.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Indemnification
            <ol className="sub">
              <li>
                You shall indemnify and hold Fieldimp, its licensors,
                subsidiaries, affiliates, officers, directors, employees and
                agents harmless from and against any and all claims, costs,
                damages, losses, liabilities and expenses, including legal fees
                and costs arising out of or in connection with:
              </li>
              <ol>
                <li>
                  a claim alleging that your use of the Services is unlawful or
                  infringes the rights of, or has caused harm to, a third party;
                  or
                </li>
                <li>
                  a breach by you of your representations and warranties; or
                </li>
                <li>
                  a claim arising from a breach by you of this Agreement; or
                </li>
                <li>any negligent act or omission by you; or</li>
                <li>
                  any claims arising from any Intellectual Property Rights or
                  thirdparty infringement arising from the combination of the
                  Services with any of your products, service, hardware or
                  business process.
                </li>
              </ol>
            </ol>
          </li>
          <li className="terms-header">
            Publicity
            <br></br>
            <div className="sub">
              You expressly give us permission to include and publish your
              business name and logo on lists of our customers. If you wish to
              withdraw this permission at any time, then please contact us at
              info@fieldimp.com
            </div>
          </li>
          <li className="terms-header">
            Internet Delays
            <br></br>
            <div className="sub">
              The Services may be subject to limitations, delays and other
              problems inherent in the use of the internet and electronic
              communications. Fieldimp is not responsible for any delays,
              delivery failures, or other damage resulting from such problems.
            </div>
          </li>
          <li className="terms-header">
            Limitation of Liability
            <ol className="sub">
              <li>
                Fieldimp shall not be liable for any consequential loss, damage,
                or corruption of other software or data, or for loss of profit,
                business, revenue, goodwill or anticipated savings.{" "}
              </li>
              <li>
                Subject to the provisions hereof and except insofar as
                Fieldimp's liability may not be excluded or limited by law, you
                acknowledge and agree that Fieldimp's entire maximum and
                aggregate liability to you whether under this Agreement or
                otherwise (including under the laws of negligence) shall be
                limited to the total of the Licence Fees paid by you in the
                12-month period immediately preceding the event giving rise to
                such claim.
              </li>
              <li>
                Nothing in this Agreement shall limit or exclude Fieldimp's
                liability for:
              </li>
              <ol>
                <li>
                  death or personal injury resulting from Fieldimp's negligence;
                  or
                </li>
                <li>fraud or fraudulent misrepresentation; or</li>
                <li>
                  any other liability that cannot be excluded or limited by
                  Scots law.
                </li>
              </ol>
            </ol>
          </li>
          <li className="terms-header">
            Force Majeure
            <br></br>
            <div className="sub">
              Fieldimp shall be under no liability to you in respect of anything
              which may constitute a breach of this Agreement arising by reason
              of force majeure, namely, circumstances beyond the control of
              Fieldimp which shall include, but shall not be limited to, acts of
              God, perils of the sea or air, fire, flood, drought, explosion,
              sabotage, accident, embargo, riot, civil commotion or civil
              authority, including acts of local government and parliamentary
              authority; inability to supply the materials necessary to provide
              the Services, breakdown of equipment and labour disputes of
              whatever nature and for whatever cause arising including, but
              without prejudice to the generality of the foregoing, work to
              rule, overtime bars, strikes and lockouts.
            </div>
          </li>
          <li className="terms-header">
            Waiver
            <br></br>
            <div className="sub">
              Failure or neglect by Fieldimp to enforce at any time any of the
              provisions of this Agreement shall not be construed nor shall be
              deemed to be a waiver of Fieldimp's rights hereunder nor in any
              way affect the validity of the whole or any part of this Agreement
              nor prejudice Fieldimp's rights to take subsequent action.
            </div>
          </li>
          <li className="terms-header">
            Severability
            <br></br>
            <div className="sub">
              In the event that any of these terms, conditions or provisions
              shall be determined by any competent authority to be invalid,
              unlawful or unenforceable to any extent, such term, condition or
              provision shall to that extent be severed from the remaining
              terms, conditions and provisions which shall continue to be valid
              to the fullest extent permitted by law.
            </div>
          </li>
          <li className="terms-header">
            Entire Agreement
            <br></br>
            <div className="sub">
              This Agreement and the Privacy Policy constitutes the entire
              agreement and understanding between us in relation to the Services
              and supersedes any previous agreement and understanding between
              the parties hereto, relative to the subject matter hereof.
            </div>
          </li>
          <li className="terms-header">
            Notices
            <ol className="sub">
              <li>
                If you wish to contact us in writing, or if any condition in
                this Agreement requires you to give us notice in writing, you
                can send this to us by email or by prepaid post to the address
                stated on the Website. We will confirm receipt of this by
                contacting you in writing, normally by e-mail.
              </li>
              <li>
                If we have to contact you or give you notice in writing, we will
                do so by e-mail or by pre-paid post to the address you provide
                to us.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            General
            <ol className="sub">
              <li>
                You shall not be entitled to novate, assign or transfer your
                rights and/or obligations under this Agreement without the prior
                written consent of Fieldimp. Fieldimp reserves the right to
                novate, assign or transfer its rights and/or obligations under
                this Agreement at any time without reference to you.
              </li>
              <li>
                Nothing in this Agreement is evidence of a joint venture or
                partnership. Neither party is the agent of the other and neither
                party shall give any undertaking on behalf of the other.
              </li>
              <li>
                If there is a conflict between this Agreement and any provision
                in any supplemental agreement or policy, the provisions of this
                Agreement will prevail, unless that supplemental agreement or
                policy expressly provides to the contrary.
              </li>
            </ol>
          </li>
          <li className="terms-header">
            Governing Law
            <br></br>
            <div className="sub">
              The parties hereby agree that this Agreement shall be construed in
              accordance with and governed by Scots law and both parties hereby
              agree to submit to the exclusive jurisdiction of the Scottish
              courts in all matters relating to this Agreement.
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};
