import { Theme } from "@material-ui/core";
import { colours } from "../theme";

export const getStyles = (theme: Theme) => {
  return {
    greyBackground: {
      backgroundColor: "rgba(22, 21, 21, 0.644) !important",
    },
    trialDays: {
      fontSize: "1rem",
    },
    trialDaysNumber: {
      fontWeight: "bold" as any,
    },
    trialDaysText: {
      paddingRight: "1rem",
    },
    trialInfo: {
      position: "absolute" as any,
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      alignItems: "center",
    },
    button: {
      "&:disabled": {
        backgroundColor: colours.secondaryPink,
        color: colours.whiteColour,
      },
    },
  };
};
