export const fakerDataTypes: { [key: string]: string } = {
  aircraftType: "faker.airline.aircraftType()",
  airline: "faker.airline.airline()",
  airplane: "faker.airline.airplane()",
  airport: "faker.airline.airport()",
  flightNumber: "faker.airline.flightNumber()",
  recordLocator: "faker.airline.recordLocator()",
  seat: "faker.airline.seat()",
  bear: "faker.animal.bear()",
  cat: "faker.animal.cat()",
  bird: "faker.animal.bird()",
  cetacean: "faker.animal.cetacean()",
  cow: "faker.animal.cow()",
  crocadilia: "faker.animal.crocodilia()",
  dog: "faker.animal.dog()",
  fish: "faker.animal.fish()",
  horse: "faker.animal.horse()",
  insect: "faker.animal.insect()",
  lion: "faker.animal.lion()",
  rabbit: "faker.animal.rabbit()",
  snake: "faker.animal.snake()",
  rodent: "faker.animal.rodent()",
  animalType: "faker.animal.type()",
  human: "faker.color.human()",
  department: "faker.commerce.department()",
  product: "faker.commerce.product()",
  price: "faker.commerce.price()",
  productAdjective: "faker.commerce.productAdjective()",
  productMaterial: "faker.commerce.productMaterial()",
  productName: "faker.commerce.productName()",
  productDescription: "faker.commerce.productDescription()",
  buzzAdjective: "faker.company.buzzAdjective()",
  buzzNoun: "faker.company.buzzNoun()",
  buzzVerb: "faker.company.buzzVerb()",
  buzzPhrase: "faker.company.buzzPhrase()",
  catchPhraseAdjective: "faker.company.catchPhraseAdjective()",
  catchPhraseDescriptor: "faker.company.catchPhraseDescriptor()",
  catchPhraseNoun: "faker.company.catchPhraseNoun()",
  catchPhrase: "faker.company.catchPhrase()",
  name: "faker.company.name()",
  column: "faker.database.column()",
  databaseType: "faker.database.type()",
  collation: "faker.database.collation()",
  engine: "faker.database.engine()",
  boolean: "faker.datatype.boolean()",
  anytime: "faker.date.anytime()",
  future: "faker.date.future()",
  month: "faker.date.month()",
  past: "faker.date.past()",
  recent: "faker.date.recent()",
  soon: "faker.date.soon()",
  weekday: "faker.date.weekday()",
  accountName: "faker.finance.accountName()",
  accountNumber: "faker.finance.accountNumber()",
  amount: "faker.finance.amount()",
  transactionType: "faker.finance.transactionType()",
  currencyCode: "faker.finance.currencyCode()",
  currencyName: "faker.finance.currencyName()",
  currencySymbol: "faker.finance.currencySymbol()",
  creditCardNumber: "faker.finance.creditCardNumber()",
  creditCardCVV: "faker.finance.creditCardCVV()",
  internetColor: "faker.internet.color()",
  domainSuffix: "faker.internet.domainSuffix()",
  domainWord: "faker.internet.domainWord()",
  email: "faker.internet.email()",
  exampleEmail: "faker.internet.exampleEmail()",
  ip: "faker.internet.ip()",
  ipv6: "faker.internet.ipv6()",
  mac: "faker.internet.mac()",
  password: "faker.internet.password()",
  port: "faker.internet.port()",
  city: "faker.location.city()",
  cityPrefix: "faker.location.cityPrefix()",
  citySuffix: "faker.location.citySuffix()",
  country: "faker.location.country()",
  countryCode: "faker.location.countryCode()",
  county: "faker.location.county()",
  latitude: "faker.location.latitude()",
  longitude: "faker.location.longitude()",
  secondaryAddress: "faker.location.secondaryAddress()",
  state: "faker.location.state()",
  streetAddress: "faker.location.streetAddress()",
  int: "faker.number.int()",
  float: "faker.number.float()",
  firstName: "faker.person.firstName()",
  lastName: "faker.person.lastName()",
  middleName: "faker.person.middleName()",
  fullName: "faker.person.fullName()",
  gender: "faker.person.gender()",
  prefix: "faker.person.prefix()",
  suffix: "faker.person.suffix()",
  sex: "faker.person.sex()",
  sexType: "faker.person.sexType()",
  number: "faker.phone.number()",
  chemicalElement: "faker.science.chemicalElement()",
  unit: "faker.science.unit()",
  alpha: "faker.string.alpha()",
  hexaDecimal: "faker.string.hexaDecimal()",
  binary: "faker.string.binary()",
  uuid: "faker.string.uuid()",
  sample: "faker.sample()",
  manufacturer: "faker.vehicle.manufacturer()",
  model: "faker.vehicle.model()",
  vehicleType: "faker.vehicle.type()",
  fuel: "faker.vehicle.fuel()",
  vin: "faker.vehicle.vin()",
  vehicleColor: "faker.vehicle.color()",
  vrm: "faker.vehicle.vrm()",
  adjective: "faker.word.adjective()",
  adverb: "faker.word.adverb()",
  conjunction: "faker.word.conjunction()",
  interjection: "faker.word.interjection()",
  noun: "faker.word.noun()",
  preposition: "faker.word.preposition()",
  wordSample: "faker.word.sample()",
  verb: "faker.word.verb()",
  words: "faker.word.words()",
};

export const fakerCallParameters = () => {
  const object: { [key: string]: any } = {};
  object[fakerDataTypes.int] = { min: 0, max: 1000 };
  object[fakerDataTypes.float] = { min: 0, max: 1000, precision: 0.01 };
  return object;
};

export const fakerCallsToReplace = () => {
  const object: { [key: string]: any } = {};
  object[fakerDataTypes.gender] = fakerDataTypes.sex;
  return object;
};

export const genderOptionData = ["Male", "Female", "Non-Binary", "Other"];
