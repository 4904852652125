import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/requiment-tick-icon.svg";
import { Modal as CustomModal } from "../../containers/general/Modal/Modal";
import { paths } from "../../navigation";
import {
  appStrings,
  privacyPolicyUpdateStrings as strings,
} from "../../resources/strings";
import { authStrings } from "../../resources/strings/auth";
import { getStyles } from "../../styles/general/popup";
import { Component } from "../../types";
import { InlineLink } from "./InlineLink";

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  isModalOpen: boolean;
  onAgreeToPolicy: () => void;
  closeModal: () => void;
}

export const PrivacyPolicyPopup: Component<Props> = ({
  isModalOpen,
  onAgreeToPolicy,
  closeModal,
}) => {
  const classes = useStyles();

  const buttonConfiguration = {
    confirmConfig: {
      text: strings.ok,
      onClick: onAgreeToPolicy,
    },
  };

  return (
    <CustomModal
      active={isModalOpen}
      header={strings.updatedPolicyHeader}
      buttonConfiguration={buttonConfiguration}
      closeButton={false}
    >
      <Paper square elevation={0} className={classes.content}>
        <img
          className={classes.logo}
          src={logo}
          alt={"Updated privacy policy"}
        />
        <Typography className={classes.paragraph}>
          <InlineLink
            linkItems={[
              { text: strings.mainTextPrefix, className: classes.label },
              {
                text: authStrings.labels.privacyPolicy + ".",
                to: paths.privacyPolicy,
                className: classes.legalLinks,
                onClick: closeModal,
                target: appStrings.other.linkTargetNewTab,
              },
              { text: strings.mainTextSuffix, className: classes.label },
            ]}
          />
        </Typography>
      </Paper>
    </CustomModal>
  );
};
