import DateFunctions from "@date-io/date-fns";
import {
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
  makeStyles,
} from "@material-ui/core";
import { AccessTime, CalendarToday } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useState } from "react";
import { convertDate, convertTime } from "../../../helpers";
import { wireframesStrings as strings } from "../../../resources/strings/wireframes";
import { getStyles } from "../../../styles/wireframes/fields";
import { Component } from "../../../types";

interface Props {
  label: string;
  type: "Time" | "Date";
  initialValue: string;
  editable: boolean;
  updateValue: (value: string) => void;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const CustomPicker: Component<Props> = ({
  label,
  type,
  initialValue,
  editable,
  updateValue,
}) => {
  const classes = useStyles();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [value, setValue] = useState(initialValue);

  const isDate = type === strings.fieldTypes.date;
  const isTime = type === strings.fieldTypes.time;

  const renderPickerInput = (props: TextFieldProps): any => (
    <TextField
      disabled={!editable}
      variant="outlined"
      onClick={openPicker}
      onSelect={openPicker}
      value={value}
      onChange={props.onChange}
      contentEditable={false}
      InputProps={inputProps()}
      placeholder={label}
    />
  );

  const openPicker = () => {
    if (editable) {
      setPickerOpen(true);
    }
  };

  const closePicker = () => {
    setPickerOpen(false);
  };

  const inputProps = () => {
    return {
      endAdornment: (
        <InputAdornment position="end">
          {isDate ? <CalendarToday /> : <AccessTime />}
        </InputAdornment>
      ),
    };
  };

  const handleChange = (event: any) => {
    const newValue = isDate ? convertDate(event) : convertTime(event);
    setValue(newValue);
    updateValue(newValue);
    closePicker();
  };

  return (
    <MuiPickersUtilsProvider utils={DateFunctions}>
      <>
        <InputLabel className={classes.inputLabel}>{label}</InputLabel>
        {isDate && (
          <KeyboardDatePicker
            open={pickerOpen}
            onClose={closePicker}
            margin="normal"
            label={label}
            format="dd/MM/yyyy"
            onChange={(e) => handleChange(e)}
            value={value}
            onClick={openPicker}
            TextFieldComponent={renderPickerInput}
          />
        )}
        {isTime && (
          <KeyboardTimePicker
            onClose={closePicker}
            open={pickerOpen}
            margin="normal"
            label={label}
            value={value}
            onChange={(e) => handleChange(e)}
            TextFieldComponent={renderPickerInput}
          />
        )}
      </>
    </MuiPickersUtilsProvider>
  );
};
