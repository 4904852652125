//@ts-nocheck
import { JobRendererTheme } from "@pulsion/forms-designer-2";
import { colours, fonts } from "../theme";

export const rendererTheme: JobRendererTheme = {
  colors: {
    primary: colours.primaryPurple,
    secondary: colours.secondaryPink,
    modalHeader: colours.darkBlueColour,
  },
  fonts: ["Noto Serif", "Varela Round"],
  font: {
    primary: {
      family: fonts.primary,
      color: colours.darkGreyColour,
    },
    secondary: {
      family: fonts.secondary,
      color: colours.greyColour,
      size: "15px",
    },
  },
  button: {
    fontSize: "16px",
  },
  table: {
    marginBottom: "1.5rem",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    header: {
      color: colours.inputBackgroundGrey,
      justification: "space-between",
      font: "Varela Round",
      fontSize: "2.2rem",
      padding: "0.5rem",
    },
    headerRow: {
      font: "Varela Round, sans-serif",
      fontSize: "15px",
      fontColor: colours.darkGreyColour,
      fontWeight: "normal",
      textAlign: "left",
      backgroundColor: colours.inputBackgroundGrey,
    },
    cell: {
      font: "Noto Serif, serif",
      fontSize: "15px",
      fontColor: colours.greyColour,
      textAlign: "left",
      backgroundColor: "inherit",
    },
    filterLabel: {
      padding: "30px",
    },
    columnHeaderResizing: {
      display: "inline-block",
      background: "rgba(224,224,224,1)",
      width: "1px",
      height: "100%",
      position: "absolute",
      right: "0",
      top: "0",
      transform: "translateX(50%)",
      zIndex: "1",
      touchAction: "none",
      hoverBackground: "rgba(158,158,158,1)",
    },
  },
  containers: {
    root: {},
    section: {
      boxShadow: "none",
    },
  },
  headers: {},
  buttons: {
    root: {},
    hover: {},
  },
  fields: {},
  submitButtonContainer: {},
  sectionContainer: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  sectionMenu: {
    header: {
      fontSize: "18px",
      textTransform: "uppercase",
      fontWeight: "bold",
      color: colours.primaryPurple,
      padding: "20px",
      margin: "0",
    },
    container: {
      marginTop: "0px !important",
      padding: 5,
      border: "none !important",
      borderRadius: 0,
    },
    selected: {
      oldSelected: {
        borderLeft: "none",
        borderTop: "none !important",
        padding: "0px",
      },
      borderLeft: "none",
      borderTop: "none !important",
      padding: "0px",
    },
    item: {
      root: {
        color: colours.darkGreyColour,
        maxWidth: "100%",
      },
      hover: {
        backgroundColor: colours.greySelectedColour,
      },
    },
    sectionLoader: {
      width: "100%",
    },
  },
  subsections: {
    all: {},
    adjacent: {},
    header: {},
    container: {},
    divider: {},
  },
  buttonSetControl: {
    root: {},
    hover: {},
    selected: {},
  },
  saveButton: {
    root: {},
    hover: {},
  },
  sectionColumns: {
    container: {},
    sectionDisplay: {},
    col1: {
      borderTop: "1px solid rgb(0, 0, 0, 0.12)",
    },
  },
  dropzone: {
    backgroundColor: "rgb(251, 249, 249)",
  },
};
