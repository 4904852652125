import { Fab, MuiThemeProvider, makeStyles } from "@material-ui/core";
import {
  Apps,
  ArrowBack,
  DesktopWindows,
  Edit,
  HelpOutline as Help,
  Description as ItemIcon,
  Refresh,
  Smartphone,
  TabletAndroid,
} from "@material-ui/icons";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import logo from "../../assets/requiment-tick-icon.svg";
import { Loader } from "../../components/general";
import { Mode } from "../../components/general/types/Modify";
import { MenuItem } from "../../components/layout/listItems";
import { WireframesDisplay } from "../../components/wireframes/WireframesDisplay";
import { WireframesMenuComponent } from "../../components/wireframes/WireframesMenuComponent";
import { ConflictChoicesModal } from "../../components/wireframes/modals/ConflictChoicesModal";
import { ModifyPageModal } from "../../components/wireframes/modals/ModifyPageModal";
import { ViewPagesModal } from "../../components/wireframes/modals/ViewPagesModal";
import { WireframesTutorial } from "../../components/wireframes/modals/WireframesTutorial";
import { AuthenticationPage } from "../../components/wireframes/pages/AuthenticationPage";
import {
  ScreenSizeContextProvider,
  getScreenSizeContextValues,
} from "../../context/ScreenSizeContext";
import { CookieEnum, DateHelper } from "../../helpers";
import { populateFieldValues } from "../../helpers/wireframesHelper";
import { useCookies } from "../../hooks/general/useCookies";
import { paths } from "../../navigation";
import { wireframesStrings as strings } from "../../resources/strings";
import { getProjectLogo } from "../../services/projectService";
import {
  checkWireframesGenerated,
  generateWireframes,
  getWireframes,
} from "../../services/wireframeService";
import { getStyles } from "../../styles/wireframes/display";
import { theme as wireframesTheme } from "../../styles/wireframes/theme";
import { Component } from "../../types";
import {
  AuthenticationScreenInfo,
  Conflict,
  Entity,
  Field,
  Page,
  WireframesAuthPage,
  WireframesData,
  WireframesScreenSize,
} from "../../types/Wireframes";

const theme = wireframesTheme;
const useStyles = makeStyles((theme) => getStyles(theme));

export interface WireframesMenuItem extends MenuItem {
  entityId?: string;
  pageId?: string;
}

interface Props {
  dateHelper: DateHelper;
}

export const WireframesRenderer: Component<Props> = ({ dateHelper }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [menuItems, setMenuItems] = useState<WireframesMenuItem[]>([]);
  const [projectLogo, setProjectLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [authInfo, setAuthInfo] = useState<AuthenticationScreenInfo>();
  const [screenSize, setScreenSize] = useState(WireframesScreenSize.DESKTOP);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [pages, setPages] = useState<Page[]>([]);
  const [selectedPage, setSelectedPage] = useState<Page>();
  const [viewPagesModalOpen, setViewPagesModalOpen] = useState(false);
  const [modifyPageModalOpen, setModifyPageModalOpen] = useState(false);
  const [conflictChoicesModalOpen, setConflictChoicesModalOpen] =
    useState(false);
  const [selectedAuthPage, setSelectedAuthPage] = useState(
    WireframesAuthPage.LOGIN
  );
  const [modifyMode, setModifyMode] = useState<Mode>("update");
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const [conflicts, setConflicts] = useState<Conflict[]>([]);
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [cookies, setCookie] = useCookies(dateHelper);

  useEffect(() => {
    if (!cookies[CookieEnum.WireframesFirstVisit]) {
      setTutorialOpen(true);
    }
  }, [cookies]);

  const openTutorial = () => setTutorialOpen(true);
  const closeTutorial = () => setTutorialOpen(false);

  const onCloseTutorial = () => {
    closeTutorial();
    setCookie(CookieEnum.WireframesFirstVisit, true);
  };

  const refresh = useCallback(() => {
    setLoading(true);
    setLoggedIn(true);

    getProjectLogo(id).then((imageData) => {
      if (imageData) setProjectLogo(imageData);
    });

    checkWireframesGenerated(id).then((res) => {
      if (res.length > 0) {
        setLoading(false);
        setConflicts(res);
        setConflictChoicesModalOpen(true);
      } else {
        getWireframes(id).then((res) => {
          updateData(res);
        });
      }
    });
  }, [id]);

  const submitConflictChoices = () => {
    setConflictChoicesModalOpen(false);
    setLoading(true);
    generateWireframes(id, conflicts).then((res) => {
      updateData(res);
    });
  };

  const updateAfterPageEdit = (newPage: Page) => {
    const pageItemIndex = pages.findIndex(
      (page) => page.pageId === newPage.pageId
    );
    const menuItemIndex = menuItems.findIndex(
      (menuItem) => menuItem.pageId === newPage.pageId
    );
    pages[pageItemIndex !== -1 ? pageItemIndex : pages.length] = newPage;
    const newMenuItem = {
      label: newPage.name,
      Icon: ItemIcon,
      onClick: () => setSelectedPage(newPage),
      entityId: getPageEntityId(newPage),
      pageId: newPage.pageId,
    };
    if (newPage.onMenu) {
      if (menuItemIndex >= 0) {
        menuItems[menuItemIndex] = newMenuItem;
        setSelectedMenuItem(menuItemIndex);
      } else {
        const newLength = menuItems.push(newMenuItem);
        setSelectedMenuItem(newLength - 1);
      }
    } else {
      if (menuItemIndex >= 0) {
        menuItems.splice(menuItemIndex, 1);
      }
    }
    setPages(pages);
    setMenuItems(menuItems);
    newMenuItem.onClick();
  };

  const refreshAfterDelete = useCallback(() => {
    setLoading(true);
    getWireframes(id).then((res) => {
      updateData(res);
    });
  }, [id]);

  const updateData = async (data: WireframesData) => {
    const menuItems: WireframesMenuItem[] = [];
    const newEntities: Entity[] = [];
    for (const entity of data.entities) {
      const newFields: Field[] = [];
      for (const field of entity.fields) {
        const newField = await populateFieldValues(entity, field);
        newFields.push(newField);
      }
      entity.fields = newFields;
      newEntities.push(entity);
    }
    data.pages
      .filter((page) => page.onMenu)
      .sort((a, b) => a.order - b.order)
      .forEach((page: Page, index) => {
        menuItems[index] = {
          label: page.name,
          Icon: ItemIcon,
          onClick: () => setSelectedPage(page),
          entityId: getPageEntityId(page),
          pageId: page.pageId,
        };
      });
    setEntities(newEntities);
    setPages(data.pages);
    setMenuItems(menuItems);
    setAuthInfo(data.authInfo);
    setLoading(false);
    const menuItem = menuItems[0];
    if (menuItem && menuItem.onClick) {
      menuItem.onClick();
    }
  };

  useEffect(() => {
    refresh();
  }, [id, refresh]);

  const updatePage = (page: Page) => {
    setSelectedPage(page);
    const entityId = getPageEntityId(page);
    const menuItem = menuItems.findIndex((item) => item.entityId === entityId);
    if (menuItem) {
      setSelectedMenuItem(menuItem);
    }
    setLoggedIn(true);
  };

  const logout = () => {
    moveToAuthPage(WireframesAuthPage.LOGIN);
  };

  const login = () => {
    setLoggedIn(true);
  };

  const moveToAuthPage = (authPage: WireframesAuthPage) => {
    setSelectedAuthPage(authPage);
    setLoggedIn(false);
  };

  const logoImageData = projectLogo
    ? `data:image/jpeg;base64,${projectLogo}`
    : "";

  const selected = (iconSize: WireframesScreenSize) => {
    return screenSize === iconSize;
  };

  const openModifyModal = (mode: Mode) => {
    setModifyPageModalOpen(true);
    setModifyMode(mode);
  };

  const screenSizeClass = () => {
    switch (screenSize) {
      case WireframesScreenSize.DESKTOP:
        return classes.desktopContainer;
      case WireframesScreenSize.TABLET:
        return classes.tabletContainer;
      case WireframesScreenSize.MOBILE:
        return classes.mobileContainer;
    }
  };

  if (loading) {
    return <Loader active={true} />;
  }

  return (
    <>
      <WireframesTutorial isOpen={tutorialOpen} closeModal={onCloseTutorial} />
      <MuiThemeProvider theme={theme}>
        <ScreenSizeContextProvider
          value={getScreenSizeContextValues(screenSize)}
        >
          <ViewPagesModal
            open={viewPagesModalOpen}
            onClose={() => setViewPagesModalOpen(false)}
            pages={pages}
            entities={entities}
            authInfo={authInfo}
            moveToAuthPage={moveToAuthPage}
            setSelectedPage={updatePage}
            currentPage={loggedIn ? selectedPage : selectedAuthPage}
            openModifyModal={openModifyModal}
            refresh={refreshAfterDelete}
          />
          <ModifyPageModal
            open={modifyPageModalOpen}
            onClose={() => setModifyPageModalOpen(false)}
            currentPage={selectedPage}
            mode={modifyMode}
            entities={entities}
            update={updateAfterPageEdit}
            setLoading={setLoading}
            menuLength={menuItems.length}
          />
          <ConflictChoicesModal
            open={conflictChoicesModalOpen}
            onClose={submitConflictChoices}
            conflicts={conflicts}
            updateConflicts={setConflicts}
          />
          <div className={classes.container}>
            <div className={screenSizeClass()}>
              {loggedIn ? (
                <WireframesMenuComponent
                  menuItems={menuItems}
                  logoImageData={logoImageData}
                  logout={authInfo?.login ? logout : undefined}
                  selectedItem={selectedMenuItem}
                >
                  <WireframesDisplay
                    selectedPage={selectedPage}
                    setSelectedPage={updatePage}
                    pages={pages}
                    entities={entities}
                  />
                </WireframesMenuComponent>
              ) : (
                <AuthenticationPage
                  authScreenInfo={authInfo}
                  login={login}
                  logoImageData={logoImageData}
                  selectedPage={selectedAuthPage}
                />
              )}
              <div
                className={clsx(classes.fabContainer, classes.fabContainerLeft)}
              >
                <Fab
                  title={strings.titles.backToForm}
                  size="medium"
                  onClick={() => history.push(paths.projects.render(id))}
                >
                  <ArrowBack />
                </Fab>
                <Fab
                  variant="extended"
                  className={classes.watermark}
                  onClick={() => history.push(paths.projects.list)}
                >
                  <img src={logo} alt={strings.labels.logoAlt} />
                  <p>{strings.labels.watermark}</p>
                </Fab>
                <Fab
                  title={strings.titles.helpGuide}
                  size="medium"
                  onClick={openTutorial}
                >
                  <Help fontSize="large" className={classes.helpIcon} />
                </Fab>
              </div>
              <div
                className={clsx(
                  classes.fabContainer,
                  classes.fabContainerRight
                )}
              >
                <Fab
                  title={strings.titles.refresh}
                  size="medium"
                  onClick={refresh}
                >
                  <Refresh />
                </Fab>
                <Fab
                  title={strings.titles.viewScreens}
                  size="medium"
                  onClick={() => setViewPagesModalOpen(true)}
                >
                  <Apps />
                </Fab>
                <Fab
                  title={strings.titles.editPage}
                  size="medium"
                  onClick={() => openModifyModal("update")}
                >
                  <Edit />
                </Fab>
                <Fab variant="extended">
                  <DesktopWindows
                    titleAccess={strings.titles.desktop}
                    onClick={() => setScreenSize(WireframesScreenSize.DESKTOP)}
                    className={clsx(
                      classes.displayIcon,
                      selected(WireframesScreenSize.DESKTOP) &&
                        classes.displayIconSelected
                    )}
                  />
                  <TabletAndroid
                    titleAccess={strings.titles.tablet}
                    onClick={() => setScreenSize(WireframesScreenSize.TABLET)}
                    className={clsx(
                      classes.displayIcon,
                      selected(WireframesScreenSize.TABLET) &&
                        classes.displayIconSelected
                    )}
                  />
                  <Smartphone
                    titleAccess={strings.titles.mobile}
                    onClick={() => setScreenSize(WireframesScreenSize.MOBILE)}
                    className={clsx(
                      classes.displayIcon,
                      selected(WireframesScreenSize.MOBILE) &&
                        classes.displayIconSelected
                    )}
                  />
                </Fab>
              </div>
            </div>
          </div>
        </ScreenSizeContextProvider>
      </MuiThemeProvider>
    </>
  );
};

function getPageEntityId(page: Page) {
  const { content } = page;
  const hasContent = !!content.length;
  return hasContent ? content[0].entityId : undefined;
}
