export const paths = {
  root: "/",
  authenticate: "/authenticate",
  auth: {
    login: "/login",
    passwordReset: "/passwordReset",
    signUp: "/signUp",
  },
  products: "/selectPlan",
  subscriptionIssue: "/manageSubscription",
  userLimitIssue: "/subscription-removeUsers",
  success: "/success",
  termsAndConditions: "/terms-of-service.html",
  privacyPolicy: "/privacy-policy.html",
  help: "/help",
  projects: {
    list: "/projects",
    create: () => "/projects/create/",
    view: (projectId: string) => `/projects/view/${projectId}`,
    render: (projectId: string) => `/projects/render/${projectId}`,
    viewChangeHistory: (projectId: string) =>
      `/projects/viewHistory/${projectId}`,
  },
  entityLibrary: {
    list: "/myLibrary",
  },
  imageLibrary: {
    list: "/imageLibrary",
    upload: "/imageLibrary/upload",
  },
  ticketTemplates: {
    list: "/ticketTemplates",
    create: "/ticketTemplates/create",
    modify: (id: string) => `/ticketTemplates/${id}`,
    upload: "/ticketTemplates/upload",
  },
  users: {
    list: "/users",
    create: "/users/create",
    view: (id: string) => `/users/view/${id}`,
  },
  settings: {
    list: "/settings",
  },
  wireframes: (projectId: string) => `/wireframes/${projectId}`,
  generateEstimates: (projectId: string) => `/estimation/${projectId}`,
  tutorial: {
    view: "/tutorial",
  },
};
