import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/requiment-tick-icon.svg";
import { Modal as CustomModal } from "../../containers/general/Modal/Modal";
import { surveyStrings as strings } from "../../resources/strings";
import { getStyles } from "../../styles/general/popup";
import { Component } from "../../types";

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  isModalOpen: boolean;
  onClickMaybeLater: () => void;
  completeModal: () => void;
}

export const Survey: Component<Props> = ({
  isModalOpen,
  onClickMaybeLater,
  completeModal,
}) => {
  const classes = useStyles();

  const handleFeedback = () => {
    completeModal();
    window.open(strings.surveyLink);
  };

  const buttonConfiguration = {
    confirmConfig: {
      text: strings.giveFeedback,
      onClick: handleFeedback,
    },
    cancelConfig: {
      text: strings.maybeLater,
      onClick: onClickMaybeLater,
      className: "float-left",
    },
  };

  return (
    <CustomModal
      active={isModalOpen}
      onCloseModal={completeModal}
      header={strings.headerText}
      buttonConfiguration={buttonConfiguration}
    >
      <Paper square elevation={0} className={classes.content}>
        <img className={classes.logo} src={logo} alt={"Survey"} />
        <Typography className={classes.paragraph}>
          {strings.mainText}
        </Typography>
      </Paper>
    </CustomModal>
  );
};
