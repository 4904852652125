import { appStrings } from "./app";

export const fileStrings = {
  global: { ...appStrings },
  errors: {
    displayError: "An error occurred while uploading files. ",
    displayErrorAction: "Please try again.",
    incorrectFileType: "Incorrect file type",
    fileTooLarge:
      "File is too large. Only files less than 500KB may be uploaded",
  },
  labels: {
    displayFilesAdded: (numberOfFiles: number) =>
      `${numberOfFiles} file${numberOfFiles > 1 ? "(s)" : ""} added`,
    thumbnailAltText: "Thumbnail of file upload",
    inputText: "Drag file(s) or click to browse",
    imageLibraryUploadSuccess: "Files uploaded to Image Library!",
    inputPlaceholder: "Enter comma-separated tags...",
  },
  breadcrumbs: {
    fileUpload: "Add Image(s)",
  },
};
