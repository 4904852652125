import { Button as UIButton } from "@material-ui/core";
import type { Button as TButton } from "../types/Modify";
import { Component } from "../../../types";

import { useModifyContext } from "../../../context/ModifyContext";

interface Props {
  config: TButton;
}

export const Button: Component<Props> = ({ config: input }) => {
  const { handleModeSwitch } = useModifyContext();

  const { label, buttonaction, ...config } = input;
  const buttonConfig = Object.assign({}, config);
  if (buttonaction) {
    switch (buttonaction) {
      case "switch":
        buttonConfig.type = "button";
        buttonConfig.onClick = () => {
          handleModeSwitch();
        };
        break;
      default:
        break;
    }
  }

  return (
    <UIButton type="submit" {...buttonConfig}>
      {label}
    </UIButton>
  );
};
