import { makeStyles } from "@material-ui/core";
import {
  ApiContextProvider,
  IEntityListApi,
  EntityListTableContainer as TableContainer,
} from "@pulsion/forms-designer-2";
import { useEffect, useState } from "react";
import Dropzone, {
  IFileWithMeta,
  ILayoutProps,
  StatusValue,
} from "react-dropzone-uploader";
import { ModalButtons } from "../../../containers/general/Modal/ModalButtons";
import { fileStrings as strings } from "../../../resources/strings/files";
import { dropzoneStyle } from "../../../styles/common";
import { listTheme } from "../../../styles/formsDesigner/listTheme";
import { getStyles } from "../../../styles/general/fileUpload";
import { Component, NotificationType } from "../../../types";
import { FileStatus } from "../../../types/FileStatus";
import { FileData } from "../../../types/documents/ImageFile";
import { Loader } from "../Loader";
import { Notification } from "../Notification";
import { InlineInput } from "./InlineInput";

interface Props {
  onSubmit: (files: FileData[]) => void;
  maxFiles?: number;
  accept?: string;
  uploadAsBase64?: boolean;
  showUploadTable?: boolean;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const FileUpload: Component<Props> = ({
  onSubmit,
  maxFiles,
  accept,
  uploadAsBase64,
  showUploadTable = true,
}) => {
  const [files, setFiles] = useState<Array<FileData>>([]);
  const [allFiles, setAllFiles] = useState<Array<IFileWithMeta>>([]);
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState("");
  const [uploadIndex, setUploadIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const getFileData = (file: IFileWithMeta) => {
      return new Promise<{
        id: string;
        data: string;
        remove: () => void;
      }>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
          if (!(typeof reader.result === "string")) {
            return;
          }

          const fileData = uploadAsBase64
            ? reader.result
            : btoa(encodeURIComponent(reader.result ? reader.result : ""));
          resolve({ id: file.meta.id, data: fileData, remove: file.remove });
        };
        reader.onerror = function (error) {
          console.error(error);
        };
        if (uploadAsBase64) {
          reader.readAsDataURL(file.file);
        } else {
          reader.readAsText(file.file);
        }
      });
    };

    const getFilesData = async (files: IFileWithMeta[]) => {
      const promises: Promise<{
        id: string;
        data: string;
        remove: () => void;
      }>[] = [];
      for (const file of files) {
        promises.push(getFileData(file));
      }
      return await Promise.all(promises);
    };

    const query = async () => {
      const filesData = await getFilesData(newDropzoneFiles);

      const newFiles: FileData[] = newDropzoneFiles.map((x, index) => ({
        fileName: x.file.name,
        tags: [],
        data: filesData.find((y) => y.id === x.meta.id)?.data ?? "",
        extension: getFileExtension(x),
        uploadIndex: uploadIndex + index,
        remove: () => x.remove(),
        uploadId: x.meta.id,
      }));

      setFiles([...files, ...newFiles]);
      setUploadIndex((prev) => prev + newFiles.length);
    };

    const newDropzoneFiles = allFiles.filter(
      (x) => !files.map((x) => x.uploadId).includes(x.meta.id)
    );
    if (newDropzoneFiles.length > 0) {
      query();
    }
  }, [allFiles, files, uploadIndex, uploadAsBase64]);

  const singleFileLimit = maxFiles === 1;

  const submitFile = async () => {
    if (!files || !files.length) {
      return;
    }

    try {
      await onSubmit(files);
      removeFiles();
    } catch (e: any) {
      console.log(strings.errors.displayError, e);
      setApiError(
        strings.errors.displayError + strings.errors.displayErrorAction
      );
    }
  };

  const removeFiles = () => {
    files.forEach((f) => f.remove());
    setFiles([]);
    setLoading(false);
    setUploadIndex(0);
  };

  const handleChangeStatus = (
    file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[]
  ) => {
    setLoading(true);
    let error = "";
    if (status === FileStatus.rejectedFileType) {
      error = strings.errors.incorrectFileType;
    }
    if (status === FileStatus.fileTooLarge) {
      error = strings.errors.fileTooLarge;
    }

    if (error) {
      setError(error);
      if (file.remove) file.remove();
      setLoading(false);
    }

    setAllFiles(allFiles.filter((x) => x.meta.status === "done"));

    if (allFiles.every((x) => ["done", "removed"].includes(x.meta.status))) {
      setLoading(false);
    }
  };

  const api: IEntityListApi = {
    getEntityRows: async () => {
      return {
        items: files.sort((a, b) => a.uploadIndex - b.uploadIndex),
        count: files.length,
      };
    },
    deleteRow: async (fileData: FileData) => {
      const fileToChange = files.find((x) => x.uploadId === fileData.uploadId);
      if (!fileToChange) {
        throw new Error("Failed to remove image from upload");
      }

      fileToChange.remove();
      setFiles((prev: FileData[]) => {
        return prev.filter((x) => x.uploadId !== fileToChange.uploadId);
      });
    },
  };

  const onEditTags = (updatedTags: string[], fileUploadId: string) => {
    const fileToChange = files.find((x) => x.uploadId === fileUploadId);
    if (fileToChange) {
      fileToChange.tags = updatedTags;
    }

    const filesUnchanged = files.filter(
      (x) => x.uploadId !== fileToChange?.uploadId
    );
    const updatedFiles = fileToChange
      ? filesUnchanged.concat(fileToChange)
      : filesUnchanged;
    setFiles(updatedFiles);
  };

  const InputComponent = () => {
    return (
      <div className={classes.dropzoneInput}>{strings.labels.inputText}</div>
    );
  };

  const InputPopulatedComponent = () => {
    return (
      <div className={classes.dropzoneInputPopulated}>
        {(!maxFiles || (maxFiles && files.length < maxFiles)) &&
          strings.labels.inputText}
        {files.length > 0 && (
          <div className={classes.filesAdded}>
            {singleFileLimit
              ? files[0]?.fileName
              : strings.labels.displayFilesAdded(files.length)}
          </div>
        )}
      </div>
    );
  };

  const LayoutComponent: Component<ILayoutProps> = ({
    dropzoneProps,
    files: dropzoneFiles,
    extra,
    input,
  }) => {
    return (
      <>
        <div {...dropzoneProps}>{input}</div>
        {files.length > 0 && showUploadTable && (
          <ApiContextProvider api={api}>
            <TableContainer<FileData>
              entity=""
              inputTheme={listTheme}
              deleteButtons={true}
              getViewEntityPath={() => ""}
              header=""
              appendHistory={() => {}}
              tableColumns={[
                {
                  id: "fileName",
                  Header: "Filename",
                  accessor: (file: FileData) => {
                    return (
                      <span className={classes.fileColumnSpan}>
                        <img
                          alt={strings.labels.thumbnailAltText}
                          src={file.data}
                        />
                        <div>{file.fileName}</div>
                      </span>
                    );
                  },
                },
                {
                  id: "tags",
                  Header: "Tags",
                  accessor: (file: FileData) => {
                    return (
                      <InlineInput
                        inputValue={file.tags.join(",")}
                        inputKey={file.uploadId}
                        className={classes.tagInput}
                        onEdit={(updatedTags) =>
                          onEditTags(updatedTags, file.uploadId)
                        }
                        placeholder={strings.labels.inputPlaceholder}
                        disabled={loading}
                      />
                    );
                  },
                },
              ]}
            />
          </ApiContextProvider>
        )}
      </>
    );
  };

  return (
    <>
      <Loader active={loading} inline></Loader>
      <Dropzone
        styles={dropzoneStyle}
        inputContent={InputComponent()}
        inputWithFilesContent={InputPopulatedComponent()}
        LayoutComponent={LayoutComponent}
        onChangeStatus={handleChangeStatus}
        maxFiles={maxFiles}
        submitButtonDisabled={true}
        accept={accept}
        disabled={loading}
        maxSizeBytes={500000}
      />
      {apiError && (
        <Notification
          message={
            strings.errors.displayError + strings.errors.displayErrorAction
          }
          type={NotificationType.error}
          onClose={() => setApiError("")}
        />
      )}
      {error && (
        <Notification
          message={error}
          type={NotificationType.error}
          onClose={() => setError("")}
        />
      )}
      <ModalButtons
        className="button-span"
        buttonConfiguration={{
          cancelConfig: {
            onClick: () => removeFiles(),
            text: singleFileLimit
              ? strings.global.labels.remove
              : strings.global.labels.removeAll,
            hidden: files.length < 1,
            disabled: loading,
          },
          confirmConfig: {
            onClick: async () => await submitFile(),
            text: strings.global.labels.submit,
            hidden: files.length < 1,
            disabled: loading,
          },
        }}
      />
    </>
  );
};

const getFileExtension = (file: IFileWithMeta) => {
  const { file: fileData } = file;
  const { name: fileName } = fileData;
  let extension = "";
  if (fileName.includes(".")) {
    extension = fileName.split(".")[1];
  }

  if (!extension) {
    throw new Error("Cannot get file extension from file to upload");
  }

  return extension;
};
