import {
  addStoredSectionToLibrary,
  createRow,
  deactivateRow,
  deleteSection,
  getControlAttachment,
  getJobData,
  getJobFormData,
  getProjectTypes,
  getStoredSections,
  getTemplateOnlySections,
  saveRow,
  saveRowReordering,
  updateAttachmentMetadata,
  updateSectionData,
  uploadAttachment,
} from "../services/projectsRendererService";

export const projectsRendererApi = (refetchData: () => void) => ({
  deleteCopyOfRepeatableSection: deleteSection(refetchData),
  setAllowChanges: async (data: any) => {
    return true;
  },
  getFormData: getJobFormData,
  getFormListData: getProjectTypes,
  getTemplateOnlySections,
  updateSectionData: updateSectionData(refetchData),
  createRow,
  deactivateRow: deactivateRow(refetchData),
  setMustBeComplete: async (data: any) => {
    return true;
  },
  getJobData,
  getControlAttachment,
  updateAttachmentMetadata,
  uploadAttachment: uploadAttachment(refetchData),
  saveRow: saveRow(refetchData),
  saveRowReordering: saveRowReordering(refetchData),
  getStoredSections,
  addStoredSectionToLibrary,
});
