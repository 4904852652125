import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { CookieEnum, CookieType } from "../../helpers/appHelper";
import { DateHelper } from "../../helpers/dateHelper";

type CookieState = {
  [key in CookieEnum]: string | undefined;
};

export const useCookies = (dateHelper: DateHelper) => {
  const [cookies, setReactCookie] = useState<CookieState>({
    [CookieEnum.Tutorial]: Cookies.get(CookieEnum.Tutorial),
    [CookieEnum.FirstVisit]: Cookies.get(CookieEnum.FirstVisit),
    [CookieEnum.AwaitSurvey]: Cookies.get(CookieEnum.AwaitSurvey),
    [CookieEnum.SurveyComplete]: Cookies.get(CookieEnum.SurveyComplete),
    [CookieEnum.PreviousUpdatedPrivacyPolicy]: Cookies.get(
      CookieEnum.PreviousUpdatedPrivacyPolicy
    ),
    [CookieEnum.UpdatedPrivacyPolicy]: Cookies.get(
      CookieEnum.UpdatedPrivacyPolicy
    ),
    [CookieEnum.WireframesFirstVisit]: Cookies.get(
      CookieEnum.WireframesFirstVisit
    ),
    [CookieEnum.TrialStart]: Cookies.get(CookieEnum.TrialStart),
  });

  useEffect(() => {
    setReactCookie({
      [CookieEnum.Tutorial]: Cookies.get(CookieEnum.Tutorial),
      [CookieEnum.FirstVisit]: Cookies.get(CookieEnum.FirstVisit),
      [CookieEnum.AwaitSurvey]: Cookies.get(CookieEnum.AwaitSurvey),
      [CookieEnum.SurveyComplete]: Cookies.get(CookieEnum.SurveyComplete),
      [CookieEnum.PreviousUpdatedPrivacyPolicy]: Cookies.get(
        CookieEnum.PreviousUpdatedPrivacyPolicy
      ),
      [CookieEnum.UpdatedPrivacyPolicy]: Cookies.get(
        CookieEnum.UpdatedPrivacyPolicy
      ),
      [CookieEnum.WireframesFirstVisit]: Cookies.get(
        CookieEnum.WireframesFirstVisit
      ),
      [CookieEnum.TrialStart]: Cookies.get(CookieEnum.TrialStart),
    });
  }, []);

  const setCookie = (
    name: CookieType,
    value: any,
    options: any = {
      path: "/",
      expires: dateHelper.twentyFiveYears,
    }
  ) => {
    Cookies.set(name, value, options);
    let newCookieState = { ...cookies, [name]: value };

    if (name === CookieEnum.UpdatedPrivacyPolicy) {
      Cookies.remove(CookieEnum.PreviousUpdatedPrivacyPolicy);
      newCookieState = {
        ...newCookieState,
        [CookieEnum.PreviousUpdatedPrivacyPolicy]: "",
      };
    }
    setReactCookie(newCookieState);
  };

  const removeCookie = (name: CookieType) => {
    Cookies.remove(name);
  };

  return [cookies, setCookie, removeCookie] as const;
};
