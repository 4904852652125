import { appStrings } from "./app";

export const userStrings = {
  global: { ...appStrings },
  header: {
    users: "Users",
    userDetails: "User Details",
    signInInfo: "Sign-In Information",
    userInfo: "User Information",
  },
  labels: {
    username: "Username",
    email: "Email address",
    roleId: "User Role",
    forename: "Forename",
    surname: "Surname",
    created: "Created",
    updated: "Updated",
    createUser: "New User",
    editUser: "Edit User",
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    role: "Role",
    admin: "Admin",
    standard: "Standard",
  },
  navLinkLabels: {
    usersList: "Users",
    viewUser: "User Details",
  },
  text: {
    userLimitReachedInfo:
      "You cannot create another user as your user limit has been reached.",
    userLimitReachedDirection:
      "Click below to manage your subscription and add more users.",
    userAdded: "User Added",
    userAddedInfo:
      "Your new user will receive a temporary login password at the address ",
  },
  errorMessages: {
    cannotDeleteLastAdmin: {
      apiMessage:
        "Cannot delete user. There must be at least one admin user on each tenant.",
      displayMessage:
        "Cannot delete user. You must have at least one admin user on your account.",
    },
    userLimitReached: "User limit reached. Cannot create user.",
    messages: [
      {
        errorMessage:
          "Cannot update user. There must be at least one admin user on each tenant.",
        displayMessage:
          "Cannot update user. You must have at least one admin user on your account.",
      },
      {
        errorMessage: "User limit reached. Cannot create user.",
        displayMessage: "Cannot create user. Your user limit has been reached.",
      },
    ],
  },
};
