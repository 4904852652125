import { useIsAuthorised } from "../../hooks/authentication";
import { Component, Permissions } from "../../types";

interface Props {
  children: JSX.Element | JSX.Element[];
  // permissions may end up coming from store rather than
  // passing in each time
  userPermissions: Permissions | null;
  entity?: string;
  permission?: string;
}

export const ShowIfAuthorised: Component<Props> = ({
  children,
  userPermissions,
  entity,
  permission,
}) => {
  const isAuthorised = useIsAuthorised(userPermissions, entity, permission);
  return <>{isAuthorised ? children : null}</>;
};
