import { Route, RouteProps } from "react-router-dom";
import { RouteProps as AppRouteProps, UserAccess, Component } from "../../types";

import { ShowIfAuthorised } from "../../components/authentication";

interface Props extends RouteProps, AppRouteProps {
  userAccess?: UserAccess;
}

export const PrivateRoute: Component<Props> = ({
  children,
  userAccess,
  permissions,
  user,
  ...props
}) => {
  // the main app component will ensure push to login when user is logged out
  if (!user) {
    return <></>;
  }
  // now validate user has the relevant permission
  return (
    <>
      <ShowIfAuthorised userPermissions={permissions} {...userAccess ?? {}}>
        <Route {...props}>{children}</Route>
      </ShowIfAuthorised>
    </>
  );
};
