import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core";
import { theme } from "../styles/theme";

// component for Router, store, providers etc.
// will be used for main app as well as any unit tests
export const Wrappers: React.FC = ({ children }) => {
  return (
    <Router>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Router>
  );
};
