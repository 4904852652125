import { mediaStrings } from "../resources/strings";

export const isMobile = () => {
  return getMediaType() === mediaStrings.mobile;
};

const mediaWidths = {
  desktop: 1680,
  tablet: 768
};

const getMediaType = () => {
var screenWidth = window.innerWidth;
  if (screenWidth >= mediaWidths.desktop) {
    return mediaStrings.desktop;
  } else if (screenWidth >= mediaWidths.tablet) {
    return mediaStrings.tablet;
  } else {
    return mediaStrings.mobile;
  }
};
