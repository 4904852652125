import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { getStyles } from "../../styles/general/detailsView";
import { Component } from "../../types";

interface Props {
  fields: Array<{
    label: string;
    value: any;
  }>;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const DetailsView: Component<Props> = ({ fields }) => {
  const classes = useStyles();

  const Item: React.FC = ({ children }) => {
    return (
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="space-evenly">
          {children}
        </Box>
      </Grid>
    );
  };

  return (
    <div className={clsx(classes.root)}>
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2} justifyContent="center">
          {fields.map(({ label, value }, index) => (
            <Item key={`${label}-${index}`}>
              <Typography className={clsx(classes.label)}>{label}</Typography>
              <Typography className={clsx(classes.value)}>{value}</Typography>
            </Item>
          ))}
        </Grid>
      </Box>
    </div>
  );
};
