import {
  ApiContextProvider,
  ControlContextProvider,
  EntityListTableContainer,
  EntityListTableContainerProps as ProjectListProps,
} from "@pulsion/forms-designer-2";
import { useHistory } from "react-router-dom";
import { projectsListApi } from "../../apis/projectsListApi";
import { formatDateTime } from "../../helpers";
import { paths } from "../../navigation/paths";
import { appStrings } from "../../resources/strings";
import { projectStrings as strings } from "../../resources/strings/projects";
import { listTheme } from "../../styles/formsDesigner/listTheme";
import { ProjectData } from "../../types/documents";
import { EmptyListDisplay } from "../general/EmptyListDisplay/EmptyListDisplay";

export const ProjectList = () => {
  const history = useHistory();
  const appendHistory = (url: string) => {
    history.push(url);
  };

  const defaultDisplay = (
    <EmptyListDisplay
      onClickButton={() => appendHistory(paths.projects.create())}
      titleText={strings.labels.getStarted}
      buttonText={strings.labels.newProject}
      infoText={appStrings.labels.noDataInfo("project")}
    ></EmptyListDisplay>
  );

  const props: ProjectListProps<ProjectData> = {
    entity: "Project",
    inputTheme: listTheme,
    createButtonConfig: {
      label: strings.labels.createProject,
      path: paths.projects.create(),
    },
    clickHandler: (rowObject) =>
      appendHistory(paths.projects.render(rowObject.projectId)),
    manualPagination: true,
    deleteButtons: true,
    getViewEntityPath: (rowObject: ProjectData) =>
      paths.projects.render(rowObject.projectId),
    sortOptions: {},
    header: strings.labels.myProjects,
    resizableColumns: true,
    tableColumns: [
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        isSortable: true,
        isResizable: true,
      },
      {
        id: "createdDate",
        Header: "Created",
        accessor: ({ createdDate }: ProjectData) => formatDateTime(createdDate),
        isSortable: true,
        isResizable: true,
      },
      {
        id: "updatedDate",
        Header: "Updated",
        accessor: ({ updatedDate }: ProjectData) => formatDateTime(updatedDate),
        isSortable: true,
      },
    ],
    appendHistory,
    defaultDisplay,
  };

  return (
    <ApiContextProvider api={projectsListApi}>
      <ControlContextProvider resizableGridTables>
        <EntityListTableContainer<ProjectData> {...props} />
      </ControlContextProvider>
    </ApiContextProvider>
  );
};
