import { makeStyles } from "@material-ui/core";
import { useScreenSizeContext } from "../../../context/ScreenSizeContext";
import {
  isDropdownField,
  isNumberEven,
} from "../../../helpers/wireframesHelper";
import { wireframesStrings as strings } from "../../../resources/strings";
import { getStyles } from "../../../styles/wireframes/fields";
import { Component } from "../../../types";
import { CollectionData, Field } from "../../../types/Wireframes";
import { CustomCheckbox } from "../controls/CustomCheckbox";
import { CustomDropdown } from "../controls/CustomDropdown";
import { CustomImageInput } from "../controls/CustomImageInput";
import { CustomPicker } from "../controls/CustomPicker";
import { CustomTextField } from "../controls/CustomTextField";

interface Props {
  fields: Field[];
  editable: boolean;
  prepopulated: boolean;
  selectedIndex: number;
  updateFields: (fields: Field[]) => void;
  fullWidth: boolean;
}

const useStyles = makeStyles((theme) => getStyles(theme));

export const InputFields: Component<Props> = ({
  fields,
  editable,
  prepopulated,
  selectedIndex,
  updateFields,
  fullWidth,
}) => {
  const classes = useStyles();
  const { isMobile } = useScreenSizeContext();

  const getInput = (field: Field, fieldIndex: number) => {
    const isDropdown = isDropdownField(field.type);
    const isCheckbox = field.type === strings.fieldTypes.boolean;

    const updateFieldValue = (newValue: any) => {
      if (isDropdown && field.collectionData) {
        field.collectionData[selectedIndex].selected = newValue;
      } else {
        field.fakeData && (field.fakeData[selectedIndex] = newValue);
      }
      fields.splice(fieldIndex, 1, field);
      updateFields(fields);
    };

    if (field.fakeData) {
      const value =
        isCheckbox || prepopulated ? field.fakeData[selectedIndex] : "";
      return getField(field, value, updateFieldValue);
    }
    if (field.collectionData) {
      const value = field.collectionData[selectedIndex];
      return getDropdownField(field, value, updateFieldValue);
    }
  };

  const getDropdownField = (
    field: any,
    value: CollectionData,
    updateFieldValue: (value: string) => void
  ) => {
    switch (field.type) {
      case strings.fieldTypes.multiValue:
        return (
          <CustomDropdown
            editable={editable}
            initialValue={prepopulated ? value.selected : []}
            label={field.name}
            type={field.type}
            values={value.values}
            updateValue={updateFieldValue}
          ></CustomDropdown>
        );
      case strings.fieldTypes.singleEntity:
        return (
          <CustomDropdown
            editable={editable}
            initialValue={prepopulated ? value.selected : []}
            label={field.name}
            type={field.type}
            values={value.values}
            updateValue={updateFieldValue}
          ></CustomDropdown>
        );
      case strings.fieldTypes.collectionEntity:
        return (
          <CustomDropdown
            editable={editable}
            initialValue={prepopulated ? value.selected : []}
            label={field.name}
            type={field.type}
            values={value.values}
            updateValue={updateFieldValue}
          ></CustomDropdown>
        );
      default:
        return (
          <CustomTextField
            editable={editable}
            initialValue={value.values[0]}
            label={field.name}
            type={strings.textFieldTypes.input}
            updateValue={updateFieldValue}
          ></CustomTextField>
        );
    }
  };

  const getField = (
    field: any,
    value: string,
    updateFieldValue: (value: string) => void
  ) => {
    switch (field.type) {
      case strings.fieldTypes.text:
        return (
          <CustomTextField
            editable={editable}
            initialValue={value}
            label={field.name}
            type={strings.textFieldTypes.input}
            updateValue={updateFieldValue}
          ></CustomTextField>
        );
      case strings.fieldTypes.number:
        return (
          <CustomTextField
            editable={editable}
            initialValue={value}
            label={field.name}
            type={strings.textFieldTypes.input}
            updateValue={updateFieldValue}
          ></CustomTextField>
        );
      case strings.fieldTypes.date:
        return (
          <CustomPicker
            editable={editable}
            initialValue={value}
            label={field.name}
            type="Date"
            updateValue={updateFieldValue}
          ></CustomPicker>
        );
      case strings.fieldTypes.time:
        return (
          <CustomPicker
            editable={editable}
            initialValue={value}
            label={field.name}
            type="Time"
            updateValue={updateFieldValue}
          ></CustomPicker>
        );
      case strings.fieldTypes.boolean:
        return (
          <CustomCheckbox
            updateValue={updateFieldValue}
            editable={editable}
            label={field.name}
            checked={value === "Yes"}
          />
        );
      case strings.fieldTypes.image:
        return (
          <CustomImageInput
            editable={editable}
            label={field.name}
            value={value}
          ></CustomImageInput>
        );
      default:
        return (
          <CustomTextField
            editable={editable}
            initialValue={value}
            label={field.name}
            type={strings.textFieldTypes.input}
            updateValue={updateFieldValue}
          ></CustomTextField>
        );
    }
  };

  const getInputField = (
    shouldReturnField: boolean,
    index: number,
    field: any
  ) => {
    if (shouldReturnField) {
      return (
        <div key={index} className={classes.inputContainer}>
          {getInput(field, index)}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classes.fieldContainer}>
      {isMobile || !fullWidth ? (
        <div className={classes.mobileColumn}>
          {fields.map((field, index) => {
            return getInputField(true, index, field);
          })}
        </div>
      ) : (
        <>
          <div className={classes.column}>
            {fields.map((field, index) => {
              return getInputField(isNumberEven(index), index, field);
            })}
          </div>
          <div className={classes.column}>
            {fields.map((field, index) => {
              return getInputField(!isNumberEven(index), index, field);
            })}
          </div>
        </>
      )}
    </div>
  );
};
