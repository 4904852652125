import { Button, Grid, makeStyles } from "@material-ui/core";
import { DeleteOutline, EditOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import loginThumbnail from "../../../assets/pageThumbnails/login-thumbnail.png";
import placeholderImage from "../../../assets/place-holder-image.jpg";
import {
  pageThumbnail,
  sortPagesByName,
} from "../../../helpers/wireframesHelper";
import { wireframesStrings as strings } from "../../../resources/strings";
import { deleteWireframesPage } from "../../../services/wireframeService";
import { getStyles } from "../../../styles/wireframes/modal";
import { Component } from "../../../types";
import {
  AuthenticationScreenInfo,
  Entity,
  Page,
  WireframesAuthPage,
} from "../../../types/Wireframes";
import { Mode } from "../../general/types/Modify";
import { FullscreenModal as CustomModal } from "./FullscreenModal";

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  open: boolean;
  onClose: () => void;
  pages: Page[];
  entities: Entity[];
  authInfo?: AuthenticationScreenInfo;
  moveToAuthPage: (authPage: WireframesAuthPage) => void;
  setSelectedPage: (page: Page) => void;
  currentPage?: WireframesAuthPage | Page;
  openModifyModal: (mode: Mode) => void;
  refresh: () => void;
}

export const ViewPagesModal: Component<Props> = ({
  open,
  onClose,
  pages,
  authInfo,
  moveToAuthPage,
  setSelectedPage,
  currentPage,
  openModifyModal,
  refresh,
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const authListItem = (name: string, pageType: WireframesAuthPage) => {
    const onClick = () => moveToAuthPage(pageType);
    const onDelete = () => {};
    const selected = currentPage === pageType;
    return listItem(name, false, selected, onClick, onDelete, loginThumbnail);
  };

  const editPage = (onClick: () => void) => {
    onClick();
    onClose();
    openModifyModal("update");
  };

  const addPage = () => {
    onClose();
    openModifyModal("create");
  };

  sortPagesByName(pages);

  const listItem = (
    name: string,
    showIcons: boolean,
    selected: boolean,
    onClick: () => void,
    onDelete: () => void,
    image?: string,
    index?: number
  ) => {
    return (
      <Grid item xs={2} key={index}>
        <div
          className={clsx(
            classes.listItem,
            selected && classes.selectedListItem
          )}
          onClick={() => {
            onClick();
            onClose();
          }}
        >
          <img
            className={classes.screenThumbnail}
            src={image ? image : placeholderImage}
            alt="thumbnail of wireframe screen"
          />
          <div className={classes.textContainer}>
            <p>{name}</p>
            {showIcons && (
              <div>
                <EditOutlined
                  onClick={() => editPage(onClick)}
                  fontSize="medium"
                  className={clsx(classes.icon, classes.iconEdit)}
                />
                <DeleteOutline
                  onClick={onDelete}
                  fontSize="medium"
                  className={clsx(classes.icon, classes.iconDelete)}
                  color="error"
                />
              </div>
            )}
          </div>
        </div>
      </Grid>
    );
  };

  const deletePage = async (page: Page) => {
    if (page.pageId) {
      deleteWireframesPage(id, page.pageId).then((res) => {
        refresh();
      });
    }
  };

  return (
    <CustomModal
      active={open}
      header={strings.headers.viewPagesModal}
      onCloseModal={onClose}
    >
      <>
        <Grid container spacing={3} className={classes.listContainer}>
          {authInfo?.login &&
            authListItem(strings.labels.login, WireframesAuthPage.LOGIN)}
          {authInfo?.signup &&
            authListItem(strings.labels.signup, WireframesAuthPage.SIGNUP)}
          {authInfo?.forgotPassword &&
            authListItem(
              strings.labels.forgotPassword,
              WireframesAuthPage.FORGOT_PASSWORD
            )}
          {pages.map((page, pageIndex) => {
            const onClick = () => setSelectedPage(page);
            const onDelete = () => deletePage(page);
            const selected = currentPage === page;
            const image = pageThumbnail(page);
            return listItem(
              page.name,
              true,
              selected,
              onClick,
              onDelete,
              image,
              pageIndex
            );
          })}
        </Grid>
        <Button
          variant="outlined"
          className={classes.actionButton}
          onClick={addPage}
          size="large"
        >
          {strings.labels.addNew}
        </Button>
      </>
    </CustomModal>
  );
};
