import { REQUIMENT_TENANT_TYPE_ID } from "../libs/config";
import { CognitoUser } from "../types";
import { DateHelper, stringToDate } from "./dateHelper";

export enum CookieEnum {
  FirstVisit = "first-visit",
  AwaitSurvey = "await-survey",
  SurveyComplete = "survey-complete",
  Tutorial = "tutorial",
  PreviousUpdatedPrivacyPolicy = "Requiment_Privacy_Policy", // Change to current one below when updating
  UpdatedPrivacyPolicy = "Requiment_Privacy_Policy_02_06_23", // Change to current date when updating
  WireframesFirstVisit = "wireframes-first-visit",
  TrialStart = "trial-start",
}

export type CookieType =
  | CookieEnum.FirstVisit
  | CookieEnum.AwaitSurvey
  | CookieEnum.SurveyComplete
  | CookieEnum.Tutorial
  | CookieEnum.PreviousUpdatedPrivacyPolicy
  | CookieEnum.UpdatedPrivacyPolicy
  | CookieEnum.WireframesFirstVisit
  | CookieEnum.TrialStart;

export const capitaliseString = (string?: string) => {
  return string ? string[0].toUpperCase() + string.substring(1) : "";
};

export function getUniqueArray<T>(array: T[]) {
  return Array.from(new Set(array));
}

export function hasAccessExpired(
  accessExpiration: string,
  now: Date,
  slack?: number
) {
  const accessExpirationDate = stringToDate(accessExpiration);
  const dateHelper = new DateHelper(accessExpirationDate);

  let expirationDate = accessExpirationDate;
  if (slack) {
    expirationDate = dateHelper.daysInFuture(slack);
  }

  return now > expirationDate;
}

export function redirectToExternalURL(encodedURL: string) {
  window.location.replace(encodedURL);
}

export function isRequimentTypeUser(user: CognitoUser | null) {
  return user?.tenantTypeId === REQUIMENT_TENANT_TYPE_ID;
}

export function getTruncatedStringDisplay(
  str: string | undefined,
  charLimit: number = 40
) {
  if (!str || str.length <= charLimit) {
    return str;
  }
  const truncatedString = str.slice(0, charLimit);
  return `${truncatedString}...`;
}

export function getRequiredFieldText(fieldName: string) {
  return `^${fieldName} is required`;
}

export function getCurrentSelectedMenuItem() {
  return sessionStorage.getItem("selectedMenuItem") || "0";
}

export function constructUserDisplayName(firstName: string, lastName: string) {
  return [firstName, lastName].join(" ");
}

export function isRequimentOrSubscriptionUser(user: CognitoUser | null) {
  return isRequimentTypeUser(user) || !!user?.subscriptionRef;
}
