import { Theme } from "@material-ui/core";

export const getStyles = (theme: Theme) => {
  return {
    fieldContainer: {
      display: "flex",
    },
    column: {
      display: "flex",
      flexDirection: "column" as any,
      margin: "5px",
      width: "50%",
    },
    mobileColumn: {
      display: "flex",
      flexDirection: "column" as any,
      margin: "5px",
      width: "100%",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column" as any,
      margin: "10px",
      minHeight: "50px",
    },
    inputLabel: {
      margin: "5px 0",
    },
    textField: {
      width: "100%",
      display: "flex",
      flexDirection: "column" as any,
      justifyContent: "center",
    },
    imageControl: {
      display: "flex",
      flexDirection: "column" as any,
      width: "fit-content",
      "& Button": {
        margin: "5px auto",
        maxWidth: "fit-content",
      },
      "& Label": {
        margin: "5px 0",
      },
    },
    image: {
      maxWidth: "200px",
    },
    mobileImage: {
      maxWidth: "100px",
    },
    textFieldContainer: {
      margin: "10px",
      display: "flex",
      flexDirection: "column" as any,
    },
    formLabel: { marginTop: "2px", marginBottom: "2px" },
  };
};
