import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  calculateConfig,
  getActionStyles,
  headerOptions,
  layouts,
} from "../../../helpers/layoutHelper";
import {
  appStrings,
  wireframesStrings as strings,
} from "../../../resources/strings";
import {
  addWireframesPage,
  updateWireframesPage,
} from "../../../services/wireframeService";
import { getStyles } from "../../../styles/wireframes/modal";
import { Component } from "../../../types";
import {
  Entity,
  Field,
  Layout,
  Page,
  PageSection,
} from "../../../types/Wireframes";
import { Mode } from "../../general/types/Modify";
import { FullscreenModal as CustomModal } from "./FullscreenModal";

const useStyles = makeStyles((theme) => getStyles(theme));

interface Props {
  open: boolean;
  onClose: () => void;
  currentPage?: Page;
  mode: Mode;
  entities: Entity[];
  update: (page: Page) => void;
  setLoading: (loading: boolean) => void;
  menuLength: number;
}

export const ModifyPageModal: Component<Props> = ({
  open,
  onClose,
  currentPage,
  mode,
  entities,
  update,
  setLoading,
  menuLength,
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [pageName, setPageName] = useState(currentPage?.name);
  const [pageMenuChoice, setPageMenuChoice] = useState(currentPage?.onMenu);
  const [pageLayout, setPageLayout] = useState(currentPage?.layout);
  const [content, setContent] = useState(currentPage?.content);

  useEffect(() => {
    if (mode === "create") {
      const newLayout: Layout = {
        name: "Vertical",
        config: [],
      };
      setPageName("");
      setPageMenuChoice(true);
      setPageLayout(newLayout);
      setContent([]);
    } else {
      setPageName(currentPage?.name);
      setPageMenuChoice(currentPage?.onMenu);
      setPageLayout(currentPage?.layout);
      setContent(currentPage?.content);
    }
  }, [mode, currentPage]);

  const savePage = () => {
    setLoading(true);
    const newPageData: Page = {
      name: pageName ? pageName : "",
      content: content ? content : [],
      onMenu: pageMenuChoice ? pageMenuChoice : false,
      layout: pageLayout ? pageLayout : { name: "Vertical", config: [] },
      order: pageMenuChoice
        ? currentPage?.order
          ? currentPage.order
          : menuLength
        : -1,
    };
    const onSuccess = (result: Page) => {
      update(result);
      setLoading(false);
      onClose();
    };
    if (mode === "create") {
      addWireframesPage(id, newPageData).then((res) => {
        onSuccess(res);
      });
    } else {
      newPageData.pageId = currentPage?.pageId;
      updateWireframesPage(id, newPageData).then((res) => {
        onSuccess(res);
      });
    }
  };

  const addSection = () => {
    const newSection: PageSection = {
      entityId: "",
      action: "",
    };
    content?.push(newSection);
    updateLayout(1);
  };

  const deleteSection = (sectionIndex: number) => {
    const newContent = content?.filter(
      (_value, index) => index !== sectionIndex
    );
    setContent(newContent);
    updateLayout(-1);
  };

  const updateLayout = (pageSectionCount: number) => {
    const layoutName = pageLayout ? pageLayout.name : "Custom";
    let currentPageSectionCount = pageSectionCount;
    pageLayout?.config.forEach((number) => (currentPageSectionCount += number));
    let newConfiguration = calculateConfig(currentPageSectionCount, layoutName);
    const newLayout = {
      name: layoutName,
      config: newConfiguration,
    };
    setPageLayout(newLayout);
  };

  const detailSelection = () => {
    return (
      <div className={classes.selectionContainer}>
        <h2>{strings.headers.details}</h2>
        <TextField
          onChange={(e) => setPageName(e.target.value)}
          value={pageName}
          InputLabelProps={{
            className: classes.colorGrey,
          }}
          InputProps={{ className: classes.underline }}
          label="Name"
        />
        <FormControlLabel
          color="secondary"
          control={
            <Checkbox
              className={classes.colorRequimentSecondary}
              onChange={(e) => setPageMenuChoice(e.target.checked)}
              checked={pageMenuChoice}
            />
          }
          label="Displayed On Menu?"
        />
      </div>
    );
  };

  const layoutSelection = () => {
    return (
      <div className={classes.selectionContainer}>
        <h2>{strings.headers.selectLayout}</h2>
        <div className={classes.thumbnailContainer}>
          {Object.values(layouts).map((layout: Layout, index: number) => {
            const layoutName = layout.name;
            const selected = layoutName === pageLayout?.name;
            return (
              <div key={index} className={clsx(classes.layoutContainer)}>
                <div
                  className={clsx(
                    classes.layoutThumbnail,
                    selected && classes.selectedLayoutThumbnail
                  )}
                  onClick={() => selectLayout(layoutName)}
                >
                  {layout.config.map((column: number, columnIndex: number) => {
                    return (
                      <div key={columnIndex} className={classes.column}>
                        {[...Array(column)].map((row, rowIndex) => {
                          return (
                            <div
                              key={rowIndex}
                              className={classes.layoutSection}
                            />
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <h3>{layoutName}</h3>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const selectLayout = (layoutName: string) => {
    let currentPageSectionCount = 0;
    pageLayout?.config.forEach((number) => (currentPageSectionCount += number));
    let newConfiguration = calculateConfig(currentPageSectionCount, layoutName);
    const newLayout = {
      name: layoutName,
      config: newConfiguration,
    };
    setPageLayout(newLayout);
  };

  const getActions = (section: PageSection) => {
    const entity = entities.find((entity) => entity.id === section.entityId);
    return entity ? entity.actions : [];
  };

  const updateSection = (
    value: any,
    section: PageSection,
    sectionIndex: number,
    key: keyof PageSection
  ) => {
    const newSection = section;
    newSection[key] = value;
    const newContent = content ? content.slice() : [];
    newContent[sectionIndex] = newSection;
    setContent(newContent);
  };

  const styleSelection = (section: PageSection, sectionIndex: number) => {
    const styles = getActionStyles(section.action);
    if (styles.length > 0) {
      return (
        <div className={classes.contentSelectionOptions}>
          <FormControl>
            <FormLabel
              className={clsx(classes.colorGrey, classes.controlLabel)}
            >
              {strings.labels.style}
            </FormLabel>
            <RadioGroup
              style={{ flexDirection: "row" }}
              value={section.style ? section.style : styles[0]}
              onChange={(e) =>
                updateSection(
                  e.target.value as string,
                  section,
                  sectionIndex,
                  "style"
                )
              }
            >
              {styles.map((style: string, index: number) => (
                <FormControlLabel
                  key={index}
                  value={style}
                  control={
                    <Radio className={classes.colorRequimentSecondary} />
                  }
                  label={style}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      );
    } else {
      return null;
    }
  };

  const fieldSelection = (section: PageSection, sectionIndex: number) => {
    const entity = entities.find((entity) => entity.id === section.entityId);
    const fields = entity ? entity.fields : [];
    const selectedFields = section.selectedFields
      ? section.selectedFields
      : fields.map((field) => field.id);
    return (
      <div className={classes.contentSelectionOptions}>
        <FormControl>
          <FormLabel className={clsx(classes.colorGrey, classes.controlLabel)}>
            {strings.labels.fields}
          </FormLabel>
          <FormGroup style={{ flexDirection: "row" }}>
            {fields.map((field: Field, index: number) => (
              <FormControlLabel
                key={index}
                value={field.id}
                control={
                  <Checkbox className={classes.colorRequimentSecondary} />
                }
                label={field.name}
                checked={selectedFields.includes(field.id)}
                onChange={(event, checked) => {
                  if (checked) {
                    selectedFields.push(field.id);
                  } else {
                    const index = selectedFields.indexOf(field.id, 0);
                    selectedFields.splice(index, 1);
                  }
                  updateSection(
                    selectedFields,
                    section,
                    sectionIndex,
                    "selectedFields"
                  );
                }}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
    );
  };

  const headerSelection = (section: PageSection, sectionIndex: number) => {
    const headerType = section?.headerType
      ? section.headerType
      : strings.headerType.titleAndButtons;
    return (
      <div className={classes.contentSelectionOptions}>
        <FormControl color="secondary" className={classes.sectionDropdown}>
          <InputLabel
            className={clsx(classes.colorGrey, classes.headerTypeLabel)}
          >
            {strings.labels.headerType}
          </InputLabel>
          <Select
            className={classes.headerTypeDropdown}
            value={headerType}
            label="Header Type"
            onChange={(e) => {
              updateSection(
                e.target.value as string,
                section,
                sectionIndex,
                "headerType"
              );
            }}
          >
            {headerOptions.map((headerOption: string, index: number) => (
              <MenuItem key={index} value={headerOption}>
                {headerOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const contentSelection = () => {
    let sectionCount = 0;
    return (
      <>
        <h2>{strings.headers.selectContent}</h2>
        <div className={classes.contentContainer}>
          {content &&
            pageLayout?.config.map((column: number, columnIndex: number) => {
              return (
                <div key={columnIndex} className={classes.contentColumn}>
                  {[...Array(column)].map((_, index) => {
                    const section = content[sectionCount];
                    const sectionIndex = sectionCount;
                    sectionCount++;
                    return (
                      <div key={index} className={classes.contentSection}>
                        <div className={classes.contentSectionHeader}>
                          <h3>{"Section " + sectionCount}</h3>
                          <DeleteOutline
                            color="error"
                            onClick={() => deleteSection(sectionIndex)}
                          />
                        </div>
                        <div>
                          <FormControl
                            color="secondary"
                            className={classes.sectionDropdown}
                          >
                            <InputLabel
                              className={clsx(
                                classes.colorGrey,
                                classes.controlLabel
                              )}
                            >
                              {strings.labels.entity}
                            </InputLabel>
                            <Select
                              value={section?.entityId}
                              label="Entity"
                              onChange={(e) => {
                                updateSection(
                                  e.target.value as string,
                                  section,
                                  sectionIndex,
                                  "entityId"
                                );
                              }}
                            >
                              {entities
                                .filter((filter) => filter.active)
                                .map((entity, index) => (
                                  <MenuItem key={index} value={entity.id}>
                                    {entity.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            color="secondary"
                            className={classes.sectionDropdown}
                          >
                            <InputLabel className={classes.colorGrey}>
                              {strings.labels.action}
                            </InputLabel>
                            <Select
                              value={section?.action}
                              label="Action"
                              onChange={(e) => {
                                updateSection(
                                  e.target.value as string,
                                  section,
                                  sectionIndex,
                                  "action"
                                );
                              }}
                            >
                              {section &&
                                getActions(section).map((action, index) => (
                                  <MenuItem key={index} value={action}>
                                    {action}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {styleSelection(section, sectionIndex)}
                          {fieldSelection(section, sectionIndex)}
                          {headerSelection(section, sectionIndex)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </>
    );
  };

  return (
    <CustomModal
      active={open}
      header={
        mode === "create"
          ? strings.headers.createPage
          : strings.headers.editPage
      }
      onCloseModal={onClose}
    >
      <>
        <div className={classes.topContainer}>
          {detailSelection()}
          {layoutSelection()}
        </div>
        {contentSelection()}
        <Button
          variant="outlined"
          className={classes.actionButton}
          onClick={addSection}
          size="large"
        >
          {strings.labels.addSection}
        </Button>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            className={classes.actionButton}
            onClick={onClose}
            size="large"
          >
            {appStrings.labels.cancel}
          </Button>
          <Button
            variant="contained"
            className={clsx(classes.containedActionButton, classes.saveButton)}
            onClick={savePage}
            size="large"
          >
            {appStrings.labels.save}
          </Button>
        </div>
      </>
    </CustomModal>
  );
};
