import type { Validator } from "./Validator";

type Options = {
  message: string;
  fieldNames: string[]; // TODO: can this be made `keyof something`?
  fieldValues: unknown[];
};

export const prerequisites: Validator<Options> = (
  value,
  options,
  _key,
  attributes
) => {
  const messages: string[] = [];

  options.fieldNames.forEach((attributeName, index) => {
    const expectedValue: unknown[] = [];
    const actualValue = attributes[attributeName.trim()];

    const fieldValue = options.fieldValues[index];
    if (Array.isArray(fieldValue)) {
      expectedValue.push(...fieldValue);
    } else {
      expectedValue.push(fieldValue);
    }

    const valueMatchFound = expectedValue.some(
      (element) => element === actualValue
    );
    if (valueMatchFound && !value) {
      messages.push(options.message);
    }
  });

  if (options.fieldNames.length === messages.length) return messages;
};

export type { Options as PrerequisitesOptions };
