import { makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import { colours, fonts } from "../../../styles/theme";
import { Component, ModalProps } from "../../../types";
import "./Modal.css";
import { ModalButtons } from "./ModalButtons";
import { extractButtonConfigOfType } from "./modalHelper";

const useStyles = makeStyles((theme) => {
  return {
    buttonRoot: {
      fontFamily: fonts.primary,
      textTransform: "none",
      fontSize: "15px",
    },
    buttonDisabled: {
      backgroundColor: theme.palette.secondary.main + " !important",
      color: "#fff",
      opacity: 0.5,
    },
    headerText: {
      marginBottom: "0",
    },
    exitButton: {
      "&:hover": {
        opacity: "0.4",
        cursor: "pointer",
      },
      color: colours.whiteColour,
      fontSize: "20px",
      right: "0.9vw",
      position: "absolute",
    },
  };
});

export const Modal: Component<ModalProps> = ({
  active,
  closeButton = true,
  buttonConfiguration,
  onCloseModal,
  children,
  header,
  modalClassName,
  outerModalClassName,
}) => {
  const classes = useStyles();

  const { onClick: onClickCancel } = extractButtonConfigOfType(
    "cancel",
    buttonConfiguration
  );
  const onClose = onCloseModal ?? onClickCancel;

  var content = (
    <div className="modal-content">
      <div>
        <span className="modal-header-req">
          <p className={classes.headerText}>{header}</p>
          {closeButton && (
            <Close
              data-testid="closeModal"
              className={classes.exitButton}
              onClick={onClose}
            />
          )}
        </span>
      </div>
      <div className="main-content">{children}</div>
      {buttonConfiguration && (
        <ModalButtons buttonConfiguration={buttonConfiguration} />
      )}
    </div>
  );
  return active ? (
    <div
      aria-label="outer-modal"
      className={clsx("req-backgroundStyle", outerModalClassName)}
    >
      <div className={clsx("req-modalStyle", modalClassName)}>{content}</div>
    </div>
  ) : (
    <></>
  );
};
