import { createCtx } from "../hooks/createCtx";
import { WireframesScreenSize } from "../types/Wireframes";

type ScreenSizeContext = {
  screenSize: WireframesScreenSize;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export const getScreenSizeContextValues = (
  screenSize: WireframesScreenSize
) => {
  return {
    screenSize: screenSize,
    isMobile: screenSize === WireframesScreenSize.MOBILE,
    isTablet: screenSize === WireframesScreenSize.TABLET,
    isDesktop: screenSize === WireframesScreenSize.DESKTOP,
  };
};

export const [useScreenSizeContext, ScreenSizeContextProvider] =
  createCtx<ScreenSizeContext>();
