import type { Axios } from "../libs/axios";
import axios from "../libs/axios/axios-permissions";
import { Permissions } from "../types";
import { getErrorMessage } from "../helpers/errorHelper";

type Response = {
  userPermissions: Permissions;
};

export const getUserPermissions =
  (cancelToken: Axios.CancelToken) => async () => {
    try {
      const config = {
        cancelToken,
      };
      const { data } = await axios.get<Response>(``, config);
      return Promise.resolve(data.userPermissions);
    } catch (e) {
      console.log(e);
      return Promise.reject(getErrorMessage(e));
    }
  };
