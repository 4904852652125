import { useCallback, useState } from "react";
import { EventTargetValues } from "../../types";

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

export const useForm = <T extends object>(formData: T) => {
  const [data, setData] = useState<T>(formData);

  const getChangeHandler = useCallback((eventValue: EventTargetValues = "value") => (e: ChangeEvent) => {
    const { name } = e.target;
    const value = e.target[eventValue];
    setData((currentData) => ({
      ...currentData,
      [name]: value,
    }));
  }, []);

  return [data, getChangeHandler] as const;
};
