import { Component } from "../../types";
import { AppBar } from "../layout/MenuComponent";

interface Props {
  loggedOut?: boolean;
}

export const PrivacyPolicy: Component<Props> = ({ loggedOut = true }) => {
  return (
    <div>
      {loggedOut && <AppBar />}
      <div id="privacy" className="terms-privacy">
        <div className="terms-header">Privacy Policy: Requiment</div>
        <div className="sub">
          Fieldimp Limited (“we”) are committed to protecting and respecting
          your privacy.
        </div>
        <div className="terms-header">Scope Of Policy</div>
        <div className="sub">
          This policy (together with our terms of service as set out at
          app.requiment.com/terms-of-service.html (“Agreement”) and any
          additional terms of use incorporated by reference into the Agreement)
          applies to your use of:
          <ul>
            <li>
              The 'Requiment' software which you have selected to download from
              either an app store or our website at www.requiment.com
              (“Service”), once you have downloaded or streamed a copy of the
              Service onto your mobile telephone, handheld device or computer
              (device).
            </li>
            <li>Any of the services accessible through the Services.</li>
          </ul>
          This policy sets out the basis on which any personal data we collect
          from you, or that you provide to us, will be processed by us. The
          reason for our processing of Personal Data will be to allow us to
          fulfil our contractual obligations, to carry out the processing you
          have consented to, and to make improvements to our service provision
          and processes. Please read the following carefully to understand our
          views and practices regarding your personal data and how we will treat
          it. We are committed to complying with the relevant provisions of data
          protection laws including but not limited to the Data Protection Act
          2018, any applicable terms of the Data Protection Directive (95/46/EC)
          and the General Data Protection Regulation (EU 679/2016) “Data
          Protection Laws”. For the purpose of the Data Protection Act 2018 ,
          the data controller is Fieldimp Limited of 5 Eagle Street, Craighall
          Business Park, Glasgow, G4 9XA.
        </div>

        <div className="terms-header">Information We May Collect From You</div>
        <div className="sub">
          We may collect and process the following data about you:
          <ul>
            <li>
              Submitted Information: information that you provide by registering
              for the Service, including the telephone number or IP address of
              your device. This includes information provided at the time of
              registering to use the Services, posting material or requesting
              further services. We may also ask you for information when you
              enter a competition or promotion sponsored by us and any of our
              partners and when you report a problem with the Services.
            </li>
            <li>Additional information:</li>
            <li>
              if you contact us, we may keep a record of that correspondence;
            </li>
            <li>
              we may also ask you to complete surveys that we use for research
              purposes, although you do not have to respond to them;
            </li>
            <li>details of transactions you carry out through the Service;</li>
            <li>
              details of your use of the Service including, but not limited to,
              traffic data, location data, weblogs and other communication data,
              and the resources that you access.
            </li>
            <li>
              Device information: We may collect information about the device or
              any computer you may use to download or stream a copy of the
              Service onto your device, including, where available, the device's
              or computer's unique device identifiers, operating system, browser
              type and mobile network information as well as the device's
              telephone number, for system administration and to report
              aggregate information to our advertisers. We may associate device
              information with Submitted Information and will treat the combined
              information as personal data in accordance with this policy for as
              long as it is combined.
            </li>
            <li>
              Location information: when you use one of our location-enabled
              Services, we may collect and process information about your actual
              location. Some of these Services require your personal data for
              the feature to work. If you wish to use the particular feature,
              you will be asked to consent to your data being used for this
              purpose. You can withdraw your consent at any time by following
              the directions available on the settings for the Service.
            </li>
            <li>
              Log information: when you use the Services we may automatically
              collect and store certain information in server logs, including
              but not limited to internet protocol (IP) addresses, internet
              service provider (ISP), clickstream data, browser type and
              language, viewed and exit pages and date or time stamps.
            </li>
            <li>
              Unique application numbers: when you install or uninstall parts of
              the Service containing a unique application number or when the
              Service searches for automatic updates, that number and
              information about your installation, for example, the type of
              operating system, may be sent to us.
            </li>
            <li>
              Customer information: we may also collect personal information
              about your customers when you use the Service. We will only use
              this information in an anonymous and aggregated format.
            </li>
          </ul>
        </div>
        <div className="terms-header">Cookies</div>
        <div className="sub">
          Our website uses cookies to distinguish you from other users. This
          helps us to provide you with a good experience when you use our
          services and also allows us to improve those services.
        </div>

        <div className="terms-header">Where We Store Your Personal Data</div>
        <div className="sub">
          The data that we collect from you may be transferred to, and stored
          at, a destination outside the European Economic Area (EEA). It may
          also be processed by staff operating outside the EEA who work for us
          or for one of our suppliers or third party partners. These staff may
          be engaged in the fulfilment of your request, order or reservation,
          the improvement of our processes and technology, the processing of
          your payment details and the provision of support services. By
          submitting your personal data, you agree to this transfer, storing or
          processing. We will take all steps reasonably necessary to ensure that
          your data is treated securely and in accordance with this privacy
          policy. All information you provide to us is stored on our secure
          servers or those of our third party partners. Any payment transactions
          carried out by us or our chosen third-party providers will be
          encrypted using Secured Sockets Layer technology. Where we have given
          you (or where you have chosen) a password that enables you to access
          certain parts of the Service, you are responsible for keeping this
          password confidential. We ask you not to share a password with anyone.
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your data
          transmitted to or using the Service; any transmission is at your own
          risk. Once we have received your information, we will use strict
          procedures and security features to try to prevent unauthorised
          access.
        </div>
        <div className="terms-header">Uses Made Of The Information</div>
        <div className="sub">
          We may use information held about you in the following ways:
          <ul>
            <li>
              to enable us to supply you with the services and information which
              you have requested;
            </li>
            <li>
              to enable us to improve our technology (including AI technology)
              and improve our service provision to you;
            </li>
            <li>
              to run demonstrations and simulations with our technology to
              improve its capability and performance;
            </li>
            <li>
              to analyse the information we collect from you in order that we
              can examine trends and patterns in the information and improve our
              services and offerings;{" "}
            </li>
            <li>
              to ensure that content from our Website is presented in the most
              effective manner for you and for your computer;
            </li>
            <li>
              to notify you about changes or to contact you for your views on
              our services;
            </li>
            <li>to contact you for market research purposes; and</li>
            <li>for our internal record keeping.</li>
          </ul>
          We may also use your data to provide you with information about goods
          and services that may be of interest to you. We may contact you about
          these by post or telephone, in accordance with our obligations under
          Data Protection Laws. We will only share your data with third parties
          where you have consented to this. If you are an existing Service user,
          we will only contact you by electronic means (e-mail or SMS) with
          information about goods and services similar to those that you have
          used previously. If you are a new customer we will contact you by
          electronic means only if you have consented to this. If you do not
          want us to use your data in this way, please contact us at
          info@fieldimp.com.
        </div>
        <div className="terms-header">Disclosure Of Your Information</div>
        <div className="sub">
          We may disclose your personal information to third parties:
          <ul>
            <li>
              In the event that we sell or buy any business or assets, in which
              case we may disclose your personal data to the prospective seller
              or buyer of such business or assets.
            </li>
            <li>
              If Fieldimp Limited or substantially all of its assets are
              acquired by a third party, in which case personal data held by it
              about its customers will be one of the transferred assets.
            </li>
            <li>
              If we are under a duty to disclose or share your personal data in
              order to comply with any legal or regulatory obligation or
              request.
            </li>
          </ul>
          In order to:
          <ul>
            <li>
              enforce or apply the Agreement and other agreements or to
              investigate potential breaches; or
            </li>
            <li>
              protect the rights, property or safety of Fieldimp Limited, our
              customers, or others. This includes exchanging information with
              other companies and organisations for the purposes of fraud
              protection and credit risk reduction.
            </li>
          </ul>
        </div>

        <div className="terms-header">Your Rights</div>
        <div className="sub">
          You have the right to ask us not to process your personal data for
          marketing purposes. We will usually inform you (before collecting your
          data) if we intend to use your data for such purposes or if we intend
          to disclose your information to any third party for such purposes. You
          can exercise your right to prevent such processing by checking certain
          boxes when we collect your data. You can also exercise the right at
          any time by contacting us at info@fieldimp.com. Data Protection Laws
          are introducing new rights for data subjects including certain rights
          to object to holding and processing of personal data beyond that used
          for marketing purposes. If you have any queries about the use of your
          data that don’t relate to marketing activity, then please contact
          info@fieldimp.com. Our Sites may, from time to time, contain links to
          and from the websites of our partner networks, advertisers and
          affiliates. If you follow a link to any of these websites, please note
          that these websites and any services that may be accessible through
          them have their own privacy policies and that we do not accept any
          responsibility or liability for these policies or for any personal
          data that may be collected through these websites or services, such as
          contact and location data. Please check these policies before you
          submit any personal data to these websites or use these services.
        </div>
        <div className="terms-header">Access To Information</div>
        <div className="sub">
          Data Protection Laws give you the right to access information held
          about you. Your right of access can be exercised in accordance with
          those laws.
        </div>

        <div className="terms-header">Changes To Privacy Policy</div>
        <div className="sub">
          Any changes we may make to our privacy policy in the future will be
          posted on this page and, where appropriate, notified to you when you
          next log onto the Services. The new terms may be displayed on-screen
          and you may be required to read and accept them to continue your use
          of the Services.
        </div>

        <div className="terms-header">Contact</div>
        <div className="sub">
          Questions, comments and requests regarding this privacy policy are
          welcomed and should be addressed to info@fieldimp.com
        </div>
      </div>
    </div>
  );
};
