import clsx from "clsx";
import { useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import { colours, fonts } from "../../styles/theme";
import { Component } from "../../types";
import { Label } from "../general/Form/Label";
import { InsertTagSelector } from "./InsertTagSelector";

const tagColour = "#e0dcdc";

interface Props {
  inputData: string;
  id: string;
  labelText: string;
  onChange: (event: any) => void;
  tagSelectorOptions: { label: string; value: string }[];
  defaultHtml?: string;
  contentEditableClassName?: string;
}

export const TagEditor: Component<Props> = ({
  inputData,
  id,
  labelText,
  onChange,
  tagSelectorOptions,
  defaultHtml = "<br>",
  contentEditableClassName,
}) => {
  const initialHtml = inputData ? inputData : defaultHtml;

  const [html, setHtml] = useState(initialHtml);
  const [lastEditRange, setLastEditRange] = useState<Range | undefined>();
  const contentEditable = useRef(null);

  const getEditor = () => document.getElementById(id);

  const handleChange = (event: any) => {
    onChange(event);
    setHtml(event.target.value);
  };

  const onClick = (tagText: string) => {
    const editor = getEditor();
    if (editor) {
      const tag = createTagElement(tagText);
      insertTagAtCurrentSelection(editor, tag, lastEditRange);
    }

    const updatedHtml = getEditor()?.innerHTML ?? html;
    setHtml(updatedHtml);
  };

  const saveRange = () => {
    let selection = getSelection();
    const editRange = selection?.getRangeAt(0);
    setLastEditRange(editRange);
  };

  return (
    <div aria-label="tag-editor">
      <span className="inputHeader">
        <Label text={labelText} />
        <InsertTagSelector
          options={tagSelectorOptions}
          onClick={onClick}
          className="tag-selector"
        />
      </span>
      <ContentEditable
        aria-label="content-editable"
        id={id}
        className={clsx(
          "input-default",
          "editable",
          "input-padding",
          "tagEditor",
          contentEditableClassName && contentEditableClassName
        )}
        innerRef={contentEditable}
        html={html}
        onChange={handleChange}
        onClick={saveRange}
        onKeyUp={saveRange}
      />
    </div>
  );
};

function insertTagAtCurrentSelection(
  editorElement: HTMLElement,
  tag: HTMLElement,
  lastEditRange: Range | undefined
) {
  let selection,
    range,
    el = editorElement;

  const isSafari = navigator.userAgent.indexOf("Safari") !== -1;

  if (window.getSelection) {
    selection = window.getSelection();

    if (!selection || !selection.rangeCount || !lastEditRange) {
      return;
    }

    if (isSafari) {
      selection.removeAllRanges();
      selection.addRange(lastEditRange);
    }

    for (var i = 0; i < selection.rangeCount; ++i) {
      if (!isOrContains(selection.getRangeAt(i).commonAncestorContainer, el)) {
        return;
      }
    }

    range = isSafari ? lastEditRange.cloneRange() : selection.getRangeAt(0);

    range.deleteContents();
    range.insertNode(tag);
    // move the cursor
    range.setStartAfter(tag);
    range.setEndAfter(tag);
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

function isOrContains(node: Node | null, container: HTMLElement) {
  while (node) {
    if (node === container) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

function createTagElement(tagText: string) {
  const tag = document.createElement("mark");
  tag.setAttribute("style", getTagStyle());
  tag.innerHTML = ` ${tagText} `;
  tag.contentEditable = "false";
  return tag;
}

function getTagStyle() {
  return `background-color: ${tagColour}; font-family: ${fonts.secondary}; font-size: 15px; color: ${colours.darkGreyColour}`;
}
